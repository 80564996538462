import { useTranslation } from "react-i18next";
import { Namespaces } from "i18n";
import { usePulseResultStyles } from "./styles/PulseResult.styles";
import StageStepFrame from "components/StageStepFrame/StageStepFrame";
import StageStepButton from "components/StageStepButton/StageStepButton";
import GBSpin from "components/GBSpin/GBSpin";
import StageStepContainer from "components/StageStepContainer/StageStepContainer";
import StageStepText from "components/StageStepText/StageStepText";
import PulseResultField from "components/PulseResultField/PulseResultField";
import { pulseReportField, pulseReport } from "constants/purposeScoreTypes";
import GBModal from "components/GBModal/GBModal";
import { useState } from "react";

const titles = {
  q1_title: "Unhealthy",
  q2_title: "Struggling",
  q3_title: "Coping",
  q4_title: "Healthy",
};

const pulseResultHeaders = [
  "Stress Response",
  "Energy Efficiency",
  "Alignment at Work",
  "Life Alignment",
  "Personal Fulfillment",
  "Lasting Impact",
];

type PulseResultViewProps = {
  pulseVal?: pulseReport;
  isFetching: boolean;
  isFetchError?: boolean;
};

export const pulseReportFields = Object.values(pulseReportField);

function PulseResult(props: PulseResultViewProps) {
  const { pulseVal, isFetching } = props;
  const [isMessage, setIsMessage] = useState<boolean>(false);
  const { t } = useTranslation([
    Namespaces.Score,
    Namespaces.Survey,
    Namespaces.Common,
  ]);

  const classes = usePulseResultStyles();
  let nextUrl: string = "/score";

  let titleCount = 0;

  return (
    <>
      <StageStepFrame
        title={t("pulse.result.title")}
        controls={[
          <StageStepButton.Next onClick={() => setIsMessage(true)}>
            {t(`${Namespaces.Score}:pulse.result.buttons.finish`)}
          </StageStepButton.Next>,
        ]}
      >
        <div className={classes.subtitle}>{t("pulse.result.subtitle")}</div>
        <StageStepText className={classes.text}>
          {t("pulse.result.description")}
        </StageStepText>
        <GBSpin spinning={isFetching}>
          <StageStepContainer>
            <div className={classes.flexBlock}>
              {pulseVal &&
                pulseReportFields.map((i, k) => (
                  <div key={i}>
                    {k % 4 === 0 ? (
                      <div className={classes.factorTitle}>
                        {pulseResultHeaders[titleCount++]}
                      </div>
                    ) : null}
                    <PulseResultField
                      key={i}
                      name={pulseVal[i].title}
                      description={pulseVal[i].description}
                      q1_title={titles.q1_title}
                      q2_title={titles.q2_title}
                      q3_title={titles.q3_title}
                      q4_title={titles.q4_title}
                      value={(pulseVal[i].score - 4) * 5}
                      error={false}
                    />
                  </div>
                ))}
            </div>
          </StageStepContainer>
        </GBSpin>
      </StageStepFrame>
      <GBModal
        open={isMessage}
        title="Congratulations!"
        size="medium"
        withTitleDivider
      >
        <p>
          Congratulations on completing Purpose Pulse. You should receive a copy
          of your results via email that you can revisit.
          <br />
          <br />
          You can now close this window.
        </p>
      </GBModal>
    </>
  );
}
export default PulseResult;
