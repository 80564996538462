import ErrorText from "components/ErrorText/ErrorText";
import { Namespaces } from "i18n";
import { useTranslation } from "react-i18next";

type ApiErrorProps = {
  errorKey: string;
  i18nKey?: string;
  className?: string;
};

function ApiErrorKey(props: ApiErrorProps) {
  const { errorKey, i18nKey, className } = props;
  const { t } = useTranslation(Namespaces.Common);

  return (
    <ErrorText
      className={className}
      text={t([
        i18nKey || "",
        `${Namespaces.Common}:api_errors.${errorKey}`,
        `${Namespaces.Common}:errors.unexpected_error`,
      ])}
    />
  );
}

export default ApiErrorKey;
