import { makeStyles, Theme } from "@material-ui/core/styles";

const useImpactListStyles = makeStyles((theme: Theme) => ({
  impactList: {
    display: "flex",
    flexDirection: "column",
    width: "410px",
    [theme.breakpoints.down(675)]: {
      margin: "0 auto",
    },
    [theme.breakpoints.down(500)]: {
      width: "100%",
    },
  },
}));

export { useImpactListStyles };
