import { makeStyles, Theme } from "@material-ui/core/styles";
import { commonColors } from "constants/themeSettings";

const useSnapshotSectionLabelStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    borderTop: "2px solid #F1F0F2",
    overflow: "hidden",
    height: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "48px",
  },
  label: {
    width: "280px",
    height: "96px",
    marginTop: "-48px",
    borderRadius: "20px",
    textAlign: "center",
    paddingTop: "56px",
    fontSize: "24px",
    lineHeight: "32px",
    color: "#fff",
    fontWeight: 700,
    textTransform: "uppercase",
    [theme.breakpoints.down("xs")]: {
      width: "220px",
    },
  },
  yellow: {
    backgroundColor: commonColors.yellow,
  },
  gray: {
    backgroundColor: commonColors.gray,
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
  },
  black: {
    backgroundColor: commonColors.black,
  },
  green: {
    backgroundColor: "#4DAB03",
  },
}));

export { useSnapshotSectionLabelStyles };
