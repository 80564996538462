import { combineReducers } from "redux";
import { auth } from "./auth/reducers";
import { contactUsModal } from "./contactUsModal/reducers";
import { authModal } from "./authModal/reducers";
const rootReducer = combineReducers({
  auth,
  contactUsModal,
  authModal,
});

export default rootReducer;
