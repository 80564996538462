import { Typography } from "@material-ui/core";
import cx from "classnames";
import { useGBDescriptionStyles } from "./styles/GBDescription.styles";

type GBDescriptionProps = {
  title?: string | JSX.Element;
  text: string | JSX.Element;
  className?: string;
  titleClassName?: string;
  textClassName?: string;
  fullWidth?: boolean;
};

function GBDescription(props: GBDescriptionProps) {
  const { title, text, className, titleClassName, textClassName, fullWidth } =
    props;

  const classes = useGBDescriptionStyles();

  const rootClasses = cx(
    classes.root,
    {
      [classes.fullWidth]: fullWidth,
    },
    className,
  );
  const titleClasses = cx(classes.title, titleClassName);
  const textClasses = cx(classes.text, textClassName);

  return (
    <div className={rootClasses}>
      {title && (
        <Typography component="h4" variant="h4" className={titleClasses}>
          {title}
        </Typography>
      )}
      <div className={textClasses}>{text}</div>
    </div>
  );
}

export default GBDescription;
