import { makeStyles, Theme } from "@material-ui/core/styles";

const useDnaResultStyles = makeStyles<Theme, {}>((theme: any) => ({
  subtitle: {
    ...theme.typography.surveySubtitle,
    padding: "10px 0",
    whiteSpace: "pre-line",
  },
  flexBlock: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  list: {
    marginTop: "32px",

    "&:last-child": {
      marginTop: "56px",
    },
  },
}));
export { useDnaResultStyles };
