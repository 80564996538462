import { Typography } from "@material-ui/core";
import cx from "classnames";
import { useStageStepTitleStyles } from "./styles/StageStepTitle.styles";

type StageStepTitleProps = {
  children: string;
  className?: string;
  gutter?: "16px";
  color?: "blue" | "red";
};

function StageStepTitle(props: StageStepTitleProps) {
  const { children, className, gutter, color } = props;
  const classes = useStageStepTitleStyles();

  const rootClasses = cx(
    classes.root,
    {
      [classes.gutter16]: gutter === "16px",
      [classes.red]: color === "red",
      [classes.blue]: color === "blue",
    },
    className,
  );

  return (
    <Typography variant="h4" component="h4" className={rootClasses}>
      {children}
    </Typography>
  );
}

export default StageStepTitle;
