import FormRow from "components/FormRow/FormRow";
import GBInputErrorText from "components/GBInputErrorText/GBInputErrorText";
import GBOutlinedInput from "components/GBOutlinedInput/GBOutlinedInput";
import GBStaticInputLabel from "components/GBStaticInputLabel/GBStaticInputLabel";
import { ValidationRule } from "constants/validationRules";
import { FormikProps } from "formik";
import { Namespaces } from "i18n";
import { useTranslation } from "react-i18next";
import { ImpactPurposePhilanthropyFormValues } from "../ImpactPurposePhilanthropy.types";

type PhilanthropyFormProps =
  FormikProps<ImpactPurposePhilanthropyFormValues> & {};

function PhilanthropyForm(props: PhilanthropyFormProps) {
  const { values, handleBlur, handleChange, handleSubmit, touched, errors } =
    props;

  const { t } = useTranslation(Namespaces.Impact);

  const hasError = (
    name: keyof ImpactPurposePhilanthropyFormValues,
  ): boolean => {
    return !!(touched[name] && errors[name]);
  };

  const getError = (
    field: keyof ImpactPurposePhilanthropyFormValues,
    errorCode: ValidationRule,
  ) => {
    return t([
      `purpose.philanthropy.form.${field}.errors.${errorCode}`,
      `${Namespaces.Common}:errors.${errorCode}`,
    ]);
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormRow>
        <GBStaticInputLabel
          labelFor="peopleHelp"
          variant="thin"
          text={t("purpose.philanthropy.form.peopleHelp.label")}
        />
        <GBOutlinedInput
          fullWidth
          id="peopleHelp"
          name="peopleHelp"
          type="text"
          value={values.peopleHelp}
          onChange={handleChange}
          onBlur={handleBlur}
          error={hasError("peopleHelp")}
        />
        {hasError("peopleHelp") && (
          <GBInputErrorText
            text={getError("peopleHelp", errors.peopleHelp as ValidationRule)}
          />
        )}
      </FormRow>
      <FormRow>
        <GBStaticInputLabel
          labelFor="helpReason"
          variant="thin"
          text={t("purpose.philanthropy.form.helpReason.label")}
        />
        <GBOutlinedInput
          fullWidth
          id="helpReason"
          name="helpReason"
          type="text"
          value={values.helpReason}
          onChange={handleChange}
          onBlur={handleBlur}
          error={hasError("helpReason")}
        />
        {hasError("helpReason") && (
          <GBInputErrorText
            text={getError("helpReason", errors.helpReason as ValidationRule)}
          />
        )}
      </FormRow>
    </form>
  );
}

export default PhilanthropyForm;
