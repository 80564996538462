import ErrorText from "components/ErrorText/ErrorText";
import { Namespaces } from "i18n";
import { useTranslation } from "react-i18next";

type UnexpectedErrorProps = {
  className?: string;
};

function UnexpectedError(props: UnexpectedErrorProps) {
  const { className } = props;
  const { t } = useTranslation(Namespaces.Common);
  return (
    <ErrorText
      className={className}
      text={t(`${Namespaces.Common}:errors.unexpected_error`)}
    />
  );
}

export default UnexpectedError;
