import { makeStyles, Theme } from "@material-ui/core/styles";

const useAdminLayoutStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: "60px",
  },
  aside: {
    "& button": {
      marginBottom: "16px",
      justifyContent: "flex-start",
      paddingLeft: "30px",
      paddingRight: "5px",
      fontSize: "16px",
      "& .MuiSvgIcon-root": {
        fontSize: "20px",
      },
      "&:last-child": {
        marginBottom: "0",
      },
    },
  },
  content: {
    paddingLeft: "50px",
  },
}));

export { useAdminLayoutStyles };
