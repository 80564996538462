import { makeStyles } from "@material-ui/core/styles";
import { borderRadiuses, textColors } from "constants/themeSettings";

const useGBDescriptionStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#F8F8F8",
    borderRadius: borderRadiuses.round,
    padding: "16px 24px",
  },
  title: {
    marginBottom: "16px",
  },
  text: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 400,
    color: textColors.black.light,
  },
  fullWidth: {
    width: "100%",
  },
}));

export { useGBDescriptionStyles };
