import { GrowComfortBeginI18nKey } from "constants/grow/growI18nKeys";
import { GrowResponsePayload } from "types/grow/GetGrowResponsePayload";
import { GrowSituation } from "types/grow/GrowSituation";
import { getSituationValue } from "../Grow.utils";
import { ComfortBeginFormValues } from "./GrowComfortBegin.types";

export const prepareInitialValues = (
  grow: GrowResponsePayload | undefined,
): ComfortBeginFormValues => {
  const comforts = grow?.situations?.comfort1;
  return {
    comfort1: getSituationValue(GrowComfortBeginI18nKey.Comfort1, comforts),
    comfort2: getSituationValue(GrowComfortBeginI18nKey.Comfort2, comforts),
    comfort3: getSituationValue(GrowComfortBeginI18nKey.Comfort3, comforts),
    comfort4: getSituationValue(GrowComfortBeginI18nKey.Comfort4, comforts),
    comfort5: getSituationValue(GrowComfortBeginI18nKey.Comfort5, comforts),
  };
};

export const prepareComfortToSubmit = (
  data: ComfortBeginFormValues,
): GrowSituation<GrowComfortBeginI18nKey>[] => {
  const entries = Object.entries(data);
  return entries.map((e) => ({
    key: e[0] as GrowComfortBeginI18nKey,
    value: e[1],
  }));
};
