import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { jsonHeaders } from "constants/api/jsonHeaders";
import { redirectRoutes } from "constants/redirectRoutes";
import { API_ENDPOINT } from "constants/settings";
import { ApiResponseType } from "types/api/ApiResponseType";
import { authToken } from "utils/authToken";

export const API = axios.create({
  baseURL: API_ENDPOINT,
  headers: jsonHeaders(),
});

API.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    config.headers = jsonHeaders();
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  },
);
API.interceptors.response.use(
  (response: AxiosResponse<ApiResponseType<any>>) => {
    if (response.data.error) {
      return Promise.reject({
        config: response.config,
        response: {
          data: {
            message: response.data.message,
            error: true,
            payload: [],
          },
        },
        isAxiosError: false,
      });
    }
    return response;
  },
  (error) => {
    if (error.response.status && error.response.status === 403) {
      authToken.remove();
      window.location.href = redirectRoutes.HOME;
    }
    return Promise.reject(error);
  },
);
