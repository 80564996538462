import { makeStyles, Theme } from "@material-ui/core/styles";
import { outlinedInputStyles, textColors } from "constants/themeSettings";

const useRangeCheckQuizFieldStyles = makeStyles<Theme, { range: number }>(
  (theme: Theme) => ({
    root: {
      width: "max-content",
      paddingTop: "34px",
    },
    tiles: ({ range }) => ({
      display: "grid",
      width: "100%",
      gridTemplateColumns: `repeat(${range}, 1fr)`,
      [theme.breakpoints.down(500)]: {
        gridTemplateColumns: "repeat(3, 1fr)",
      },
    }),
    error: {
      backgroundColor: outlinedInputStyles.error.backgroundColor,
    },
    fullWidth: {
      width: "100%",
    },
    tileWrap: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#EEEEEE",
      [theme.breakpoints.down(500)]: {
        marginBottom: "55px",
      },
    },
    number: {
      textAlign: "center",
      position: "absolute",
      fontSize: "16px",
      lineHeight: "22px",
      color: textColors.black.light,
      fontWeight: 400,
      width: "100%",
      top: "-30px",
    },
    rangeText: {
      color: textColors.black.light,
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 400,
      paddingTop: "8px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down(500)]: {
        marginTop: "-50px",
      },
      [theme.breakpoints.down(430)]: {
        fontSize: "12px",
      },
    },
    check: {
      padding: "4px 0",
    },
  }),
);

export { useRangeCheckQuizFieldStyles };
