import {
  AnyAction,
  applyMiddleware,
  CombinedState,
  createStore,
  PreloadedState,
  Reducer,
} from "redux";
import rootReducer from "./rootReducer";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk, { ThunkAction } from "redux-thunk";

export type ReducerCombinedState = CombinedState<
  typeof rootReducer extends Reducer<CombinedState<infer T>> ? T : never
>;

export type StoreInitialState = PreloadedState<ReducerCombinedState>;

export const configureStore = (
  args: {
    initialState?: StoreInitialState;
  } = {},
) => {
  const { initialState } = args;
  return createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(thunk)),
  );
};

export const store = configureStore();

export type RootState = ReturnType<typeof store.getState>;
export type DispatchType = typeof store.dispatch;

export type AppThunkAction<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>;
