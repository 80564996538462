import { Button } from "@material-ui/core";
import GBCustomButton from "components/GBCustomButton/GBCustomButton";
import ArrowLeftIcon from "components/svg-icons/ArrowLeftIcon";
import React from "react";
import { useHistory } from "react-router";

type NextProps = React.ComponentProps<typeof Button> & {
  noArrow?: boolean;
  completed?: boolean;
  goTo?: string;
};

function Next(props: NextProps) {
  const { noArrow, children, completed, goTo, onClick, ...rest } = props;
  const history = useHistory();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(e);
    }
    if (goTo) {
      history.push({ pathname: goTo, search: history.location.search });
    }
  };

  return (
    <GBCustomButton
      variant="contained"
      color={completed ? "primary" : "default"}
      endIcon={!noArrow ? <ArrowLeftIcon /> : undefined}
      onClick={handleClick}
      {...rest}
    >
      {children}
    </GBCustomButton>
  );
}

export default Next;
