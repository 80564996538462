import { useMediaQuery, useTheme } from "@material-ui/core";
import AuthBox from "components/AuthBox/AuthBox";
import { useAuthBoxStyles } from "components/AuthBox/styles/AuthBox.styles";
import ContentSpinner from "components/ContentSpinner/ContentSpinner";
import ErrorText from "components/ErrorText/ErrorText";
import FormRow from "components/FormRow/FormRow";
import GBCustomButton from "components/GBCustomButton/GBCustomButton";
import GBInputErrorText from "components/GBInputErrorText/GBInputErrorText";
import GBOutlinedInput from "components/GBOutlinedInput/GBOutlinedInput";
import GBSimpleTextButton from "components/GBSimpleTextButton/GBSimpleTextButton";
import GBStaticInputLabel from "components/GBStaticInputLabel/GBStaticInputLabel";
import PasswordToggler from "components/PasswordToggler/PasswordToggler";
import { ApiErrorKey } from "constants/api/apiErrors";
import { ValidationRule } from "constants/validationRules";
import { FormikProps } from "formik";
import { Namespaces } from "i18n";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PartnerLoginFormValues } from "./PartnerLogin.types";

type PartnerLoginViewProps = FormikProps<PartnerLoginFormValues> & {
  isProcessing: boolean;
  errorCode: ApiErrorKey | null;
  handleGoToSignup: () => void;
  handleGoToForgotPassword: () => void;
};

function PartnerLoginView(props: PartnerLoginViewProps) {
  const {
    isProcessing,
    errorCode,
    handleGoToSignup,
    handleGoToForgotPassword,
    ...formik
  } = props;

  const { handleSubmit, values, handleChange, handleBlur, touched, errors } =
    formik;

  const { t } = useTranslation([Namespaces.Auth, Namespaces.Common]);
  const authBoxClasses = useAuthBoxStyles();
  const theme = useTheme();
  const isDown450 = useMediaQuery(theme.breakpoints.down(450));

  const [isPasswdVisible, setIsPasswdVisible] = useState<boolean>(false);

  const hasError = (name: keyof PartnerLoginFormValues): boolean => {
    return !!(touched[name] && errors[name]);
  };

  const getError = (
    field: keyof PartnerLoginFormValues,
    errorCode: ValidationRule,
  ) => {
    return t([
      `forms.partnerLogin.fields.${field}.errors.${errorCode}`,
      `${Namespaces.Common}:errors.${errorCode}`,
    ]);
  };

  const _onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmit();
    }
  };

  return (
    <AuthBox title={t("forms.partnerLogin.title")}>
      <form onSubmit={handleSubmit}>
        <ContentSpinner spinning={isProcessing}>
          <FormRow>
            <div className={authBoxClasses.labelBox}>
              <GBStaticInputLabel
                labelFor="email"
                text={t("forms.partnerLogin.fields.email.label")}
              />
              {!isDown450 && (
                <GBSimpleTextButton
                  textAlign="right"
                  color="primary"
                  onClick={handleGoToSignup}
                >
                  {t("forms.partnerLogin.buttons.create_acc")}
                </GBSimpleTextButton>
              )}
            </div>
            <GBOutlinedInput
              id="email"
              fullWidth
              name="email"
              type="text"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={hasError("email")}
              disabled={isProcessing}
              onKeyPress={_onKeyPress}
            />
            {hasError("email") && (
              <GBInputErrorText
                text={getError("email", errors.email as ValidationRule)}
              />
            )}
          </FormRow>
          <FormRow>
            <div className={authBoxClasses.labelBox}>
              <GBStaticInputLabel
                labelFor="password"
                text={t("forms.partnerLogin.fields.password.label")}
              />
              {!isDown450 && (
                <GBSimpleTextButton
                  textAlign="right"
                  color="primary"
                  onClick={handleGoToForgotPassword}
                >
                  {t("forms.partnerLogin.buttons.forgot_passwd")}
                </GBSimpleTextButton>
              )}
            </div>
            <GBOutlinedInput
              id="password"
              fullWidth
              name="password"
              type={isPasswdVisible ? "text" : "password"}
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              error={hasError("password")}
              disabled={isProcessing}
              endAdornment={
                <PasswordToggler
                  isVisible={isPasswdVisible}
                  setIsVisible={(state: boolean) => setIsPasswdVisible(state)}
                />
              }
              onKeyPress={_onKeyPress}
            />
            {hasError("password") && (
              <GBInputErrorText
                text={getError("password", errors.password as ValidationRule)}
              />
            )}
          </FormRow>
        </ContentSpinner>
        <div className={authBoxClasses.buttonsWrap}>
          <GBCustomButton
            className={authBoxClasses.blockCenter}
            type="submit"
            disabled={isProcessing}
          >
            {t("forms.partnerLogin.buttons.submit")}
          </GBCustomButton>
          {isDown450 && (
            <div className={authBoxClasses.xsAdditionButtons}>
              <GBSimpleTextButton
                textAlign="center"
                color="primary"
                onClick={handleGoToSignup}
              >
                {t("forms.partnerLogin.buttons.create_acc")}
              </GBSimpleTextButton>
              <GBSimpleTextButton
                textAlign="center"
                color="primary"
                onClick={handleGoToForgotPassword}
              >
                {t("forms.partnerLogin.buttons.forgot_passwd")}
              </GBSimpleTextButton>
            </div>
          )}
          {errorCode ? (
            <ErrorText
              textAlign="center"
              text={t([
                `forms.login.errors.${errorCode}`,
                `${Namespaces.Common}:errors.${errorCode}`,
                `${Namespaces.Common}:api_errors.${errorCode}`,
                `${Namespaces.Common}:errors.unexpected_error`,
              ])}
            />
          ) : null}
        </div>
      </form>
    </AuthBox>
  );
}

export default PartnerLoginView;
