import { makeStyles, withStyles, Select, Theme } from "@material-ui/core";
import { outlinedInputStyles } from "constants/themeSettings";

export const RedesignedSelect = withStyles((theme: Theme) => ({
  root: {},
  select: {
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
}))(Select);

export const useGBSelectStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingRight: "0",
    "& svg": {
      fontSize: ".9rem",
      marginRight: "10px",
      fill: theme.palette.common.black,
      cursor: "pointer",
      position: "absolute",
      right: "14px",
      pointerEvents: "none",
    },
    "&.Mui-select": {
      paddingRight: "62px",
    },
    "&.Mui-error": {
      backgroundColor: outlinedInputStyles.error.backgroundColor,
      "&:not($focused) $notchedOutline": {
        border: `2px solid ${outlinedInputStyles.error.borderColor}`,
      },
    },
    "& .MuiSelect-outlined": {
      paddingRight: "62px",
    },
  },
  focused: {},
  notchedOutline: {},
}));
