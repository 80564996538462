import { makeStyles, Theme } from "@material-ui/core/styles";
import { textColors } from "constants/themeSettings";

const useHeaderStyles = makeStyles((theme: Theme) => ({
  appBar: {
    "&.MuiAppBar-colorDefault": {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.default,
    },
  },
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "70px",
  },
  logoBox: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    "& a": {
      paddingTop: "6px",
    },
    "& img": {
      height: "55px",
    },
  },
  menu: {
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  nav: {
    height: "inherit",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0",
    padding: "0",
    listStyleType: "none",
  },
  navItem: {
    height: "inherit",
    cursor: "pointer",

    "& > *": {
      padding: "0 20px",
      cursor: "pointer",
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "19px",
      textDecoration: "none",
      height: "100%",
      display: "flex",
      alignItems: "center",
      width: "100%",
      margin: "0",
      border: "none",
      background: "none",
      color: textColors.black.main,
    },

    "&:hover *": {
      color: theme.palette.primary.main,
    },
  },
  disablePaddingRight: {
    "& > *": {
      paddingRight: 0,
    },
  },
  buttons: {
    height: "inherit",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0",
    padding: "0",
    listStyleType: "none",
  },
  buttonItem: {
    display: "flex",
    alignItems: "center",
    padding: "0 0 0 20px",
  },
}));

export { useHeaderStyles };
