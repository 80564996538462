import { makeStyles, Theme } from "@material-ui/core/styles";

const useBuildFormStyles = makeStyles((theme: Theme) => ({
  form: {
    paddingTop: "12px",
  },
  flexBox: {
    display: "flex",
    [theme.breakpoints.down(460)]: {
      flexDirection: "column",
    },
  },
  promiseVerb: {
    marginRight: "16px",
    width: "150px",
    [theme.breakpoints.down(460)]: {
      marginRight: "0",
      width: "100%",
    },
  },
  promiseComponent: {
    [theme.breakpoints.down(460)]: {
      marginTop: "16px",
      width: "100%",
    },
    width: "100%",
  },
}));

export { useBuildFormStyles };
