import { Languages } from "./appLanguages";

export const APP_DEFAULT_LANGUAGE: string = Languages.En;
export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const LOCAL_STORAGE_TOKEN_NAME: string = "token";

export const MAX_YEAR_TO_SELECT: number = new Date().getFullYear() - 10;
export const MIN_YEAR_TO_SELECT: number = MAX_YEAR_TO_SELECT - 90;

// narrow design container = 1140
// wide design container = 1320
//+ 48px paddings
export const NARROW_CONTAINER_WIDTH: number = 1188;
export const WIDE_CONTAINER_WIDTH: number = 1368;
export const XNARROW_CONTAINER_WIDTH: number = 770;

export const TEXT_INPUT_MAX_SYMBOLS: number = 128;

export const ALIGN_SURVEY_INFO_FIELDS_COUNT: number = 10;
export const ALIGN_SURVEY_THEME_FIELDS_COUNT: number = 3;

export const ELLIPSIS: string = "...";

export const IMPACT_VERBS_COUNT: number = 9;
export const ABSTRACT_COMPONENTS_COUNT: number = 8;
export const OUTCOME_COMPONENTS_COUNT: number = 10;

export const FB_PIXEL_ID: string = process.env.REACT_APP_FB_PIXEL_ID || "";

export const PARTNER_LICENSES_MIN: number = 0;

export const STRIPE_PUBLIC_KEY: string =
  process.env.REACT_APP_STRIPE_PUBLIC_KEY ||
  "pk_live_51IoYOBGix6qBK8bPaS15zHAb2q5y3ovG7rwF9NrdoYFkCLviEmaD7OiWV8O9SC0fOmR8cihLPW4UXwwaOkvtXtRS00PqvSGezk";

export const GOBEYOND_PUBLIC: string = window.location.origin;
// process.env.REACT_APP_GOBEYOND_PUBLIC_URL || window.location.origin;
