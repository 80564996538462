import { GrowResponsePayload } from "types/grow/GetGrowResponsePayload";

export const getCompletedStatus = (
  grow: GrowResponsePayload | undefined,
): boolean => {
  return (
    !!grow?.situations?.comfort1?.length &&
    !!grow?.situations?.comfort2?.length &&
    !!grow?.situations?.conflict1?.length &&
    !!grow?.situations?.conflict2?.length
  );
};
