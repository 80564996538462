import GBButton from "components/GBButton/GBButton";
import { useScoreResultStyles } from "./styles/ScoreResult.styles";

type ScoreResultProps = {
  type: string;
  title: string | JSX.Element;
  text: string | JSX.Element;
  onButtonClick: () => void;
  buttonText: string;
  img: JSX.Element;
};

function ScoreResult(props: ScoreResultProps) {
  const { type, title, text, onButtonClick, buttonText, img } = props;
  const classes = useScoreResultStyles();

  return (
    <div className={classes.root}>
      <div className={classes.title}>{title}</div>
      <div className={classes.flexBlock}>
        <div className={classes.text}>{text}</div>
        <div className={classes.imgTypeBlock}>
          <div className={classes.img}>{img}</div>
          <div className={classes.type}>{type}</div>
        </div>
      </div>
      <div className={classes.buttonsBlock}>
        <GBButton variant="contained" color="primary" onClick={onButtonClick}>
          {buttonText}
        </GBButton>
      </div>
    </div>
  );
}

export default ScoreResult;
