import React from "react";
import { SvgIcon } from "@material-ui/core";

function InstagramIcon(props: React.ComponentProps<typeof SvgIcon>) {
  return (
    <SvgIcon {...props} viewBox="0 0 32 32">
      <path d="M19.627 16c0 2.003-1.624 3.627-3.627 3.627s-3.627-1.624-3.627-3.627c0-2.003 1.624-3.627 3.627-3.627s3.627 1.624 3.627 3.627z"></path>
      <path d="M0 0v32h32v-32h-32zM26.667 20.8c0 3.2-2.667 5.867-5.867 5.867h-9.6c-3.2 0-5.867-2.667-5.867-5.867v-9.6c0-3.2 2.667-5.867 5.867-5.867h9.6c3.2 0 5.867 2.667 5.867 5.867v9.6zM20.8 7.253h-9.6c-2.24 0-4.053 1.813-4.053 4.053v9.6c0 2.24 1.813 4.053 4.053 4.053h9.6c2.24 0 4.053-1.813 4.053-4.053v-9.6c-0.107-2.24-1.92-4.053-4.053-4.053zM16 21.547c-2.987 0-5.547-2.453-5.547-5.547s2.453-5.547 5.547-5.547 5.547 2.453 5.547 5.547-2.56 5.547-5.547 5.547zM22.72 11.307c-0.213 0.32-0.64 0.427-0.96 0.427s-0.747-0.107-0.96-0.427c-0.213-0.213-0.427-0.64-0.427-0.96s0.107-0.747 0.427-0.96c0.213-0.213 0.64-0.427 0.96-0.427s0.747 0.107 0.96 0.427c0.213 0.213 0.427 0.64 0.427 0.96s-0.213 0.64-0.427 0.96z"></path>
    </SvgIcon>
  );
}

export default InstagramIcon;
