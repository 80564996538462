import { makeStyles, Theme } from "@material-ui/core/styles";
import { textColors } from "constants/themeSettings";

const useStageStepButtonStyles = makeStyles((theme: Theme) => ({
  backArrow: {
    transform: "rotate(180deg)",
  },
  backButton: {
    color: textColors.black.light,
  },
}));

export { useStageStepButtonStyles };
