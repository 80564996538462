import PulseBeginView from "./PulseBeginView";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { fetchPulse } from "utils/api/queryFns";
import PulseResult from "../PulseResult/PulseResultView";
import {
  preparePulseReport,
  pulseReport,
  pulseReportIsValid,
} from "constants/purposeScoreTypes";
import PulseResultView from "../PulseResult/PulseResultView";

function PulseBegin() {
  const scoreQuery = useQuery(fetchPulse.key, fetchPulse.fn);
  const pulse = useMemo(
    () => scoreQuery.data?.data.payload,
    [scoreQuery.data?.data.payload],
  );
  const pulseResult = useMemo(
    () => preparePulseReport(pulse),
    [pulse],
  ) as pulseReport;
  const isFetching = useMemo(
    () => scoreQuery.isLoading,
    [scoreQuery.isLoading],
  );

  const isFetchError = useMemo(() => scoreQuery.isError, [scoreQuery.isError]);
  if (pulse?.completed) {
    return (
      <PulseResultView
        pulseVal={pulseResult}
        isFetching={isFetching}
        isFetchError={isFetchError}
      />
    );
  }
  return <PulseBeginView />;
}
export default PulseBegin;
