import { MenuItem } from "@material-ui/core";
import FormRow from "components/FormRow/FormRow";
import GBOutlinedInput from "components/GBOutlinedInput/GBOutlinedInput";
import GBSelect from "components/GBSelect/GBSelect";
import { FormikProps } from "formik";
import { Namespaces } from "i18n";
import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { getSelectYearsList } from "utils/getSelectYearsList";
import { useImpactStyles } from "../../../styles/Impact.styles";
import { ImpactPurposeStory1FormValues } from "../ImpactPurposeStory1.types";

const yearsToSelect = getSelectYearsList().reverse();

type Story1FormProps = FormikProps<ImpactPurposeStory1FormValues> & {};

function Story1Form(props: Story1FormProps) {
  const { values, handleBlur, handleChange, handleSubmit, touched, errors } =
    props;

  const { t } = useTranslation(Namespaces.Impact);
  const classes = useImpactStyles();

  const hasError = (name: keyof ImpactPurposeStory1FormValues): boolean => {
    return !!(touched[name] && errors[name]);
  };

  const familyClasses = useMemo(
    () => [
      t("purpose.story1.family_classes.class1"),
      t("purpose.story1.family_classes.class2"),
      t("purpose.story1.family_classes.class3"),
    ],
    [t],
  );

  const familyRelations = useMemo(
    () => [
      t("purpose.story1.family_relations.relation1"),
      t("purpose.story1.family_relations.relation2"),
      t("purpose.story1.family_relations.relation3"),
    ],
    [t],
  );

  const immediateFamilyTypes = useMemo(
    () => [
      t("purpose.story1.immediate_family_types.type1"),
      t("purpose.story1.immediate_family_types.type2"),
      t("purpose.story1.immediate_family_types.type3"),
    ],
    [t],
  );

  return (
    <form onSubmit={handleSubmit}>
      <FormRow className={classes.storyFormRow}>
        <Trans
          i18nKey={t("purpose.story1.form.born")}
          components={{
            year: (
              <GBSelect
                id="year"
                name="year"
                value={values.year}
                onChange={handleChange}
                onBlur={handleBlur}
                error={hasError("year")}
                className={classes.storyFormField}
              >
                {yearsToSelect.map((y, k) => (
                  <MenuItem key={k} value={y.toString()}>
                    {y}
                  </MenuItem>
                ))}
              </GBSelect>
            ),
            city: (
              <GBOutlinedInput
                id="city"
                name="city"
                type="text"
                value={values.city}
                onChange={handleChange}
                onBlur={handleBlur}
                error={hasError("city")}
                className={classes.storyFormField}
              />
            ),
          }}
        />
      </FormRow>
      <FormRow className={classes.storyFormRow}>
        <Trans
          i18nKey={t("purpose.story1.form.familyClass")}
          components={{
            familyClass: (
              <GBSelect
                id="familyClass"
                name="familyClass"
                value={values.familyClass}
                onChange={handleChange}
                onBlur={handleBlur}
                error={hasError("familyClass")}
                className={classes.storyFormField}
              >
                {familyClasses.map((c, k) => (
                  <MenuItem key={k} value={c}>
                    {c}
                  </MenuItem>
                ))}
              </GBSelect>
            ),
          }}
        />
      </FormRow>
      <FormRow className={classes.storyFormRow}>
        <Trans
          i18nKey={t("purpose.story1.form.familyRelations")}
          components={{
            familyRelations: (
              <GBSelect
                id="familyRelations"
                name="familyRelations"
                value={values.familyRelations}
                onChange={handleChange}
                onBlur={handleBlur}
                error={hasError("familyRelations")}
                className={classes.storyFormFieldShort}
              >
                {familyRelations.map((r, k) => (
                  <MenuItem key={k} value={r}>
                    {r}
                  </MenuItem>
                ))}
              </GBSelect>
            ),
          }}
        />
      </FormRow>
      <FormRow className={classes.storyFormRow}>
        <Trans
          i18nKey={t("purpose.story1.form.immediateFamily")}
          components={{
            immediateFamily: (
              <GBSelect
                id="immediateFamily"
                name="immediateFamily"
                value={values.immediateFamily}
                onChange={handleChange}
                onBlur={handleBlur}
                error={hasError("immediateFamily")}
                className={classes.storyFormField}
              >
                {immediateFamilyTypes.map((i, k) => (
                  <MenuItem key={k} value={i}>
                    {i}
                  </MenuItem>
                ))}
              </GBSelect>
            ),
          }}
        />
      </FormRow>
    </form>
  );
}

export default Story1Form;
