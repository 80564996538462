import StageStepFrame from "components/StageStepFrame/StageStepFrame";
import { Trans, useTranslation } from "react-i18next";
import { Namespaces } from "i18n";
import { useSurvey } from "components/hooks/useSurvey";
import StageStepButton from "components/StageStepButton/StageStepButton";
import { generalRoutes } from "constants/generalRoutes";
import StageStepImage from "components/StageStepImage/StageStepImage";
import StageStepText from "components/StageStepText/StageStepText";
import StageStepContainer from "components/StageStepContainer/StageStepContainer";

function ImpactPurposeBeginView() {
  const { t } = useTranslation([Namespaces.Impact, Namespaces.Survey]);
  const { nextUrl } = useSurvey();

  return (
    <StageStepFrame
      title={t("purpose.title")}
      controls={[
        <StageStepButton.Next goTo={nextUrl}>
          {t(`${Namespaces.Survey}:buttons.begin`)}
        </StageStepButton.Next>,
      ]}
    >
      <StageStepContainer>
        <StageStepImage url={t("purpose.begin.img")} />
        <StageStepText>
          <Trans
            i18nKey={t("purpose.begin.text")}
            components={{
              p: <p />,
              b: <b />,
            }}
          />
        </StageStepText>
      </StageStepContainer>
    </StageStepFrame>
  );
}

export default ImpactPurposeBeginView;
