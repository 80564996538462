import DnaRankView from "./DnaRankView";
import { useSurvey } from "components/hooks/useSurvey";
import { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { useQuery, useQueryClient } from "react-query";
import { fetchScore } from "utils/api/queryFns";
import { generalRoutes } from "constants/generalRoutes";
import { QueryKeys } from "constants/api/queryKeys";
import ScoreApiService from "services/ScoreApiService";
import { prepareRanksInitialValues } from "constants/purposeScoreTypes";
import { IdentityVerb } from "types/score/DNA";
import { ApiClientError } from "types/api/ApiClientError";
import { selectApiError } from "utils/api/selectApiError";
import { ApiErrorKey } from "constants/api/apiErrors";
import { Namespaces } from "i18n";
import { useTranslation } from "react-i18next";

function DnaRank() {
  const { t } = useTranslation([Namespaces.Survey, Namespaces.Common]);
  const { nextUrl, prevUrl } = useSurvey();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [apiError, setApiError] = useState<ApiErrorKey | null>(null);
  const history = useHistory();
  const queryClient = useQueryClient();
  const scoreQuery = useQuery(fetchScore.key, fetchScore.fn);
  const score = useMemo(
    () => scoreQuery.data?.data.payload,
    [scoreQuery.data?.data.payload],
  );
  const preparedInitialValues = useMemo(
    () => prepareRanksInitialValues(score?.DNA),
    [score],
  );

  const handleStepNext = () =>
    history.push(
      { pathname: nextUrl, search: history.location.search } ||
        generalRoutes.SCORE_MENU,
    );

  const handleStepPrev = () =>
    history.push({
      pathname: prevUrl,
      search: history.location.search,
    });

  const handleFormSubmit = async () => {
    try {
      if (score) {
        setIsUpdating(true);
        setApiError(null);
        await ScoreApiService.putScore({
          field: "completed",
          value: true,
          step: "DNA",
        });
        await queryClient.refetchQueries([QueryKeys.fetchScore]);
        setIsUpdating(false);
        handleStepNext();
      }
    } catch (e) {
      console.error(e);
      const error = e as ApiClientError;
      setIsUpdating(false);
      setApiError(selectApiError(error.response?.data.message));
    }
  };

  const handleValueChange = async (value: Array<IdentityVerb>) => {
    try {
      setIsUpdating(true);
      await ScoreApiService.putScore({
        field: "impacts",
        value,
        step: "DNA",
      });
      await queryClient.refetchQueries([QueryKeys.fetchScore]);
      setIsUpdating(false);
    } catch (e) {
      setIsUpdating(false);
      console.error(e);
    }
  };
  const getApiError = useCallback(() => {
    return apiError
      ? t([
          `steps.info.errors.${apiError}`,
          `${Namespaces.Survey}:errors.${apiError}`,
          `${Namespaces.Common}:errors.${apiError}`,
        ])
      : undefined;
  }, [apiError, t]);

  return (
    <DnaRankView
      onValueChange={handleValueChange}
      values={preparedInitialValues}
      isFetching={scoreQuery.isLoading}
      isFetchError={scoreQuery.isError}
      getApiError={getApiError}
      isUpdating={isUpdating}
      onStepNext={handleFormSubmit}
      onStepBack={handleStepPrev}
    />
  );
}
export default DnaRank;
