import React from "react";
import { SvgIcon } from "@material-ui/core";

function FacebookIcon(props: React.ComponentProps<typeof SvgIcon>) {
  return (
    <SvgIcon {...props} viewBox="0 0 32 32">
      <path d="M0 0v32h32v-32h-32zM21.547 8.853h-1.92c-1.493 0-1.813 0.747-1.813 1.813v2.667h3.413l-0.427 3.84h-2.987v9.493h-3.84v-9.493h-3.093v-3.84h3.093v-2.987c0-3.2 1.92-5.013 4.693-5.013 1.387 0 2.453 0.107 2.88 0.107v3.413z"></path>
    </SvgIcon>
  );
}

export default FacebookIcon;
