import { makeStyles, Theme } from "@material-ui/core/styles";

const useImpactPromiseResultStyles = makeStyles((theme: Theme) => ({
  promiseWrap: {
    position: "relative",
  },
  promiseInner: {
    position: "absolute",
    top: "0",
    left: "0",
    bottom: "0",
    right: "0",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    padding: "32px 35px",
    background:
      "linear-gradient(0deg, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0) 100%)",
  },
  promise: {
    fontSize: "48px",
    lineHeight: "58px",
    color: theme.palette.primary.main,
    fontWeight: 700,
    width: "537px",
    fontStyle: "italic",
    [theme.breakpoints.down(800)]: {
      width: "100%",
      fontSize: "38px",
    },
    [theme.breakpoints.down(450)]: {
      fontSize: "30px",
      lineHeight: "48px",
    },
  },
  author: {
    fontSize: "20px",
    lineHeight: "34px",
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  promiseMobile: {},
}));

export { useImpactPromiseResultStyles };
