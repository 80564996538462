import {
  ABSTRACT_COMPONENTS_COUNT,
  IMPACT_VERBS_COUNT,
  OUTCOME_COMPONENTS_COUNT,
} from "constants/settings";
import _ from "lodash";
import { ImpactPurpose } from "types/impact/ImpactPurpose";
import { ImpactPurposeBuildFormValues } from "./ImpactPurposeBuild.types";
import { PurposeFormValues } from "types/score/ScoreForm.types";

export const prepareInitialValues = (
  data?: ImpactPurpose,
): ImpactPurposeBuildFormValues => ({
  purposeVerb: data?.purposeVerb || "",
  purposeComponent: data?.purposeComponent || "",
  purposeOutcome: data?.purposeOutcome || "",
  purposeStatement: "",
});

export const prepareImpactVerbs = (
  score?: PurposeFormValues,
): string[] | null => {
  const result = !!score?.DNA
    ? _.compact(score?.DNA.impacts.map((i) => i.verb))
    : [];
  return result.length === IMPACT_VERBS_COUNT ? result : null;
};

export const prepareAbstractComponents = (
  data?: ImpactPurpose,
): string[] | null => {
  const result = _.compact([
    data?.parentsLesson,
    data?.adultDecision,
    data?.adversity,
    data?.talent1,
    data?.talent2,
    data?.talent3,
    data?.teachAbout,
    data?.improveArea,
  ]);
  return result.length === ABSTRACT_COMPONENTS_COUNT ? result : null;
};

export const prepareOutcomeComponents = (
  data?: ImpactPurpose,
): string[] | null => {
  const result = _.compact([
    data?.talent1,
    data?.talent2,
    data?.talent3,
    data?.teachAbout,
    data?.improveArea,
    data?.mainPassion,
    data?.passion1,
    data?.passion2,
    data?.passion3,
    data?.peopleHelp,
  ]);
  return result.length === OUTCOME_COMPONENTS_COUNT ? result : null;
};
