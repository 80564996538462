import React from "react";
import { SvgIcon } from "@material-ui/core";

function GBProfileIcon(props: React.ComponentProps<typeof SvgIcon>) {
  return (
    <SvgIcon {...props} viewBox="0 0 26 26" fill="none">
      <path
        d="M13 25C19.6274 25 25 19.6274 25 13C25 6.37258 19.6274 1 13 1C6.37258 1 1 6.37258 1 13C1 19.6274 6.37258 25 13 25Z"
        stroke="#07283B"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g transform="translate(7, 6)">
        <path
          d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z"
          fill="none"
          stroke="#07283B"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g transform="translate(3, 16)">
        <path
          d="M1.97485 5.92176C2.72812 4.44077 3.8765 3.19711 5.29289 2.32845C6.70928 1.45978 8.33841 1 9.99995 1C11.6615 0.999997 13.2906 1.45977 14.707 2.32843C16.1234 3.19709 17.2718 4.44074 18.0251 5.92172"
          fill="none"
          stroke="#07283B"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}

export default GBProfileIcon;
