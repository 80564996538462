import { makeStyles, Theme } from "@material-ui/core/styles";
import { borderRadiuses, textColors } from "constants/themeSettings";

const useScoreResultStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: "#F8F8F8",
    borderRadius: borderRadiuses.round,
    padding: "25px 24px 24px 24px",
  },
  title: {
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
  },
  flexBlock: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down(650)]: {
      flexDirection: "column-reverse",
    },
  },
  text: {
    color: textColors.black.light,
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 400,
  },
  buttonsBlock: {
    paddingTop: "7px",
  },
  img: {
    paddingRight: "10px",
    width: "201px",
    height: "auto",
  },
  type: {
    color: theme.palette.primary.main,
    fontSize: "16px",
    lineHeight: "19px",
    fontWeight: 700,
    textAlign: "center",
    [theme.breakpoints.down(650)]: {
      textAlign: "left",
    },
  },
  imgTypeBlock: {
    [theme.breakpoints.down(650)]: {
      padding: "10px 0",
    },
  },
}));

export { useScoreResultStyles };
