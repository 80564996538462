import GBCircularProgress from "components/GBCircularProgress/GBCircularProgress";
import GBSpin from "components/GBSpin/GBSpin";
import StageStepButton from "components/StageStepButton/StageStepButton";
import StageStepContainer from "components/StageStepContainer/StageStepContainer";
import StageStepFrame from "components/StageStepFrame/StageStepFrame";
import StageStepText from "components/StageStepText/StageStepText";
import StageStepTitle from "components/StageStepTitle/StageStepTitle";
import UnexpectedError from "components/UnexpectedError/UnexpectedError";
import { ApiErrorKey } from "constants/api/apiErrors";
import { useFormik } from "formik";
import { Namespaces } from "i18n";
import { useTranslation, Trans } from "react-i18next";
import BuildForm from "./components/BuildForm/BuildForm";
import { buildFormValidationSchema } from "./ImpactPromiseBuild.consts";
import { ImpactPromiseBuildFormValues } from "./ImpactPromiseBuild.types";

type ImpactPromiseBuildViewProps = {
  onFormSubmit: (data: ImpactPromiseBuildFormValues) => void;
  initialValues: ImpactPromiseBuildFormValues;
  prevUrl: string;
  isUpdating: boolean;
  isFetching: boolean;
  isFetchError: boolean;
  getApiError: () => ApiErrorKey | undefined;
};

function ImpactPromiseBuildView(props: ImpactPromiseBuildViewProps) {
  const {
    onFormSubmit,
    prevUrl,
    isUpdating,
    isFetching,
    isFetchError,
    initialValues,
    getApiError,
  } = props;

  const { t } = useTranslation([Namespaces.Impact, Namespaces.Survey]);

  const formik = useFormik<ImpactPromiseBuildFormValues>({
    enableReinitialize: true,
    validationSchema: buildFormValidationSchema,
    initialValues,
    onSubmit: onFormSubmit,
  });

  return (
    <StageStepFrame
      title={t("promise.title")}
      controls={[
        <StageStepButton.Back
          goTo={prevUrl}
          disabled={isFetching || isUpdating}
        >
          {t(`${Namespaces.Survey}:buttons.back`)}
        </StageStepButton.Back>,
        <StageStepButton.Next
          onClick={() => formik.handleSubmit()}
          disabled={isFetching || isUpdating}
        >
          {t(`${Namespaces.Survey}:buttons.continue`)}
        </StageStepButton.Next>,
      ]}
      error={getApiError()}
    >
      {isFetching ? (
        <GBCircularProgress keepHeight="504px" />
      ) : isFetchError ? (
        <UnexpectedError />
      ) : (
        <GBSpin spinning={isUpdating}>
          <StageStepContainer>
            <StageStepTitle gutter="16px">
              {t("promise.build.title")}
            </StageStepTitle>
            <StageStepText>
              <Trans
                i18nKey={t("promise.build.text")}
                components={{
                  p: <p />,
                  span: <span style={{ display: "block" }} />,
                  b: <b />,
                }}
              />
            </StageStepText>
          </StageStepContainer>
          <BuildForm {...formik} />
        </GBSpin>
      )}
    </StageStepFrame>
  );
}

export default ImpactPromiseBuildView;
