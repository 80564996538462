import { Namespaces } from "i18n";
import { ImpactSectionData } from "../../AdminUserSnapshot.types";
import SnapshotSectionLabel from "components/SnapshotSectionLabel/SnapshotSectionLabel";
import { useTranslation, Trans } from "react-i18next";
import { useImpactSectionStyles } from "./styles/ImpactSection.styles";
import { rmSubstr } from "utils/rmSubstr";

type ImpactSectionProps = {
  data: ImpactSectionData;
};

function ImpactSection(props: ImpactSectionProps) {
  const {
    data: { purpose, promise },
  } = props;

  const classes = useImpactSectionStyles();
  const { t } = useTranslation([Namespaces.Admin, Namespaces.Snapshot]);

  return (
    <>
      <SnapshotSectionLabel
        text={t("snapshot.content.impact.label")}
        color="primary"
      />
      {purpose && promise && (
        <div className={classes.content}>
          <div className={classes.text}>
            <Trans
              i18nKey={t(`${Namespaces.Snapshot}:step1.impact.purpose`, {
                purpose: rmSubstr(
                  purpose?.toLowerCase() || "",
                  t(
                    `${Namespaces.Snapshot}:step1.impact.purposeAccent`,
                  ).toLowerCase(),
                ),
                accent: t(`${Namespaces.Snapshot}:step1.impact.purposeAccent`),
              })}
              components={{
                accent: <span className={classes.accent} />,
              }}
            />
          </div>
          <div className={classes.text}>
            <Trans
              i18nKey={t(`${Namespaces.Snapshot}:step1.impact.promise`, {
                promise: rmSubstr(
                  promise?.toLowerCase() || "",
                  t(
                    `${Namespaces.Snapshot}:step1.impact.promiseAccent`,
                  ).toLowerCase(),
                ),
                accent: t(`${Namespaces.Snapshot}:step1.impact.promiseAccent`),
              })}
              components={{
                accent: <span className={classes.accent} />,
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default ImpactSection;
