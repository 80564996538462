import GBCustomButton from "components/GBCustomButton/GBCustomButton";
import AdminIcon from "components/svg-icons/AdminIcon";
import { generalRoutes } from "constants/generalRoutes";
import { UserType } from "constants/userTypes";
import { Namespaces } from "i18n";
import { useTranslation } from "react-i18next";

type AdminButtonProps = {
  userType: UserType.SuperAdmin | UserType.Partner;
};

function AdminButton(props: AdminButtonProps) {
  const { userType } = props;
  const { t } = useTranslation(Namespaces.Common);

  return (
    <GBCustomButton
      linkType="router-link"
      href={
        userType === UserType.SuperAdmin
          ? generalRoutes.SUPERADMIN_PROFILE
          : generalRoutes.PARTNER_PROFILE
      }
      size="small"
      variant="contained"
      color="secondary"
      startIcon={<AdminIcon />}
    >
      {userType === UserType.SuperAdmin
        ? t("header.buttons.superadminPanel")
        : t("header.buttons.partnerPanel")}
    </GBCustomButton>
  );
}

export default AdminButton;
