import { ELLIPSIS } from "constants/settings";
import { ValidationRule } from "constants/validationRules";
import * as Yup from "yup";

export const buildFormValidationSchema = Yup.object({
  purposeVerb: Yup.string()
    .trim()
    .not([ELLIPSIS], ValidationRule.Required)
    .required(ValidationRule.Required),
  purposeComponent: Yup.string()
    .trim()
    .not([ELLIPSIS], ValidationRule.Required)
    .required(ValidationRule.Required),
  purposeOutcome: Yup.string()
    .trim()
    .not([ELLIPSIS], ValidationRule.Required)
    .required(ValidationRule.Required),
  purposeStatement: Yup.string().trim().required(),
});
