import {
  AuthFormInitValues,
  AuthFormValuesFormValidationSchema,
} from "./AuthModal.consts";
import { EmailAuthValues } from "./AuthModal.types";
import FormRow from "components/FormRow/FormRow";
import GBInputErrorText from "components/GBInputErrorText/GBInputErrorText";
import GBModal from "components/GBModal/GBModal";
import GBOutlinedInput from "components/GBOutlinedInput/GBOutlinedInput";
import GBStaticInputLabel from "components/GBStaticInputLabel/GBStaticInputLabel";
import { ApiErrorKey } from "constants/api/apiErrors";
import { ValidationRule } from "constants/validationRules";
import { useFormik } from "formik";
import { Namespaces } from "i18n";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import CommonApiService from "services/CommonApiService";
import { ApiClientError } from "types/api/ApiClientError";
import { selectApiError } from "utils/api/selectApiError";
import GBSpin from "components/GBSpin/GBSpin";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { ExerciseName } from "constants/exerciseNames";
import { useHistory } from "react-router-dom";

type AuthModalProps = {
  open: boolean;
  onClose: () => void;
};

type EmailAuthRequestPayLoad = {
  email: string;
  exerciseLink?: ExerciseName;
};

function AuthModal(props: AuthModalProps) {
  const { open, onClose } = props;
  const { t } = useTranslation(Namespaces.Common);
  const exerciseLink = useSelector((state: RootState) =>
    state.authModal.exerciseLink.split("/").pop(),
  );
  console.log(exerciseLink);
  const authModalHeadingText = `Enter your work email to access ${
    ExerciseName[exerciseLink as keyof typeof ExerciseName]
  }`;
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<ApiErrorKey | undefined>(
    undefined,
  );
  const onSubmit = async (data: EmailAuthValues) => {
    const requestPayload: EmailAuthRequestPayLoad = {
      email: data.email.trim(),
      exerciseLink: exerciseLink as ExerciseName,
    };

    try {
      setIsProcessing(true);
      await CommonApiService.requestEmailLink(requestPayload);
      setIsProcessing(false);
      notify();
    } catch (e) {
      const error = e as ApiClientError;
      setIsProcessing(false);
      setErrorCode(selectApiError(error.response?.data.message));
    }
  };

  const formik = useFormik<EmailAuthValues>({
    validationSchema: AuthFormValuesFormValidationSchema,
    enableReinitialize: true,
    initialValues: AuthFormInitValues,
    onSubmit: onSubmit,
  });

  const { values, handleBlur, handleChange, handleSubmit, touched, errors } =
    formik;

  const hasError = (name: keyof EmailAuthValues): boolean => {
    return !!(touched[name] && errors[name]);
  };
  const notify = () =>
    toast.success(
      "We just emailed you a link to access this exercise. Please follow the instructions in your email. You can now close this window.",
    );
  const getError = (
    field: keyof EmailAuthValues,
    errorCode: ValidationRule,
  ) => {
    return t([
      `footer.modal.contact_us.form.fields.${field}.errors.${errorCode}`,
      `errors.${errorCode}`,
    ]);
  };

  const _onClose = () => {
    setErrorCode(undefined);
    onClose();
  };

  return (
    <>
      <ToastContainer autoClose={9000} style={{ fontSize: "20px" }} />
      <GBModal
        open={open}
        title="Enter your email for Access"
        size="medium"
        withTitleDivider
        onOk={handleSubmit}
        cancelButtonText="Close"
        cancelButtonProps={{
          disabled: isProcessing,
        }}
        okButtonText="Request Access"
        okButtonProps={{
          disabled: isProcessing,
        }}
        errorText={
          errorCode
            ? t([`api_errors.${errorCode}`, `errors.unexpected_error`])
            : undefined
        }
      >
        <GBSpin spinning={isProcessing}>
          <form onSubmit={handleSubmit}>
            <FormRow>
              <GBStaticInputLabel
                labelFor="Please verify you email"
                text={authModalHeadingText}
              />
              <GBOutlinedInput
                id="email"
                fullWidth
                name="email"
                type="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={hasError("email")}
                disabled={isProcessing}
              />
              {hasError("email") && (
                <GBInputErrorText
                  text={getError("email", errors.email as ValidationRule)}
                />
              )}
            </FormRow>
          </form>
        </GBSpin>
      </GBModal>
    </>
  );
}

export default AuthModal;
