import { Alert } from "@material-ui/lab";
import GBCircularProgress from "components/GBCircularProgress/GBCircularProgress";
import GBDivider from "components/GBDivider/GBDivider";
import StageStepButton from "components/StageStepButton/StageStepButton";
import UnexpectedError from "components/UnexpectedError/UnexpectedError";
import { Namespaces } from "i18n";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SurveysResultResponsePayload } from "types/common/GetSurveysResultResponsePayload";
import Step1 from "./components/Step1/Step1";
import Step2 from "./components/Step2/Step2";
import { SnapshotStep } from "./Snapshot.consts";
import { useSnapshotStyles } from "./styles/Snapshot.styles";

type SnapshotViewProps = {
  snapshotStep: SnapshotStep;
  onStepNext: () => void;
  onStepBack: () => void;
  onLastStepNext: () => void;
  isLoading: boolean;
  isError: boolean;
  surveysResult: SurveysResultResponsePayload | undefined;
};

function SnapshotView(props: SnapshotViewProps) {
  const {
    snapshotStep,
    onStepBack,
    onStepNext,
    onLastStepNext,
    isLoading,
    isError,
    surveysResult,
  } = props;

  const classes = useSnapshotStyles();
  const { t } = useTranslation([Namespaces.Common, Namespaces.Snapshot]);

  const isUncompletedAll = useMemo(
    () =>
      !surveysResult?.align.completed &&
      !surveysResult?.grow.completed &&
      !surveysResult?.impact.completed &&
      !surveysResult?.score.completed,
    [
      surveysResult?.align.completed,
      surveysResult?.grow.completed,
      surveysResult?.impact.completed,
      surveysResult?.score.completed,
    ],
  );

  const isButtonsAvailable = surveysResult?.grow.completed;

  return (
    <div className={classes.root}>
      {isLoading ? (
        <GBCircularProgress />
      ) : isError || !surveysResult ? (
        <UnexpectedError />
      ) : isUncompletedAll ? (
        <Alert className={classes.warninig} severity="warning">
          {t("messages.no_surveys_complete")}
        </Alert>
      ) : (
        <>
          {snapshotStep === SnapshotStep.Step1 && (
            <Step1 surveysResult={surveysResult} />
          )}
          {snapshotStep === SnapshotStep.Step2 && (
            <Step2 surveysResult={surveysResult} />
          )}
          {isButtonsAvailable && (
            <div className={classes.stepButtons}>
              <GBDivider />
              <div className={classes.stepsButtonsInner}>
                {snapshotStep !== SnapshotStep.Step1 && (
                  <StageStepButton.Back onClick={onStepBack}>
                    {t("buttons.back")}
                  </StageStepButton.Back>
                )}
                {snapshotStep !== SnapshotStep.Step2 ? (
                  <StageStepButton.Next onClick={onStepNext}>
                    {t("buttons.next")}
                  </StageStepButton.Next>
                ) : (
                  <StageStepButton.Next noArrow onClick={onLastStepNext}>
                    {t(`${Namespaces.Snapshot}:buttons.home`)}
                  </StageStepButton.Next>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default SnapshotView;
