import { Alert } from "@material-ui/lab";
import GBCircularProgress from "components/GBCircularProgress/GBCircularProgress";
import GBSpin from "components/GBSpin/GBSpin";
import StageStepButton from "components/StageStepButton/StageStepButton";
import StageStepFrame from "components/StageStepFrame/StageStepFrame";
import StageStepTitle from "components/StageStepTitle/StageStepTitle";
import UnexpectedError from "components/UnexpectedError/UnexpectedError";
import { ApiErrorKey } from "constants/api/apiErrors";
import { useFormik } from "formik";
import { Namespaces } from "i18n";
import { useTranslation } from "react-i18next";
import BuildForm from "./components/BuildForm/BuildForm";
import { buildFormValidationSchema } from "./ImpactPurposeBuild.consts";
import { ImpactPurposeBuildFormValues } from "./ImpactPurposeBuild.types";

type ImpactPurposeBuildViewProps = {
  onFormSubmit: (data: ImpactPurposeBuildFormValues) => void;
  initialValues: ImpactPurposeBuildFormValues;
  prevUrl: string;
  isUpdating: boolean;
  isFetching: boolean;
  isFetchError: boolean;
  getApiError: () => ApiErrorKey | undefined;
  abstractComponents: string[] | null;
  outcomeComponents: string[] | null;
  impactVerbs: string[] | null;
};

function ImpactPurposeBuildView(props: ImpactPurposeBuildViewProps) {
  const {
    onFormSubmit,
    prevUrl,
    isUpdating,
    isFetching,
    isFetchError,
    initialValues,
    getApiError,
    abstractComponents,
    outcomeComponents,
    impactVerbs,
  } = props;

  const { t } = useTranslation([Namespaces.Impact, Namespaces.Survey]);

  const formik = useFormik<ImpactPurposeBuildFormValues>({
    enableReinitialize: true,
    validationSchema: buildFormValidationSchema,
    initialValues,
    onSubmit: onFormSubmit,
  });

  const isCompletedPrevious = abstractComponents && outcomeComponents;

  return (
    <StageStepFrame
      title={t("purpose.title")}
      controls={[
        <StageStepButton.Back
          goTo={prevUrl}
          disabled={isFetching || isUpdating}
        >
          {t(`${Namespaces.Survey}:buttons.back`)}
        </StageStepButton.Back>,
        <StageStepButton.Next
          onClick={() => formik.handleSubmit()}
          disabled={isFetching || isUpdating || !isCompletedPrevious}
        >
          {t(`${Namespaces.Survey}:buttons.continue`)}
        </StageStepButton.Next>,
      ]}
      error={getApiError()}
    >
      {isFetching ? (
        <GBCircularProgress keepHeight="356px" />
      ) : isFetchError ? (
        <UnexpectedError />
      ) : abstractComponents && outcomeComponents && impactVerbs ? (
        <GBSpin spinning={isUpdating}>
          <StageStepTitle>{t("purpose.build.title")}</StageStepTitle>
          <BuildForm
            {...formik}
            abstractComponents={abstractComponents}
            outcomeComponents={outcomeComponents}
            impactVerbs={impactVerbs}
          />
        </GBSpin>
      ) : !impactVerbs ? (
        <Alert severity="warning">
          {t("purpose.build.warnings.uncompleted_score")}
        </Alert>
      ) : (
        <Alert severity="warning">
          {t(`${Namespaces.Survey}:warnings.uncompleted_steps`)}
        </Alert>
      )}
    </StageStepFrame>
  );
}

export default ImpactPurposeBuildView;
