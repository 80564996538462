import cx from "classnames";
import { useStageStepImageStyles } from "./styles/StageStepImage.styles";

type StageStepImageProps = {
  url: string;
  className?: string;
};

function StageStepImage(props: StageStepImageProps) {
  const { url, className } = props;
  const classes = useStageStepImageStyles();

  const rootClasses = cx(classes.root, className);

  return (
    <div
      className={rootClasses}
      style={{
        background: `url('${url}') no-repeat center center / cover`,
      }}
    ></div>
  );
}

export default StageStepImage;
