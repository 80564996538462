import { PartnerSignupFormValues } from "./PartnerSignup.types";
import * as Yup from "yup";
import { emailRegExp, passwordRegExp } from "constants/regExps";
import { ValidationRule } from "constants/validationRules";

export const partnerSignupFormInitialValues: PartnerSignupFormValues = {
  firstName: "",
  lastName: "",
  email: "",
  advCode: "",
  password: "",
  confPassword: "",
};

export const partnerSignupFormValidationSchema = Yup.object({
  firstName: Yup.string().trim().required(ValidationRule.Required),
  lastName: Yup.string().trim().required(ValidationRule.Required),
  email: Yup.string()
    .trim()
    .matches(emailRegExp, ValidationRule.InvalidEmailFormat)
    .required(ValidationRule.Required),
  advCode: Yup.string().trim().required(ValidationRule.Required),
  password: Yup.string()
    .trim()
    .matches(passwordRegExp, ValidationRule.InvalidPasswordFormat)
    .required(ValidationRule.Required),
  confPassword: Yup.string()
    .trim()
    .matches(passwordRegExp, ValidationRule.InvalidPasswordFormat)
    .oneOf([Yup.ref("password"), null], ValidationRule.NotMatch)
    .required(ValidationRule.Required),
});
