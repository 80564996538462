import { useTheme } from "@material-ui/core/styles";

const ImpactIcon = (): JSX.Element => {
  const theme = useTheme();
  return (
    <svg
      width="88"
      height="98"
      viewBox="0 0 88 98"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.5 5.06218C42.2846 3.45448 45.7154 3.45448 48.5 5.06218L79.8013 23.134C82.5859 24.7417 84.3013 27.7128 84.3013 30.9282V67.0718C84.3013 70.2872 82.5859 73.2583 79.8013 74.866L48.5 92.9378C45.7154 94.5455 42.2846 94.5455 39.5 92.9378L8.19873 74.866C5.41412 73.2583 3.69873 70.2872 3.69873 67.0718V30.9282C3.69873 27.7128 5.41412 24.7417 8.19873 23.134L39.5 5.06218Z"
        className="dynamicColor"
        stroke={theme.palette.secondary.light}
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g transform="translate(18, 23)">
        <path
          d="M37 3.54379C33.6806 1.91474 29.9471 1 26 1C12.1929 1 1 12.1929 1 26C1 39.8071 12.1929 51 26 51C39.8071 51 51 39.8071 51 26C51 22.0529 50.0853 18.3194 48.4562 15"
          stroke="#07283B"
          strokeWidth="2"
        />
      </g>
      <circle cx="44" cy="50" r="18" stroke="#07283B" strokeWidth="2" />
      <circle cx="44" cy="50" r="11" stroke="#07283B" strokeWidth="2" />
      <circle cx="44" cy="50" r="4" stroke="#f7882f" strokeWidth="2" />
      <g transform="translate(43, 25)">
        <path d="M1 25L25 1" stroke="#f7882f" strokeWidth="2" />
      </g>
      <g transform="translate(57, 23)">
        <path
          d="M7 1V6L1 12V7L7 1Z"
          stroke="#f7882f"
          strokeWidth="2"
          strokeLinejoin="bevel"
        />
      </g>
      <g transform="translate(58, 29)">
        <path
          d="M0 7H5L11 1H6"
          stroke="#f7882f"
          strokeWidth="2"
          strokeLinejoin="bevel"
        />
      </g>
    </svg>
  );
};

export default ImpactIcon;
