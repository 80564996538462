import ExerciseMenuView from "./ExerciseMenuView";
import AlignIllustration from "assets/img/align_illustration.svg";
import { exerciseRoutes } from "constants/generalRoutes";

function AlignMenu() {
  return (
    <ExerciseMenuView
      title="Align"
      exercises={[
        {
          title: "Trials and Triumphs",
          description: "Explore Your Core Values",
          path: exerciseRoutes.TRIALS_TRIUMPHS,
          newWindow: false,
          accessGated: true,
        },
        {
          title: "Friction EQ",
          description: "Uncover your healthy and unhealthy states",
          path: exerciseRoutes.FEQ,
          newWindow: false,
          accessGated: true,
        },
      ]}
      illustration={AlignIllustration}
    />
  );
}
export default AlignMenu;
