import FormRow from "components/FormRow/FormRow";
import GBInputErrorText from "components/GBInputErrorText/GBInputErrorText";
import GBOutlinedInput from "components/GBOutlinedInput/GBOutlinedInput";
import GBStaticInputLabel from "components/GBStaticInputLabel/GBStaticInputLabel";
import { ValidationRule } from "constants/validationRules";
import { FormikProps } from "formik";
import { Namespaces } from "i18n";
import { useTranslation } from "react-i18next";
import { ImpactPurposePassionsFormValues } from "../ImpactPurposePassions.types";

type PassionsFormProps = FormikProps<ImpactPurposePassionsFormValues> & {};

function PassionsForm(props: PassionsFormProps) {
  const { values, handleBlur, handleChange, handleSubmit, touched, errors } =
    props;

  const { t } = useTranslation(Namespaces.Impact);

  const hasError = (name: keyof ImpactPurposePassionsFormValues): boolean => {
    return !!(touched[name] && errors[name]);
  };

  const getError = (
    field: keyof ImpactPurposePassionsFormValues,
    errorCode: ValidationRule,
  ) => {
    return t([
      `purpose.passions.form.${field}.errors.${errorCode}`,
      `${Namespaces.Common}:errors.${errorCode}`,
    ]);
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormRow>
        <GBStaticInputLabel
          labelFor="mainPassion"
          variant="thin"
          text={t("purpose.passions.form.mainPassion.label")}
        />
        <GBOutlinedInput
          fullWidth
          id="mainPassion"
          name="mainPassion"
          type="text"
          value={values.mainPassion}
          onChange={handleChange}
          onBlur={handleBlur}
          error={hasError("mainPassion")}
        />
        {hasError("mainPassion") && (
          <GBInputErrorText
            text={getError("mainPassion", errors.mainPassion as ValidationRule)}
          />
        )}
      </FormRow>
      <FormRow gutter="16px">
        <GBStaticInputLabel
          component="div"
          variant="thin"
          text={t("purpose.passions.form.passionsLabel")}
        />
        <GBOutlinedInput
          fullWidth
          id="passion1"
          name="passion1"
          type="text"
          value={values.passion1}
          onChange={handleChange}
          onBlur={handleBlur}
          error={hasError("passion1")}
        />
        {hasError("passion1") && (
          <GBInputErrorText
            text={getError("passion1", errors.passion1 as ValidationRule)}
          />
        )}
      </FormRow>
      <FormRow gutter="16px">
        <GBOutlinedInput
          fullWidth
          id="passion2"
          name="passion2"
          type="text"
          value={values.passion2}
          onChange={handleChange}
          onBlur={handleBlur}
          error={hasError("passion2")}
        />
        {hasError("passion2") && (
          <GBInputErrorText
            text={getError("passion2", errors.passion2 as ValidationRule)}
          />
        )}
      </FormRow>
      <FormRow gutter="16px">
        <GBOutlinedInput
          fullWidth
          id="passion3"
          name="passion3"
          type="text"
          value={values.passion3}
          onChange={handleChange}
          onBlur={handleBlur}
          error={hasError("passion3")}
        />
        {hasError("passion3") && (
          <GBInputErrorText
            text={getError("passion3", errors.passion3 as ValidationRule)}
          />
        )}
      </FormRow>
    </form>
  );
}

export default PassionsForm;
