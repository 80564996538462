import { Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { Namespaces } from "i18n";
import { Trans, useTranslation } from "react-i18next";
import SnapshotSectionLabel from "components/SnapshotSectionLabel/SnapshotSectionLabel";
import { useScoreSectionStyles } from "./styles/ScoreSection.styles";
import MentorImg from "assets/img/snap-score-mentor.svg";
import NudgeImg from "assets/img/snap-score-nudge.svg";
import PushImg from "assets/img/snap-score-push.svg";
import EmergencyImg from "assets/img/snap-score-emergency.svg";
import Alert from "@material-ui/lab/Alert";
import cx from "classnames";
import { getPurposeScoreI18nKey } from "utils/getPurposeScoreI18nKey";
import { PurposeScoreTypesI18nKey } from "constants/purposeScoreTypes";
import { useMemo } from "react";
import _ from "lodash";
import { ScoreSectionData } from "pages/Snapshot/components/Step1/Step1.types";
import GBDescription from "components/GBDescription/GBDescription";
import {
  EmotionalIntlScoreLevel,
  getEmotionalIntlScoreI18nKey,
} from "utils/getEmotionalIntlScoreI18nKey";

type ScoreSectionProps = {
  data: ScoreSectionData;
};

function ScoreSection(props: ScoreSectionProps) {
  const {
    data: { score, identities, emotionalIntlScore },
  } = props;

  const classes = useScoreSectionStyles();
  const { t } = useTranslation([
    Namespaces.Snapshot,
    Namespaces.Identity,
    Namespaces.Score,
  ]);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));

  const purposeScore = useMemo(
    () => getPurposeScoreI18nKey(score || 0),
    [score],
  );

  const scoreClases = cx(classes.score, {
    [classes.emergency]: purposeScore === PurposeScoreTypesI18nKey.Emergency,
    [classes.needPush]: purposeScore === PurposeScoreTypesI18nKey.NeedPush,
    [classes.needNudge]: purposeScore === PurposeScoreTypesI18nKey.NeedNudge,
  });

  const mobileScoreClases = cx(classes.mobileScore, {
    [classes.mobileEmergency]:
      purposeScore === PurposeScoreTypesI18nKey.Emergency,
    [classes.mobilePush]: purposeScore === PurposeScoreTypesI18nKey.NeedPush,
    [classes.mobileNudge]: purposeScore === PurposeScoreTypesI18nKey.NeedNudge,
  });

  const scoreImg = useMemo(() => {
    if (purposeScore === PurposeScoreTypesI18nKey.Emergency) {
      return EmergencyImg;
    } else if (purposeScore === PurposeScoreTypesI18nKey.NeedPush) {
      return PushImg;
    } else if (purposeScore === PurposeScoreTypesI18nKey.NeedNudge) {
      return NudgeImg;
    }
    return MentorImg;
  }, [purposeScore]);

  const emotionalIntlScoreI18nKey = useMemo(
    () => getEmotionalIntlScoreI18nKey(emotionalIntlScore || 0),
    [emotionalIntlScore],
  );

  const emotionalIntlScoreClasses = cx(classes.accent, {
    [classes.colorRed]:
      emotionalIntlScoreI18nKey === EmotionalIntlScoreLevel.CRITICAL,
    [classes.colorYellow]:
      emotionalIntlScoreI18nKey === EmotionalIntlScoreLevel.LOW,
    [classes.colorBlue]:
      emotionalIntlScoreI18nKey === EmotionalIntlScoreLevel.MIDDLE,
    [classes.colorGreen]:
      emotionalIntlScoreI18nKey === EmotionalIntlScoreLevel.HIGH,
  });

  return (
    <>
      <SnapshotSectionLabel text={t("step1.score.label")} color="yellow" />
      <Typography component="h4" variant="h4" className={classes.title}>
        {isXs ? (
          <Trans
            i18nKey={t("step1.score.purpose_score_mobile", {
              score,
            })}
            components={{
              color: <span className={mobileScoreClases} />,
            }}
          />
        ) : (
          t("step1.score.purpose_score")
        )}
      </Typography>
      <Grid container spacing={2} className={classes.container}>
        <Grid item lg>
          {score ? (
            <>
              <div className={classes.scoreImg}>
                <img src={scoreImg} alt="" />
              </div>
              {!isXs && <div className={scoreClases}>{score}</div>}
            </>
          ) : (
            <Alert severity="warning">
              {t("step1.score.warnings.not_completed_score")}
            </Alert>
          )}
        </Grid>
        <Grid item lg>
          {identities?.length ? (
            <div className={classes.dnaBlock}>
              <Typography component="h4" variant="h4" className={classes.title}>
                {t("step1.score.purpose_dna")}
              </Typography>
              <div className={classes.dnaList}>
                {identities.map((i, k) => (
                  <div key={k} className={classes.identityBlock}>
                    <div className={classes.identity}>
                      {t(`${Namespaces.Identity}:${i}.title`)}
                    </div>
                    {_.compact(
                      t(`${Namespaces.Identity}:${i}.description`).split("."),
                    ).map((text, k) => (
                      <div key={k} className={classes.identityText}>
                        <span>●</span>
                        {text}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <Alert severity="warning" className={classes.warn}>
              {t("step1.score.warnings.not_completed_dna")}
            </Alert>
          )}
        </Grid>
      </Grid>
      <div className={classes.emotionalIntlWrap}>
        {!!emotionalIntlScore ? (
          <GBDescription
            title={
              <Trans
                i18nKey={t("step1.score.emotionalIntl.title", {
                  score: emotionalIntlScore.toFixed(1) || 0,
                })}
                components={{
                  accent: <span className={emotionalIntlScoreClasses} />,
                }}
              />
            }
            text={t(
              `${Namespaces.Score}:emotionalIntl.result.results.${emotionalIntlScoreI18nKey}`,
            )}
          />
        ) : (
          <Alert severity="warning">
            {t("step1.score.warnings.not_completed_emotionalintl")}
          </Alert>
        )}
      </div>
    </>
  );
}

export default ScoreSection;
