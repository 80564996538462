import { useEffect, useState } from "react";
import { isTouchDevice as detectTouchDevice } from "utils/isTouchDevice";

export const useTouchDeviceDetector = (): {
  isTouchDevice: boolean;
} => {
  const [isTouchDevice, setIsTouchDevice] = useState<boolean>(() =>
    detectTouchDevice(),
  );

  useEffect(() => {
    const handleResize = () => {
      setIsTouchDevice(detectTouchDevice());
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return { isTouchDevice };
};
