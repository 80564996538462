import { API } from "utils/api/http-client";
import { apiEndpoints } from "constants/api/apiEndpoints";
import queryString from "query-string";
import { GetAdminUsersResponsePayload } from "types/admin/users/GetAdminUsersResponsePayload";
import { GetAdminUserDetailResponsePayload } from "types/admin/users/GetAdminUserDetailResponsePayload";
import { PaginationQueryParams } from "types/PaginationQueryParams";
import { GetAdminFeedbacksResponsePayload } from "types/admin/feedbacks/GetAdminFeedbacksResponsePayload";
import { DeleteAdminFeedbacksRequestPayload } from "types/admin/feedbacks/DeleteAdminFeedbacksRequestPayload";
import { SearchQueryParams } from "types/SearchQueryParams";
import { EntityId } from "types/EntityId";
import { GetPartnersResponsePayload } from "types/admin/partners/GetPartnersResponsePayload";
import { PutPartnerRequestPayload } from "types/admin/partners/PutPartnerRequestPayload";

const { superadmin } = apiEndpoints;

class SuperAdminApiService {
  // Users

  getUsers = (params: PaginationQueryParams & SearchQueryParams) => {
    return API.get<GetAdminUsersResponsePayload>(
      `${superadmin.users.getUsers}?${queryString.stringify(params)}`,
    );
  };

  getUserDetail = (id: EntityId) => {
    return API.get<GetAdminUserDetailResponsePayload>(
      superadmin.users.getUserDetail.replace(":id", id),
    );
  };

  // Partners

  getPartners = (params: PaginationQueryParams & SearchQueryParams) => {
    return API.get<GetPartnersResponsePayload>(
      `${superadmin.partners.getPartners}?${queryString.stringify(params)}`,
    );
  };

  putPartner = (data: PutPartnerRequestPayload) => {
    return API.put(superadmin.partners.putPartner, data);
  };

  // Feedbaks

  getFeedbacks = (params: PaginationQueryParams & SearchQueryParams) => {
    return API.get<GetAdminFeedbacksResponsePayload>(
      `${superadmin.feedbacks.getFeebacks}?${queryString.stringify(params)}`,
    );
  };

  deleteFeedbacks = (data: DeleteAdminFeedbacksRequestPayload) => {
    return API.delete(
      `${superadmin.feedbacks.deleteFeedback}?${queryString.stringify(data, {
        arrayFormat: "index",
      })}`,
    );
  };
}

export default new SuperAdminApiService();
