import { makeStyles, Theme } from "@material-ui/core/styles";
import { textColors } from "constants/themeSettings";

const useGBListStyles = makeStyles((theme: Theme) => ({
  root: {},
  label: {
    fontSize: "20px",
    lineHeight: "34px",
    fontWeight: 500,
    marginBottom: "16px",
  },
  liBlock: {
    display: "flex",
    alignItems: "center",
  },
  liMarker: {
    display: "block",
    height: "7px",
    width: "7px",
    borderRadius: "50%",
    backgroundColor: textColors.black.main,
  },
  markerWrap: {
    marginRight: "9px",
  },
  li: {
    fontSize: "24px",
    lineHeight: "32px",
    fontWeight: 700,
    marginBottom: "16px",
    display: "flex",
    alignItems: "center",

    "&:last-child": {
      marginBottom: "0",
    },
  },
  liFlexStart: {
    alignItems: "flex-start",
  },
  markerFlexStart: {
    paddingTop: "12px",
  },
}));

export { useGBListStyles };
