import { makeStyles, Theme } from "@material-ui/core/styles";
import { WIDE_CONTAINER_WIDTH } from "constants/settings";
import { textColors } from "constants/themeSettings";

let barStyles = {
  height: "10px",
  margin: "5px 2px",
  red: "#D34837",
  yellow: "#E3AD42",
  blue: "#2C8CE6",
  green: "#4E9F68",
  borderRadius: "5px",
};

const usePulseResultFieldStyles = makeStyles<Theme, { value: number }>(
  (theme: Theme) => ({
    root: {},

    fullWidth: {
      width: "100%",
    },
    insight: { margin: "10px 0px" },
    pulseBar: {
      width: "100%",
      display: "flex",
      position: "relative",
      [theme.breakpoints.up(WIDE_CONTAINER_WIDTH)]: {
        marginRight: "10px",
        "&:last-child": {
          marginRight: "0",
        },
      },
    },
    insightTitle: {
      // fontWeight: "Medium",
      fontSize: "20px",
      color: "#F7882F",
      marginBottom: "5px",
    },
    dot: ({ value }) => ({
      width: "20px",
      height: "20px",
      backgroundColor: "#F7882F",
      border: "2px solid white",
      borderRadius: "50%",
      position: "absolute",
      left: `${value}%`,
    }),
    greenBar: {
      width: "25%",
      height: barStyles.height,
      margin: barStyles.margin,
      backgroundColor: barStyles.green,

      borderTopRightRadius: barStyles.borderRadius,
      borderBottomRightRadius: barStyles.borderRadius,
    },
    blueBar: {
      width: "25%",
      height: barStyles.height,
      margin: barStyles.margin,
      backgroundColor: barStyles.blue,
    },
    yellowBar: {
      width: "25%",
      height: barStyles.height,
      margin: barStyles.margin,
      backgroundColor: barStyles.yellow,
    },
    redBar: {
      width: "25%",
      height: barStyles.height,
      margin: barStyles.margin,
      backgroundColor: barStyles.red,
      borderTopLeftRadius: barStyles.borderRadius,
      borderBottomLeftRadius: barStyles.borderRadius,
    },
    selected: {
      filter: "brightness(1.2)",
    },
    tile: {
      [theme.breakpoints.down(WIDE_CONTAINER_WIDTH)]: {
        width: "auto",
      },
      [theme.breakpoints.down(500)]: {
        width: "auto",
        boxSizing: "initial",
        height: "38px",
      },
    },
    rangeText: {
      color: textColors.black.light,
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 400,
      paddingTop: "8px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      textAlign: "center",
      [theme.breakpoints.down(430)]: {
        fontSize: "12px",
      },
    },
    label: {
      width: "25%",
      "&:last-child": {
        textAlign: "right",
      },
      "&:first-child": {
        textAlign: "left",
      },
    },
    rangedTextRight: {
      textAlign: "right",
    },
  }),
);

export { usePulseResultFieldStyles };
