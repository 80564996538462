import { makeStyles, Theme } from "@material-ui/core/styles";

const useSnapshotStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingBottom: "50px",
  },
  errorBlock: {
    padding: "50px 0",
  },
  stepButtons: {
    paddingTop: "48px",
    paddingBottom: "105px",
  },
  stepsButtonsInner: {
    width: "100%",
    paddingTop: "8px",
    display: "flex",

    "& button:first-child": {
      marginRight: "16px",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  warninig: {
    marginTop: "40px",
  },
}));

export { useSnapshotStyles };
