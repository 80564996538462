import { makeStyles, Theme } from "@material-ui/core/styles";
import { textColors } from "constants/themeSettings";

const usePurposeScoreFormStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: "56px 0",
  },
  sublabel: {
    fontSize: "22px",
    lineHeight: "27px",
    fontWeight: 400,
    color: textColors.black.light,
    marginBottom: "24px",
  },
  divider: {
    marginTop: "56px",
  },
  formRow: {
    marginBottom: "40px",
  },
  formRowAge: {
    marginBottom: "64px",
  },
  quiz: {
    paddingTop: "64px",
  },
  quizFormRow: {
    marginBottom: "48px",
  },
  submitBlock: {
    paddingTop: "72px",
  },
  errorBlock: {
    paddingTop: "40px",
  },
  accent: {
    color: theme.palette.primary.main,
  },
}));

export { usePurposeScoreFormStyles };
