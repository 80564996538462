import { Alert } from "@material-ui/lab";
import FormRow from "components/FormRow/FormRow";
import GBCircularProgress from "components/GBCircularProgress/GBCircularProgress";
import GBSpin from "components/GBSpin/GBSpin";
import StageStepButton from "components/StageStepButton/StageStepButton";
import StageStepContainer from "components/StageStepContainer/StageStepContainer";
import StageStepFrame from "components/StageStepFrame/StageStepFrame";
import StageStepText from "components/StageStepText/StageStepText";
import StageStepTitle from "components/StageStepTitle/StageStepTitle";
import UnexpectedError from "components/UnexpectedError/UnexpectedError";
import { ApiErrorKey } from "constants/api/apiErrors";
import { useFormik } from "formik";
import { Namespaces } from "i18n";
import { useTranslation, Trans } from "react-i18next";
import FinalForm from "./components/FinalForm";
import { finalFormValidationSchema } from "./ImpactPurposeFinal.consts";
import { ImpactPurposeFinalFormValues } from "./ImpactPurposeFinal.types";
import { useImpactPurposeFinalStyles } from "./styles/ImpactPurposeFinal.styles";

type ImpactPurposeFinalViewProps = {
  onFormSubmit: (data: ImpactPurposeFinalFormValues) => void;
  initialValues: ImpactPurposeFinalFormValues;
  prevUrl: string;
  isUpdating: boolean;
  isFetching: boolean;
  isFetchError: boolean;
  getApiError: () => ApiErrorKey | undefined;
  purposeVerb: string | null;
  purposeComponent: string | null;
  purposeOutcome: string | null;
};

function ImpactPurposeFinalView(props: ImpactPurposeFinalViewProps) {
  const {
    onFormSubmit,
    prevUrl,
    isUpdating,
    isFetching,
    isFetchError,
    initialValues,
    getApiError,
    purposeVerb,
    purposeComponent,
    purposeOutcome,
  } = props;

  const { t } = useTranslation([Namespaces.Impact, Namespaces.Survey]);
  const classes = useImpactPurposeFinalStyles();

  const formik = useFormik<ImpactPurposeFinalFormValues>({
    enableReinitialize: true,
    validationSchema: finalFormValidationSchema,
    initialValues,
    onSubmit: onFormSubmit,
  });

  const isUncompletedPrevious =
    !purposeVerb || !purposeComponent || !purposeOutcome;

  return (
    <StageStepFrame
      title={t("purpose.title")}
      controls={[
        <StageStepButton.Back
          goTo={prevUrl}
          disabled={isFetching || isUpdating}
        >
          {t(`${Namespaces.Survey}:buttons.back`)}
        </StageStepButton.Back>,
        <StageStepButton.Next
          onClick={() => formik.handleSubmit()}
          disabled={isFetching || isUpdating || isUncompletedPrevious}
        >
          {t(`${Namespaces.Survey}:buttons.continue`)}
        </StageStepButton.Next>,
      ]}
      error={getApiError()}
    >
      <StageStepContainer>
        {isFetching ? (
          <GBCircularProgress keepHeight="318px" />
        ) : isFetchError ? (
          <UnexpectedError />
        ) : (
          <GBSpin spinning={isUpdating}>
            <FormRow>
              <StageStepTitle gutter="16px">
                {t("purpose.final.title")}
              </StageStepTitle>
              <StageStepText>{t("purpose.final.text")}</StageStepText>
            </FormRow>
            {!isUncompletedPrevious ? (
              <>
                <div className={classes.statement}>
                  <Trans
                    i18nKey={t(
                      "purpose.build.form.fields.purposeStatement.text",
                      {
                        impact: purposeVerb,
                        component: purposeComponent,
                        outcome: purposeOutcome,
                      },
                    )}
                    components={{
                      accent: <span className={classes.accent} />,
                    }}
                  />
                </div>

                <FinalForm {...formik} />
              </>
            ) : (
              <Alert severity="warning">
                {t(`${Namespaces.Survey}:warnings.uncompleted_previous`)}
              </Alert>
            )}
          </GBSpin>
        )}
      </StageStepContainer>
    </StageStepFrame>
  );
}

export default ImpactPurposeFinalView;
