import { useEffect, useState } from "react";
import { SurveyType, StageType, StepType } from "types/MainMenu";
import { useHistory, useParams } from "react-router";
import { MAIN_MENU } from "constants/mainMenu";
import { Survey } from "types/Survey";

const useSurvey = (): Survey => {
  const history = useHistory();
  const matchParams: { subcomponent?: string; step?: string; token?: string } =
    useParams();
  const [survey, setSurvey] = useState<SurveyType>();
  const [stage, setStage] = useState<StageType>();
  const [step, setStep] = useState<StepType>();
  const [nextUrl, setNextUrl] = useState<string>();
  const [prevUrl, setPrevUrl] = useState<string>();
  //Set Current Exercise
  useEffect(() => {
    const paths: Array<string> = history.location.pathname.split("/");
    console.log(paths);
    if (paths.length > 1) {
      const menuItemFound =
        Object.values(MAIN_MENU).find(
          (menuItem: SurveyType) => menuItem.path === paths[1],
        ) || Object.values(MAIN_MENU)[0];
      console.log("menuItemFound: ", menuItemFound);
      setSurvey(menuItemFound);
      if (menuItemFound && menuItemFound.stages) {
        const subMenuItemFound =
          Object.values(menuItemFound.stages).find(
            (subMenuItem: StageType) =>
              subMenuItem.path === matchParams.subcomponent,
          ) || Object.values(menuItemFound.stages)[0];
        console.log("subMenuItemFound: ", subMenuItemFound);
        setStage(subMenuItemFound);
        if (subMenuItemFound && subMenuItemFound.steps) {
          const subMenuStepFound =
            Object.values(subMenuItemFound.steps).find((subMenuStep) => {
              if (subMenuItemFound.path) {
                return subMenuStep.path === matchParams.step;
              } else {
                return subMenuStep.path === matchParams.subcomponent;
              }
            }) || Object.values(subMenuItemFound.steps)[0];
          console.log("step: ", subMenuItemFound);
          if (subMenuStepFound) {
            setStep(subMenuStepFound);
          }
        }
      }
    }
  }, [history.location.pathname, matchParams]);

  //Set Links
  useEffect(() => {
    let prevStage: string = "",
      prevStep: string = "",
      nextStage: string = "",
      nextStep: string = "";
    if (step && stage && survey?.stages) {
      const [currStep] =
        Object.entries(stage.steps).filter(
          ([key, val]) => val.path === step.path,
        )[0] || [];
      const [currStage] =
        Object.entries(survey.stages).filter(
          ([key, val]) => val.path === stage.path,
        )[0] || [];
      console.log("currStep: ", currStep);
      console.log("currStage: ", currStage);
      if (currStep && currStage) {
        const allSteps = Object.keys(stage.steps);
        const allStages = Object.keys(survey.stages);
        const currStepIndex = allSteps.indexOf(currStep);
        const currStageIndex = allStages.indexOf(currStage);
        if (currStepIndex < allSteps.length - 1) {
          nextStep = allSteps[currStepIndex + 1];
          nextStage = currStage;
        } else {
          if (currStageIndex < allStages.length - 1) {
            nextStage = allStages[currStageIndex + 1];
            nextStep = Object.keys(survey.stages[nextStage].steps)[0];
          }
        }
        console.log("currStepIndex: ", currStepIndex);
        if (currStepIndex > 0) {
          prevStep = allSteps[currStepIndex - 1];
          prevStage = currStage;
        } else {
          prevStage = "";
          // if (currStageIndex > 0) {
          //   prevStage = allStages[currStageIndex - 1];
          //   prevStep =
          //     Object.keys(survey.stages[nextStage].steps).slice(-1).pop() || "";
          // }
        }
      }
      setNextUrl(buildUrl(survey, nextStage, nextStep));
      setPrevUrl(buildUrl(survey, prevStage, prevStep));
    }
  }, [survey, stage, step]);

  const buildUrl = (
    survey: SurveyType,
    stage: string,
    step: string,
  ): string => {
    let url: Array<string> = [];
    if (survey?.path && stage && step) {
      url.push(survey.path.toString());
      const stageObj: StageType | undefined = survey.stages?.[stage];
      if (stageObj) {
        if (stageObj.path) url.push(stageObj.path);
        const stepObj: StepType = stageObj.steps[step];
        if (stepObj && stepObj.path) {
          url.push(stepObj.path);
          return `/${url.join("/")}`;
        }
      }
    }
    return "";
  };

  return {
    survey,
    stage,
    step,
    prevUrl,
    nextUrl,
  };
};

export { useSurvey };
