import { ValidationRule } from "constants/validationRules";
import * as Yup from "yup";

export const buildFormValidationSchema = Yup.object({
  promiseVerb: Yup.string().trim().required(ValidationRule.Required),
  promiseComponent: Yup.string()
    .trim()
    .max(100, ValidationRule.Max)
    .required(ValidationRule.Required),
  finalPromise: Yup.string().trim().required(),
});
