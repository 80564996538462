import React from "react";
import { SvgIcon } from "@material-ui/core";

function GBPasswordInvisibleIcon(props: React.ComponentProps<typeof SvgIcon>) {
  return (
    <SvgIcon {...props} viewBox="0 0 33 32">
      <path
        fill="none"
        stroke="#687a8f"
        strokeLinejoin="miter"
        strokeLinecap="butt"
        strokeMiterlimit="4"
        strokeWidth="2"
        d="M1.333 10.667c0 0 5.333 9.333 14.667 9.333s14.667-9.333 14.667-9.333"
      ></path>
      <path
        fill="none"
        stroke="#687a8f"
        strokeLinejoin="miter"
        strokeLinecap="butt"
        strokeMiterlimit="4"
        strokeWidth="2"
        d="M16 20v5.333"
      ></path>
      <path
        fill="none"
        stroke="#687a8f"
        strokeLinejoin="miter"
        strokeLinecap="butt"
        strokeMiterlimit="4"
        strokeWidth="2"
        d="M23.333 18l2.643 4.4"
      ></path>
      <path
        fill="none"
        stroke="#687a8f"
        strokeLinejoin="miter"
        strokeLinecap="butt"
        strokeMiterlimit="4"
        strokeWidth="2"
        d="M9.333 18.667l-2.667 4"
      ></path>
    </SvgIcon>
  );
}

export default GBPasswordInvisibleIcon;
