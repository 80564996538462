import { useImpactCardStyles } from "./styles/ImpactCard.styles";
import { SortableElement } from "react-sortable-hoc";
import { IdentityVerb } from "types/score/DNA";
import TextChip from "components/TextChip/TextChip";
import DragIcon from "components/svg-icons/DragIcon";
import { Namespaces } from "i18n";
import { useTranslation } from "react-i18next";
import { useMediaQuery, useTheme } from "@material-ui/core";

type ImpactCardProps = {
  impact: IdentityVerb;
  rank: number;
};

function ImpactCard(props: ImpactCardProps) {
  const { impact, rank } = props;
  const { t } = useTranslation([Namespaces.Identity]);
  const classes = useImpactCardStyles();
  const theme = useTheme();
  const isDown500 = useMediaQuery(theme.breakpoints.down(500));

  return (
    <div className={classes.cardOuter}>
      {!isDown500 && (
        <TextChip className={classes.number} text={rank.toString()} />
      )}
      <div className={classes.card}>
        {t(`${Namespaces.Identity}:${impact.identity}.verbs.${impact.verb}`)}
        <DragIcon className={classes.icon} />
      </div>
    </div>
  );
}

export default SortableElement(ImpactCard);
