import GBPasswordInvisibleIcon from "components/svg-icons/GBPasswordInvisibleIcon";
import GBPasswordVisibleIcon from "components/svg-icons/GBPasswordVisibleIcon";
import { usePasswordTogglerStyles } from "./styles/PasswordToggler.styles";

type PasswordTogglerProps = {
  isVisible: boolean;
  setIsVisible: (state: boolean) => void;
};

function PasswordToggler(props: PasswordTogglerProps) {
  const { isVisible, setIsVisible } = props;
  const classes = usePasswordTogglerStyles();

  return (
    <div
      className={classes.passwdToggler}
      onClick={() => setIsVisible(!isVisible)}
    >
      {isVisible ? <GBPasswordVisibleIcon /> : <GBPasswordInvisibleIcon />}
    </div>
  );
}

export default PasswordToggler;
