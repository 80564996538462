import { ValidationRule } from "constants/validationRules";
import * as Yup from "yup";

export const conflictBeginFormValidationSchema = Yup.object().shape({
  conflict1: Yup.number()
    .moreThan(0, ValidationRule.Required)
    .required(ValidationRule.Required),
  conflict2: Yup.number()
    .moreThan(0, ValidationRule.Required)
    .required(ValidationRule.Required),
  conflict3: Yup.number()
    .moreThan(0, ValidationRule.Required)
    .required(ValidationRule.Required),
  conflict4: Yup.number()
    .moreThan(0, ValidationRule.Required)
    .required(ValidationRule.Required),
  conflict5: Yup.number()
    .moreThan(0, ValidationRule.Required)
    .required(ValidationRule.Required),
});
