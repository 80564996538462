import { GrowConflictBeginI18nKey } from "constants/grow/growI18nKeys";
import { GrowResponsePayload } from "types/grow/GetGrowResponsePayload";
import { GrowSituation } from "types/grow/GrowSituation";
import { getSituationValue } from "../Grow.utils";
import { ConflictBeginFormValues } from "./GrowConflictBegin.types";

export const prepareInitialValues = (
  grow: GrowResponsePayload | undefined,
): ConflictBeginFormValues => {
  const conflicts = grow?.situations?.conflict1;
  return {
    conflict1: getSituationValue(GrowConflictBeginI18nKey.Conflict1, conflicts),
    conflict2: getSituationValue(GrowConflictBeginI18nKey.Conflict2, conflicts),
    conflict3: getSituationValue(GrowConflictBeginI18nKey.Conflict3, conflicts),
    conflict4: getSituationValue(GrowConflictBeginI18nKey.Conflict4, conflicts),
    conflict5: getSituationValue(GrowConflictBeginI18nKey.Conflict5, conflicts),
  };
};

export const prepareConflictToSubmit = (
  data: ConflictBeginFormValues,
): GrowSituation<GrowConflictBeginI18nKey>[] => {
  const entries = Object.entries(data);
  return entries.map((e) => ({
    key: e[0] as GrowConflictBeginI18nKey,
    value: e[1],
  }));
};
