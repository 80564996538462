import React from "react";
import { useQuizTileStyles } from "./styles/QuizTile.styles";
import cx from "classnames";

type QuizTileProps = React.HTMLAttributes<HTMLDivElement> & {
  variant?: "small";
  textAlign?: "right" | "left";
  selected?: boolean;
  disabled?: boolean;
  title: string;
  text?: string;
  titleClassName?: string;
  error?: boolean;
};

function QuizTile(props: QuizTileProps) {
  const {
    variant = "default",
    textAlign,
    className,
    titleClassName,
    onClick,
    selected,
    disabled,
    title,
    text,
    error,
    ...rest
  } = props;

  const classes = useQuizTileStyles();

  const rootClasses = cx(
    classes.root,
    {
      [classes.small]: variant === "small",
      [classes.textRight]: textAlign === "right",
      [classes.textLeft]: textAlign === "left",
      [classes.selected]: selected && !disabled,
      [classes.disabled]: disabled,
      [classes.error]: error,
    },
    className,
  );

  const titleClasses = cx(
    classes.title,
    {
      [classes.titleSelected]: selected && !disabled,
      [classes.titleSmall]: variant === "small",
    },
    titleClassName,
  );

  return (
    <div {...rest} onClick={onClick} className={rootClasses}>
      <div className={titleClasses}>{title}</div>
      {text && variant === "default" ? (
        <div className={classes.text}>{text}</div>
      ) : null}
    </div>
  );
}

export default QuizTile;
