import React from "react";
import { SvgIcon } from "@material-ui/core";

function ArrowLeftIcon(props: React.ComponentProps<typeof SvgIcon>) {
  return (
    <SvgIcon {...props} viewBox="0 0 36 32">
      <path d="M36.253 17.013c0.203 0 0.203 0 0 0 0.203-0.405 0.203-0.608 0.203-1.013 0-0.203 0-0.608-0.203-0.81 0 0 0-0.203-0.203-0.203 0-0.203-0.203-0.405-0.405-0.405v0l-16.203-14.177c-0.81-0.81-2.025-0.608-2.835 0.203s-0.608 2.025 0.203 2.835l12.152 10.532h-26.937c-1.215 0-2.025 0.81-2.025 2.025s0.81 2.025 2.025 2.025h26.937l-12.152 10.532c-0.81 0.81-1.013 2.025-0.203 2.835 0.405 0.405 1.013 0.608 1.62 0.608 0.405 0 1.013-0.203 1.418-0.405l16.203-14.177c0.203 0 0.405-0.203 0.405-0.405z"></path>
    </SvgIcon>
  );
}

export default ArrowLeftIcon;
