import { makeStyles, Theme } from "@material-ui/core/styles";
import { commonColors, textColors } from "constants/themeSettings";

const useFooterStyles = makeStyles<Theme>((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    width: "100%",
    height: "150px",
    bottom: 0,
    left: 0,
    [theme.breakpoints.down("sm")]: {
      height: "210px",
    },
    [theme.breakpoints.down(750)]: {
      height: "400px",
    },
    [theme.breakpoints.down(450)]: {
      height: "440px",
    },
  },
  footerTop: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
      paddingTop: "30px",
    },
  },
  logo: {
    display: "flex",
    alignItems: "center",
    "& img": {
      height: "27px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      justifyContent: "center",
      marginBottom: "10px",
    },
  },
  nav: {
    display: "flex",
    justifyContent: "space-between",
    margin: "0",
    padding: "0",
    alignItems: "center",
    listStyleType: "none",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.down(750)]: {
      flexDirection: "column",
      padding: "15px 0",
    },
  },
  navItem: {
    "& > *": {
      padding: "0 20px !important",
      cursor: "pointer",
      height: "80px",
      display: "flex",
      alignItems: "center",
      fontSize: "18px",
      lineHeight: "22px",
      fontWeight: 500,
      border: "none",
      background: "none",
      color: textColors.black.main,
      margin: "0",
      textDecoration: "none",
      "&:hover": {
        color: theme.palette.primary.main,
      },
      [theme.breakpoints.down(750)]: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        padding: "20px 0 !important",
        height: "60px",
      },
    },
    "&:last-child > *": {
      paddingRight: "0 !important",
      [theme.breakpoints.down("sm")]: {
        paddingRight: "20px !important",
      },
      [theme.breakpoints.down(750)]: {
        paddingRight: "0 !important",
      },
    },
    [theme.breakpoints.down(750)]: {
      width: "100%",
    },
  },
  rootHidePricing: {
    height: "400px",
  },
  hr: {
    backgroundColor: "#A4AFBC",
    border: "none",
    height: "1px",
    opacity: ".3",
    margin: "0",
  },
  footerBottom: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: "16px",
    [theme.breakpoints.down(450)]: {
      justifyContent: "center",
      flexDirection: "column-reverse",
    },
  },
  rights: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "17px",
    color: textColors.black.light,
    cursor: "default",
    [theme.breakpoints.down(450)]: {
      padding: "20px 0",
    },
  },
  socials: {
    display: "flex",
    justifyContent: "space-between",
  },
  social: {
    padding: "0 20px",
    display: "flex",
    alignItems: "center",
    "& svg": {
      fill: commonColors.gray,
    },
    "&:hover": {
      "& svg": {
        fill: theme.palette.primary.main,
      },
    },
    "&:last-child": {
      padding: "0 0 0 20px",
      [theme.breakpoints.down(450)]: {
        padding: "0 20px",
      },
    },
  },
}));

export { useFooterStyles };
