import { MenuItem, useMediaQuery, useTheme } from "@material-ui/core";
import FormRow from "components/FormRow/FormRow";
import GBDescription from "components/GBDescription/GBDescription";
import GBInputErrorText from "components/GBInputErrorText/GBInputErrorText";
import GBSelect from "components/GBSelect/GBSelect";
import GBStaticInputLabel from "components/GBStaticInputLabel/GBStaticInputLabel";
import { ELLIPSIS, WIDE_CONTAINER_WIDTH } from "constants/settings";
import { ValidationRule } from "constants/validationRules";
import { FormikProps } from "formik";
import { Namespaces } from "i18n";
import { useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import { ImpactPurposeBuildFormValues } from "../../ImpactPurposeBuild.types";
import { useBuildFormStyles } from "./styles/BuildForm.styles";

type BuildFormProps = FormikProps<ImpactPurposeBuildFormValues> & {
  abstractComponents: string[];
  outcomeComponents: string[];
  impactVerbs: string[];
};

function BuildForm(props: BuildFormProps) {
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    errors,
    setFieldValue,
    abstractComponents,
    outcomeComponents,
    impactVerbs,
  } = props;

  const classes = useBuildFormStyles();
  const { t } = useTranslation(Namespaces.Impact);
  const theme = useTheme();
  const isDownWideContainer = useMediaQuery(
    theme.breakpoints.down(WIDE_CONTAINER_WIDTH),
  );

  const hasError = (name: keyof ImpactPurposeBuildFormValues): boolean => {
    return !!(touched[name] && errors[name]);
  };

  const getError = (
    field: keyof ImpactPurposeBuildFormValues,
    errorCode: ValidationRule,
  ) => {
    return t([
      `purpose.build.form.${field}.errors.${errorCode}`,
      `${Namespaces.Common}:errors.${errorCode}`,
    ]);
  };

  useEffect(() => {
    setFieldValue(
      "purposeStatement",
      t("purpose.build.form.fields.purposeStatement.text", {
        impact: values.purposeVerb,
        component: values.purposeComponent,
        outcome: values.purposeOutcome,
      }),
    );
  }, [
    classes.accent,
    setFieldValue,
    t,
    values.purposeComponent,
    values.purposeOutcome,
    values.purposeVerb,
  ]);

  return (
    <form onSubmit={handleSubmit}>
      <GBDescription
        className={classes.statementBlock}
        title={
          <Trans
            i18nKey={t("purpose.build.form.fields.purposeStatement.text", {
              impact: values.purposeVerb || ELLIPSIS,
              component: values.purposeComponent || ELLIPSIS,
              outcome: values.purposeOutcome || ELLIPSIS,
            })}
            components={{
              accent: <span className={classes.accent} />,
            }}
          />
        }
        text={t("purpose.build.form.fields.purposeStatement.description")}
        textClassName={classes.statementText}
        titleClassName={classes.statementTitle}
      />
      <FormRow>
        {!isDownWideContainer && (
          <div className={classes.flexBox}>
            <GBStaticInputLabel
              labelFor="purposeVerb"
              className={classes.selectLabel}
              variant="thin"
              text={t("purpose.build.form.fields.purposeVerb.label")}
            />
            <GBStaticInputLabel
              labelFor="purposeComponent"
              className={classes.selectLabel}
              variant="thin"
              text={t("purpose.build.form.fields.purposeComponent.label")}
            />
            <GBStaticInputLabel
              labelFor="purposeOutcome"
              className={classes.selectLabel}
              variant="thin"
              text={t("purpose.build.form.fields.purposeOutcome.label")}
            />
          </div>
        )}
        <div className={classes.flexBox}>
          <div>
            {isDownWideContainer && (
              <GBStaticInputLabel
                labelFor="purposeVerb"
                className={classes.selectLabel}
                variant="thin"
                text={t("purpose.build.form.fields.purposeVerb.label")}
              />
            )}
            <GBSelect
              id="purposeVerb"
              name="purposeVerb"
              value={values.purposeVerb}
              onChange={handleChange}
              onBlur={handleBlur}
              error={hasError("purposeVerb")}
              className={classes.select}
              inputProps={{
                id: "purposeVerb",
              }}
            >
              {impactVerbs.map((w, k) => (
                <MenuItem key={k} value={t(w)}>
                  {t(w)}
                </MenuItem>
              ))}
            </GBSelect>
            {hasError("purposeVerb") && (
              <GBInputErrorText
                text={getError(
                  "purposeVerb",
                  errors.purposeVerb as ValidationRule,
                )}
              />
            )}
          </div>
          <div>
            {isDownWideContainer && (
              <GBStaticInputLabel
                labelFor="purposeComponent"
                className={classes.selectLabel}
                variant="thin"
                text={t("purpose.build.form.fields.purposeComponent.label")}
              />
            )}
            <GBSelect
              id="purposeComponent"
              name="purposeComponent"
              value={values.purposeComponent}
              onChange={handleChange}
              onBlur={handleBlur}
              error={hasError("purposeComponent")}
              className={classes.select}
              inputProps={{
                id: "purposeComponent",
              }}
            >
              {abstractComponents.map((a, k) => (
                <MenuItem key={k} value={a}>
                  {a}
                </MenuItem>
              ))}
            </GBSelect>
            {hasError("purposeComponent") && (
              <GBInputErrorText
                text={getError(
                  "purposeComponent",
                  errors.purposeComponent as ValidationRule,
                )}
              />
            )}
          </div>
          <div>
            {isDownWideContainer && (
              <GBStaticInputLabel
                labelFor="purposeOutcome"
                className={classes.selectLabel}
                variant="thin"
                text={t("purpose.build.form.fields.purposeOutcome.label")}
              />
            )}
            <GBSelect
              id="purposeOutcome"
              name="purposeOutcome"
              value={values.purposeOutcome}
              onChange={handleChange}
              onBlur={handleBlur}
              error={hasError("purposeOutcome")}
              className={classes.select}
              inputProps={{
                id: "purposeOutcome",
              }}
            >
              {outcomeComponents.map((a, k) => (
                <MenuItem key={k} value={a}>
                  {a}
                </MenuItem>
              ))}
            </GBSelect>
            {hasError("purposeOutcome") && (
              <GBInputErrorText
                text={getError(
                  "purposeOutcome",
                  errors.purposeOutcome as ValidationRule,
                )}
              />
            )}
          </div>
        </div>
      </FormRow>
    </form>
  );
}

export default BuildForm;
