import { Namespaces } from "i18n";
import GBList from "components/GBList/GBList";
import { AlignSectionData } from "../../AdminUserSnapshot.types";
import { useAlignSectionStyles } from "./styles/AlignSection.styles";
import SnapshotSectionLabel from "components/SnapshotSectionLabel/SnapshotSectionLabel";
import { useTranslation } from "react-i18next";
import GBDescription from "components/GBDescription/GBDescription";

type AlignSectionProps = {
  data: AlignSectionData;
};

function AlignSection(props: AlignSectionProps) {
  const {
    data: { trials, triumphs },
  } = props;

  const classes = useAlignSectionStyles();
  const { t } = useTranslation(Namespaces.Admin);

  return (
    <>
      <SnapshotSectionLabel
        text={t("snapshot.content.align.label")}
        color="gray"
      />
      {trials?.length && triumphs?.length && (
        <div className={classes.content}>
          <GBDescription
            className={classes.desc}
            title={`${t("snapshot.content.align.trials")}:`}
            text={
              <GBList
                items={trials}
                markerFlex="flex-start"
                itemClassName={classes.item}
              />
            }
          />
          <GBDescription
            className={classes.desc}
            title={`${t("snapshot.content.align.triumphs")}:`}
            text={
              <GBList
                items={triumphs}
                markerFlex="flex-start"
                itemClassName={classes.item}
              />
            }
          />
        </div>
      )}
    </>
  );
}

export default AlignSection;
