import { Typography, useMediaQuery, useTheme } from "@material-ui/core";
import FormRow from "components/FormRow/FormRow";
import GBOutlinedInput from "components/GBOutlinedInput/GBOutlinedInput";
import TextChip from "components/TextChip/TextChip";
import { ALIGN_SURVEY_THEME_FIELDS_COUNT } from "constants/settings";
import { FieldArray, Form, FormikProps } from "formik";
import { Namespaces } from "i18n";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AlignThemeFormValues } from "../../AlignTheme.types";
import { useThemeFormStyles } from "./styles/ThemeForm.styles";

type ThemeFormProps = FormikProps<AlignThemeFormValues> & {
  setFormError: (code: string | null) => void;
  submitButtonRef: React.RefObject<HTMLButtonElement>;
};

function ThemeForm(props: ThemeFormProps) {
  const {
    setFormError,
    submitButtonRef,
    errors,
    values,
    handleChange,
    handleBlur,
  } = props;

  const classes = useThemeFormStyles();
  const { t } = useTranslation(Namespaces.Align);
  const theme = useTheme();
  const isDownXs = useMediaQuery(theme.breakpoints.down("xs"));

  const formErrors = useMemo(() => Object.values(errors), [errors]);

  useEffect(() => {
    if (formErrors.length && typeof formErrors[0] === "string") {
      setFormError(formErrors[0]);
    } else {
      setFormError(null);
    }
  }, [formErrors, setFormError]);

  return (
    <Form className={classes.wrapper}>
      <div>
        <Typography variant="h4" component="h4" className={classes.label}>
          {t("steps.theme.trials.title")}
        </Typography>
        <FieldArray
          name="importantTrials"
          render={() => (
            <>
              {values.importantTrials.map((trial, index) => (
                <FormRow key={index} className={classes.formRow}>
                  <GBOutlinedInput
                    id="importantTrials"
                    name={`importantTrials.${index}`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={trial}
                    error={!!errors.importantTrials}
                    className={classes.input}
                    placeholder={t("steps.theme.form.field.placeholder", {
                      number: index + 1,
                    })}
                  />
                  {!isDownXs && (
                    <TextChip
                      className={classes.number}
                      text={(index + 1).toString()}
                    />
                  )}
                </FormRow>
              ))}
            </>
          )}
        />
      </div>
      <div>
        <Typography variant="h4" component="h4" className={classes.label}>
          {t("steps.theme.triumphs.title")}
        </Typography>
        <FieldArray
          name="importantTriumphs"
          render={() => (
            <>
              {values.importantTriumphs.map((triumph, index) => (
                <FormRow key={index} className={classes.formRow}>
                  <GBOutlinedInput
                    id="importantTriumphs"
                    name={`importantTriumphs.${index}`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={triumph}
                    error={!!errors.importantTriumphs}
                    className={classes.input}
                    placeholder={t("steps.theme.form.field.placeholder", {
                      number: index + ALIGN_SURVEY_THEME_FIELDS_COUNT + 1,
                    })}
                  />
                </FormRow>
              ))}
            </>
          )}
        />
      </div>
      <button ref={submitButtonRef} type="submit" hidden></button>
    </Form>
  );
}

export default ThemeForm;
