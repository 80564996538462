import { UserType } from "constants/userTypes";
import { EntityId } from "types/EntityId";
import { PaymentStatus } from "types/payment/PaymentStatus";

export enum AuthActions {
  LOGIN_SUCCESS = "auth/LOGIN_SUCCESS",
  SIGNUP_SUCCESS = "auth/SIGNUP_SUCCESS",
  LOGOUT_SUCCESS = "auth/LOGOUT_SUCCESS",
  SET_USER = "auth/SET_USER",
}

export type AuthState = {
  isAuthenticated: boolean;
  user: AuthStoreUserType | null;
};

export type AuthStoreUserType = {
  id: EntityId;
  firstName: string;
  lastName: string;
  email: string;
  userType: UserType;
  createdAt: string;
  paymentStatus: PaymentStatus;
};

export type LoginSuccessAction = {
  type: AuthActions.LOGIN_SUCCESS;
};

export type SignupSuccessAction = {
  type: AuthActions.SIGNUP_SUCCESS;
};

export type LogoutSuccessAction = {
  type: AuthActions.LOGOUT_SUCCESS;
};

export type SetUserAction = {
  type: AuthActions.SET_USER;
  payload: AuthStoreUserType;
};

export type AuthActionTypes =
  | LoginSuccessAction
  | SignupSuccessAction
  | LogoutSuccessAction
  | SetUserAction;
