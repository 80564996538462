import {
  AuthModalActions,
  AuthModalActionTypes,
  AuthModalState,
} from "./types";

export const initialAuthModalState: AuthModalState = {
  open: false,
  exerciseLink: "",
};

export function authModal(
  state = initialAuthModalState,
  action: AuthModalActionTypes,
): AuthModalState {
  switch (action.type) {
    case AuthModalActions.OPEN:
      return {
        ...state,
        open: true,
        exerciseLink: action?.exerciseLink,
      };
    case AuthModalActions.CLOSE:
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
}
