import { makeStyles, Theme } from "@material-ui/core/styles";

const useMobileMenuStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: "10px 0",
    width: "300px",
  },
  navItem: {
    marginTop: "15px",
    padding: "0",
    display: "block",

    "&:first-child": {
      marginTop: "0",
    },
    "& > *": {
      padding: "10px 25px",
      width: "100%",
      display: "block",
    },
  },
  navBtn: {
    padding: "10px 25px",
  },
}));

export { useMobileMenuStyles };
