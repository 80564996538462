import { useExerciseLinkStyles } from "./styles/ExerciseLink.styles";
import { useHistory } from "react-router";
import { exercise } from "types/menus/exercise";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authModalActions } from "store/authModal/actions";

export default function ExerciseLink(props: exercise) {
  const history = useHistory();
  const classes = useExerciseLinkStyles();
  const { title, description, path, newWindow, accessGated } = props;
  const dispatch = useDispatch();
  return accessGated ? (
    <div
      style={{ textDecoration: "none", color: "inherit" }}
      key={title}
      onClick={() => dispatch(authModalActions.open(path))}
      className={classes.exerciseLink}
    >
      <img />
      <div>
        <h2 className={classes.exerciseTitle}>{title}</h2>
        <h4 className={classes.exerciseDescription}>{description}</h4>
      </div>
    </div>
  ) : (
    <Link
      style={{ textDecoration: "none", color: "inherit" }}
      // push
      to={`${history.location.pathname}${path}`}
      key={title}
      target={newWindow ? "_blank" : ""}
      className={classes.exerciseLink}
    >
      <img />
      <div>
        <h2 className={classes.exerciseTitle}>{title}</h2>
        <h4 className={classes.exerciseDescription}>{description}</h4>
      </div>
    </Link>
  );
}
