import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { ApiErrorKey } from "constants/api/apiErrors";
import { generalRoutes } from "constants/generalRoutes";
import { useFormik } from "formik";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import AuthenticationApiService from "services/AuthenticationApiService";
import CommonApiService from "services/CommonApiService";
import PaymentApiService from "services/PaymentApiService";
import { authActions } from "store/auth/actions";
import { ApiClientError } from "types/api/ApiClientError";
import { PaymentPrice } from "types/payment/PaymentPrices";
import { selectApiError } from "utils/api/selectApiError";
import {
  partnerSignupFormInitialValues,
  partnerSignupFormValidationSchema,
} from "./PartnerSignup.conts";
import {
  PartnerSignupFormValues,
  CustomerDataState,
} from "./PartnerSignup.types";
import PartnerSignupView from "./PartnerSignupView";

function PartnerSignup() {
  const history = useHistory();
  const stripeElements = useElements();
  const stripe = useStripe();
  const dispatch = useDispatch();

  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<ApiErrorKey | null>(null);
  const [partnerSignupToken, setPartnerSignupToken] = useState<string | null>(
    null,
  );
  const [customerData, setCustomerData] = useState<CustomerDataState>({
    customerId: null,
    priceId: null,
  });
  const [isPaymentStep, setIsPaymentStep] = useState<boolean>(false);
  const [isCodeGenerating, setIsCodeGenerating] = useState<boolean>(false);

  const handleSubmit = async (data: PartnerSignupFormValues) => {
    setErrorCode(null);
    setPartnerSignupToken(null);
    setIsProcessing(true);

    try {
      const configResponse = await PaymentApiService.getConfig();
      const config = configResponse.data?.payload;

      const priceId = config.prices.filter(
        (i) => i.name === PaymentPrice.Basic,
      )[0]?.id;

      if (priceId) {
        const customerResponse = await PaymentApiService.createCustomer({
          email: data.email,
        });
        const { customer } = customerResponse.data?.payload;
        const customerId = customer.id;

        if (customerId) {
          setCustomerData({
            customerId,
            priceId,
          });

          const signupData = await AuthenticationApiService.registerPartner({
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            password: data.password,
            advCode: data.advCode,
            customerId,
          });

          const { token } = signupData.data?.payload;

          if (token) {
            setIsProcessing(false);
            setPartnerSignupToken(token);
            setIsPaymentStep(true);
          } else {
            throw Error("Unexpected error. No token was provided.");
          }
          setIsProcessing(false);
        } else {
          throw Error("Unexpected error. Customer not found.");
        }
      } else {
        throw Error("Unexpected error. No price was found.");
      }
    } catch (e) {
      console.error(e);
      const error = e as ApiClientError;
      setIsProcessing(false);
      setErrorCode(selectApiError(error.response?.data.message));
    }
  };

  const handlePayment = async () => {
    const card = stripeElements?.getElement(CardElement);
    const { customerId, priceId } = customerData;
    if (stripe && card && partnerSignupToken && customerId && priceId) {
      try {
        setIsProcessing(true);
        const subscriptionResponse = await PaymentApiService.createSubscription(
          {
            customerId,
            priceId,
          },
        );
        const { subscriptionId, clientSecret } =
          subscriptionResponse.data?.payload;

        if (subscriptionId && clientSecret) {
          const { error, paymentIntent } = await stripe.confirmCardPayment(
            clientSecret,
            {
              payment_method: {
                card,
                billing_details: {
                  name: `${formik.values.firstName} ${formik.values.lastName}`,
                  email: formik.values.email,
                },
              },
            },
          );

          if (error) {
            throw Error("Unexpected error. Cannot perform payment.");
          } else {
            if (paymentIntent) {
              setIsProcessing(false);
              formik.resetForm();
              dispatch(authActions.signup(partnerSignupToken));
              window.location.pathname = generalRoutes.USER_MENU;
            } else {
              throw Error("Unexpected error. Payment intent is not available.");
            }
          }
        } else {
          throw Error(
            "Unexpected error. Payment confirmation data is not found.",
          );
        }
      } catch (e) {
        console.error(e);
        const error = e as ApiClientError;
        setIsProcessing(false);
        setErrorCode(selectApiError(error.response?.data.message));
      }
    }
  };

  const generateRegCode = async () => {
    setIsCodeGenerating(true);
    const data = await CommonApiService.generateRegistrationCode();
    setIsCodeGenerating(false);
    formik.setFieldValue("advCode", data.data.payload.code);
  };

  const formik = useFormik<PartnerSignupFormValues>({
    validationSchema: partnerSignupFormValidationSchema,
    enableReinitialize: true,
    initialValues: partnerSignupFormInitialValues,
    onSubmit: handleSubmit,
  });

  const handleGoToLogin = () => {
    history.push(generalRoutes.PARTNER_LOGIN);
  };

  return (
    <PartnerSignupView
      isProcessing={isProcessing}
      errorCode={errorCode}
      isPaymentStep={isPaymentStep}
      handlePayment={handlePayment}
      handleGoToLogin={handleGoToLogin}
      isCodeGenerating={isCodeGenerating}
      generateRegCode={generateRegCode}
      {...formik}
    />
  );
}

export default PartnerSignup;
