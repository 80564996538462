import { makeStyles, Theme } from "@material-ui/core";
import {
  commonColors,
  primaryColor,
  textColors,
} from "constants/themeSettings";

const useAuthBoxStyles = makeStyles((theme: Theme) => ({
  root: {},
  box: {
    width: "450px",
    margin: "0 auto",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  title: {
    marginBottom: "56px",
  },
  labelBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  xsAdditionButtons: {
    paddingTop: "10px",
    "& > *": {
      width: "100%",
      marginTop: "10px",
    },
  },
  blockCenter: {
    margin: "0 auto",
    display: "block",
  },
  buttonsWrap: {
    paddingTop: "43px",
  },
  linkInTextEnd: {
    marginLeft: "8px",
  },
  signUpAddition: {
    textAlign: "center",
    paddingTop: "21px",
    fontSize: "20px",
    lineHeight: "32px",
    color: textColors.black.light,
    fontWeight: 400,
    [theme.breakpoints.down(450)]: {
      textAlign: "center",
    },
  },
  successBox: {
    paddingTop: "30px",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    "& svg": {
      fill: commonColors.green,
      marginRight: "10px",
      fontSize: "28px",
    },
  },
  successBoxFlexCenter: {
    alignItems: "center",
  },
  successMsg: {
    color: commonColors.green,
    textAlign: "left",
    fontWeight: 500,
    fontSize: "20px",
  },
  successMsgAccent: {
    fontWeight: 600,
    color: primaryColor.main,
  },
}));

export { useAuthBoxStyles };
