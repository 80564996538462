import { List, ListItem } from "@material-ui/core";
import { useMobileMenuStyles } from "./styles/MobileMenu.styles";
import GBDivider from "components/GBDivider/GBDivider";
import { useHeaderStyles } from "components/Header/styles/Header.styles";
import cx from "classnames";

type MobileMenuProps = {
  nav?: Array<JSX.Element | null>;
  buttons?: JSX.Element[];
};

function MobileMenu(props: MobileMenuProps) {
  const { nav, buttons } = props;
  const classes = useMobileMenuStyles();
  const appBarClasses = useHeaderStyles();

  return (
    <div className={classes.root}>
      {nav?.length ? (
        <List>
          {nav.map((i, k) => (
            <ListItem
              key={k}
              className={cx(appBarClasses.navItem, classes.navItem)}
            >
              {i}
            </ListItem>
          ))}
        </List>
      ) : null}
      {nav?.length && buttons?.length ? <GBDivider /> : null}
      {buttons?.length ? (
        <List>
          {buttons.map((b, k) => (
            <ListItem key={k} className={classes.navBtn}>
              {b}
            </ListItem>
          ))}
        </List>
      ) : null}
    </div>
  );
}

export default MobileMenu;
