import React from "react";
import { useGBContainerStyles } from "./styles/GBContainer.styles";
import Container from "@material-ui/core/Container";
import cx from "classnames";

type GBContainerProps = {
  children: React.ReactNode;
  className?: string;
  width?: number;
};

function GBContainer(props: GBContainerProps) {
  const { children, className, width } = props;
  const classes = useGBContainerStyles(width || 1280);

  return (
    <Container className={cx(classes.container, className)} maxWidth="lg">
      <>{children}</>
    </Container>
  );
}

export default GBContainer;
