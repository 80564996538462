import StageStepFrame from "components/StageStepFrame/StageStepFrame";
import { Trans, useTranslation } from "react-i18next";
import { Namespaces } from "i18n";
import { useSurvey } from "components/hooks/useSurvey";
import StageStepButton from "components/StageStepButton/StageStepButton";
import StageStepImage from "components/StageStepImage/StageStepImage";
import StageStepText from "components/StageStepText/StageStepText";
import StageStepContainer from "components/StageStepContainer/StageStepContainer";

function ImpactPromiseBeginView() {
  const { t } = useTranslation([Namespaces.Impact, Namespaces.Survey]);
  const { prevUrl, nextUrl } = useSurvey();

  return (
    <StageStepFrame
      title={t("promise.title")}
      controls={[
        <StageStepButton.Back goTo={prevUrl}>
          {t(`${Namespaces.Survey}:buttons.back`)}
        </StageStepButton.Back>,
        <StageStepButton.Next goTo={nextUrl}>
          {t(`${Namespaces.Survey}:buttons.begin`)}
        </StageStepButton.Next>,
      ]}
    >
      <StageStepContainer>
        <StageStepImage url={t("promise.begin.img")} />
        <StageStepText>
          <Trans
            i18nKey={t("promise.begin.text")}
            components={{
              p: <p />,
              b: <b />,
            }}
          />
        </StageStepText>
      </StageStepContainer>
    </StageStepFrame>
  );
}

export default ImpactPromiseBeginView;
