import { GrowConflictEndI18nKey } from "constants/grow/growI18nKeys";
import { GrowResponsePayload } from "types/grow/GetGrowResponsePayload";
import { GrowSituation } from "types/grow/GrowSituation";
import { getSituationValue } from "../Grow.utils";
import { ConflictEndFormValues } from "./GrowConflictEnd.types";

export const prepareInitialValues = (
  grow: GrowResponsePayload | undefined,
): ConflictEndFormValues => {
  const conflicts = grow?.situations?.conflict2;
  return {
    conflict6: getSituationValue(GrowConflictEndI18nKey.Conflict6, conflicts),
    conflict7: getSituationValue(GrowConflictEndI18nKey.Conflict7, conflicts),
    conflict8: getSituationValue(GrowConflictEndI18nKey.Conflict8, conflicts),
    conflict9: getSituationValue(GrowConflictEndI18nKey.Conflict9, conflicts),
    conflict10: getSituationValue(GrowConflictEndI18nKey.Conflict10, conflicts),
  };
};

export const prepareConflictToSubmit = (
  data: ConflictEndFormValues,
): GrowSituation<GrowConflictEndI18nKey>[] => {
  const entries = Object.entries(data);
  return entries.map((e) => ({
    key: e[0] as GrowConflictEndI18nKey,
    value: e[1],
  }));
};
