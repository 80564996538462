import GBCircularProgress from "components/GBCircularProgress/GBCircularProgress";
import UnexpectedError from "components/UnexpectedError/UnexpectedError";
import { Namespaces } from "i18n";
import { useSnapshotStyles } from "pages/Snapshot/styles/Snapshot.styles";
import { useTranslation } from "react-i18next";
import { GrowResultResponsePayload } from "types/grow/GetGrowResultResponsePayload";
import HeaderSection from "../HeaderSection/HeaderSection";
import GrowSection from "./components/GrowSection/GrowSection";

type Step2ViewProps = {
  growResult: GrowResultResponsePayload | undefined;
  isLoading: boolean;
  isError: boolean;
};

function Step2View(props: Step2ViewProps) {
  const { growResult, isLoading, isError } = props;

  const { t } = useTranslation(Namespaces.Snapshot);
  const classes = useSnapshotStyles();

  return (
    <>
      {isLoading ? (
        <GBCircularProgress keepHeight="880px" />
      ) : isError ? (
        <div className={classes.errorBlock}>
          <UnexpectedError />
        </div>
      ) : (
        <div>
          <HeaderSection text={t("step2.header.subtitle")} />
          {!!growResult?.situations.result.length && (
            <GrowSection data={growResult} />
          )}
        </div>
      )}
    </>
  );
}

export default Step2View;
