import { makeStyles, Theme } from "@material-ui/core/styles";
import { WIDE_CONTAINER_WIDTH } from "constants/settings";

const useGBSurveyStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: "60px",
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down(WIDE_CONTAINER_WIDTH)]: {
      flexWrap: "wrap",
    },
  },
  aside: {
    marginRight: "100px",
    display: "flex",
    width: "300px",
    flexShrink: 0,
    flexGrow: 0,
    [theme.breakpoints.down(WIDE_CONTAINER_WIDTH)]: {
      flexWrap: "wrap",
      width: "722px",
      margin: "0 auto 60px auto",
    },
    [theme.breakpoints.down(800)]: {
      width: "100%",
    },
  },
  content: {
    width: "100%",
    paddingBottom: "78px",
    [theme.breakpoints.down(WIDE_CONTAINER_WIDTH)]: {
      width: "722px",
      margin: "0 auto",
    },
    [theme.breakpoints.down(800)]: {
      width: "100%",
    },
  },
}));

export { useGBSurveyStyles };
