import Details from "components/GBMTDetailPanel/GBMTDetailPanel";
import { Namespaces } from "i18n";
import { useTranslation } from "react-i18next";
import { AdminUser } from "types/admin/users/AdminUser";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import { useDetailPanelStyles } from "./styles/DetailPanel.styles";
import { prepareDate } from "utils/prepareDate";
import { Button } from "@material-ui/core";
import { useMemo } from "react";
import _ from "lodash";
import { useHistory } from "react-router";
import { EntityId } from "types/EntityId";
import { generalRoutes } from "constants/generalRoutes";
import { prepareRoutePath } from "utils/prepareRoutePath";
import { UserType } from "constants/userTypes";
import { useSelector } from "react-redux";
import { RootState } from "store";

type DetailPanelProps = {
  rowData: AdminUser;
};

function DetailPanel(props: DetailPanelProps) {
  const { rowData } = props;

  const { user } = useSelector((state: RootState) => state.auth);
  const isSuperAdmin = user?.userType === UserType.SuperAdmin;
  const isPartner = user?.userType === UserType.Partner;

  const { t, i18n } = useTranslation([
    Namespaces.Admin,
    Namespaces.Common,
    Namespaces.Survey,
  ]);

  const classes = useDetailPanelStyles();
  const history = useHistory();

  const getIcon = (status: boolean) =>
    status ? (
      <CheckCircleIcon className={classes.iconCompleted} />
    ) : (
      <CancelIcon className={classes.icon} />
    );

  const isSnapshotButtonVisible = useMemo(() => {
    const { surveyProgress } = rowData;
    return !!_.compact(
      Object.keys(surveyProgress).map(
        (s) => surveyProgress[s as keyof typeof surveyProgress].completed,
      ),
    ).length;
  }, [rowData]);

  const handleRedirectToSnapshot = (id: EntityId) => {
    history.push(
      prepareRoutePath(
        isSuperAdmin
          ? generalRoutes.SUPERADMIN_USER_SNAPSHOT
          : isPartner
          ? generalRoutes.PARTNER_USER_SNAPSHOT
          : "",
        {
          id,
        },
      ),
    );
  };

  return (
    <Details>
      <Details.Row>
        <Details.Col bold>
          {t("users.table.detailPanel.fields.firstName")}
        </Details.Col>
        <Details.Col>{rowData.first_name}</Details.Col>
      </Details.Row>
      <Details.Row>
        <Details.Col bold>
          {t("users.table.detailPanel.fields.lastName")}
        </Details.Col>
        <Details.Col>{rowData.last_name}</Details.Col>
      </Details.Row>
      <Details.Row>
        <Details.Col bold>
          {t("users.table.detailPanel.fields.email")}
        </Details.Col>
        <Details.Col>{rowData.email}</Details.Col>
      </Details.Row>
      <Details.Row>
        <Details.Col bold>
          {t("users.table.detailPanel.fields.role")}
        </Details.Col>
        <Details.Col>
          {t(`${Namespaces.Common}:user_types.${rowData.user_type}`)}
        </Details.Col>
      </Details.Row>
      <Details.Row>
        <Details.Col bold>
          {t("users.table.detailPanel.fields.createdAt")}
        </Details.Col>
        <Details.Col>
          {prepareDate(rowData.createdAt, i18n.language)}
        </Details.Col>
      </Details.Row>
      <Details.Row vFlexStart>
        <Details.Col bold>
          {t("users.table.detailPanel.fields.progress")}
        </Details.Col>
        <Details.Col>
          <Details.Row vFlexStart>
            <Details.List className={classes.list}>
              <Details.ListItem
                bold
                icon={getIcon(rowData.surveyProgress.score.completed)}
              >
                {t(`${Namespaces.Common}:main_menu.score_title`)}
              </Details.ListItem>
              {/* {!rowData.surveyProgress.score.completed ? (
                <>
                  <Details.ListItem
                    small
                    level={2}
                    icon={getIcon(
                      rowData.surveyProgress.score.stages.purposeScore,
                    )}
                  >
                    {t(`${Namespaces.Survey}:score.score.title`)}
                  </Details.ListItem>
                  <Details.ListItem
                    small
                    level={2}
                    icon={getIcon(
                      rowData.surveyProgress.score.stages.purposeDNA,
                    )}
                  >
                    {t(`${Namespaces.Survey}:score.dna.title`)}
                  </Details.ListItem>
                </>
              ) : null} */}
            </Details.List>
            <Details.List className={classes.list}>
              <Details.ListItem
                bold
                icon={getIcon(rowData.surveyProgress.align.completed)}
              >
                {t(`${Namespaces.Common}:main_menu.align_title`)}
              </Details.ListItem>
            </Details.List>
            <Details.List className={classes.list}>
              <Details.ListItem
                bold
                icon={getIcon(rowData.surveyProgress.impact.completed)}
              >
                {t(`${Namespaces.Common}:main_menu.impact_title`)}
              </Details.ListItem>
              {/* {!rowData.surveyProgress.impact.completed ? (
                <>
                  <Details.ListItem
                    small
                    level={2}
                    icon={getIcon(
                      rowData.surveyProgress.impact.stages.myLifePurpose,
                    )}
                  >
                    {t(`${Namespaces.Survey}:impact.purpose.title`)}
                  </Details.ListItem>
                  <Details.ListItem
                    small
                    level={2}
                    icon={getIcon(
                      rowData.surveyProgress.impact.stages.promiseStatements,
                    )}
                  >
                    {t(`${Namespaces.Survey}:impact.promise.title`)}
                  </Details.ListItem>
                </>
              ) : null} */}
            </Details.List>
            <Details.List className={classes.list}>
              <Details.ListItem
                bold
                icon={getIcon(rowData.surveyProgress.grow.completed)}
              >
                {t(`${Namespaces.Common}:main_menu.grow_title`)}
              </Details.ListItem>
            </Details.List>
          </Details.Row>
        </Details.Col>
      </Details.Row>
      {isSnapshotButtonVisible && (
        <Details.Row>
          <Button
            color="secondary"
            size="small"
            variant="contained"
            onClick={() => handleRedirectToSnapshot(rowData._id)}
          >
            {t("users.table.detailPanel.buttons.view_snapshot")}
          </Button>
        </Details.Row>
      )}
    </Details>
  );
}

export default DetailPanel;
