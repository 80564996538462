import FormRow from "components/FormRow/FormRow";
import GBCardInput from "components/GBCardInput/GBCardInput";
import GBModal from "components/GBModal/GBModal";
import { ValidationRule } from "constants/validationRules";
import { useFormik } from "formik";
import { Namespaces } from "i18n";
import { useMemo, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import {
  paymentModalFormInitValues,
  paymentModalFormValidationSchema,
} from "./PaymentModal.consts";
import { PaymentModalFormValues } from "./PaymentModal.types";
import { ApiErrorKey } from "constants/api/apiErrors";
import { usePaymentModalStyles } from "./styles/PaymentModal.styles";
import { EntityId } from "types/EntityId";
import { OnChangeEventType } from "components/GBCardInput/GBCardInput.types";
import GBInputErrorText from "components/GBInputErrorText/GBInputErrorText";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { AuthStoreUserType } from "store/auth/types";
import { ApiClientError } from "types/api/ApiClientError";
import { selectApiError } from "utils/api/selectApiError";
import PaymentApiService from "services/PaymentApiService";
import { PaymentPrice } from "types/payment/PaymentPrices";
import { StripeSubscriptionStatus } from "constants/stripeSubsriptionStatuses";
import { useQuery } from "react-query";
import { fetchPaymentConfig } from "utils/api/queryFns";
import UnexpectedError from "components/UnexpectedError/UnexpectedError";
import GBCircularProgress from "components/GBCircularProgress/GBCircularProgress";
import { PaymentPriceLicenses } from "types/payment/PaymentPriceLicenses";

type PaymentModalProps = {
  open: boolean;
  onClose: () => void;
  customerId: EntityId;
  userData: AuthStoreUserType;
};

function PaymentModal(props: PaymentModalProps) {
  const { open, onClose, customerId, userData } = props;

  const { t, i18n } = useTranslation([Namespaces.Admin, Namespaces.Common]);
  const classes = usePaymentModalStyles();
  const stripeElements = useElements();
  const stripe = useStripe();

  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<ApiErrorKey | undefined>(
    undefined,
  );

  const configQuery = useQuery(fetchPaymentConfig.key, fetchPaymentConfig.fn);
  const config = useMemo(
    () => configQuery.data?.data.payload,
    [configQuery.data?.data.payload],
  );

  const basicPlan = useMemo(
    () =>
      config?.prices.filter(
        (p) => p.active && p.name === PaymentPrice.Basic,
      )[0],
    [config?.prices],
  );

  const isLoading = configQuery.isLoading;
  const isError = configQuery.isError;

  const isBasicPlanActive = async (priceId: EntityId) => {
    const {
      data: { payload },
    } = await PaymentApiService.getSubscriptions(customerId);
    const subsWithPrice = payload.data.filter(
      (i) => i.plan.active && i.plan.id === priceId,
    );
    if (subsWithPrice.length) {
      const activeSubs = subsWithPrice.filter(
        (i) => i.status === StripeSubscriptionStatus.Active,
      );
      return !!activeSubs.length;
    }
    return false;
  };

  const onSubmit = async (data: PaymentModalFormValues) => {
    const card = stripeElements?.getElement(CardElement);
    try {
      setIsProcessing(true);
      if (data.cardData && stripe && card && basicPlan) {
        const priceId = basicPlan.id;

        if (priceId) {
          const isActive = await isBasicPlanActive(priceId);

          if (!isActive) {
            const subscriptionResponse =
              await PaymentApiService.createSubscription({
                customerId,
                priceId,
              });
            const { subscriptionId, clientSecret } =
              subscriptionResponse.data?.payload;

            if (subscriptionId && clientSecret) {
              const { error, paymentIntent } = await stripe.confirmCardPayment(
                clientSecret,
                {
                  payment_method: {
                    card,
                    billing_details: {
                      name: `${userData.firstName} ${userData.lastName}`,
                      email: userData.email,
                    },
                  },
                },
              );

              if (error) {
                throw Error("Unexpected error. Cannot perform payment.");
              } else {
                if (paymentIntent) {
                  setIsProcessing(false);
                  window.location.reload();
                } else {
                  throw Error(
                    "Unexpected error. Payment intent is not available.",
                  );
                }
              }
              setIsProcessing(false);
            } else {
              throw Error(
                "Unexpected error. No subscription data was provided.",
              );
            }
          } else {
            throw Error("Subscription already paid.");
          }
        } else {
          throw Error("Unexpected error. No price was found.");
        }
      } else {
        throw Error("Unexpected error. Payment data not found.");
      }
    } catch (e) {
      console.error(e);
      const error = e as ApiClientError;
      setIsProcessing(false);
      setErrorCode(selectApiError(error.response?.data.message));
    }
  };

  const formik = useFormik<PaymentModalFormValues>({
    validationSchema: paymentModalFormValidationSchema,
    enableReinitialize: true,
    initialValues: paymentModalFormInitValues,
    onSubmit: onSubmit,
  });

  const {
    handleSubmit,
    setFieldError,
    setFieldTouched,
    setFieldValue,
    touched,
    errors,
  } = formik;

  const hasError = (name: keyof PaymentModalFormValues): boolean => {
    return !!(touched[name] && errors[name]);
  };

  const getError = (
    field: keyof PaymentModalFormValues,
    errorCode: ValidationRule,
  ) => {
    return t([
      `profile.modals.payment.form.fields.${field}.errors.${errorCode}`,
      `errors.${errorCode}`,
    ]);
  };

  const _onClose = () => {
    setErrorCode(undefined);
    onClose();
  };

  const handleCardDataChange = (event: OnChangeEventType) => {
    const errorCode = event.error?.code;
    const isCompleted = event.complete;
    if (!!errorCode) {
      setFieldError("cardData", errorCode);
    } else {
      setFieldError("cardData", undefined);
    }
    setFieldValue("cardData", isCompleted);
    setFieldTouched("cardData");
  };

  return (
    <GBModal
      open={open}
      onClose={_onClose}
      title={t("profile.modals.payment.title")}
      size="medium"
      withTitleDivider
      onOk={handleSubmit}
      okButtonText={
        isProcessing
          ? t("profile.modals.payment.buttons.paying")
          : t("profile.modals.payment.buttons.submit")
      }
      okButtonProps={{
        disabled: isProcessing || isLoading || isError,
      }}
      errorText={
        errorCode
          ? t([
              `profile.modals.payment.errors.${errorCode}`,
              `${Namespaces.Common}:errors.${errorCode}`,
              `${Namespaces.Common}:errors.unexpected_error`,
            ])
          : undefined
      }
    >
      {isLoading ? (
        <GBCircularProgress />
      ) : isError || !basicPlan ? (
        <UnexpectedError />
      ) : (
        <form onSubmit={handleSubmit}>
          <FormRow>
            <div className={classes.priceWrap}>
              <Trans
                i18nKey={t("profile.modals.payment.subtitle", {
                  amount: new Intl.NumberFormat(i18n.language, {
                    style: "currency",
                    currency: basicPlan.currency,
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  }).format(basicPlan.amount / 100),
                  interval: t(
                    `${Namespaces.Common}:misc.${basicPlan.interval}`,
                  ),
                  licenses: PaymentPriceLicenses.Basic,
                })}
                components={{
                  accent: <span className={classes.accent} />,
                }}
              />
            </div>
            <GBCardInput
              error={hasError("cardData")}
              onChange={handleCardDataChange}
              disabled={isProcessing}
            />
            {hasError("cardData") && (
              <GBInputErrorText
                text={getError("cardData", errors.cardData as ValidationRule)}
              />
            )}
          </FormRow>
        </form>
      )}
    </GBModal>
  );
}

export default PaymentModal;
