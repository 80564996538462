import { DispatchType } from "store";
import { AuthActions, AuthActionTypes, AuthStoreUserType } from "./types";
import { authToken } from "utils/authToken";
import UserApiService from "services/UserApiService";

const checkUserAuthAction = () => {
  return async (dispatch: DispatchType) => {
    const token = authToken.get();
    if (token) {
      dispatch(authActions.loginSuccess());
      try {
        const { data } = await UserApiService.fetchProfile();
        const {
          _id,
          first_name,
          last_name,
          email,
          user_type,
          createdAt,
          payment_status,
        } = data.payload.user;

        dispatch(
          authActions.setUser({
            id: _id,
            firstName: first_name,
            lastName: last_name,
            email,
            userType: user_type,
            createdAt,
            paymentStatus: payment_status,
          }),
        );
      } catch (e) {
        console.error(e);
        dispatch(authActions.logoutSuccess());
      }
    } else {
      dispatch(authActions.logoutSuccess());
    }
  };
};

const loginAction = (token: string) => {
  return (dispatch: DispatchType) => {
    try {
      authToken.set(token);
      dispatch(authActions.loginSuccess());
    } catch (e) {
      console.error(e);
      dispatch(authActions.logoutSuccess());
    }
  };
};

const logoutAction = () => {
  return (dispatch: DispatchType) => {
    try {
      authToken.remove();
      dispatch(authActions.logoutSuccess());
    } catch (e) {
      console.error(e);
    }
  };
};

const signupAction = (token: string) => {
  return (dispatch: DispatchType) => {
    try {
      authToken.set(token);
      dispatch(authActions.signupSuccess());
    } catch (e) {
      console.error(e);
      dispatch(authActions.logoutSuccess());
    }
  };
};

const loginSuccessAction = (): AuthActionTypes => ({
  type: AuthActions.LOGIN_SUCCESS,
});

const signupSuccessAction = (): AuthActionTypes => ({
  type: AuthActions.SIGNUP_SUCCESS,
});

const logoutSuccessAction = (): AuthActionTypes => ({
  type: AuthActions.LOGOUT_SUCCESS,
});

const setUserAction = (user: AuthStoreUserType): AuthActionTypes => ({
  type: AuthActions.SET_USER,
  payload: user,
});

export const authActions = {
  login: loginAction,
  logout: logoutAction,
  signup: signupAction,
  loginSuccess: loginSuccessAction,
  signupSuccess: signupSuccessAction,
  logoutSuccess: logoutSuccessAction,
  checkAuth: checkUserAuthAction,
  setUser: setUserAction,
};
