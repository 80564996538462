import { useGBCircularProgressStyles } from "./styles/GBCircularProgress.styles";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { Namespaces } from "i18n";

export type GBCircularProgressProps = {
  wrapperClassName?: string;
  className?: string;
  keepHeight?: string;
};

function GBCircularProgress(props: GBCircularProgressProps) {
  const { wrapperClassName, className, keepHeight } = props;
  const classes = useGBCircularProgressStyles();
  const { t } = useTranslation(Namespaces.Common);

  const wrapperClasses = cx(classes.spinnerWrap, wrapperClassName);
  const spinnerClasses = cx(classes.spinner, className);

  return (
    <div
      aria-label={t("labels.aria.spinner")}
      className={wrapperClasses}
      style={{
        height: keepHeight,
      }}
    >
      <div className={spinnerClasses}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

export default GBCircularProgress;
