import React from "react";
import { SvgIcon } from "@material-ui/core";

function GBTimeIcon(props: React.ComponentProps<typeof SvgIcon>) {
  return (
    <SvgIcon {...props} viewBox="0 0 33 32">
      <path
        fill="none"
        stroke="#687a8f"
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="2.2667"
        d="M16 28c6.627 0 12-5.373 12-12s-5.373-12-12-12c-6.627 0-12 5.373-12 12s5.373 12 12 12z"
      ></path>
      <path
        fill="none"
        stroke="#687a8f"
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="2.2667"
        d="M19.5 20.5l-3.5-4.5v-7"
      ></path>
    </SvgIcon>
  );
}

export default GBTimeIcon;
