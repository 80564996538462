import { Typography } from "@material-ui/core";
import { Namespaces } from "i18n";
import { useTranslation } from "react-i18next";
import { useHeaderSectionStyles } from "./styles/HeaderSection.styles";

type HeaderSectionProps = {
  text: string;
};

function HeaderSection(props: HeaderSectionProps) {
  const { text } = props;
  const { t } = useTranslation(Namespaces.Snapshot);
  const classes = useHeaderSectionStyles();

  return (
    <div className={classes.root}>
      <Typography component="h1" variant="h3" className={classes.title}>
        {t("header")}
      </Typography>
      <Typography component="h4" variant="h4" className={classes.subtitle}>
        {text}
      </Typography>
    </div>
  );
}

export default HeaderSection;
