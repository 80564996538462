import { Namespaces } from "i18n";
import { useMemo } from "react";
import { useTranslation, Trans } from "react-i18next";
import SnapshotSectionLabel from "components/SnapshotSectionLabel/SnapshotSectionLabel";
import { useImpactSectionStyles } from "./styles/ImpactSection.styles";
import Alert from "@material-ui/lab/Alert";
import { ImpactSectionData } from "pages/Snapshot/components/Step1/Step1.types";
import { rmSubstr } from "utils/rmSubstr";

type ImpactSectionProps = {
  data: ImpactSectionData;
};

function ImpactSection(props: ImpactSectionProps) {
  const {
    data: { purpose, promise },
  } = props;

  const classes = useImpactSectionStyles();
  const { t } = useTranslation(Namespaces.Snapshot);

  const isUncompleted = useMemo(() => !purpose || !promise, [promise, purpose]);

  return (
    <div className={classes.root}>
      <SnapshotSectionLabel text={t("step1.impact.label")} color="primary" />
      {isUncompleted ? (
        <Alert severity="warning">
          {t("step1.impact.warnings.not_completed")}
        </Alert>
      ) : (
        <>
          {purpose ? (
            <div className={classes.text}>
              <Trans
                i18nKey={t("step1.impact.purpose", {
                  purpose: rmSubstr(
                    purpose?.toLowerCase() || "",
                    t("step1.impact.purposeAccent").toLowerCase(),
                  ),
                  accent: t("step1.impact.purposeAccent"),
                })}
                components={{
                  accent: <span className={classes.accent} />,
                }}
              />
            </div>
          ) : null}
          {promise ? (
            <div className={classes.text}>
              <Trans
                i18nKey={t("step1.impact.promise", {
                  promise: rmSubstr(
                    promise?.toLowerCase() || "",
                    t("step1.impact.promiseAccent").toLowerCase(),
                  ),
                  accent: t("step1.impact.promiseAccent"),
                })}
                components={{
                  accent: <span className={classes.accent} />,
                }}
              />
            </div>
          ) : null}
        </>
      )}
    </div>
  );
}

export default ImpactSection;
