import Footer from "components/Footer/Footer";
import Header from "components/Header/Header";
import { WIDE_CONTAINER_WIDTH } from "constants/settings";
import { Namespaces } from "i18n";
import { useTranslation } from "react-i18next";
import gbLogo from "assets/img/gb-logo.png";
import { useExerciseLayoutStyles } from "./styles/ExerciseLayout.styles";
import GBContainer from "../../components/GBContainer/GBContainer";
import LogoutButton from "components/LogoutButton/LogoutButton";
import AdminButton from "components/AdminButton/AdminButton";
import ProfileButton from "components/ProfileButton/ProfileButton";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { UserType } from "constants/userTypes";
import { useHistory, useRouteMatch } from "react-router";
import { useEffect } from "react";
import { scrollTop } from "utils/scrollTop";
import { generalRoutes } from "constants/generalRoutes";
import GBButton from "components/GBButton/GBButton";

type ExerciseLayoutProps = {
  children: JSX.Element;
};

function ExerciseLayout(props: ExerciseLayoutProps) {
  const classes = useExerciseLayoutStyles();
  const { children } = props;
  const { t } = useTranslation(Namespaces.Common);
  const auth = useSelector((state: RootState) => state.auth);
  const history = useHistory();

  const buttons =
    auth.user?.userType === UserType.SuperAdmin ||
    auth.user?.userType === UserType.Partner
      ? [
          <ProfileButton />,
          <GBButton
            onClick={() => history.push(generalRoutes.PARTNER_LOGIN)}
            size="small"
            variant="contained"
            color="primary"
          >
            {t("header.buttons.partnerLogin")}
          </GBButton>,
          <ProfileButton />,
          <AdminButton userType={auth.user?.userType} />,
          <LogoutButton />,
        ]
      : [
          // <ProfileButton />,
          <GBButton
            onClick={() => history.push(generalRoutes.PARTNER_LOGIN)}
            size="small"
            variant="contained"
            color="primary"
          >
            {t("header.buttons.partnerLogin")}
          </GBButton>,
        ];

  const match = useRouteMatch();

  useEffect(() => {
    scrollTop();
  }, [match.url]);

  return (
    <div className={classes.wrapper}>
      <Header
        fixed
        appBarClassName={classes.navbarShadow}
        logo={<img src={gbLogo} alt={t("app_name")} />}
        buttons={buttons}
        containerWith={WIDE_CONTAINER_WIDTH}
      />
      <GBContainer className={classes.content} width={WIDE_CONTAINER_WIDTH}>
        {children}
      </GBContainer>
      <Footer />
    </div>
  );
}

export default ExerciseLayout;
