import { makeStyles, Theme } from "@material-ui/core/styles";
import { secondaryColor } from "constants/themeSettings";

const useImpactCardStyles = makeStyles((theme: Theme) => ({
  card: {
    width: "100%",
    height: "64px",
    borderRadius: theme.shape.borderRadius,
    border: `2px solid ${secondaryColor.lighten}`,
    color: theme.palette.text.primary,
    fontSize: "18px",
    lineHeight: "26px",
    fontWeight: 600,
    textTransform: "capitalize",
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: "20px",
    alignItems: "center",
    cursor: "move",

    "&:hover": {
      backgroundColor: secondaryColor.lightest,
    },
  },
  cardOuter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginTop: "20px",
    alignItems: "center",
  },
  number: {
    margin: "0 24px",
  },
  icon: {
    marginRight: "10px",
  },
}));

export { useImpactCardStyles };
