import { makeStyles, Theme } from "@material-ui/core/styles";

const useImpactSectionStyles = makeStyles((theme: Theme) => ({
  content: {
    paddingTop: "20px",
  },
  accent: {
    color: theme.palette.primary.main,
  },
  text: {
    fontSize: "32px",
    lineHeight: "34px",
    fontWeight: 700,
    marginBottom: "32px",
  },
}));

export { useImpactSectionStyles };
