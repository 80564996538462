import ContentSpinner from "components/ContentSpinner/ContentSpinner";
import FormRow from "components/FormRow/FormRow";
import GBInputErrorText from "components/GBInputErrorText/GBInputErrorText";
import GBModal from "components/GBModal/GBModal";
import GBOutlinedInput from "components/GBOutlinedInput/GBOutlinedInput";
import GBStaticInputLabel from "components/GBStaticInputLabel/GBStaticInputLabel";
import { ValidationRule } from "constants/validationRules";
import { useFormik } from "formik";
import { Namespaces } from "i18n";
import React from "react";
import { useTranslation } from "react-i18next";
import { DataHandlers, DataQueryStatuses } from "../../AdminPartners.types";
import { editPartnerFormValidationSchema } from "./EditPartnerModal.consts";
import { EditPartnerModalFormValues } from "./EditPartnerModal.types";

type EditPartnerModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (data: EditPartnerModalFormValues, cb: () => void) => void;
  partnerData: {
    licenses: number;
  };
  dataHandlers: DataHandlers;
  dataQueryStatuses: DataQueryStatuses;
};

function EditPartnerModal(props: EditPartnerModalProps) {
  const {
    isOpen,
    onClose,
    onSave,
    partnerData,
    dataHandlers,
    dataQueryStatuses,
  } = props;

  const { t } = useTranslation(Namespaces.Admin);

  const { handleApiError } = dataHandlers;
  const { isProcessing, apiError } = dataQueryStatuses;

  const _onSave = (data: EditPartnerModalFormValues) => {
    onSave(data, () => _onClose());
  };

  const formik = useFormik<EditPartnerModalFormValues>({
    enableReinitialize: true,
    validationSchema: editPartnerFormValidationSchema,
    onSubmit: _onSave,
    initialValues: {
      licenses: partnerData.licenses,
    },
  });

  const {
    handleSubmit,
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    resetForm,
  } = formik;

  const hasError = (name: keyof EditPartnerModalFormValues): boolean => {
    return !!(touched[name] && errors[name]);
  };

  const getError = (
    field: keyof EditPartnerModalFormValues,
    errorCode: ValidationRule,
  ) => {
    return t([
      `partners.modals.editPartner.form.fields.${field}.errors.${errorCode}`,
      `${Namespaces.Common}:errors.${errorCode}`,
    ]);
  };

  const _onClose = () => {
    resetForm();
    handleApiError(null);
    onClose();
  };

  const _onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmit();
    }
  };

  return (
    <GBModal
      open={isOpen}
      onClose={_onClose}
      title={t("partners.modals.editPartner.title")}
      size="medium"
      withTitleDivider
      onOk={handleSubmit}
      okButtonText={t("partners.modals.editPartner.form.buttons.save")}
      okButtonProps={{
        disabled: isProcessing,
      }}
      errorText={
        apiError
          ? t([
              `partners.modal.editPartner.errors.${apiError}`,
              `${Namespaces.Common}:api_errors.${apiError}`,
              `${Namespaces.Common}:errors.unexpected_error`,
            ])
          : undefined
      }
    >
      <ContentSpinner spinning={isProcessing}>
        <form>
          <FormRow>
            <GBStaticInputLabel
              labelFor="licenses"
              text={t("partners.modals.editPartner.form.fields.licenses.label")}
            />
            <GBOutlinedInput
              id="licenses"
              fullWidth
              name="licenses"
              type="number"
              value={values.licenses}
              onChange={handleChange}
              onBlur={handleBlur}
              error={hasError("licenses")}
              disabled={isProcessing}
              onKeyPress={_onKeyPress}
            />
            {hasError("licenses") && (
              <GBInputErrorText
                text={getError("licenses", errors.licenses as ValidationRule)}
              />
            )}
          </FormRow>
        </form>
      </ContentSpinner>
    </GBModal>
  );
}

export default EditPartnerModal;
