import DnaVerbsView from "./DnaVerbsView";
import { useSurvey } from "components/hooks/useSurvey";
import { useMemo } from "react";
import { useHistory } from "react-router";
import { useQuery, useQueryClient } from "react-query";
import { fetchScore } from "utils/api/queryFns";
import { generalRoutes } from "constants/generalRoutes";
import { QueryKeys } from "constants/api/queryKeys";
import ScoreApiService from "services/ScoreApiService";
import { prepareVerbsInitialValues } from "constants/purposeScoreTypes";
import { IdentityVerb } from "types/score/DNA";

function DnaVerbs() {
  const { nextUrl, prevUrl } = useSurvey();
  const history = useHistory();
  const queryClient = useQueryClient();
  const scoreQuery = useQuery(fetchScore.key, fetchScore.fn);
  const score = useMemo(
    () => scoreQuery.data?.data.payload,
    [scoreQuery.data?.data.payload],
  );
  const preparedInitialValues = useMemo(
    () => prepareVerbsInitialValues(score?.DNA),
    [score],
  );

  const handleStepNext = () =>
    history.push(
      { pathname: nextUrl, search: history.location.search } ||
        generalRoutes.SCORE_MENU,
    );

  const handleStepPrev = () =>
    history.push({
      pathname: prevUrl,
      search: history.location.search,
    });

  const handleValueChange = async (value: Array<IdentityVerb>) => {
    try {
      await ScoreApiService.putScore({
        field: "impacts",
        value,
        step: "DNA",
      });
      await queryClient.refetchQueries([QueryKeys.fetchScore]);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <DnaVerbsView
      onValueChange={handleValueChange}
      values={preparedInitialValues}
      isFetching={scoreQuery.isLoading}
      isFetchError={scoreQuery.isError}
      onStepNext={handleStepNext}
      onStepBack={handleStepPrev}
    />
  );
}
export default DnaVerbs;
