import { createMuiTheme } from "@material-ui/core";
import {
  borderRadiuses,
  commonColors,
  errorColor,
  greyColors,
  infoColor,
  outlinedInputStyles,
  primaryColor,
  secondaryColor,
  shadows,
  successColor,
  textColors,
  typographySettings,
  warningColor,
} from "constants/themeSettings";

export const appTheme = createMuiTheme({
  typography: {
    fontFamily: typographySettings.fontFamily,
    h2: {
      fontSize: typographySettings.h2.fontSize,
      fontWeight: typographySettings.h2.fontWeight,
      lineHeight: typographySettings.h2.lineHeight,
      color: textColors.black.main,
    },
    h3: {
      fontSize: typographySettings.h3.fontSize,
      fontWeight: typographySettings.h3.fontWeight,
      lineHeight: typographySettings.h3.lineHeight,
      color: textColors.black.main,
    },
    h4: {
      fontSize: typographySettings.h4.fontSize,
      fontWeight: typographySettings.h4.fontWeight,
      lineHeight: typographySettings.h4.lineHeight,
      color: textColors.black.main,
    },
    menuTitle: {
      fontSize: typographySettings.menuTitle.fontSize,
      fontWeight: typographySettings.menuTitle.fontWeight,
      lineHeight: typographySettings.menuTitle.lineHeight,
      color: commonColors.black,
    },
    menuDescription: {
      fontSize: typographySettings.menuDescription.fontSize,
      fontWeight: typographySettings.menuDescription.fontWeight,
      lineHeight: typographySettings.menuDescription.lineHeight,
      color: secondaryColor.main,
    },
    surveySubtitle: {
      color: typographySettings.surveySubtitle.color,
      fontWeight: typographySettings.surveySubtitle.fontWeight,
      fontSize: typographySettings.surveySubtitle.fontSize,
      lineHeight: typographySettings.surveySubtitle.lineHeight,
    },
    formMedium: {
      display: typographySettings.formMedium.display,
      flexDirection: typographySettings.formMedium.flexDirection,
      width: typographySettings.formMedium.width,
    },
    formLabel: {
      color: typographySettings.formLabel.color,
      fontWeight: typographySettings.formLabel.fontWeight,
      fontSize: typographySettings.formLabel.fontSize,
      lineHeight: typographySettings.formLabel.lineHeight,
    },
    surveyText: {
      color: typographySettings.surveyText.color,
      fontWeight: typographySettings.surveyText.fontWeight,
      fontSize: typographySettings.surveyText.fontSize,
      lineHeight: typographySettings.surveyText.lineHeight,
    },
  },
  palette: {
    common: {
      black: commonColors.black,
      white: commonColors.white,
    },
    primary: {
      light: primaryColor.light,
      main: primaryColor.main,
      dark: primaryColor.dark,
      contrastText: primaryColor.contrastLight,
    },
    secondary: {
      light: secondaryColor.light,
      main: secondaryColor.main,
      dark: secondaryColor.dark,
      contrastText: secondaryColor.contrastLight,
    },
    error: {
      light: errorColor.light,
      main: errorColor.main,
      dark: errorColor.dark,
      contrastText: errorColor.contrastLight,
    },
    warning: {
      light: warningColor.light,
      main: warningColor.main,
      dark: warningColor.dark,
      contrastText: warningColor.contrastDark,
    },
    info: {
      light: infoColor.light,
      main: infoColor.main,
      dark: infoColor.dark,
      contrastText: infoColor.contrastLight,
    },
    success: {
      light: successColor.light,
      main: successColor.main,
      dark: successColor.dark,
      contrastText: successColor.contrastLight,
    },
    text: {
      primary: textColors.black.main,
      secondary: textColors.black.light,
    },
    background: {
      default: commonColors.white,
    },
    action: {
      disabled: greyColors[3],
    },
    grey: {
      50: greyColors[0],
      100: greyColors[1],
      200: greyColors[2],
      300: greyColors[3],
      400: greyColors[4],
      500: greyColors[5],
      600: greyColors[6],
      700: greyColors[7],
      800: greyColors[8],
      900: greyColors[9],
    },
  },
  shape: {
    borderRadius: borderRadiuses.main,
  },
  shadows: [
    "none",
    shadows.tiny,
    shadows.soft,
    shadows.soft,
    shadows.soft,
    shadows.soft,
    shadows.soft,
    shadows.soft,
    shadows.soft,
    shadows.soft,
    shadows.soft,
    shadows.soft,
    shadows.soft,
    shadows.soft,
    shadows.soft,
    shadows.soft,
    shadows.soft,
    shadows.soft,
    shadows.soft,
    shadows.soft,
    shadows.soft,
    shadows.soft,
    shadows.soft,
    shadows.soft,
    shadows.soft,
  ],
  overrides: {
    MuiOutlinedInput: {
      root: {
        cursor: "default",
        backgroundColor: outlinedInputStyles.backgroundColor,
        fontSize: outlinedInputStyles.fontSize,
        lineHeight: outlinedInputStyles.lineHeight,
        fontWeight: outlinedInputStyles.fontWeight,
        color: outlinedInputStyles.color,
        "&:hover:not($focused):not($error) $notchedOutline": {
          borderWidth: outlinedInputStyles.borderWidth,
          borderColor: outlinedInputStyles.borderColor,
        },
        "&:hover&$disabled $notchedOutline": {
          borderColor: outlinedInputStyles.borderColor,
        },
        borderRadius: outlinedInputStyles.borderRadius,
        "&.MuiInputBase-fullWidth": {
          width: "100%",
        },
        "&$disabled": {
          backgroundColor: outlinedInputStyles.disabled.backgroundColor,
          color: outlinedInputStyles.disabled.color,
          cursor: "not-allowed",
          pointerEvents: "none",
        },
        "& $notchedOutline": {
          borderWidth: outlinedInputStyles.borderWidth,
          borderColor: outlinedInputStyles.borderColor,
        },
        "&$disabled $notchedOutline": {
          borderWidth: outlinedInputStyles.borderWidth,
          borderColor: outlinedInputStyles.borderColor,
        },
        "&$focused": {
          $notchedOutline: {
            borderWidth: outlinedInputStyles.borderWidth,
            borderColor: outlinedInputStyles.focused.borderColor,
          },
          caretColor: outlinedInputStyles.focused.borderColor,
        },
        "&$error": {
          backgroundColor: outlinedInputStyles.error.backgroundColor,
          "&:not($focused) $notchedOutline": {
            border: `2px solid ${outlinedInputStyles.error.borderColor}`,
          },
        },
      },
      input: {
        padding: outlinedInputStyles.padding,
        height: outlinedInputStyles.height,
        "$adornedStart &": {
          paddingLeft: 0,
        },
      },
      notchedOutline: {
        borderWidth: outlinedInputStyles.borderWidth,
      },
    },
  },
});
