import React from "react";
import { useGBSimpleTextButtonStyles } from "./styles/GBSimpleTextButton.styles";
import cx from "classnames";

type GBSimpleTextButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  className?: string;
  color?: "primary" | "secondary";
  textAlign?: "center" | "right";
};

function GBSimpleTextButton(props: GBSimpleTextButtonProps) {
  const { className, color, textAlign, disabled, ...rest } = props;
  const classes = useGBSimpleTextButtonStyles();

  const rootClasses = cx(
    classes.root,
    {
      [classes.primary]: color === "primary",
      [classes.secondary]: color === "secondary",
      [classes.textCenter]: textAlign === "center",
      [classes.textRight]: textAlign === "right",
      [classes.disabled]: !!disabled,
    },
    className,
  );

  return <button {...rest} className={rootClasses} />;
}

export default GBSimpleTextButton;
