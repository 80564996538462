import FormRow from "components/FormRow/FormRow";
import GBOutlinedInput from "components/GBOutlinedInput/GBOutlinedInput";
import { FormikProps } from "formik";
import { Namespaces } from "i18n";
import { Trans, useTranslation } from "react-i18next";
import { useImpactStyles } from "../../../styles/Impact.styles";
import { ImpactPurposeStory2FormValues } from "../ImpactPurposeStory2.types";

type Story2FormProps = FormikProps<ImpactPurposeStory2FormValues> & {};

function Story2Form(props: Story2FormProps) {
  const { values, handleBlur, handleChange, handleSubmit, touched, errors } =
    props;

  const { t } = useTranslation(Namespaces.Impact);
  const classes = useImpactStyles();

  const hasError = (name: keyof ImpactPurposeStory2FormValues): boolean => {
    return !!(touched[name] && errors[name]);
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormRow className={classes.storyFormRow}>
        <Trans
          i18nKey={t("purpose.story2.form.study")}
          components={{
            college: (
              <GBOutlinedInput
                id="college"
                name="college"
                type="text"
                value={values.college}
                onChange={handleChange}
                onBlur={handleBlur}
                error={hasError("college")}
                className={classes.storyFormField}
              />
            ),
            major: (
              <GBOutlinedInput
                id="major"
                name="major"
                type="text"
                value={values.major}
                onChange={handleChange}
                onBlur={handleBlur}
                error={hasError("major")}
                className={classes.storyFormField}
              />
            ),
          }}
        />
      </FormRow>
      <FormRow className={classes.storyFormRow}>
        <Trans
          i18nKey={t("purpose.story2.form.industry")}
          components={{
            industry: (
              <GBOutlinedInput
                id="industry"
                name="industry"
                type="text"
                value={values.industry}
                onChange={handleChange}
                onBlur={handleBlur}
                error={hasError("industry")}
                className={classes.storyFormField}
              />
            ),
          }}
        />
      </FormRow>
      <FormRow className={classes.storyFormRow}>
        <Trans
          i18nKey={t("purpose.story2.form.position")}
          components={{
            position: (
              <GBOutlinedInput
                id="position"
                name="position"
                type="text"
                value={values.position}
                onChange={handleChange}
                onBlur={handleBlur}
                error={hasError("position")}
                className={classes.storyFormField}
              />
            ),
          }}
        />
      </FormRow>
      <FormRow className={classes.storyFormRow}>
        <Trans
          i18nKey={t("purpose.story2.form.childLove")}
          components={{
            childLove: (
              <GBOutlinedInput
                id="childLove"
                name="childLove"
                type="text"
                value={values.childLove}
                onChange={handleChange}
                onBlur={handleBlur}
                error={hasError("childLove")}
                className={classes.storyFormField}
              />
            ),
          }}
        />
      </FormRow>
      <FormRow className={classes.storyFormRow}>
        <Trans
          i18nKey={t("purpose.story2.form.career")}
          components={{
            career: (
              <GBOutlinedInput
                id="career"
                name="career"
                type="text"
                value={values.career}
                onChange={handleChange}
                onBlur={handleBlur}
                error={hasError("career")}
                className={classes.storyFormField}
              />
            ),
          }}
        />
      </FormRow>
    </form>
  );
}

export default Story2Form;
