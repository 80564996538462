import _ from "lodash";
import { AlignResponsePayload } from "types/align/GetAlignResponsePayload";

export const checkSuccess = (
  align: AlignResponsePayload | undefined,
): boolean => {
  return (
    !!_.compact(align?.importantTrials).length &&
    !!_.compact(align?.importantTriumphs).length &&
    !!_.compact(align?.trials).length &&
    !!_.compact(align?.triumphs).length
  );
};
