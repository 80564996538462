import { AuthModalActions, AuthModalActionTypes } from "./types";

const openAuthModalAction = (exerciseLink: string): AuthModalActionTypes => ({
  type: AuthModalActions.OPEN,
  exerciseLink,
});

const closeAuthModalAction = (): AuthModalActionTypes => ({
  type: AuthModalActions.CLOSE,
});

export const authModalActions = {
  open: openAuthModalAction,
  close: closeAuthModalAction,
};
