import { ALIGN_SURVEY_INFO_FIELDS_COUNT } from "constants/settings";
import { ValidationRule } from "constants/validationRules";
import { getArrayOfEmptyStrings } from "utils/getArrayOfEmptyStrings";
import * as Yup from "yup";

export const initialArray = getArrayOfEmptyStrings(
  ALIGN_SURVEY_INFO_FIELDS_COUNT,
);

export const alignInfoFormValidationSchema = Yup.object().shape({
  trials: Yup.array().compact().min(1, ValidationRule.AtLeastOne),
  triumphs: Yup.array().compact().min(1, ValidationRule.AtLeastOne),
});
