import React from "react";
import { useGBMTDetailPanelStyles } from "../styles/GBMTDetailPanel.styles";
import cx from "classnames";

type ListItemProps = {
  children: string;
  className?: string;
  icon?: JSX.Element;
  level?: number;
  small?: boolean;
  bold?: boolean;
};

function ListItem(props: ListItemProps) {
  const { children, className, icon, level, small, bold } = props;
  const classes = useGBMTDetailPanelStyles();

  const listtItemClasses = cx(
    classes.listItem,
    {
      [classes.listItemSmall]: small,
      [classes.bold]: bold,
    },
    className,
  );

  return (
    <div
      className={listtItemClasses}
      style={{ paddingLeft: `${(level || 0) * 5}px` }}
    >
      {icon && <div className={classes.listItemIcon}>{icon}</div>}
      <div className={classes.listItemText}>{children || <>&mdash;</>}</div>
    </div>
  );
}

export default ListItem;
