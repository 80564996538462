import { useMutation } from "react-query";
import AlignApiService from "services/AlignApiService";
import { AlignResponsePayload } from "types/align/GetAlignResponsePayload";
import { PostAlignRequestPayload } from "types/align/PostAlignRequestPayload";
import { getArrayOfEmptyStrings } from "utils/getArrayOfEmptyStrings";

const useUpdateAlign = () => {
  return useMutation(
    ({
      completed,
      formData,
      align,
    }: {
      completed?: boolean;
      formData?: Partial<PostAlignRequestPayload>;
      align?: AlignResponsePayload;
    }) => {
      const array10 = getArrayOfEmptyStrings(10);
      const array3 = getArrayOfEmptyStrings(3);

      return AlignApiService.postAlign({
        trials: align?.trials || array10,
        triumphs: align?.triumphs || array10,
        importantTrials: align?.importantTrials || array3,
        importantTriumphs: align?.importantTriumphs || array3,
        ...formData,
        completed: completed || align?.completed || false,
      });
    },
    {
      onError: (e) => {
        throw e;
      },
    },
  );
};

export { useUpdateAlign };
