import GBMaterialTable from "components/GBMaterialTable/GBMaterialTable";
import TitleHint from "components/TitleHint/TitleHint";
import { Namespaces } from "i18n";
import { Trans, useTranslation } from "react-i18next";
import { Modals, QueryPartnersFn } from "../../AdminPartners.types";
import DetailPanel from "../DetailPanel/DetailPanel";
import { useTableStyles } from "./styles/Tabl.styles";
import EditIcon from "@material-ui/icons/Edit";
import { MaterialTableProps, Query } from "material-table";
import { MutableRefObject, useState } from "react";
import { IPartner } from "types/admin/partners/IPartner";

type TableProps = {
  modals: Modals;
  tableRef: MutableRefObject<MaterialTableProps<object> | null>;
  queryData: QueryPartnersFn;
};

function Table(props: TableProps) {
  const { modals, tableRef, queryData } = props;

  const [totalCount, setTotalCount] = useState<number>(0);
  const classes = useTableStyles();
  const { t } = useTranslation(Namespaces.Admin);

  return (
    <div className={classes.root}>
      <GBMaterialTable
        tableRef={tableRef}
        columns={[
          {
            title: t("partners.table.cols.firstName"),
            field: "first_name",
          },
          {
            title: t("partners.table.cols.lastName"),
            field: "last_name",
          },
          {
            title: t("partners.table.cols.email"),
            field: "email",
          },
          {
            title: t("partners.table.cols.initialLicences"),
            field: "initialRegistrationsLimit",
          },
          {
            title: t("partners.table.cols.currentLicences"),
            field: "currentRegistrationsLimit",
          },
        ]}
        data={async (query: Query<IPartner>) =>
          queryData({
            query,
            cb: (totalCount: number) => setTotalCount(totalCount),
          })
        }
        title={
          <h6>
            <Trans
              i18nKey={t("partners.table.title")}
              components={{
                total: (
                  <TitleHint
                    text={t(`${Namespaces.Common}:labels.total`, {
                      value: totalCount,
                    })}
                  />
                ),
              }}
            />
          </h6>
        }
        actions={[
          (rowData) => ({
            icon: EditIcon,
            tooltip: t("partners.table.actions.editPartner"),
            onClick: () =>
              modals.editPartner.setData({
                isOpen: true,
                partnerData: {
                  id: rowData._id,
                  licenses: rowData.initialRegistrationsLimit,
                },
              }),
          }),
        ]}
        detailPanel={[
          {
            tooltip: t("partners.table.detailPanel.tooltip"),
            render: (rowData) => <DetailPanel rowData={rowData} />,
          },
        ]}
        options={{
          actionsColumnIndex: -1,
          draggable: false,
          sorting: false,
        }}
      />
    </div>
  );
}

export default Table;
