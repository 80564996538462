import FormRow from "components/FormRow/FormRow";
import GBCircularProgress from "components/GBCircularProgress/GBCircularProgress";
import GBSpin from "components/GBSpin/GBSpin";
import RangeQuizField from "components/RangeQuizField/RangeQuizField";
import StageStepButton from "components/StageStepButton/StageStepButton";
import StageStepContainer from "components/StageStepContainer/StageStepContainer";
import StageStepFrame from "components/StageStepFrame/StageStepFrame";
import GBTimeIcon from "components/svg-icons/GBTimeIcon";
import UnexpectedError from "components/UnexpectedError/UnexpectedError";
import { ApiErrorKey } from "constants/api/apiErrors";
import { ValidationRule } from "constants/validationRules";
import { FormikProps } from "formik";
import { Namespaces } from "i18n";
import { Trans, useTranslation } from "react-i18next";
import {
  emotionalIntlQuizFields,
  emotionalIntlQuizFieldSettings,
} from "./EmotionalIntlForm.consts";
import { EmotionalIntlFormValues } from "./EmotionalIntlForm.types";
import { useEmotionalIntlFormStyles } from "./styles/EmotionalIntlForm.styles";
import GBStaticInputLabel from "components/GBStaticInputLabel/GBStaticInputLabel";
import GBOutlinedInput from "components/GBOutlinedInput/GBOutlinedInput";
import GBInputErrorText from "components/GBInputErrorText/GBInputErrorText";

type EmotionalIntlFormViewProps = FormikProps<EmotionalIntlFormValues> & {
  isProcessing: boolean;
  isLoading: boolean;
  isError: boolean;
  hasError: (name: keyof EmotionalIntlFormValues) => boolean;
  getError: (
    field: keyof EmotionalIntlFormValues,
    errorCode: ValidationRule,
  ) => string;
  apiError: ApiErrorKey | null;
  onValueChange: (field: keyof EmotionalIntlFormValues, value: number) => void;
};

function EmotionalIntlFormView(props: EmotionalIntlFormViewProps) {
  const {
    isProcessing,
    apiError,
    isLoading,
    isError,
    hasError,
    getError,
    onValueChange,
    ...formik
  } = props;

  const { t } = useTranslation([Namespaces.Score, Namespaces.Common]);
  const classes = useEmotionalIntlFormStyles();

  const { values, setFieldValue, handleSubmit } = formik;

  return (
    <StageStepFrame
      title={t("emotionalIntl.title")}
      controls={[
        <StageStepButton.Next
          onClick={() => handleSubmit()}
          disabled={isProcessing || isError || !!apiError}
        >
          {t("emotionalIntl.form.buttons.submit")}
        </StageStepButton.Next>,
      ]}
      error={
        apiError
          ? t([
              `emotionalIntl.form.errors.${apiError}`,
              `${Namespaces.Common}:api_errors.${apiError}`,
              `${Namespaces.Common}:errors.unexpected_error`,
            ])
          : undefined
      }
    >
      <StageStepContainer>
        <div className={classes.time}>
          <GBTimeIcon className={classes.timeIcon} />
          {t("emotionalIntl.form.time")}
        </div>
        {isLoading ? (
          <GBCircularProgress keepHeight="848px" />
        ) : isError ? (
          <UnexpectedError />
        ) : (
          <GBSpin spinning={isProcessing}>
            <form onSubmit={handleSubmit} className={classes.wrapper}>
              <FormRow>
                <GBStaticInputLabel
                  component="div"
                  variant="medium"
                  labelFor="name"
                  text="Name"
                />
                <GBOutlinedInput
                  fullWidth
                  id="name"
                  name="name"
                  type="text"
                  onChange={props.handleChange}
                />
              </FormRow>
              <FormRow>
                <GBStaticInputLabel
                  component="div"
                  variant="medium"
                  labelFor="email"
                  text="Email"
                />
                <GBOutlinedInput
                  fullWidth
                  id="email"
                  name="email"
                  type="text"
                  onChange={props.handleChange}
                />
              </FormRow>
              {emotionalIntlQuizFields.map((i, k) => (
                <FormRow key={k} fullWidth className={classes.quizFormRow}>
                  <GBStaticInputLabel
                    labelFor={i}
                    variant="medium"
                    text={
                      <Trans
                        i18nKey={t(`emotionalIntl.form.fields.${i}.label`)}
                        components={{
                          br: <br />,
                        }}
                      />
                    }
                  />
                  <RangeQuizField
                    fullWidth
                    id={i}
                    name={i}
                    value={values[i]}
                    from={emotionalIntlQuizFieldSettings.smallest}
                    to={emotionalIntlQuizFieldSettings.highest}
                    error={hasError(i)}
                    fromText={t(`emotionalIntl.form.fields.${i}.smallest`)}
                    toText={t(`emotionalIntl.form.fields.${i}.highest`)}
                    onChange={(value: number) => {
                      setFieldValue(i, value);
                      onValueChange(i, value);
                    }}
                  />
                </FormRow>
              ))}
            </form>
          </GBSpin>
        )}
      </StageStepContainer>
    </StageStepFrame>
  );
}

export default EmotionalIntlFormView;
