import { makeStyles, Theme } from "@material-ui/core/styles";
import { textColors } from "constants/themeSettings";

const useLogoutButtonStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: "5px",
    cursor: "pointer",
    border: "none",
    background: "none",
    color: textColors.black.light,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));

export { useLogoutButtonStyles };
