import { useAdminProfileStyles } from "./styles/AdminProfile.styles";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { Typography } from "@material-ui/core";
import { useTranslation, Trans } from "react-i18next";
import { Namespaces } from "i18n";
import UnexpectedError from "components/UnexpectedError/UnexpectedError";
import { capitalize as cpt } from "utils/capitalize";
import { PartnerProfilePayload } from "types/admin/partners/GetPartnerProfileResponsePayload";
import { AuthStoreUserType } from "store/auth/types";
import GBCircularProgress from "components/GBCircularProgress/GBCircularProgress";
import { Alert } from "@material-ui/lab";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import CachedIcon from "@material-ui/icons/Cached";
import PayCurrentPlanModal from "./components/PaymentModal/PaymentModal";
import { useState } from "react";
import GBSimpleTextButton from "components/GBSimpleTextButton/GBSimpleTextButton";

type AdminProfileViewProps = {
  isLoading: boolean;
  isError: boolean;
  partnerProfile: PartnerProfilePayload | undefined;
  isPartner: boolean;
  userData: AuthStoreUserType | null;
};

function AdminProfileView(props: AdminProfileViewProps) {
  const { isPartner, partnerProfile, userData, isLoading, isError } = props;
  const classes = useAdminProfileStyles();
  const { t } = useTranslation([Namespaces.Admin, Namespaces.Common]);

  const isNotPayed = partnerProfile?.user.payment_status === "not_payed";
  const [isActivatePlanModalOpened, setIsActivatePlanModalOpened] =
    useState<boolean>(false);

  return (
    <>
      {(isPartner && isError) || !userData ? (
        <UnexpectedError />
      ) : isLoading && isPartner ? (
        <GBCircularProgress />
      ) : (
        <>
          <div className={classes.nameBlock}>
            <AccountCircleIcon className={classes.profileIcon} />
            <Typography variant="h2" component="h1">{`${cpt(
              userData?.firstName,
            )} ${cpt(userData?.lastName)}`}</Typography>
          </div>
          <div className={classes.infoBlock}>
            <div className={classes.flexBox}>
              <b>{t("profile.details.role")}:</b>&nbsp;
              {t(`${Namespaces.Common}:user_types.${userData.userType}`)}
            </div>
            <div className={classes.flexBox}>
              <b>{t("profile.details.email")}:</b>&nbsp;
              {userData.email}
            </div>
            {isPartner && partnerProfile && (
              <>
                <div className={classes.flexBox}>
                  <b>{t("profile.details.advCode")}:</b>&nbsp;
                  {partnerProfile.user.advCode}
                </div>
                <div className={classes.flexBox}>
                  <b>{t("profile.details.initLicenses")}:</b>&nbsp;
                  {partnerProfile.user.initialRegistrationsLimit}
                </div>
                <div className={classes.flexBox}>
                  <b>{t("profile.details.currentLicenses")}:</b>&nbsp;
                  {partnerProfile.user.currentRegistrationsLimit}
                </div>
                <div className={classes.flexBox}>
                  <b>{t("profile.details.paymentStatus.label")}:</b>&nbsp;
                  {!isNotPayed ? (
                    <div className={classes.statusBox}>
                      <CheckCircleIcon className={classes.successCheck} />
                      {t("profile.details.paymentStatus.payed")}
                    </div>
                  ) : isNotPayed ? (
                    <div className={classes.payStatusBox}>
                      <div className={classes.statusBox}>
                        <CancelIcon color="error" />
                        {t("profile.details.paymentStatus.notPayed")}
                      </div>
                      <GBSimpleTextButton
                        color="primary"
                        onClick={() => setIsActivatePlanModalOpened(true)}
                      >
                        {t("profile.buttons.pay")}
                      </GBSimpleTextButton>
                    </div>
                  ) : (
                    <div className={classes.statusBox}>
                      <CachedIcon color="action" />
                      {t("profile.details.paymentStatus.pending")}
                    </div>
                  )}
                </div>
              </>
            )}
            <div className={classes.info}>
              {isPartner && isNotPayed && (
                <>
                  <Alert severity="info" className={classes.expired}>
                    <Trans
                      i18nKey={t("profile.expired_subscription")}
                      components={{
                        accent: <span className={classes.accent} />,
                      }}
                    />
                  </Alert>
                  <Alert severity="info" className={classes.expired}>
                    <Trans
                      i18nKey={t("profile.tip")}
                      components={{
                        accent: <span className={classes.accent} />,
                      }}
                    />
                  </Alert>
                </>
              )}
              {isPartner && !isNotPayed && (
                <Alert severity="info" className={classes.expired}>
                  <Trans
                    i18nKey={t("profile.update_plan_details.text")}
                    components={{
                      accent: <span className={classes.accent} />,
                    }}
                  />
                </Alert>
              )}
            </div>
          </div>
        </>
      )}
      {partnerProfile?.customer.id && userData && (
        <PayCurrentPlanModal
          open={isActivatePlanModalOpened}
          onClose={() => setIsActivatePlanModalOpened(false)}
          customerId={partnerProfile?.customer.id}
          userData={userData}
        />
      )}
    </>
  );
}

export default AdminProfileView;
