import { makeStyles, Theme } from "@material-ui/core/styles";

const useContentSpinnerStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: "relative",
  },
  spinnerOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 10,
  },
  childrenWrap: {
    opacity: ".5",
    "& *": {
      pointerEvents: "none",
    },
  },
  spinnerWrapperOverrides: {
    padding: "0",
  },
}));

export { useContentSpinnerStyles };
