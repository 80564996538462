import { API } from "utils/api/http-client";
import { apiEndpoints } from "constants/api/apiEndpoints";
import { ContactUsRequestPayload } from "types/common/ContactUsRequestPayload";
import { CalculatePurposeScoreRequestPayload } from "types/common/CalculatePurposeScoreRequestPayload";
import { CalculatePurposeScoreResponsePayload } from "types/common/CalculatePurposeScoreResponsePayload";
import { FeedbackRequestPayload } from "types/common/FeedbackRequestPayload";
import { GenerateRegistrationCodeResponsePayload } from "types/common/GenerateRegistrationCodeResponsePayload";
import { CheckRegistrationCodeResponsePayload } from "types/common/CheckRegistrationCodeResponsePayload";
import queryString from "query-string";
import { GetSurveysResultResponsePayload } from "types/common/GetSurveysResultResponsePayload";
import { EmotionalIntlFormValues } from "pages/Exercises/components/surveys/Score/EmotionalIntl/EmotionalIntlForm/EmotionalIntlForm.types";

const { common } = apiEndpoints;

class CommonApiService {
  contactUs = (data: ContactUsRequestPayload) => {
    return API.post(common.contactUs, data);
  };

  calculatePurposeScoreGuest = (data: CalculatePurposeScoreRequestPayload) => {
    return API.post<CalculatePurposeScoreResponsePayload>(
      common.calculatePurposeScoreGuest,
      data,
    );
  };

  calculateEmotionalInt = (data: EmotionalIntlFormValues) => {
    return API.post<CalculatePurposeScoreResponsePayload>(
      common.calculateEmotionalIntScore,
      data,
    );
  };

  feedback = (data: FeedbackRequestPayload) => {
    return API.post(common.feedback, data);
  };

  generateRegistrationCode = () => {
    return API.get<GenerateRegistrationCodeResponsePayload>(
      common.generateRegistrationCode,
    );
  };

  checkRegistrationCode = (params: { code: string }) => {
    return API.get<CheckRegistrationCodeResponsePayload>(
      `${common.checkRegistrationCode}?${queryString.stringify(params)}`,
    );
  };

  getSurveysResult = () => {
    return API.get<GetSurveysResultResponsePayload>(common.getSurveysResult);
  };

  requestEmailLink = (data: any) => {
    return API.post(common.purposePulseLink, data);
  };
}

export default new CommonApiService();
