import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  primaryColor,
  secondaryColor,
  textColors,
} from "constants/themeSettings";

const useGBSimpleTextButtonStyles = makeStyles((theme: Theme) => ({
  root: {
    color: textColors.black.main,
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "20px",
    cursor: "pointer",
    border: "none",
    background: "none",
    padding: "5px 1px",
    textAlign: "left",
    "&:hover": {
      color: textColors.black.light,
    },
  },
  primary: {
    color: primaryColor.main,
    "&:hover": {
      color: primaryColor.light,
    },
  },
  secondary: {
    color: secondaryColor.main,
    "&:hover": {
      color: secondaryColor.light,
    },
  },
  textCenter: {
    textAlign: "center",
  },
  textRight: {
    textAlign: "right",
  },
  disabled: {
    pointerEvents: "none",
    cursor: "default",
    color: primaryColor.lighten,
  },
}));

export { useGBSimpleTextButtonStyles };
