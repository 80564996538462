import React from "react";
import { SvgIcon } from "@material-ui/core";

function LogoutIcon(props: React.ComponentProps<typeof SvgIcon>) {
  return (
    <SvgIcon viewBox="0 0 32 32">
      <path d="M15.947 29.289h-11.96c-0.734 0-1.329-0.595-1.329-1.329v-23.92c0-0.734 0.595-1.329 1.329-1.329h11.96c0.735 0 1.329-0.594 1.329-1.329s-0.594-1.329-1.329-1.329h-11.96c-2.198 0-3.987 1.789-3.987 3.987v23.92c0 2.198 1.789 3.987 3.987 3.987h11.96c0.735 0 1.329-0.594 1.329-1.329s-0.594-1.329-1.329-1.329z"></path>
      <path d="M31.604 15.054l-8.080-7.973c-0.521-0.516-1.363-0.509-1.879 0.013s-0.51 1.363 0.013 1.879l5.774 5.698h-15.472c-0.735 0-1.329 0.594-1.329 1.329s0.594 1.329 1.329 1.329h15.472l-5.774 5.698c-0.524 0.516-0.528 1.357-0.013 1.879 0.26 0.263 0.603 0.396 0.946 0.396 0.338 0 0.675-0.128 0.933-0.383l8.080-7.973c0.253-0.25 0.396-0.59 0.396-0.946s-0.142-0.695-0.396-0.946z"></path>
    </SvgIcon>
  );
}

export default LogoutIcon;
