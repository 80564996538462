import { Grid } from "@material-ui/core";
import { Namespaces } from "i18n";
// import { SnapshotColor } from "pages/Snapshot/Snapshot.consts";
import { useTranslation } from "react-i18next";
// import GrowDescription from "../GrowDescription/GrowDescription";
import { useGrowSectionStyles } from "./styles/GrowSection.styles";
import GrowSquare from "components/GrowSquare/GrowSquare";
import { GrowResultResponsePayload } from "types/grow/GetGrowResultResponsePayload";
import SnapshotSectionLabel from "components/SnapshotSectionLabel/SnapshotSectionLabel";

type GrowSectionProps = {
  data: GrowResultResponsePayload;
};

function GrowSection(props: GrowSectionProps) {
  const {
    data: {
      situations: { result },
    },
  } = props;

  const classes = useGrowSectionStyles();
  const { t } = useTranslation(Namespaces.Snapshot);

  return (
    <div>
      <SnapshotSectionLabel text={t("step2.grow.label")} color="black" />
      <Grid container className={classes.inner}>
        {/* <Grid item md>
          <div className={classes.growDescWrap}>
            <GrowDescription
              color={SnapshotColor.Yellow}
              index={1}
              header="Husband"
              title="John: The Commander"
              keywords={["Just Do It", "Results Driven", "Bottom Line"]}
              text={t("step2.grow.key_question", {
                question: "What’ needs to be done now?",
              })}
            />
          </div>
          <div className={classes.growDescWrap}>
            <GrowDescription
              color={SnapshotColor.Gray}
              index={2}
              header="Wife"
              title="Mary: The Coordinator"
              keywords={[
                "High Touch",
                "Relationship Driven",
                "Openness & Trust",
              ]}
              text={t("step2.grow.key_question", {
                question: "How to people feel?",
              })}
            />
          </div>
          <div className={classes.growDescWrap}>
            <GrowDescription
              index={3}
              header="Partner"
              title="Greg: The Conductor"
              keywords={["Visionary", "Change Driven", "Create & Innovate"]}
              text={t("step2.grow.key_question", {
                question: "What’s new and exciting?",
              })}
            />
          </div>
        </Grid> */}
        <Grid item xs>
          <GrowSquare data={[result[0], result[1], result[2], result[3]]} />
        </Grid>
      </Grid>
    </div>
  );
}

export default GrowSection;
