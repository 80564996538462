import ExerciseMenuView from "./ExerciseMenuView";
import ImpactIllustration from "assets/img/impact_illustration.svg";
import { exerciseRoutes } from "constants/generalRoutes";

function ImpactMenu() {
  return (
    <ExerciseMenuView
      title="Impact"
      exercises={[
        {
          title: "Purpose DNA",
          description: "Discern my Purpose DNA.",
          path: exerciseRoutes.DNA,
          newWindow: false,
          accessGated: true,
        },
        {
          title: "My Life Purpose",
          description: "Define My Purpose",
          path: exerciseRoutes.PURPOSE,
          accessGated: true,
        },
        {
          title: "Promise Statements",
          description: "Declare My Promise",
          path: exerciseRoutes.PROMISE,
          accessGated: true,
        },
      ]}
      illustration={ImpactIllustration}
    />
  );
}
export default ImpactMenu;
