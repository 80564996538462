import React from "react";
import cx from "classnames";
import { useStageStepFrameStyles } from "./styles/StageStepFrame.styles";
import GBTypography from "../GBTypography/GBTypography";
import GBDivider from "../GBDivider/GBDivider";
import SuccessIcon from "components/svg-icons/SuccessIcon";
import ErrorText from "components/ErrorText/ErrorText";
import { useMediaQuery, useTheme } from "@material-ui/core";

type StageStepFrameProps = {
  children: React.ReactNode;
  className?: string;
  headingClassName?: string;
  controlsClassName?: string;
  childrenClassName?: string;
  title: string;
  headingDetails?: React.ReactNode;
  controls?: Array<React.ReactElement>;
  variant?: "success";
  error?: string;
};

function StageStepFrame(props: StageStepFrameProps) {
  const {
    children,
    title,
    headingDetails,
    className = "",
    childrenClassName = "",
    controlsClassName = "",
    headingClassName = "",
    controls,
    variant,
    error,
  } = props;

  const classes = useStageStepFrameStyles();

  const rootClasses = cx(classes.root, className);
  const headingClasses = cx(classes.headingBlock, headingClassName);
  const childrenClasses = cx(classes.children, childrenClassName);
  const controlsClasses = cx(classes.controlsBlock, controlsClassName);
  const theme = useTheme();
  const isDownXs = useMediaQuery(theme.breakpoints.down("xs"));

  const titleClasses = cx(classes.mainTitle, {
    [classes.titleSuccess]: variant === "success",
  });

  return (
    <div className={rootClasses}>
      <div className={headingClasses}>
        <GBTypography variant="h1" className={titleClasses}>
          {title}
          {variant === "success" && !isDownXs && (
            <SuccessIcon className={classes.successIcon} />
          )}
        </GBTypography>
        {headingDetails && (
          <div className={classes.headingDetails}>{headingDetails}</div>
        )}
        <GBDivider className={classes.headingDivider} />
      </div>
      <div className={childrenClasses}>{children}</div>
      {controls && (
        <div className={controlsClasses}>
          <GBDivider className={classes.controlsDivider} />
          <div className={classes.controlsContainer}>
            {controls.map((control, index) => (
              <div key={index} className={classes.controlsItem}>
                {control}
              </div>
            ))}
          </div>
          {error ? <ErrorText text={error} /> : null}
        </div>
      )}
    </div>
  );
}

export default StageStepFrame;
