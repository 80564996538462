import { MaterialTableProps } from "material-table";
import { MutableRefObject } from "react";
import EditPartnerModal from "./components/EditPartnerModal/EditPartnerModal";
import Table from "./components/Table/Table";
import { DataHandlers, DataQueryStatuses, Modals } from "./AdminPartners.types";

type AdminPartnersViewProps = {
  modals: Modals;
  dataQueryStatuses: DataQueryStatuses;
  dataHandlers: DataHandlers;
  tableRef: MutableRefObject<MaterialTableProps<object> | null>;
};

function AdminPartnersView(props: AdminPartnersViewProps) {
  const { modals, dataHandlers, dataQueryStatuses, tableRef } = props;

  return (
    <>
      <Table
        tableRef={tableRef}
        modals={modals}
        queryData={dataHandlers.queryPartners}
      />
      {!!modals.editPartner.data.partnerData && (
        <EditPartnerModal
          isOpen={modals.editPartner.data.isOpen}
          onClose={() =>
            modals.editPartner.setData({ isOpen: false, partnerData: null })
          }
          onSave={dataHandlers.handleEditPartner}
          dataHandlers={dataHandlers}
          dataQueryStatuses={dataQueryStatuses}
          partnerData={modals.editPartner.data.partnerData}
        />
      )}
    </>
  );
}

export default AdminPartnersView;
