import { ValidationRule } from "constants/validationRules";
import * as Yup from "yup";

export const story3FormValidationSchema = Yup.object({
  momInspired: Yup.string().trim().required(ValidationRule.Required),
  momTaught: Yup.string().trim().required(ValidationRule.Required),
  dadInspired: Yup.string().trim().required(ValidationRule.Required),
  dadTaught: Yup.string().trim().required(ValidationRule.Required),
  parentsLesson: Yup.string()
    .trim()
    .max(35, ValidationRule.Max)
    .required(ValidationRule.Required),
});
