import { initialArray } from "./AlignInfo.consts";
import { AlignInfoFormValues } from "./AlignInfo.types";

export const prepareInitialArray = (fetchedData: string[]): string[] => {
  const _data = fetchedData;
  return [
    ..._data,
    ...initialArray.slice(0, initialArray.length - _data.length),
  ];
};

export const prepareInitialValues = (
  fetchedTrials: string[] | undefined,
  fetchedTriumphs: string[] | undefined,
): AlignInfoFormValues => ({
  trials: fetchedTrials ? prepareInitialArray(fetchedTrials) : initialArray,
  triumphs: fetchedTriumphs
    ? prepareInitialArray(fetchedTriumphs)
    : initialArray,
});
