import React from "react";
import { useGBMTDetailPanelStyles } from "../styles/GBMTDetailPanel.styles";
import cx from "classnames";

type ColProps = {
  children: React.ReactNode;
  className?: string;
  bold?: boolean;
};

function Col(props: ColProps) {
  const { children, className, bold } = props;
  const classes = useGBMTDetailPanelStyles();

  const colClasses = cx(
    classes.col,
    {
      [classes.bold]: bold,
    },
    className,
  );

  return <div className={colClasses}>{children || <>&mdash;</>}</div>;
}

export default Col;
