import { GrowComfortEndI18nKey } from "constants/grow/growI18nKeys";
import { GrowResponsePayload } from "types/grow/GetGrowResponsePayload";
import { GrowSituation } from "types/grow/GrowSituation";
import { getSituationValue } from "../Grow.utils";
import { ComfortEndFormValues } from "./GrowComfortEnd.types";

export const prepareInitialValues = (
  grow: GrowResponsePayload | undefined,
): ComfortEndFormValues => {
  const comforts = grow?.situations?.comfort2;
  return {
    comfort6: getSituationValue(GrowComfortEndI18nKey.Comfort6, comforts),
    comfort7: getSituationValue(GrowComfortEndI18nKey.Comfort7, comforts),
    comfort8: getSituationValue(GrowComfortEndI18nKey.Comfort8, comforts),
    comfort9: getSituationValue(GrowComfortEndI18nKey.Comfort9, comforts),
    comfort10: getSituationValue(GrowComfortEndI18nKey.Comfort10, comforts),
  };
};

export const prepareComfortToSubmit = (
  data: ComfortEndFormValues,
): GrowSituation<GrowComfortEndI18nKey>[] => {
  const entries = Object.entries(data);
  return entries.map((e) => ({
    key: e[0] as GrowComfortEndI18nKey,
    value: e[1],
  }));
};
