import { makeStyles, Theme } from "@material-ui/core/styles";
import { textColors } from "constants/themeSettings";

const useTitleHintStyles = makeStyles((theme: Theme) => ({
  root: {
    fontSize: "14px",
    fontWeight: 500,
    paddingLeft: "10px",
    color: textColors.black.light,
  },
  tiny: {
    paddingLeft: "5px",
    fontSize: "10px",
  },
}));

export { useTitleHintStyles };
