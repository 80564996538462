import AuthBox from "components/AuthBox/AuthBox";
import { useAuthBoxStyles } from "components/AuthBox/styles/AuthBox.styles";
import ContentSpinner from "components/ContentSpinner/ContentSpinner";
import ErrorText from "components/ErrorText/ErrorText";
import FormRow from "components/FormRow/FormRow";
import GBCustomButton from "components/GBCustomButton/GBCustomButton";
import GBInputErrorText from "components/GBInputErrorText/GBInputErrorText";
import GBOutlinedInput from "components/GBOutlinedInput/GBOutlinedInput";
import GBSimpleTextButton from "components/GBSimpleTextButton/GBSimpleTextButton";
import GBStaticInputLabel from "components/GBStaticInputLabel/GBStaticInputLabel";
import PasswordToggler from "components/PasswordToggler/PasswordToggler";
import { ApiErrorKey } from "constants/api/apiErrors";
import { ValidationRule } from "constants/validationRules";
import { FormikProps } from "formik";
import { Namespaces } from "i18n";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PartnerSignupFormValues } from "./PartnerSignup.types";
import PaymentStep from "./components/PaymentStep/PaymentStep";

type PartnerSignupViewProps = FormikProps<PartnerSignupFormValues> & {
  isProcessing: boolean;
  errorCode: ApiErrorKey | null;
  isPaymentStep: boolean;
  isCodeGenerating: boolean;
  generateRegCode: () => void;
  handlePayment: () => void;
  handleGoToLogin: () => void;
};

function PartnerSignupView(props: PartnerSignupViewProps) {
  const {
    isProcessing,
    errorCode,
    isCodeGenerating,
    isPaymentStep,
    handleGoToLogin,
    generateRegCode,
    handlePayment,
    ...formik
  } = props;

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    formik;

  const { t } = useTranslation([Namespaces.Auth, Namespaces.Common]);
  const authBoxClasses = useAuthBoxStyles();

  const [isPasswdVisible, setIsPasswdVisible] = useState<boolean>(false);
  const [isConfPasswdVisible, setIsConfPasswdVisible] =
    useState<boolean>(false);

  const hasError = (name: keyof PartnerSignupFormValues): boolean => {
    return !!(touched[name] && errors[name]);
  };

  const getError = (
    field: keyof PartnerSignupFormValues,
    errorCode: ValidationRule,
  ) => {
    return t([
      `forms.partnerSignup.fields.${field}.errors.${errorCode}`,
      `${Namespaces.Common}:api_errors.${errorCode}`,
      `${Namespaces.Common}:errors.${errorCode}`,
    ]);
  };

  const _onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmit();
    }
  };

  return (
    <AuthBox
      title={!isPaymentStep ? t("forms.partnerSignup.title") : undefined}
    >
      {!isPaymentStep ? (
        <form onSubmit={handleSubmit}>
          <ContentSpinner spinning={isProcessing}>
            <FormRow>
              <GBStaticInputLabel
                labelFor="firstName"
                text={t("forms.partnerSignup.fields.firstName.label")}
              />
              <GBOutlinedInput
                id="firstName"
                fullWidth
                name="firstName"
                type="text"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={hasError("firstName")}
                disabled={isProcessing}
                onKeyPress={_onKeyPress}
              />
              {hasError("firstName") && (
                <GBInputErrorText
                  text={getError(
                    "firstName",
                    errors.firstName as ValidationRule,
                  )}
                />
              )}
            </FormRow>
            <FormRow>
              <GBStaticInputLabel
                labelFor="lastName"
                text={t("forms.partnerSignup.fields.lastName.label")}
              />
              <GBOutlinedInput
                id="lastName"
                fullWidth
                name="lastName"
                type="text"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={hasError("lastName")}
                disabled={isProcessing}
                onKeyPress={_onKeyPress}
              />
              {hasError("lastName") && (
                <GBInputErrorText
                  text={getError("lastName", errors.lastName as ValidationRule)}
                />
              )}
            </FormRow>
            <FormRow>
              <GBStaticInputLabel
                labelFor="email"
                text={t("forms.partnerSignup.fields.email.label")}
              />
              <GBOutlinedInput
                id="email"
                fullWidth
                name="email"
                type="text"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={hasError("email")}
                disabled={isProcessing}
                onKeyPress={_onKeyPress}
              />
              {hasError("email") && (
                <GBInputErrorText
                  text={getError("email", errors.email as ValidationRule)}
                />
              )}
            </FormRow>
            <FormRow>
              <div className={authBoxClasses.labelBox}>
                <GBStaticInputLabel
                  labelFor="advCode"
                  text={t("forms.partnerSignup.fields.advCode.label")}
                />
                <GBSimpleTextButton
                  onClick={generateRegCode}
                  color="primary"
                  type="button"
                >
                  {t("forms.partnerSignup.fields.advCode.buttons.generate")}
                </GBSimpleTextButton>
              </div>
              <GBOutlinedInput
                id="advCode"
                fullWidth
                name="advCode"
                type="text"
                value={values.advCode}
                onChange={handleChange}
                onBlur={handleBlur}
                error={hasError("advCode")}
                disabled={isProcessing || isCodeGenerating}
                onKeyPress={_onKeyPress}
              />
              {hasError("advCode") && (
                <GBInputErrorText
                  text={getError("advCode", errors.advCode as ValidationRule)}
                />
              )}
            </FormRow>
            <FormRow>
              <GBStaticInputLabel
                labelFor="password"
                text={t("forms.partnerSignup.fields.password.label")}
              />
              <GBOutlinedInput
                id="password"
                fullWidth
                name="password"
                type={isPasswdVisible ? "text" : "password"}
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={hasError("password")}
                disabled={isProcessing}
                endAdornment={
                  <PasswordToggler
                    isVisible={isPasswdVisible}
                    setIsVisible={(state: boolean) => setIsPasswdVisible(state)}
                  />
                }
                onKeyPress={_onKeyPress}
              />
              {hasError("password") && (
                <GBInputErrorText
                  text={getError("password", errors.password as ValidationRule)}
                />
              )}
            </FormRow>
            <FormRow>
              <GBStaticInputLabel
                labelFor="confPassword"
                text={t("forms.partnerSignup.fields.confPassword.label")}
              />
              <GBOutlinedInput
                id="confPassword"
                fullWidth
                name="confPassword"
                type={isConfPasswdVisible ? "text" : "password"}
                value={values.confPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                error={hasError("confPassword")}
                disabled={isProcessing}
                endAdornment={
                  <PasswordToggler
                    isVisible={isConfPasswdVisible}
                    setIsVisible={(state: boolean) =>
                      setIsConfPasswdVisible(state)
                    }
                  />
                }
                onKeyPress={_onKeyPress}
              />
              {hasError("confPassword") && (
                <GBInputErrorText
                  text={getError(
                    "confPassword",
                    errors.confPassword as ValidationRule,
                  )}
                />
              )}
            </FormRow>
          </ContentSpinner>
          <div className={authBoxClasses.buttonsWrap}>
            <GBCustomButton
              className={authBoxClasses.blockCenter}
              type="submit"
              disabled={isProcessing}
            >
              {t(`${Namespaces.Common}:buttons.continue`)}
            </GBCustomButton>
            {errorCode ? (
              <ErrorText
                textAlign="center"
                text={t([
                  `forms.partnerSignup.errors.${errorCode}`,
                  `${Namespaces.Common}:errors.${errorCode}`,
                  `${Namespaces.Common}:errors.unexpected_error`,
                ])}
              />
            ) : null}
            <div className={authBoxClasses.signUpAddition}>
              {t("forms.partnerSignup.addition.login")}
              <GBSimpleTextButton
                color="primary"
                onClick={handleGoToLogin}
                className={authBoxClasses.linkInTextEnd}
                disabled={isProcessing}
              >
                {t("forms.partnerSignup.buttons.to_login")}
              </GBSimpleTextButton>
            </div>
          </div>
        </form>
      ) : (
        <PaymentStep
          isProcessing={isProcessing}
          handlePayment={handlePayment}
          errorCode={errorCode}
        />
      )}
    </AuthBox>
  );
}

export default PartnerSignupView;
