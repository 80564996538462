import { ValidationRule } from "constants/validationRules";
import * as Yup from "yup";

export const story2FormValidationSchema = Yup.object({
  college: Yup.string().trim().required(ValidationRule.Required),
  major: Yup.string().trim().required(ValidationRule.Required),
  industry: Yup.string().trim().required(ValidationRule.Required),
  position: Yup.string().trim().required(ValidationRule.Required),
  childLove: Yup.string()
    .trim()
    .max(35, ValidationRule.Max)
    .required(ValidationRule.Required),
  career: Yup.string()
    .trim()
    .max(35, ValidationRule.Max)
    .required(ValidationRule.Required),
});
