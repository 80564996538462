import { makeStyles, Theme } from "@material-ui/core";

const useGBMTDetailPanelStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: "20px",
  },
  row: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginBottom: "10px",
    "&:last-child": {
      marginBottom: "0",
    },
  },
  rowDirCol: {
    flexDirection: "column",
  },
  rowSpaceBetween: {
    justifyContent: "space-between",
  },
  vFlexStart: {
    alignItems: "flex-start",
  },
  col: {
    marginRight: "10px",
    "&:last-child": {
      marginRight: "0",
    },
  },
  bold: {
    fontWeight: 700,
  },
  list: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  listItem: {
    display: "flex",
    marginBottom: "10px",
    "&:last-child": {
      marginBottom: "0",
    },
  },
  listItemIcon: {
    marginRight: "2px",
    "& svg": {
      height: "18px",
    },
  },
  listItemText: {
    textTransform: "capitalize",
  },
  listItemSmall: {
    fontSize: "13px",
    "& svg": {
      height: "15px",
      marginRight: "-2px",
      marginTop: "3px",
    },
  },
}));

export { useGBMTDetailPanelStyles };
