import { API } from "utils/api/http-client";
import { apiEndpoints } from "constants/api/apiEndpoints";
import { GetGrowResultResponsePayload } from "types/grow/GetGrowResultResponsePayload";
import { GetGrowResponsePayload } from "types/grow/GetGrowResponsePayload";
import { PostGrowRequestPayload } from "types/grow/PostGrowRequestPayload";
import { PutGrowRequestPayload } from "types/grow/PutGrowRequestPayload";

const { grow } = apiEndpoints;

class GrowApiService {
  getResult = () => {
    return API.get<GetGrowResultResponsePayload>(grow.getResult);
  };

  getGrow = () => {
    return API.get<GetGrowResponsePayload>(grow.getGrow);
  };

  postGrow = (data: PostGrowRequestPayload) => {
    return API.post(grow.postGrow, data);
  };

  putGrow = (data: PutGrowRequestPayload) => {
    return API.put(grow.putGrow, data);
  };
}

export default new GrowApiService();
