import { Trans, useTranslation } from "react-i18next";
import { Namespaces } from "i18n";
import { useScoreResultStyles } from "./styles/ScoreResult.styles";
import StageStepFrame from "components/StageStepFrame/StageStepFrame";
import { useSurvey } from "components/hooks/useSurvey";
import StageStepButton from "components/StageStepButton/StageStepButton";
import {
  getPurposeScoreI18nKey,
  getPurposeScoreKey,
  scoreLevel,
} from "utils/getPurposeScoreI18nKey";
import cx from "classnames";
import ScoreFormSurveyIcon from "components/svg-icons/ScoreFormSurveyIcon";
import { useEffect, useState } from "react";
import GBSpin from "components/GBSpin/GBSpin";
import StageStepContainer from "components/StageStepContainer/StageStepContainer";
import { PurposeScoreTypesI18nKey } from "constants/purposeScoreTypes";

type ScoreResultViewProps = {
  scoreVal?: number;
  isFetching: boolean;
  isFetchError?: boolean;
};

function ScoreResult(props: ScoreResultViewProps) {
  const { scoreVal, isFetching } = props;
  const { t } = useTranslation([
    Namespaces.Score,
    Namespaces.Survey,
    Namespaces.Common,
  ]);

  const classes = useScoreResultStyles();
  const { nextUrl } = useSurvey();
  const [currScoreLevel, setCurrScoreLevel] = useState<scoreLevel>(
    scoreLevel.CRITICAL,
  );

  useEffect(() => {
    scoreVal && setCurrScoreLevel(getPurposeScoreKey(scoreVal));
  }, [scoreVal]);

  const getResultText = (key?: string) => {
    if (scoreVal) {
      return t(
        `score.result.types.${getPurposeScoreI18nKey(scoreVal)}.${
          key || "label"
        }`,
      );
    }
    return "";
  };

  const getResultValueText = () => {
    switch (currScoreLevel) {
      case scoreLevel.CRITICAL:
        return "≤ 2.5 ";
      default:
        return scoreVal;
    }
  };

  const getScoreResultSubtitle = (scoreValue?: number) => {
    if (!scoreValue) return "";
    const key: PurposeScoreTypesI18nKey = getPurposeScoreI18nKey(scoreValue);
    const titleKey =
      key === PurposeScoreTypesI18nKey.Emergency ||
      key === PurposeScoreTypesI18nKey.NeedPush
        ? "subtitleLowScore"
        : "subtitle";
    return t(`score.result.${titleKey}`, {
      score: scoreValue,
    });
  };

  const resultValueClassName = cx({
    [classes.colorRed]: currScoreLevel === scoreLevel.CRITICAL,
    [classes.colorYellow]: currScoreLevel === scoreLevel.LOW,
    [classes.colorBlue]: currScoreLevel === scoreLevel.MIDDLE,
    [classes.colorGreen]: currScoreLevel === scoreLevel.HIGH,
  });

  return (
    <StageStepFrame
      title={t("score.result.title")}
      controls={[
        <StageStepButton.Next goTo={nextUrl}>
          {t(`${Namespaces.Score}:score.result.buttons.finish`)}
        </StageStepButton.Next>,
      ]}
    >
      <div className={classes.subtitle}>{getScoreResultSubtitle(scoreVal)}</div>
      <GBSpin spinning={isFetching}>
        <StageStepContainer>
          <div className={classes.flexBlock}>
            <div className={classes.scoreTitle}>
              {getResultText()}
              <span
                className={resultValueClassName}
              >{` ${getResultValueText()}`}</span>
            </div>
            <div className={classes.iconWrap}>
              <ScoreFormSurveyIcon score={currScoreLevel} />
            </div>
            <div className={classes.scoreDescription}>
              <Trans>{getResultText("desc")}</Trans>
            </div>
          </div>
        </StageStepContainer>
      </GBSpin>
    </StageStepFrame>
  );
}
export default ScoreResult;
