import { useMemo } from "react";
import { useQuery } from "react-query";
import { SurveysResultResponsePayload } from "types/common/GetSurveysResultResponsePayload";
import {
  fetchAlignResult,
  fetchImpact,
  fetchScoreResult,
} from "utils/api/queryFns";
import {
  AlignSectionData,
  ImpactSectionData,
  ScoreSectionData,
} from "./Step1.types";
import Step1View from "./Step1View";

type Step1Props = {
  surveysResult: SurveysResultResponsePayload | undefined;
};

function Step1(props: Step1Props) {
  const { surveysResult } = props;

  const scoreQuery = useQuery(fetchScoreResult.key, fetchScoreResult.fn, {
    enabled: surveysResult?.score.completed,
  });
  const score = useMemo(
    () => scoreQuery.data?.data.payload,
    [scoreQuery.data?.data.payload],
  );

  const alignQuery = useQuery(fetchAlignResult.key, fetchAlignResult.fn, {
    enabled: surveysResult?.align.completed,
  });
  const align = useMemo(
    () => alignQuery.data?.data.payload,
    [alignQuery.data?.data.payload],
  );

  const impactQuery = useQuery(fetchImpact.key, fetchImpact.fn, {
    enabled: surveysResult?.impact.completed,
  });
  const impact = useMemo(
    () => impactQuery.data?.data.payload,
    [impactQuery.data?.data.payload],
  );

  const scoreSectionData: ScoreSectionData = useMemo(
    () => ({
      score: score?.score,
      identities: score?.DNA?.identities,
      emotionalIntlScore: score?.emotionalIntl,
    }),
    [score?.DNA?.identities, score?.emotionalIntl, score?.score],
  );

  const alignSectionData: AlignSectionData = useMemo(
    () => ({
      trials: align?.importantTrials,
      triumphs: align?.importantTriumphs,
    }),
    [align?.importantTrials, align?.importantTriumphs],
  );

  const impactSectionData: ImpactSectionData = useMemo(
    () => ({
      purpose: impact?.lifePurpose?.purposeFinal,
      promise: impact?.promiseStatements?.finalPromise,
    }),
    [
      impact?.lifePurpose?.purposeFinal,
      impact?.promiseStatements?.finalPromise,
    ],
  );

  const isFetching = useMemo(
    () => scoreQuery.isLoading || alignQuery.isLoading || impactQuery.isLoading,
    [alignQuery.isLoading, impactQuery.isLoading, scoreQuery.isLoading],
  );
  const isFetchError = useMemo(
    () => scoreQuery.isError && alignQuery.isError && impactQuery.isError,
    [alignQuery.isError, impactQuery.isError, scoreQuery.isError],
  );

  const isStepsIncomplete =
    !surveysResult?.score.completed &&
    !surveysResult?.align.completed &&
    !surveysResult?.impact.completed;

  return (
    <Step1View
      isFetching={isFetching}
      isFetchError={isFetchError}
      isStepsIncomplete={isStepsIncomplete}
      scoreSectionData={scoreSectionData}
      alignSectionData={alignSectionData}
      impactSectionData={impactSectionData}
    />
  );
}

export default Step1;
