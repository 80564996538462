import cx from "classnames";
import { useStageStepContainerStyles } from "./styles/StageStepContainer.styles";

type StageStepContainerProps = {
  children: React.ReactNode;
  className?: string;
  fullWidth?: boolean;
};

function StageStepContainer(props: StageStepContainerProps) {
  const { children, className, fullWidth } = props;
  const classes = useStageStepContainerStyles();

  const rootClasses = cx(
    classes.root,
    {
      [classes.fullWidth]: fullWidth,
    },
    className,
  );

  return <div className={rootClasses}>{children}</div>;
}

export default StageStepContainer;
