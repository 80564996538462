import { Button } from "@material-ui/core";
import GBCustomButton from "components/GBCustomButton/GBCustomButton";
import ArrowLeftIcon from "components/svg-icons/ArrowLeftIcon";
import React from "react";
import { useHistory } from "react-router";
import { useStageStepButtonStyles } from "../styles/StageStepButton.styles";

type BackProps = React.ComponentProps<typeof Button> & {
  noArrow?: boolean;
  goTo?: string;
};

function Back(props: BackProps) {
  const { noArrow, children, onClick, goTo, ...rest } = props;
  const classes = useStageStepButtonStyles();
  const history = useHistory();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(e);
    }
    if (goTo) {
      history.replace({ pathname: goTo, search: history.location.search });
      // history.replace(goTo);
    }
  };

  return (
    <GBCustomButton
      variant="contained"
      color="secondary"
      className={classes.backButton}
      startIcon={
        !noArrow ? <ArrowLeftIcon className={classes.backArrow} /> : undefined
      }
      onClick={handleClick}
      {...rest}
    >
      {children}
    </GBCustomButton>
  );
}

export default Back;
