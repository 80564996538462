import { ApiErrorKey } from "constants/api/apiErrors";
import { MaterialTableProps } from "material-table";
import { useRef, useState } from "react";
import SuperAdminApiService from "services/SuperAdminApiService";
import { ApiClientError } from "types/api/ApiClientError";
import { PutPartnerRequestPayload } from "types/admin/partners/PutPartnerRequestPayload";
import { selectApiError } from "utils/api/selectApiError";
import { EditPartnerModalFormValues } from "./components/EditPartnerModal/EditPartnerModal.types";
import {
  DataHandlers,
  DataQueryStatuses,
  EditPartnerModalData,
  Modals,
  QueryPartnersFnArgs,
  QueryPartnersFnPayload,
} from "./AdminPartners.types";
import AdminPartnersView from "./AdminPartnersView";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { UserType } from "constants/userTypes";

function AdminPartners() {
  const [apiError, setApiError] = useState<ApiErrorKey | null>(null);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isQueryError, setIsQueryError] = useState<boolean>(false);
  const tableRef = useRef<MaterialTableProps<object> | null>(null);
  const { user } = useSelector((state: RootState) => state.auth);

  const isSuperAdmin = user?.userType === UserType.SuperAdmin;

  const [editPartnerModalData, setEditPartnerModalData] =
    useState<EditPartnerModalData>({
      isOpen: false,
      partnerData: null,
    });

  const modals: Modals = {
    editPartner: {
      data: editPartnerModalData,
      setData: setEditPartnerModalData,
    },
  };

  const refetchPartners = () => {
    //@ts-ignore
    tableRef?.current?.onQueryChange();
  };

  const handleApiError = (e: Error) => {
    console.error(e);
    const error = e as ApiClientError;
    setIsProcessing(false);
    setApiError(selectApiError(error.response?.data.message));
  };

  const queryPartners = async ({
    query,
    cb,
  }: QueryPartnersFnArgs): Promise<QueryPartnersFnPayload> => {
    let result: QueryPartnersFnPayload = {
      data: [],
      page: 0,
      totalCount: 0,
    };

    if (isSuperAdmin) {
      try {
        setApiError(null);
        setIsQueryError(false);
        const { data } = await SuperAdminApiService.getPartners({
          skip: query.page * query.pageSize,
          limit: query.pageSize,
          search: query.search,
        });
        if (cb) {
          cb(data.payload.pagination.totalCount);
        }
        result = {
          data: data.payload.items,
          page: data.payload.pagination.page,
          totalCount: data.payload.pagination.totalCount,
        };
      } catch (e) {
        console.error(e);
        const error = e as ApiClientError;
        setIsQueryError(true);
        setApiError(selectApiError(error.response?.data.message));
      }
    }

    return result;
  };

  const handleEditPartner = async (
    data: EditPartnerModalFormValues,
    cb: () => void,
  ) => {
    if (isSuperAdmin) {
      const partnerId = modals.editPartner.data.partnerData?.id;
      if (partnerId) {
        const requestData: PutPartnerRequestPayload = {
          id: partnerId,
          initialRegistrationsLimit: data.licenses,
        };
        try {
          setApiError(null);
          setIsQueryError(false);
          setIsProcessing(true);
          await SuperAdminApiService.putPartner(requestData);
          if (cb) {
            cb();
          }
          refetchPartners();
          setIsProcessing(false);
        } catch (e) {
          handleApiError(e as Error);
        }
      }
    }
  };

  const dataHandlers: DataHandlers = {
    handleEditPartner,
    handleApiError: setApiError,
    queryPartners,
  };

  const dataQueryStatuses: DataQueryStatuses = {
    apiError,
    isProcessing,
    isQueryError,
  };

  return (
    <AdminPartnersView
      modals={modals}
      dataQueryStatuses={dataQueryStatuses}
      dataHandlers={dataHandlers}
      tableRef={tableRef}
    />
  );
}

export default AdminPartners;
