import { makeStyles, Theme } from "@material-ui/core/styles";

const useGBCircularProgressStyles = makeStyles((theme: Theme) => ({
  spinnerWrap: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    padding: "100px 0",
  },
  spinner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    width: "50px",
    height: "50px",

    "& div": {
      boxSizing: "border-box",
      display: "block",
      position: "absolute",
      width: "50px",
      height: "50px",
      margin: "8px",
      border: `5px solid ${theme.palette.primary.main}`,
      borderRadius: "50%",
      animation: `$spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite`,
      borderColor: `${theme.palette.primary.main} transparent transparent transparent`,
    },

    "& div:nth-child(1)": {
      animationDelay: "-0.45s",
    },

    "& div:nth-child(2)": {
      animationDelay: "-0.3s",
    },

    "& div:nth-child(3)": {
      animationDelay: "-0.15s",
    },
  },

  "@keyframes spinner": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
}));

export { useGBCircularProgressStyles };
