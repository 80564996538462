import { ValidationRule } from "constants/validationRules";
import * as Yup from "yup";

export const philanthropyFormValidationSchema = Yup.object({
  peopleHelp: Yup.string()
    .trim()
    .max(35, ValidationRule.Max)
    .required(ValidationRule.Required),
  helpReason: Yup.string().trim().required(ValidationRule.Required),
});
