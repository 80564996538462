import { makeStyles, Theme } from "@material-ui/core/styles";

const useAlignThemeStyles = makeStyles((theme: Theme) => ({
  textBlock: {
    marginBottom: "56px",
  },
  text: {
    paddingTop: "16px",
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

export { useAlignThemeStyles };
