import { SignUpRequestBody } from "types/auth/SignUpRequestBody";
import { LoginRequestBody } from "types/auth/LoginRequestBody";
import { API } from "utils/api/http-client";
import { LoginResponsePayload } from "types/auth/LoginResponsePayload";
import { SignupResponsePayload } from "types/auth/SignupResponsePayload";
import { ForgotPasswdRequestBody } from "types/auth/ForgotPasswdRequestBody";
import { ResetPasswordRequestBody } from "types/auth/ResetPasswordRequestBody";
import { apiEndpoints } from "constants/api/apiEndpoints";
import { GeneratePasswordResponsePayload } from "types/auth/GeneratePasswordResponsePayload";
import { PartnerSignupRequestPayload } from "types/auth/PartnerSignupRequestPayload";

const { auth } = apiEndpoints;

class AuthenticationApiService {
  register = (data: SignUpRequestBody) => {
    return API.post<SignupResponsePayload>(auth.register, data);
  };

  login = (data: LoginRequestBody) => {
    return API.post<LoginResponsePayload>(auth.login, data);
  };

  forgotPassword = (data: ForgotPasswdRequestBody) => {
    return API.post(auth.forgotPasswd, data);
  };

  getProfile = (token: string) => {
    return API.get(`${auth.resetPasswd}/${token}`);
  };

  resetPassword = (token: string, data: ResetPasswordRequestBody) => {
    return API.post<LoginResponsePayload>(`${auth.resetPasswd}/${token}`, data);
  };

  generatePassword = () => {
    return API.get<GeneratePasswordResponsePayload>(auth.generatePasswd);
  };

  registerPartner = (data: PartnerSignupRequestPayload) => {
    return API.post<SignupResponsePayload>(auth.registerPartner, data);
  };

  getToken = (token: string) => {
    return API.get(`${auth.getToken}/?token=${token}`);
  };
}

export default new AuthenticationApiService();
