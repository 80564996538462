import { ValidationRule } from "constants/validationRules";
import * as Yup from "yup";

export const conflictEndFormValidationSchema = Yup.object().shape({
  conflict6: Yup.number()
    .moreThan(0, ValidationRule.Required)
    .required(ValidationRule.Required),
  conflict7: Yup.number()
    .moreThan(0, ValidationRule.Required)
    .required(ValidationRule.Required),
  conflict8: Yup.number()
    .moreThan(0, ValidationRule.Required)
    .required(ValidationRule.Required),
  conflict9: Yup.number()
    .moreThan(0, ValidationRule.Required)
    .required(ValidationRule.Required),
  conflict10: Yup.number()
    .moreThan(0, ValidationRule.Required)
    .required(ValidationRule.Required),
});
