import { useGBSurveyStyles } from "./styles/GBSurvey.styles";
import { StageType, StepType, SurveyType } from "types/MainMenu";
import { useDispatch, useSelector } from "react-redux";
import AuthenticationApiService from "services/AuthenticationApiService";
import { RootState } from "store";
import { useEffect } from "react";
import { authActions } from "store/auth/actions";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
//import { useDispatch, useSelector } from "react-redux";

type SurveyViewProps = {
  survey?: SurveyType;
  stage?: StageType;
  step?: StepType;
  token?: string | null;
};

function ExerciseView(props: SurveyViewProps) {
  const auth = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const { step, token } = props;

  useEffect(() => {
    if (token && !auth.isAuthenticated) {
      AuthenticationApiService.getToken(token)
        .then((response) => {
          if (response.data.payload.user) {
            console.log(history);
            dispatch(
              authActions.login(
                response.data.payload.user.tokens[
                  response.data.payload.user.tokens.length - 1
                ].token,
              ),
            );
          }
        })
        .catch((e) => {
          history.push(history.location.pathname);
          toast.error("Please enter your email to access this exercise.");
        });
    }
  }, [auth.isAuthenticated, dispatch, history, token]);

  const classes = useGBSurveyStyles();
  const ViewComponent = step?.component;
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        {ViewComponent && <ViewComponent />}
      </div>
    </div>
  );
}
export default ExerciseView;
