import GBInputErrorText from "components/GBInputErrorText/GBInputErrorText";
import GBStaticInputLabel from "components/GBStaticInputLabel/GBStaticInputLabel";
import { Namespaces } from "i18n";
import { Trans, useTranslation } from "react-i18next";
import FormRow from "components/FormRow/FormRow";
import GBCardInput from "components/GBCardInput/GBCardInput";
import { OnChangeEventType } from "components/GBCardInput/GBCardInput.types";
import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { PaymentPrice } from "types/payment/PaymentPrices";
import { fetchPaymentConfig } from "utils/api/queryFns";
import GBCircularProgress from "components/GBCircularProgress/GBCircularProgress";
import UnexpectedError from "components/UnexpectedError/UnexpectedError";
import { PaymentPriceLicenses } from "types/payment/PaymentPriceLicenses";
import { usePaymentStepStyles } from "./styles/PaymentStep.styles";
import { useFormik } from "formik";
import { PaymentStepFormValues } from "./PaymentStep.types";
import {
  paymentStepFormInitialValues,
  paymentStepFormValidationSchema,
} from "./PaymentStep.consts";
import { ValidationRule } from "constants/validationRules";
import { ApiErrorKey } from "constants/api/apiErrors";
import { useAuthBoxStyles } from "components/AuthBox/styles/AuthBox.styles";
import GBCustomButton from "components/GBCustomButton/GBCustomButton";
import ErrorText from "components/ErrorText/ErrorText";

type PaymentStepProps = {
  isProcessing: boolean;
  errorCode: ApiErrorKey | null;
  handlePayment: () => void;
};

function PaymentStep(props: PaymentStepProps) {
  const { isProcessing, handlePayment, errorCode } = props;

  const { t, i18n } = useTranslation([Namespaces.Auth, Namespaces.Common]);
  const classes = usePaymentStepStyles();
  const authBoxClasses = useAuthBoxStyles();
  const [stripeCardError, setStripeCardError] = useState<string>("");

  const configQuery = useQuery(fetchPaymentConfig.key, fetchPaymentConfig.fn);
  const config = useMemo(
    () => configQuery.data?.data.payload,
    [configQuery.data?.data.payload],
  );

  const basicPlan = useMemo(
    () =>
      config?.prices.filter(
        (p) => p.active && p.name === PaymentPrice.Basic,
      )[0],
    [config?.prices],
  );

  const isLoading = configQuery.isLoading;
  const isError = configQuery.isError;

  const onSubmit = (data: PaymentStepFormValues) => {
    if (data.cardData) {
      handlePayment();
    }
  };

  const formik = useFormik<PaymentStepFormValues>({
    validationSchema: paymentStepFormValidationSchema,
    enableReinitialize: true,
    initialValues: paymentStepFormInitialValues,
    onSubmit: onSubmit,
  });

  const { touched, errors, handleSubmit, setFieldTouched, setFieldValue } =
    formik;

  const hasError = (name: keyof PaymentStepFormValues): boolean => {
    return !!(touched[name] && errors[name]);
  };

  const getError = (
    field: keyof PaymentStepFormValues,
    errorCode: ValidationRule,
  ) => {
    return t([
      `forms.partnerSignup.fields.${field}.errors.${errorCode}`,
      `${Namespaces.Common}:api_errors.${errorCode}`,
      `${Namespaces.Common}:errors.${errorCode}`,
    ]);
  };

  const handleCardDataChange = (event: OnChangeEventType) => {
    const errorCode = event.error?.code;
    const isCompleted = event.complete;
    if (!!errorCode) {
      setStripeCardError(errorCode);
    } else {
      setStripeCardError("");
    }
    setFieldValue("cardData", isCompleted);
    setFieldTouched("cardData");
  };

  return (
    <form onSubmit={handleSubmit}>
      {isLoading ? (
        <GBCircularProgress />
      ) : isError || !basicPlan ? (
        <UnexpectedError />
      ) : (
        <>
          <div className={classes.paymentMessage}>
            <Trans
              i18nKey={t("forms.partnerSignup.payment_message", {
                amount: new Intl.NumberFormat(i18n.language, {
                  style: "currency",
                  currency: basicPlan.currency,
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                }).format(basicPlan.amount / 100),
                interval: t(`${Namespaces.Common}:misc.${basicPlan.interval}`),
                licenses: PaymentPriceLicenses.Basic,
              })}
              components={{
                accent: <span className={classes.accent} />,
              }}
            />
          </div>
          <div>
            <FormRow>
              <GBStaticInputLabel
                text={t("forms.partnerSignup.fields.cardData.label")}
              />
              <GBCardInput
                error={hasError("cardData")}
                onChange={handleCardDataChange}
                disabled={isProcessing}
              />
              {!!stripeCardError ? (
                <GBInputErrorText
                  text={t(
                    `forms.partnerSignup.fields.cardData.errors.${stripeCardError}`,
                  )}
                />
              ) : hasError("cardData") ? (
                <GBInputErrorText
                  text={getError("cardData", errors.cardData as ValidationRule)}
                />
              ) : null}
            </FormRow>
          </div>
          <div className={authBoxClasses.buttonsWrap}>
            <GBCustomButton
              className={authBoxClasses.blockCenter}
              type="submit"
              disabled={isProcessing}
            >
              {isProcessing
                ? t("forms.partnerSignup.buttons.processing")
                : t("forms.partnerSignup.buttons.submit")}
            </GBCustomButton>
            {errorCode ? (
              <ErrorText
                textAlign="center"
                text={t([
                  `forms.partnerSignup.errors.${errorCode}`,
                  `${Namespaces.Common}:errors.${errorCode}`,
                  `${Namespaces.Common}:errors.unexpected_error`,
                ])}
              />
            ) : null}
          </div>
        </>
      )}
    </form>
  );
}

export default PaymentStep;
