import { makeStyles, Theme } from "@material-ui/core/styles";
import { primaryColor } from "constants/themeSettings";

const useExerciseMenuPageStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    paddingBottom: "0",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px",
    },
  },
  splitDiv: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    padding: "5%",
  },
  menu: {},
  titleDiv: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    alignItems: "center",
    justifyContent: "center",
  },
  exerciseLink: {
    backgroundColor: "#f0f0f0",
    display: "flex",
    flexDirection: "row",
    padding: "8px 15px",
    borderRadius: "20px",
    minWidth: "200px",
    cursor: "pointer",
    marginBottom: "20px",
    "&:hover": {
      opacity: "90%",
      border: "2px solid lightgray",
    },
  },
  exerciseTitle: {
    padding: "0px",
    margin: "5px",
    color: primaryColor.main,
  },
  exerciseDescription: {
    padding: "0px",
    margin: "5px",
  },
}));

export { useExerciseMenuPageStyles };
