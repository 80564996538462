import { makeStyles, Theme } from "@material-ui/core";
import { primaryColor } from "constants/themeSettings";

const usePaymentModalStyles = makeStyles((theme: Theme) => ({
  priceWrap: {
    paddingBottom: "32px",
    fontSize: "20px",
    textAlign: "center",
  },
  accent: {
    color: primaryColor.main,
    fontWeight: 600,
  },
}));

export { usePaymentModalStyles };
