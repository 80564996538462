import { makeStyles } from "@material-ui/core";

const usePasswordTogglerStyles = makeStyles({
  passwdToggler: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    paddingRight: "9px",
    "& svg": {
      fontSize: "31px",
    },
  },
});

export { usePasswordTogglerStyles };
