import { makeStyles } from "@material-ui/core";
import {
  borderRadiuses,
  primaryColor,
  textColors,
} from "constants/themeSettings";

const usePaymentStepStyles = makeStyles({
  flexBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  paymentMessage: {
    fontSize: "18px",
    fontWeight: 400,
    textAlign: "center",
    color: textColors.black.light,
    lineHeight: "30px",
    background: "#fff4ed",
    padding: "10px",
    borderRadius: borderRadiuses.main,
    marginBottom: "32px",
  },
  accent: {
    fontWeight: 600,
    color: primaryColor.main,
  },
});

export { usePaymentStepStyles };
