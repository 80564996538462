import { Grid } from "@material-ui/core";
import ExerciseLayout from "layouts/ExerciseLayout/ExerciseLayout";
import { useLocation } from "react-router";
import { useAdminLayoutStyles } from "./styles/AdminLayout.styles";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { UserType } from "constants/userTypes";
import SuperAdminMenu from "./components/SuperAdminMenu/SuperAdminMenu";
import PartnerMenu from "./components/PartnerMenu/PartnerMenu";

type AdminLayoutProps = {
  children: JSX.Element;
};

function AdminLayout(props: AdminLayoutProps) {
  const { children } = props;
  const classes = useAdminLayoutStyles();

  const auth = useSelector((state: RootState) => state.auth);
  const location = useLocation();

  const isSuperAdmin = auth.user?.userType === UserType.SuperAdmin;
  const isPartner = auth.user?.userType === UserType.Partner;

  const checkActive = (route: string): "secondary" | undefined =>
    location.pathname === route ? undefined : "secondary";

  return (
    <ExerciseLayout>
      <Grid container className={classes.root}>
        <Grid item md={2} className={classes.aside}>
          {isSuperAdmin && <SuperAdminMenu checkActive={checkActive} />}
          {isPartner && <PartnerMenu checkActive={checkActive} />}
        </Grid>
        <Grid item md={10} className={classes.content}>
          {children}
        </Grid>
      </Grid>
    </ExerciseLayout>
  );
}

export default AdminLayout;
