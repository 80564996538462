import SuccessIcon from "components/svg-icons/SuccessIcon";
import { Checkbox, CheckboxProps } from "@material-ui/core";
import cx from "classnames";
import { useGBCheckStyles } from "./styles/GBCheck.styles";

export type GBCheckColor = "primary" | "secondary" | "blue" | "red";

type GBCheckProps = Omit<CheckboxProps, "color"> & {
  color?: GBCheckColor;
};

function GBCheck(props: GBCheckProps) {
  const { color, ...rest } = props;
  const classes = useGBCheckStyles();

  const checkClasses = cx(classes.check, {
    [classes.checkedPrimary]: color === "primary",
    [classes.checkedBlue]: color === "blue",
    [classes.checkedRed]: color === "red",
    [classes.checkedSecondary]: color === "secondary",
  });

  return (
    <Checkbox
      checkedIcon={<SuccessIcon className={checkClasses} />}
      icon={<span className={classes.icon} />}
      color="default"
      {...rest}
    />
  );
}

export default GBCheck;
