export enum ContactUsModalActions {
  OPEN = "contactUsModal/OPEN",
  CLOSE = "contactUsModal/CLOSE",
}

export type ContactUsModalState = {
  open: boolean;
};

export type OpenContactUsModalAction = {
  type: ContactUsModalActions.OPEN;
};

export type CloseContactUsModalAction = {
  type: ContactUsModalActions.CLOSE;
};

export type ContactUsModalActionTypes =
  | OpenContactUsModalAction
  | CloseContactUsModalAction;
