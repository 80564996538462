import { makeStyles, Theme } from "@material-ui/core/styles";
import { WIDE_CONTAINER_WIDTH } from "constants/settings";

const useExerciseLayoutStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    minHeight: "100vh",
    position: "relative",
    paddingTop: "70px",
    margin: "0 auto",
  },
  navbarShadow: {
    boxShadow: "0px 3px 6px 1px rgba(0, 0, 0, 0.04)",
  },
  content: {
    paddingBottom: "150px",
    width: `${WIDE_CONTAINER_WIDTH}px`,
    margin: "0 auto",
    [theme.breakpoints.down(WIDE_CONTAINER_WIDTH)]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "210px",
    },
    [theme.breakpoints.down(750)]: {
      paddingBottom: "400px",
    },
    [theme.breakpoints.down(450)]: {
      paddingBottom: "440px",
    },
  },
  link: {
    textDecoration: "none",
  },
}));

export { useExerciseLayoutStyles };
