import { useMemo } from "react";
import { useQuery } from "react-query";
import { SurveysResultResponsePayload } from "types/common/GetSurveysResultResponsePayload";
import { fetchGrowResult } from "utils/api/queryFns";
import Step2View from "./Step2View";

type Step2Props = {
  surveysResult: SurveysResultResponsePayload | undefined;
};

function Step2(props: Step2Props) {
  const { surveysResult } = props;

  const growResultQuery = useQuery(fetchGrowResult.key, fetchGrowResult.fn, {
    enabled: surveysResult?.grow.completed,
  });
  const growResult = useMemo(
    () => growResultQuery.data?.data.payload,
    [growResultQuery.data?.data.payload],
  );

  const isLoading = growResultQuery.isLoading;
  const isError = growResultQuery.isError;

  return (
    <Step2View
      growResult={growResult}
      isLoading={isLoading}
      isError={isError}
    />
  );
}

export default Step2;
