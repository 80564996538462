import { makeStyles, Theme } from "@material-ui/core";

const useAuthLayoutStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: "100vh",
    position: "relative",
    margin: "0 auto",
  },
  content: {
    paddingTop: "150px",
    paddingBottom: "150px",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "210px",
    },
    [theme.breakpoints.down(750)]: {
      paddingBottom: "460px",
    },
    [theme.breakpoints.down(450)]: {
      paddingBottom: "500px",
    },
  },
}));

export { useAuthLayoutStyles };
