import GBMaterialTable from "components/GBMaterialTable/GBMaterialTable";
import { Namespaces } from "i18n";
import { MutableRefObject, useCallback, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
import MoodIcon from "@material-ui/icons/Mood";
import { useAdminFeedbacksStyles } from "pages/Admin/AdminFeedbacks/styles/AdminFeedbacks.styles";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  Modals,
  QueryFeedbacksFn,
} from "pages/Admin/AdminFeedbacks/AdminFeedbacks.types";
import { MaterialTableProps, Query } from "material-table";
import { AdminFeedback } from "types/admin/feedbacks/AdminFeedback";
import TitleHint from "components/TitleHint/TitleHint";

type TableProps = {
  queryData: QueryFeedbacksFn;
  tableRef: MutableRefObject<MaterialTableProps<object> | null>;
  modals: Modals;
};

function Table(props: TableProps) {
  const { queryData, tableRef, modals } = props;
  const { t } = useTranslation([Namespaces.Admin, Namespaces.Common]);
  const classes = useAdminFeedbacksStyles();
  const [totalCount, setTotalCount] = useState<number>(0);

  const getRateEmotion = useCallback(
    (rate: number) => {
      if (rate === 1 || rate === 2) {
        return <SentimentVeryDissatisfiedIcon className={classes.sad} />;
      } else if (rate === 3 || rate === 4) {
        return <SentimentSatisfiedIcon className={classes.satisfied} />;
      } else {
        return <MoodIcon className={classes.happy} />;
      }
    },
    [classes.happy, classes.sad, classes.satisfied],
  );

  return (
    <div className={classes.tableRoot}>
      <GBMaterialTable
        tableRef={tableRef}
        columns={[
          {
            title: t("feedbacks.table.cols.rate"),
            render: (rowData) => {
              return (
                <div className={classes.emotionBlock}>
                  {getRateEmotion(rowData.rate)}
                  {rowData.rate}
                </div>
              );
            },
          },
          {
            title: t("feedbacks.table.cols.author"),
            render: (rowData) => {
              return `${rowData.user?.first_name} ${rowData.user?.last_name}`;
            },
          },
          {
            title: t("feedbacks.table.cols.email"),
            field: "user.email",
          },
        ]}
        data={(query: Query<AdminFeedback>) =>
          queryData({
            query,
            cb: (totalCount: number) => setTotalCount(totalCount),
          })
        }
        title={
          <h6>
            <Trans
              i18nKey={t("feedbacks.table.title")}
              components={{
                total: (
                  <TitleHint
                    text={t(`${Namespaces.Common}:labels.total`, {
                      value: totalCount,
                    })}
                  />
                ),
              }}
            />
          </h6>
        }
        actions={[
          (rowData) => ({
            icon: DeleteIcon,
            tooltip: t("feedbacks.table.actions.delete"),
            onClick: () => {
              modals.delete.setData({ feedback: rowData, isOpen: true });
            },
          }),
        ]}
        detailPanel={[
          {
            tooltip: t("feedbacks.table.detailPanel.tooltip"),
            render: (rowData) => (
              <div className={classes.feedback}>
                {rowData.feedback || <>&mdash;</>}
              </div>
            ),
          },
        ]}
        options={{
          actionsColumnIndex: -1,
          draggable: false,
          sorting: false,
        }}
      />
    </div>
  );
}

export default Table;
