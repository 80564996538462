import FormRow from "components/FormRow/FormRow";
import GBInputErrorText from "components/GBInputErrorText/GBInputErrorText";
import GBOutlinedInput from "components/GBOutlinedInput/GBOutlinedInput";
import { ValidationRule } from "constants/validationRules";
import { FormikProps } from "formik";
import { Namespaces } from "i18n";
import { useTranslation } from "react-i18next";
import { ImpactPurposeFinalFormValues } from "../ImpactPurposeFinal.types";

type FinalFormProps = FormikProps<ImpactPurposeFinalFormValues> & {};

function FinalForm(props: FinalFormProps) {
  const { values, handleBlur, handleChange, handleSubmit, touched, errors } =
    props;

  const { t } = useTranslation(Namespaces.Impact);

  const hasError = (name: keyof ImpactPurposeFinalFormValues): boolean => {
    return !!(touched[name] && errors[name]);
  };

  const getError = (
    field: keyof ImpactPurposeFinalFormValues,
    errorCode: ValidationRule,
  ) => {
    return t([
      `purpose.final.form.${field}.errors.${errorCode}`,
      `${Namespaces.Common}:errors.${errorCode}`,
    ]);
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormRow>
        <GBOutlinedInput
          id="purposeFinal"
          fullWidth
          name="purposeFinal"
          type="text"
          multiline={true}
          rows={2}
          value={values.purposeFinal}
          onChange={handleChange}
          onBlur={handleBlur}
          error={hasError("purposeFinal")}
          placeholder={t("purpose.final.form.purposeFinal.placeholder")}
        />
        {hasError("purposeFinal") && (
          <GBInputErrorText
            text={getError(
              "purposeFinal",
              errors.purposeFinal as ValidationRule,
            )}
          />
        )}
      </FormRow>
    </form>
  );
}

export default FinalForm;
