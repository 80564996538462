import { generalRoutes } from "constants/generalRoutes";
import { UserType } from "constants/userTypes";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router";
import { RootState } from "store";
import { EntityId } from "types/EntityId";
import {
  fetchPartnerUserDetail,
  fetchSuperAdminUserDetail,
} from "utils/api/queryFns";
import AdminUserSnapshotView from "./AdminUserSnapshotView";

function AdminUserSnapshot() {
  const auth = useSelector((state: RootState) => state.auth);
  const isSuperAdmin = auth.user?.userType === UserType.SuperAdmin;
  const isPartner = auth.user?.userType === UserType.Partner;

  const match = useRouteMatch<{ id: EntityId }>({
    path: isSuperAdmin
      ? generalRoutes.SUPERADMIN_USER_SNAPSHOT
      : isPartner
      ? generalRoutes.PARTNER_USER_SNAPSHOT
      : "",
    strict: true,
    sensitive: true,
  });

  const superAdminUserQuery = useQuery(
    fetchSuperAdminUserDetail.key,
    () => fetchSuperAdminUserDetail.fn(match?.params.id || "unknown"),
    {
      enabled: isSuperAdmin,
    },
  );

  const partnerUserQuery = useQuery(
    fetchPartnerUserDetail.key,
    () => fetchPartnerUserDetail.fn(match?.params.id || "unknown"),
    {
      enabled: isPartner,
    },
  );

  const user = useMemo(() => {
    if (isSuperAdmin) {
      return superAdminUserQuery.data?.data.payload;
    }
    if (isPartner) {
      return partnerUserQuery.data?.data.payload;
    }
  }, [
    partnerUserQuery.data?.data.payload,
    isPartner,
    isSuperAdmin,
    superAdminUserQuery.data?.data.payload,
  ]);

  const isFetching =
    superAdminUserQuery.isFetching || partnerUserQuery.isFetching;
  const isError = superAdminUserQuery.isError || partnerUserQuery.isError;

  return (
    <AdminUserSnapshotView
      user={user}
      isFetching={isFetching}
      isError={isError}
    />
  );
}

export default AdminUserSnapshot;
