import { makeStyles, Theme } from "@material-ui/core/styles";
import { commonColors } from "constants/themeSettings";

const useGBConfirmModalStyles = makeStyles((theme: Theme) => ({
  text: {
    color: commonColors.black,
    fontSize: "18px",
    lineHeight: "24px",
    textAlign: "center",
  },
}));

export { useGBConfirmModalStyles };
