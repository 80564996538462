export enum EmotionalIntlScoreLevel {
  CRITICAL = "critical",
  LOW = "low",
  MIDDLE = "middle",
  HIGH = "high",
}

export const getEmotionalIntlScoreI18nKey = (score: number) => {
  if (score <= 2.5) {
    return EmotionalIntlScoreLevel.CRITICAL;
  } else if (score >= 2.6 && score <= 3.9) {
    return EmotionalIntlScoreLevel.LOW;
  } else if (score >= 4 && score <= 4.9) {
    return EmotionalIntlScoreLevel.MIDDLE;
  } else {
    return EmotionalIntlScoreLevel.HIGH;
  }
};
