import GBCustomButton from "components/GBCustomButton/GBCustomButton";
import PersonIcon from "@material-ui/icons/Person";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useTranslation } from "react-i18next";
import { Namespaces } from "i18n";
import { useHistory, useLocation } from "react-router";
import { partnerMenuRoutes } from "./PartnerMenu.consts";
import GroupIcon from "@material-ui/icons/Group";
import { useQuery } from "react-query";
import { fetchPartnerProfile } from "utils/api/queryFns";
import { useMemo } from "react";

type PartnerMenuProps = {
  checkActive: (route: string) => "secondary" | undefined;
};

function PartnerMenu(props: PartnerMenuProps) {
  const { checkActive } = props;

  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation(Namespaces.Admin);

  const partnerProfileQuery = useQuery(
    fetchPartnerProfile.key,
    fetchPartnerProfile.fn,
  );
  const isPayedPartner = useMemo(
    () =>
      partnerProfileQuery.data?.data.payload.user.payment_status === "payed",
    [partnerProfileQuery.data?.data.payload],
  );

  return (
    <>
      {Object.values(partnerMenuRoutes).includes(location.pathname) ? (
        <>
          <GBCustomButton
            linkType="router-link"
            href={partnerMenuRoutes.profile}
            variant="contained"
            color={checkActive(partnerMenuRoutes.profile)}
            fullWidth
            startIcon={<PersonIcon />}
          >
            {t("layout.aside.buttons.profile")}
          </GBCustomButton>
          {isPayedPartner && (
            <GBCustomButton
              linkType="router-link"
              href={partnerMenuRoutes.users}
              variant="contained"
              color={checkActive(partnerMenuRoutes.users)}
              fullWidth
              startIcon={<GroupIcon />}
            >
              {t("layout.aside.buttons.users")}
            </GBCustomButton>
          )}
        </>
      ) : (
        <GBCustomButton
          variant="contained"
          fullWidth
          startIcon={<ArrowBackIcon />}
          onClick={() => history.goBack()}
          color="secondary"
        >
          {t("layout.aside.buttons.back")}
        </GBCustomButton>
      )}
    </>
  );
}

export default PartnerMenu;
