export enum AuthModalActions {
  OPEN = "AuthModal/OPEN",
  CLOSE = "AuthModal/CLOSE",
}

export type AuthModalState = {
  open: boolean;
  exerciseLink: string;
};

export type OpenAuthModalAction = {
  type: AuthModalActions.OPEN;
  exerciseLink: string;
};

export type CloseAuthModalAction = {
  type: AuthModalActions.CLOSE;
};

export type AuthModalActionTypes = OpenAuthModalAction | CloseAuthModalAction;
