import FormRow from "components/FormRow/FormRow";
import GBStaticInputLabel from "components/GBStaticInputLabel/GBStaticInputLabel";
import RangeCheckQuizField from "components/RangeCheckQuizField/RangeCheckQuizField";
import { GrowConflictBeginI18nKey } from "constants/grow/growI18nKeys";
import { GROW_QUIZ_RANGE_MAX } from "constants/grow/settings";
import { FormikProps } from "formik";
import { Namespaces } from "i18n";
import { useTranslation, Trans } from "react-i18next";
import { ConflictBeginFormValues } from "../GrowConflictBegin.types";

type ConflictBeginFormProps = FormikProps<ConflictBeginFormValues> & {};

function ConflictBeginForm(props: ConflictBeginFormProps) {
  const { values, setFieldValue, touched, errors } = props;
  const { t } = useTranslation(Namespaces.Grow);

  const hasError = (name: keyof ConflictBeginFormValues): boolean => {
    return !!(touched[name] && errors[name]);
  };

  return (
    <>
      {Object.entries(values).map((entrie, key) => (
        <FormRow key={key} gutter="48px">
          <GBStaticInputLabel
            labelFor={entrie[0]}
            text={
              <Trans
                i18nKey={t(`steps.conflict_begin.quiz.${entrie[0]}`)}
                components={{
                  br: <br />,
                }}
              />
            }
          />
          <RangeCheckQuizField
            id={entrie[0]}
            name={entrie[0]}
            fullWidth
            value={entrie[1]}
            from={1}
            to={GROW_QUIZ_RANGE_MAX}
            fromText={t("quizRange.smallest")}
            toText={t("quizRange.highest")}
            checkColor="red"
            error={hasError(entrie[0] as GrowConflictBeginI18nKey)}
            onChange={(value: number) => setFieldValue(entrie[0], value)}
          />
        </FormRow>
      ))}
    </>
  );
}

export default ConflictBeginForm;
