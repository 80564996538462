import { makeStyles, Theme } from "@material-ui/core";

const useArrowStyles = makeStyles<
  Theme,
  { width?: number; vertical?: boolean; textUnder?: boolean }
>((theme: Theme) => ({
  root: ({ width, vertical, textUnder }) => ({
    width: `${width || 510}px`,
    transform: vertical ? "rotate(-90deg)" : "unset",
    display: "flex",
    flexDirection: textUnder ? "column-reverse" : "column",
  }),
  textBlock: ({ textUnder }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: !textUnder ? "20px" : "unset",
    paddingTop: textUnder ? "20px" : "unset",
    [theme.breakpoints.down(600)]: {
      paddingBottom: !textUnder ? "10px" : "unset",
      paddingTop: textUnder ? "10px" : "unset",
    },
  }),
  text: {
    fontSize: "16px",
    fontWeight: 500,
    [theme.breakpoints.down(600)]: {
      fontSize: "13px",
    },
  },
  arrowWrap: {
    height: "4px",
    width: "100%",
    backgroundColor: "#E0E0E0",
    position: "relative",
  },
  icon: {
    position: "absolute",
    left: "3px",
    top: "50%",
    transform: "translate(-50%, -50%)",
    fill: "#E0E0E0",
    fontSize: "35px",
  },
  iconRight: {
    left: "unset",
    right: "-32px",
  },
}));

export { useArrowStyles };
