import {
  GrowComfortBeginI18nKey,
  GrowComfortEndI18nKey,
  GrowConflictBeginI18nKey,
  GrowConflictEndI18nKey,
} from "constants/grow/growI18nKeys";
import { GrowSituation } from "types/grow/GrowSituation";

type KeyType =
  | GrowComfortBeginI18nKey
  | GrowComfortEndI18nKey
  | GrowConflictBeginI18nKey
  | GrowConflictEndI18nKey;

export const getSituationValue = <T extends KeyType>(
  key: KeyType,
  data: Array<GrowSituation<T>> | undefined,
): number => {
  const defaultValue = 0;
  return data?.find((i) => i.key === key)?.value ?? defaultValue;
};
