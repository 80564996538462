import { AuthActions, AuthActionTypes, AuthState } from "./types";

export const initialAuthState: AuthState = {
  isAuthenticated: false,
  user: null,
};

export function auth(
  state = initialAuthState,
  action: AuthActionTypes,
): AuthState {
  switch (action.type) {
    case AuthActions.LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
      };
    case AuthActions.SIGNUP_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
      };
    case AuthActions.LOGOUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    case AuthActions.SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
}
