import { makeStyles, Theme } from "@material-ui/core/styles";
import { commonColors, textColors } from "constants/themeSettings";

const useScoreSectionStyles = makeStyles((theme: Theme) => ({
  content: {
    paddingTop: "20px",
  },
  flexBox: {
    display: "flex",
    justifyContent: "space-between",
  },
  score: {
    fontSize: "150px",
    color: commonColors.green,
  },
  emergency: { color: commonColors.red },
  push: { color: commonColors.yellow },
  nudge: { color: commonColors.blue },
  scoreWrap: {
    textAlign: "center",
  },
  dnaWrap: {
    width: "600px",
  },
  dnaDesc: {
    display: "block",
    fontSize: "17px",
    fontWeight: 500,
    color: textColors.black.main,
    paddingTop: "5px",
  },
  accent: {
    fontWeight: 700,
  },
  colorRed: {
    color: commonColors.red,
  },
  colorYellow: {
    color: commonColors.yellow,
  },
  colorBlue: {
    color: commonColors.blue,
  },
  colorGreen: {
    color: commonColors.green,
  },
  emotionalIntlWrap: {
    marginTop: "32px",
  },
}));

export { useScoreSectionStyles };
