import { useMediaQuery, useTheme } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import GBCircularProgress from "components/GBCircularProgress/GBCircularProgress";
import GBModal from "components/GBModal/GBModal";
import GBSpin from "components/GBSpin/GBSpin";
import StageStepButton from "components/StageStepButton/StageStepButton";
import StageStepContainer from "components/StageStepContainer/StageStepContainer";
import StageStepFrame from "components/StageStepFrame/StageStepFrame";
import StageStepImage from "components/StageStepImage/StageStepImage";
import StageStepTitle from "components/StageStepTitle/StageStepTitle";
import UnexpectedError from "components/UnexpectedError/UnexpectedError";
import { ApiErrorKey } from "constants/api/apiErrors";
import { Namespaces } from "i18n";
import { useTranslation } from "react-i18next";
import { useImpactPromiseResultStyles } from "./styles/ImpactPromiseResult.styles";

type ImpactPurposeResultViewProps = {
  onComplete: () => void;
  prevUrl: string;
  isUpdating: boolean;
  isFetching: boolean;
  isFetchError: boolean;
  isMessage: boolean;
  getApiError: () => ApiErrorKey | undefined;
  promise: string | null;
  author: string | null;
};

function ImpactPurposeResultView(props: ImpactPurposeResultViewProps) {
  debugger;
  const {
    onComplete,
    prevUrl,
    isUpdating,
    isFetching,
    isFetchError,
    getApiError,
    promise,
    author,
    isMessage,
  } = props;
  const classes = useImpactPromiseResultStyles();
  const { t } = useTranslation([Namespaces.Impact, Namespaces.Survey]);
  const theme = useTheme();
  const isDownXs = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <>
      <StageStepFrame
        title={t("promise.title")}
        variant={promise ? "success" : undefined}
        controls={[
          <StageStepButton.Back
            goTo={prevUrl}
            disabled={isFetching || isUpdating}
          >
            {t(`${Namespaces.Survey}:buttons.back`)}
          </StageStepButton.Back>,
          <StageStepButton.Next
            onClick={onComplete}
            disabled={isFetching || isUpdating || !promise}
            completed
            noArrow
          >
            {t(`${Namespaces.Survey}:buttons.complete`)}
          </StageStepButton.Next>,
        ]}
        error={getApiError()}
      >
        <StageStepContainer>
          {isFetching ? (
            <GBCircularProgress keepHeight="469px" />
          ) : isFetchError ? (
            <UnexpectedError />
          ) : (
            <GBSpin spinning={isUpdating}>
              {promise ? (
                <>
                  <StageStepTitle>{t("promise.result.title")}</StageStepTitle>
                  <div className={classes.promiseWrap}>
                    {!isDownXs && (
                      <div className={classes.promiseInner}>
                        <div className={classes.promise}>“{promise}”</div>
                        {author ? (
                          <div className={classes.author}>- {author}</div>
                        ) : null}
                      </div>
                    )}
                    <StageStepImage url={t("promise.result.img")} />
                  </div>
                  {isDownXs && (
                    <div className={classes.promiseMobile}>
                      <div className={classes.promise}>“{promise}”</div>
                      {author ? (
                        <div className={classes.author}>- {author}</div>
                      ) : null}
                    </div>
                  )}
                </>
              ) : (
                <Alert severity="warning">
                  {t(`${Namespaces.Survey}:warnings.uncompleted_steps`)}
                </Alert>
              )}
            </GBSpin>
          )}
        </StageStepContainer>
      </StageStepFrame>
      <GBModal
        open={isMessage}
        title="Congratulations!"
        size="medium"
        withTitleDivider
      >
        <p>
          Congratulations on writing the first draft of your promise statement.
          You should have an email with your draft. You can revisit this
          exercise at any time.
          <br />
          <br />
          You can now close this window.
        </p>
      </GBModal>
    </>
  );
}

export default ImpactPurposeResultView;
