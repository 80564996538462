import { makeStyles, Theme } from "@material-ui/core";
import { commonColors, textColors } from "constants/themeSettings";

const useAdminFeedbacksStyles = makeStyles((theme: Theme) => ({
  feedback: {
    padding: "20px",
    color: textColors.black.light,
    fontSize: "15px",
    lineHeight: "20px",
  },
  emotionBlock: {
    display: "flex",
    alignItems: "center",
    fontWeight: 700,

    "& svg": {
      fontSize: "35px",
      marginRight: "10px",
    },
  },
  sad: {
    fill: commonColors.red,
  },
  satisfied: {
    fill: commonColors.yellow,
  },
  happy: {
    fill: commonColors.green,
  },
  tableRoot: {
    position: "relative",
  },
}));

export { useAdminFeedbacksStyles };
