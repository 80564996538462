import FormRow from "components/FormRow/FormRow";
import GBInputErrorText from "components/GBInputErrorText/GBInputErrorText";
import GBOutlinedInput from "components/GBOutlinedInput/GBOutlinedInput";
import GBStaticInputLabel from "components/GBStaticInputLabel/GBStaticInputLabel";
import { ValidationRule } from "constants/validationRules";
import { FormikProps } from "formik";
import { Namespaces } from "i18n";
import { useTranslation } from "react-i18next";
import { ImpactPurposeStory3FormValues } from "../ImpactPurposeStory3.types";

type Story3FormProps = FormikProps<ImpactPurposeStory3FormValues> & {};

function Story3Form(props: Story3FormProps) {
  const { values, handleBlur, handleChange, handleSubmit, touched, errors } =
    props;

  const { t } = useTranslation(Namespaces.Impact);

  const hasError = (name: keyof ImpactPurposeStory3FormValues): boolean => {
    return !!(touched[name] && errors[name]);
  };

  const getError = (
    field: keyof ImpactPurposeStory3FormValues,
    errorCode: ValidationRule,
  ) => {
    return t([
      `purpose.story3.form.${field}.errors.${errorCode}`,
      `${Namespaces.Common}:errors.${errorCode}`,
    ]);
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormRow>
        <GBStaticInputLabel
          labelFor="momInspired"
          variant="thin"
          text={t("purpose.story3.form.momInspired.label")}
        />
        <GBOutlinedInput
          fullWidth
          id="momInspired"
          name="momInspired"
          type="text"
          value={values.momInspired}
          onChange={handleChange}
          onBlur={handleBlur}
          error={hasError("momInspired")}
        />
        {hasError("momInspired") && (
          <GBInputErrorText
            text={getError("momInspired", errors.momInspired as ValidationRule)}
          />
        )}
      </FormRow>
      <FormRow>
        <GBStaticInputLabel
          labelFor="momTaught"
          variant="thin"
          text={t("purpose.story3.form.momTaught.label")}
        />
        <GBOutlinedInput
          fullWidth
          id="momTaught"
          name="momTaught"
          type="text"
          value={values.momTaught}
          onChange={handleChange}
          onBlur={handleBlur}
          error={hasError("momTaught")}
        />
        {hasError("momTaught") && (
          <GBInputErrorText
            text={getError("momTaught", errors.momTaught as ValidationRule)}
          />
        )}
      </FormRow>
      <FormRow>
        <GBStaticInputLabel
          labelFor="dadInspired"
          variant="thin"
          text={t("purpose.story3.form.dadInspired.label")}
        />
        <GBOutlinedInput
          fullWidth
          id="dadInspired"
          name="dadInspired"
          type="text"
          value={values.dadInspired}
          onChange={handleChange}
          onBlur={handleBlur}
          error={hasError("dadInspired")}
        />
        {hasError("dadInspired") && (
          <GBInputErrorText
            text={getError("dadInspired", errors.dadInspired as ValidationRule)}
          />
        )}
      </FormRow>
      <FormRow>
        <GBStaticInputLabel
          labelFor="dadTaught"
          variant="thin"
          text={t("purpose.story3.form.dadTaught.label")}
        />
        <GBOutlinedInput
          fullWidth
          id="dadTaught"
          name="dadTaught"
          type="text"
          value={values.dadTaught}
          onChange={handleChange}
          onBlur={handleBlur}
          error={hasError("dadTaught")}
        />
        {hasError("dadTaught") && (
          <GBInputErrorText
            text={getError("dadTaught", errors.dadTaught as ValidationRule)}
          />
        )}
      </FormRow>
      <FormRow>
        <GBStaticInputLabel
          labelFor="parentsLesson"
          variant="thin"
          text={t("purpose.story3.form.parentsLesson.label")}
        />
        <GBOutlinedInput
          fullWidth
          id="parentsLesson"
          name="parentsLesson"
          type="text"
          value={values.parentsLesson}
          onChange={handleChange}
          onBlur={handleBlur}
          error={hasError("parentsLesson")}
        />
        {hasError("parentsLesson") && (
          <GBInputErrorText
            text={getError(
              "parentsLesson",
              errors.parentsLesson as ValidationRule,
            )}
          />
        )}
      </FormRow>
    </form>
  );
}

export default Story3Form;
