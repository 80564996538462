import GBMaterialTable from "components/GBMaterialTable/GBMaterialTable";
import TitleHint from "components/TitleHint/TitleHint";
import { Namespaces } from "i18n";
import { Trans, useTranslation } from "react-i18next";
import DetailPanel from "../DetailPanel/DetailPanel";
import { Query } from "material-table";
import { useState } from "react";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { useTableStyles } from "./styles/Table.styles";
import { QueryUsersFn } from "pages/Admin/AdminUsers/AdminUsers.types";
import { AdminUser } from "types/admin/users/AdminUser";

type TableProps = {
  queryData: QueryUsersFn;
};

function Table(props: TableProps) {
  const { queryData } = props;
  const { t } = useTranslation([Namespaces.Admin, Namespaces.Common]);
  const auth = useSelector((state: RootState) => state.auth);
  const [totalCount, setTotalCount] = useState<number>(0);
  const classes = useTableStyles();

  return (
    <div className={classes.root}>
      <GBMaterialTable
        columns={[
          {
            title: t("users.table.cols.firstName"),
            field: "first_name",
            searchable: false,
            render: (rowData) => (
              <>
                {rowData.first_name}
                &nbsp;
                {rowData._id === auth.user?.id && (
                  <TitleHint
                    variant="tiny"
                    text={`(${t(`${Namespaces.Common}:labels.you`)})`}
                  />
                )}
              </>
            ),
          },
          {
            title: t("users.table.cols.lastName"),
            field: "last_name",
            searchable: false,
          },
          {
            title: t("users.table.cols.email"),
            field: "email",
            searchable: false,
          },
        ]}
        data={(query: Query<AdminUser>) =>
          queryData({
            query,
            cb: (totalCount: number) => setTotalCount(totalCount),
          })
        }
        title={
          <h6>
            <Trans
              i18nKey={t("users.table.title")}
              components={{
                total: (
                  <TitleHint
                    text={t(`${Namespaces.Common}:labels.total`, {
                      value: totalCount,
                    })}
                  />
                ),
              }}
            />
          </h6>
        }
        detailPanel={[
          {
            tooltip: t("users.table.detailPanel.tooltip"),
            render: (rowData) => <DetailPanel rowData={rowData} />,
          },
        ]}
        options={{
          actionsColumnIndex: -1,
          draggable: false,
          sorting: false,
        }}
      />
    </div>
  );
}

export default Table;
