import { ValidationRule } from "constants/validationRules";
import * as Yup from "yup";

export const story1FormValidationSchema = Yup.object({
  year: Yup.string().trim().required(ValidationRule.Required),
  city: Yup.string().trim().required(ValidationRule.Required),
  familyClass: Yup.string().trim().required(ValidationRule.Required),
  familyRelations: Yup.string().trim().required(ValidationRule.Required),
  immediateFamily: Yup.string().trim().required(ValidationRule.Required),
});
