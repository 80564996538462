import { makeStyles, Theme } from "@material-ui/core/styles";

const useDownloadFileStyles = makeStyles((theme: Theme) => ({
  root: {
    color: "inherit",
    textDecoration: "none",
    padding: "0",
    margin: "0",
  },
}));

export { useDownloadFileStyles };
