import cx from "classnames";
import { useTextChipStyles } from "./styles/TextChip.styles";

type TextChipProps = {
  text: string;
  className?: string;
};

function TextChip(props: TextChipProps) {
  const { text, className } = props;
  const classes = useTextChipStyles();

  return <div className={cx(classes.root, className)}>{text}</div>;
}

export default TextChip;
