import DnaIdentitiesView from "./DnaIdentitiesView";
import { useSurvey } from "components/hooks/useSurvey";
import { useMemo, useState } from "react";
import { useHistory } from "react-router";
import { useQuery, useQueryClient } from "react-query";
import { fetchScore } from "utils/api/queryFns";
import { generalRoutes } from "constants/generalRoutes";
import { QueryKeys } from "constants/api/queryKeys";
import ScoreApiService from "services/ScoreApiService";
import { prepareIdentitiesInitialValues } from "constants/purposeScoreTypes";

function DnaIdentities() {
  const { nextUrl, prevUrl } = useSurvey();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const history = useHistory();
  const queryClient = useQueryClient();
  const scoreQuery = useQuery(fetchScore.key, fetchScore.fn);
  const score = useMemo(
    () => scoreQuery.data?.data.payload,
    [scoreQuery.data?.data.payload],
  );
  const preparedInitialValues = useMemo(
    () => prepareIdentitiesInitialValues(score?.DNA),
    [score],
  );

  const handleStepNext = () =>
    history.push(
      { pathname: nextUrl, search: history.location.search } ||
        generalRoutes.SCORE_MENU,
    );

  const handleStepPrev = () =>
    history.push({
      pathname: prevUrl,
      search: history.location.search,
    });

  const handleValueChange = async (value: Array<string>) => {
    try {
      setIsUpdating(true);
      await ScoreApiService.putScore({
        field: "identities",
        value,
        step: "DNA",
      });
      await queryClient.refetchQueries([QueryKeys.fetchScore]);
      setIsUpdating(false);
    } catch (e) {
      setIsUpdating(false);
      console.error(e);
    }
  };
  const isFetching = useMemo(
    () => scoreQuery.isLoading,
    [scoreQuery.isLoading],
  );

  const isFetchError = useMemo(() => scoreQuery.isError, [scoreQuery.isError]);

  return (
    <DnaIdentitiesView
      onValueChange={handleValueChange}
      values={preparedInitialValues}
      isFetching={isFetching}
      isFetchError={isFetchError}
      isUpdating={isUpdating}
      onStepNext={handleStepNext}
      onStepBack={handleStepPrev}
    />
  );
}
export default DnaIdentities;
