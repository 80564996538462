import GBCircularProgress from "components/GBCircularProgress/GBCircularProgress";
import UnexpectedError from "components/UnexpectedError/UnexpectedError";
import { Typography } from "@material-ui/core";
import { useTranslation, Trans } from "react-i18next";
import { Namespaces } from "i18n";
import { useAdminUserSnapshotStyles } from "./styles/AdminUserSnapshot.styles";
import ScoreSection from "./components/ScoreSection/ScoreSection";
import AlignSection from "./components/AlignSection/AlignSection";
import ImpactSection from "./components/ImpactSection/ImpactSection";
import GrowSection from "./components/GrowSection/GrowSection";
import { AdminUserDetail } from "types/admin/users/AdminUserDetail";

type AdminUserSnapshotViewProps = {
  user: AdminUserDetail | undefined;
  isFetching: boolean;
  isError: boolean;
};

function AdminUserSnapshotView(props: AdminUserSnapshotViewProps) {
  const { user, isFetching, isError } = props;

  const { t } = useTranslation(Namespaces.Admin);
  const classes = useAdminUserSnapshotStyles();

  return (
    <>
      {isFetching ? (
        <GBCircularProgress />
      ) : isError ? (
        <UnexpectedError />
      ) : user ? (
        <>
          <Typography component="h1" variant="h2">
            <Trans
              i18nKey={t("snapshot.title", {
                user: `${user.first_name} ${user.last_name}`,
              })}
              components={{
                accent: <span className={classes.accent} />,
              }}
            ></Trans>
          </Typography>
          <div className={classes.content}>
            {user.surveyProgress.score.completed && (
              <div className={classes.sectionWrap}>
                <ScoreSection
                  data={{
                    score: user.surveyProgress.score.score || 0,
                    identities: user.surveyProgress.score.DNA.identities,
                    emotionalIntlScore:
                      user.surveyProgress.score.emotionalIntl || 0,
                  }}
                />
              </div>
            )}
            {user.surveyProgress.align.completed && (
              <div className={classes.sectionWrap}>
                <AlignSection
                  data={{
                    trials: user.surveyProgress.align.importantTrials,
                    triumphs: user.surveyProgress.align.importantTriumphs,
                  }}
                />
              </div>
            )}
            {user.surveyProgress.impact.completed && (
              <div className={classes.sectionWrap}>
                <ImpactSection
                  data={{
                    promise:
                      user.surveyProgress.impact.promiseStatements
                        ?.finalPromise || "",
                    purpose:
                      user.surveyProgress.impact.lifePurpose?.purposeFinal ||
                      "",
                  }}
                />
              </div>
            )}
            {user.surveyProgress.grow.completed && (
              <div className={classes.sectionWrap}>
                <GrowSection
                  situations={{
                    result: user.surveyProgress.grow.situations.result,
                  }}
                />
              </div>
            )}
          </div>
        </>
      ) : null}
    </>
  );
}

export default AdminUserSnapshotView;
