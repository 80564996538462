import { makeStyles, Theme } from "@material-ui/core/styles";

const useGBStaticInputLabelStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "block",
    fontSize: "20px",
    lineHeight: "30px",
    fontWeight: 700,
    paddingBottom: "15px",
  },
  medium: {
    fontSize: "24px",
    lineHeight: "32px",
    paddingBottom: "16px",
  },
  thin: {
    fontSize: "20px",
    lineHeight: "32px",
    fontWeight: 400,
    paddingBottom: "16px",
  },
  small: {
    fontSize: "20px",
    lineHeight: "30px",
    paddingBottom: "16px",
  },
}));

export { useGBStaticInputLabelStyles };
