import DnaResultView from "./DnaResultView";
import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { fetchScore } from "utils/api/queryFns";
import { prepareDNAResultValue } from "constants/purposeScoreTypes";

function DnaResult() {
  const [isMessage, setIsMessage] = useState<boolean>(false);
  const scoreQuery = useQuery(fetchScore.key, fetchScore.fn);
  const score = useMemo(
    () => scoreQuery.data?.data.payload,
    [scoreQuery.data?.data.payload],
  );

  const DNAResult = useMemo(() => prepareDNAResultValue(score?.DNA), [score]);

  const isFetching = useMemo(
    () => scoreQuery.isLoading,
    [scoreQuery.isLoading],
  );

  const handleComplete = () => {
    debugger;
    setIsMessage(true);
  };

  const isFetchError = useMemo(() => scoreQuery.isError, [scoreQuery.isError]);

  return (
    <DnaResultView
      result={DNAResult}
      onComplete={handleComplete}
      isFetching={isFetching}
      isFetchError={isFetchError}
      isMessage={isMessage}
    />
  );
}
export default DnaResult;
