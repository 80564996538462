import { makeStyles, Theme } from "@material-ui/core/styles";

const useAdminUserSnapshotStyles = makeStyles((theme: Theme) => ({
  accent: {
    color: theme.palette.primary.main,
  },
  content: {
    paddingTop: "60px",
    paddingBottom: "100px",
  },
  sectionWrap: {
    marginBottom: "80px",
    "&:last-child": {
      marginBottom: "0",
    },
  },
}));

export { useAdminUserSnapshotStyles };
