import { makeStyles, Theme } from "@material-ui/core/styles";
import { commonColors } from "constants/themeSettings";

const useMainMenuPageStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingBottom: "0",
    paddingTop: "100px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px",
    },
  },
  menuOuter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "188px",
    paddingBottom: "135px",
  },
  menuCircle: {
    borderRadius: "50%",
    border: `4px solid ${theme.palette.secondary.light}`,
    display: "flex",
    position: "relative",
    marginLeft: "-300px",
  },
  menuItemDot: {
    width: "24px",
    height: "24px",
    background: theme.palette.common.white,
    border: `5px solid ${theme.palette.secondary.light}`,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  menuItem: {
    width: "270px",
    display: "flex",
    justifyContent: "center",
    margin: "20px",
    cursor: "pointer",
    "&:hover": {
      "&:not($menuItemCompleted) .dynamicColor": {
        stroke: theme.palette.primary.light,
      },
      "&:not($menuItemCompleted) .title": {
        color: theme.palette.primary.main,
      },
      "& .title": {
        color: commonColors.green,
      },
      "& .description": {
        color: theme.palette.common.black,
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      position: "static",
    },
  },

  menuItemCompleted: {
    "& .dynamicColor": {
      stroke: commonColors.green,
    },
  },
  completedIcon: {
    fill: commonColors.green,
    fontSize: "26px",
  },

  scoreMenuItem: {
    margin: "-120px 0 0 -4px",
    [theme.breakpoints.down("sm")]: {
      margin: "0",
    },
  },
  alignMenuItem: {
    margin: "-66px 0 0 50px",
    [theme.breakpoints.down("sm")]: {
      margin: "0",
    },
  },
  impactMenuItem: {
    margin: "-38px 0 0 47px",
    [theme.breakpoints.down("sm")]: {
      margin: "0",
    },
  },
  growMenuItem: {
    margin: "-12px 0 0 50px",
    [theme.breakpoints.down("sm")]: {
      margin: "0",
    },
  },
  engageMenuItem: {
    margin: "44px 0 0 -4px",
    pointerEvents: "none",
    [theme.breakpoints.down("sm")]: {
      margin: "0",
    },
  },
  menuCircleInner: {
    borderRadius: "50%",
    width: "85%",
    height: "85%",
    backgroundColor: theme.palette.grey["100"],
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    alignSelf: "center",
    padding: "20% 12%",
    margin: "auto",
    textAlign: "center",
  },
  snapshotBtn: {
    fontWeight: 700,
    padding: "9px 40px",
    [theme.breakpoints.down("sm")]: {
      padding: "9px 60px",
      display: "block",
      margin: "20px auto 0 auto",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
      padding: "7px 30px",
    },
  },
  mobileWrapper: {
    paddingTop: "45px",
  },
  mobileMenuItems: {
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
  },
  mobileMenuItem: {
    marginBottom: "30px",
  },
  mobileInner: {
    width: "300px",
    [theme.breakpoints.down(480)]: {
      width: "100%",
    },
  },
}));

export { useMainMenuPageStyles };
