import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  borderRadiuses,
  outlinedInputStyles,
  textColors,
} from "constants/themeSettings";

const useQuizTileStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: "32px 24px",
    maxHeight: "232px",
    maxWidth: "296px",
    height: "232px",
    width: "296px",
    textAlign: "center",
    border: "2px solid #DFDFDF",
    borderRadius: borderRadiuses.round,
    backgroundColor: "#fff",
    cursor: "pointer",
    overflow: "hidden",

    "&:hover": {
      backgroundColor: "#F8F8F8",
    },
  },
  small: {
    padding: "10px",
    maxHeight: "64px",
    height: "64px",
    width: "112px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  textRight: {
    textAlign: "right",
  },
  textLeft: {
    textAlign: "left",
  },
  selected: {
    borderColor: theme.palette.primary.main,
  },
  disabled: {
    pointerEvents: "none",
    backgroundColor: "#F2EDEB",
    color: textColors.black.lighten,
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  error: {
    backgroundColor: outlinedInputStyles.error.backgroundColor,
    borderColor: outlinedInputStyles.error.borderColor,

    "&:hover": {
      backgroundColor: outlinedInputStyles.error.backgroundColor,
    },
  },
  title: {
    fontSize: "20px",
    lineHeight: "30px",
    fontWeight: 700,
  },
  titleSelected: {
    color: theme.palette.primary.main,
  },
  titleSmall: {
    userSelect: "none",
    fontSize: "18px",
    lineHeight: "26px",
    fontWeight: 600,
  },
  text: {
    fontSize: "18px",
    lineHeight: "26px",
    fontWeight: 400,
    paddingTop: "8px",
    whiteSpace: "pre-line",
  },
}));

export { useQuizTileStyles };
