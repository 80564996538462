import { APP_DEFAULT_LANGUAGE } from "constants/settings";
import moment from "moment";

export const prepareDate = (
  date: string,
  lang: string = APP_DEFAULT_LANGUAGE,
  format: string = "LLL",
) => {
  return moment(date).locale(lang).format(format);
};
