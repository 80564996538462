import { ValidationRule } from "constants/validationRules";
import * as Yup from "yup";

export const passionsFormValidationSchema = Yup.object({
  mainPassion: Yup.string()
    .trim()
    .max(35, ValidationRule.Max)
    .required(ValidationRule.Required),
  passion1: Yup.string()
    .trim()
    .max(35, ValidationRule.Max)
    .required(ValidationRule.Required),
  passion2: Yup.string()
    .trim()
    .max(35, ValidationRule.Max)
    .required(ValidationRule.Required),
  passion3: Yup.string()
    .trim()
    .max(35, ValidationRule.Max)
    .required(ValidationRule.Required),
});
