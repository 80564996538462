import { useTheme } from "@material-ui/core/styles";

const GrowIcon = (): JSX.Element => {
  const theme = useTheme();
  return (
    <svg
      width="88"
      height="98"
      viewBox="0 0 88 98"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.5 5.06218C42.2846 3.45448 45.7154 3.45448 48.5 5.06218L79.8013 23.134C82.5859 24.7417 84.3013 27.7128 84.3013 30.9282V67.0718C84.3013 70.2872 82.5859 73.2583 79.8013 74.866L48.5 92.9378C45.7154 94.5455 42.2846 94.5455 39.5 92.9378L8.19873 74.866C5.41412 73.2583 3.69873 70.2872 3.69873 67.0718V30.9282C3.69873 27.7128 5.41412 24.7417 8.19873 23.134L39.5 5.06218Z"
        className="dynamicColor"
        stroke={theme.palette.secondary.light}
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g transform="translate(46, 61)">
        <path
          d="M10 1C5.02944 1 1 5.02944 1 10H19C19 5.02944 14.9706 1 10 1Z"
          stroke="#07283B"
          strokeWidth="2"
        />
      </g>
      <circle cx="32" cy="57" r="5" stroke="#f7882f" strokeWidth="2" />
      <g transform="translate(22, 61)">
        <path
          d="M10 1C5.02944 1 1 5.02944 1 10H19C19 5.02944 14.9706 1 10 1Z"
          stroke="#f7882f"
          strokeWidth="2"
        />
      </g>
      <circle cx="56" cy="57" r="5" stroke="#07283B" strokeWidth="2" />
      <g transform="translate(23, 28)">
        <path
          d="M28 1H1V17H4.915L5 22L12 17H28V1Z"
          stroke="#f7882f"
          strokeWidth="2"
        />
      </g>
      <g transform="translate(37, 22)">
        <path
          d="M0.999981 5.5092V1H27L27 17H23V22L16 17H15"
          stroke="#07283B"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};

export default GrowIcon;
