import {
  contactUsFormInitValues,
  contactUsFormValidationSchema,
} from "./ContactUsModal.consts";
import { ContactUsFormValues } from "./ContactUsModal.types";
import FormRow from "components/FormRow/FormRow";
import GBInputErrorText from "components/GBInputErrorText/GBInputErrorText";
import GBModal from "components/GBModal/GBModal";
import GBOutlinedInput from "components/GBOutlinedInput/GBOutlinedInput";
import GBStaticInputLabel from "components/GBStaticInputLabel/GBStaticInputLabel";
import { ApiErrorKey } from "constants/api/apiErrors";
import { ValidationRule } from "constants/validationRules";
import { useFormik } from "formik";
import { Namespaces } from "i18n";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ContactUsRequestPayload } from "types/common/ContactUsRequestPayload";
import CommonApiService from "services/CommonApiService";
import { ApiClientError } from "types/api/ApiClientError";
import { selectApiError } from "utils/api/selectApiError";
import GBSpin from "components/GBSpin/GBSpin";

type ContactUsModalProps = {
  open: boolean;
  onClose: () => void;
};

function ContactUsModal(props: ContactUsModalProps) {
  const { open, onClose } = props;
  const { t } = useTranslation(Namespaces.Common);

  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<ApiErrorKey | undefined>(
    undefined,
  );

  const onSubmit = async (data: ContactUsFormValues) => {
    const requestPayload: ContactUsRequestPayload = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email.trim(),
    };

    try {
      setIsProcessing(true);
      setErrorCode(undefined);
      await CommonApiService.contactUs(requestPayload);
      onClose();
      setIsProcessing(false);
      formik.resetForm();
    } catch (e) {
      console.error(e);
      const error = e as ApiClientError;
      setIsProcessing(false);
      setErrorCode(selectApiError(error.response?.data.message));
    }
  };

  const formik = useFormik<ContactUsFormValues>({
    validationSchema: contactUsFormValidationSchema,
    enableReinitialize: true,
    initialValues: contactUsFormInitValues,
    onSubmit: onSubmit,
  });

  const { values, handleBlur, handleChange, handleSubmit, touched, errors } =
    formik;

  const hasError = (name: keyof ContactUsFormValues): boolean => {
    return !!(touched[name] && errors[name]);
  };

  const getError = (
    field: keyof ContactUsFormValues,
    errorCode: ValidationRule,
  ) => {
    return t([
      `footer.modal.contact_us.form.fields.${field}.errors.${errorCode}`,
      `errors.${errorCode}`,
    ]);
  };

  const _onClose = () => {
    setErrorCode(undefined);
    onClose();
  };

  return (
    <GBModal
      open={open}
      onClose={_onClose}
      title={t("footer.modal.contact_us.title")}
      size="medium"
      withTitleDivider
      onOk={handleSubmit}
      okButtonText={t("footer.modal.contact_us.form.buttons.submit")}
      okButtonProps={{
        disabled: isProcessing,
      }}
      errorText={
        errorCode
          ? t([
              `footer.modal.contact_us.form.errors.${errorCode}`,
              `errors.${errorCode}`,
              `errors.unexpected_error`,
            ])
          : undefined
      }
    >
      <GBSpin spinning={isProcessing}>
        <form onSubmit={handleSubmit}>
          <FormRow>
            <GBStaticInputLabel
              labelFor="firstName"
              text={t("footer.modal.contact_us.form.fields.firstName.label")}
            />
            <GBOutlinedInput
              id="firstName"
              fullWidth
              name="firstName"
              type="text"
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={hasError("firstName")}
              disabled={isProcessing}
            />
            {hasError("firstName") && (
              <GBInputErrorText
                text={getError("firstName", errors.firstName as ValidationRule)}
              />
            )}
          </FormRow>
          <FormRow>
            <GBStaticInputLabel
              labelFor="lastName"
              text={t("footer.modal.contact_us.form.fields.lastName.label")}
            />
            <GBOutlinedInput
              id="lastName"
              fullWidth
              name="lastName"
              type="text"
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={hasError("lastName")}
              disabled={isProcessing}
            />
            {hasError("lastName") && (
              <GBInputErrorText
                text={getError("lastName", errors.lastName as ValidationRule)}
              />
            )}
          </FormRow>
          <FormRow>
            <GBStaticInputLabel
              labelFor="email"
              text={t("footer.modal.contact_us.form.fields.email.label")}
            />
            <GBOutlinedInput
              id="email"
              fullWidth
              name="email"
              type="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={hasError("email")}
              disabled={isProcessing}
            />
            {hasError("email") && (
              <GBInputErrorText
                text={getError("email", errors.email as ValidationRule)}
              />
            )}
          </FormRow>
        </form>
      </GBSpin>
    </GBModal>
  );
}

export default ContactUsModal;
