import { makeStyles, Theme } from "@material-ui/core/styles";

const useUserMenuItemStyles = makeStyles<Theme, {}>((theme: any) => ({
  outer: {
    border: "none",
    background: "none",
    textAlign: "left",
    padding: "0",
    display: "flex",
    alignItems: "center",
    "&>svg": {
      display: "flex",
      flexShrink: 0,
    },
  },
  itemInfo: {
    display: "flex",
    flexDirection: "column",
    padding: "0 0 0 20px",
    justifyContent: "center",
  },
  title: {
    ...theme.typography.menuTitle,
  },
  description: {
    ...theme.typography.menuDescription,
    whiteSpace: "pre-line",
  },
}));

export { useUserMenuItemStyles };
