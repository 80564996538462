import { makeStyles, Theme } from "@material-ui/core/styles";
import { WIDE_CONTAINER_WIDTH } from "constants/settings";
import { textColors } from "constants/themeSettings";

const useRangeQuizFieldStyles = makeStyles<Theme, { range: number }>(
  (theme: Theme) => ({
    root: {
      width: "max-content",
    },
    tiles: ({ range }) => ({
      display: "grid",
      width: "100%",
      gridTemplateColumns: `repeat(${range}, 1fr)`,
      [theme.breakpoints.down(WIDE_CONTAINER_WIDTH)]: {
        gridGap: "1rem",
      },
      [theme.breakpoints.down("xs")]: {
        gridGap: ".5rem",
      },
      [theme.breakpoints.down(500)]: {
        gridTemplateColumns: "repeat(3, 1fr)",
      },
    }),
    fullWidth: {
      width: "100%",
    },
    tileWrap: {
      width: "100%",
      [theme.breakpoints.up(WIDE_CONTAINER_WIDTH)]: {
        marginRight: "10px",
        "&:last-child": {
          marginRight: "0",
        },
      },
    },
    tile: {
      [theme.breakpoints.down(WIDE_CONTAINER_WIDTH)]: {
        width: "auto",
      },
      [theme.breakpoints.down(500)]: {
        width: "auto",
        boxSizing: "initial",
        height: "38px",
      },
    },
    rangeText: {
      color: textColors.black.light,
      fontSize: "18px",
      lineHeight: "24px",
      fontWeight: 400,
      paddingTop: "8px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down(430)]: {
        fontSize: "16px",
      },
    },
    rangedTextRight: {
      textAlign: "right",
    },
  }),
);

export { useRangeQuizFieldStyles };
