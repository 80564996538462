import { makeStyles, Theme } from "@material-ui/core/styles";

const useAlignSectionStyles = makeStyles((theme: Theme) => ({
  content: {
    paddingTop: "20px",
    display: "flex",
    justifyContent: "space-between",
  },
  desc: {
    width: "500px",
    backgroundColor: "#fdefe6",
  },
  item: {
    color: theme.palette.primary.main,
    fontSize: "20px",
    fontWeight: 500,
  },
}));

export { useAlignSectionStyles };
