import Row from "./components/Row";
import Col from "./components/Col";
import List from "./components/List";
import ListItem from "./components/ListItem";
import { useGBMTDetailPanelStyles } from "./styles/GBMTDetailPanel.styles";
import cx from "classnames";
import React from "react";

type GBMTDetailPanelProps = {
  children: React.ReactNode;
  className?: string;
};

const GBMTDetailPanel = (props: GBMTDetailPanelProps) => {
  const { children, className } = props;
  const classes = useGBMTDetailPanelStyles();

  const rootClasses = cx(classes.root, className);

  return <div className={rootClasses}>{children}</div>;
};

GBMTDetailPanel.Row = Row;
GBMTDetailPanel.Col = Col;
GBMTDetailPanel.List = List;
GBMTDetailPanel.ListItem = ListItem;

export default GBMTDetailPanel;
