import { makeStyles, Theme } from "@material-ui/core/styles";
import { WIDE_CONTAINER_WIDTH } from "constants/settings";

const useImpactPurposeResultStyles = makeStyles((theme: Theme) => ({
  children: {
    [theme.breakpoints.down(WIDE_CONTAINER_WIDTH)]: {
      overflow: "hidden",
    },
  },
}));

export { useImpactPurposeResultStyles };
