import GBModal from "components/GBModal/GBModal";
import { Namespaces } from "i18n";
import { useTranslation } from "react-i18next";
import { useGBConfirmModalStyles } from "./styles/GBConfirmModal.styles";

type GBConfirmModalProps = {
  open: boolean;
  text: string;
  onClose: () => void;
  onCancel?: () => void;
  onConfirm: () => void;
  cancelBtnText?: string;
  confirmBtnText?: string;
  keepAfterClose?: boolean;
  keepAfterConfirm?: boolean;
};

function GBConfirmModal(props: GBConfirmModalProps) {
  const {
    open,
    text,
    onCancel,
    onClose,
    onConfirm,
    cancelBtnText,
    confirmBtnText,
    keepAfterConfirm,
    keepAfterClose,
  } = props;

  const classes = useGBConfirmModalStyles();
  const { t } = useTranslation(Namespaces.Common);

  const _onConfirm = () => {
    onConfirm();
    if (!keepAfterConfirm) {
      onClose();
    }
  };

  const _onCancel = () => {
    if (onCancel) {
      onCancel();
    }
    if (!keepAfterClose) {
      onClose();
    }
  };

  return (
    <GBModal
      size="medium"
      open={open}
      onClose={onClose}
      onOk={_onConfirm}
      okButtonText={confirmBtnText || t("buttons.confirm")}
      onCancel={_onCancel}
      cancelButtonText={cancelBtnText || t("buttons.cancel")}
    >
      <div className={classes.text}>{text}</div>
    </GBModal>
  );
}

export default GBConfirmModal;
