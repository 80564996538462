import AuthBox from "components/AuthBox/AuthBox";
import { useAuthBoxStyles } from "components/AuthBox/styles/AuthBox.styles";
import ContentSpinner from "components/ContentSpinner/ContentSpinner";
import ErrorText from "components/ErrorText/ErrorText";
import FormRow from "components/FormRow/FormRow";
import GBCustomButton from "components/GBCustomButton/GBCustomButton";
import GBInputErrorText from "components/GBInputErrorText/GBInputErrorText";
import GBOutlinedInput from "components/GBOutlinedInput/GBOutlinedInput";
import GBStaticInputLabel from "components/GBStaticInputLabel/GBStaticInputLabel";
import PasswordToggler from "components/PasswordToggler/PasswordToggler";
import { ApiErrorKey } from "constants/api/apiErrors";
import { ForgotPasswordFormStatus } from "constants/ForgotPasswordFormStatus";
import { ValidationRule } from "constants/validationRules";
import { FormikProps } from "formik";
import { Namespaces } from "i18n";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ResetPasswordFormValues } from "./ResetPassword.types";
import DoneIcon from "@material-ui/icons/Done";
import GBCircularProgress from "components/GBCircularProgress/GBCircularProgress";
import cx from "classnames";

type ResetPasswordProps = FormikProps<ResetPasswordFormValues> & {
  isTokenError: boolean;
  isVerifying: boolean;
  isProcessing: boolean;
  errorCode: ApiErrorKey | null;
};

function ResetPasswordView(props: ResetPasswordProps) {
  const { isVerifying, isProcessing, errorCode, isTokenError, ...formik } =
    props;

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    status,
  } = formik;

  const { t } = useTranslation([Namespaces.Auth, Namespaces.Common]);
  const authBoxClasses = useAuthBoxStyles();

  const [isPasswdVisible, setIsPasswdVisible] = useState<boolean>(false);
  const [isConfPasswdVisible, setIsConfPasswdVisible] =
    useState<boolean>(false);

  const getError = (
    field: keyof ResetPasswordFormValues,
    errorCode: ValidationRule,
  ) => {
    return t([
      `forms.reset.fields.${field}.errors.${errorCode}`,
      `${Namespaces.Common}:errors.${errorCode}`,
    ]);
  };

  const _onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmit();
    }
  };

  const hasError = (name: keyof ResetPasswordFormValues): boolean => {
    return !!(touched[name] && errors[name]);
  };

  return (
    <AuthBox title={t("forms.reset.title")}>
      <>
        {isVerifying ? (
          <GBCircularProgress />
        ) : isTokenError ? (
          <ErrorText
            textAlign="center"
            text={t("forms.reset.errors.token_error")}
          />
        ) : status === ForgotPasswordFormStatus.IsSent ? (
          <div className={authBoxClasses.successBox}>
            <DoneIcon />
            <div
              className={cx(
                authBoxClasses.successMsg,
                authBoxClasses.successBoxFlexCenter,
              )}
            >
              {t("forms.reset.success_msg")}
            </div>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <ContentSpinner spinning={isProcessing}>
              <div>
                <FormRow>
                  <GBStaticInputLabel
                    labelFor="password"
                    text={t("forms.signup.fields.password.label")}
                  />
                  <GBOutlinedInput
                    id="password"
                    fullWidth
                    name="password"
                    type={isPasswdVisible ? "text" : "password"}
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={hasError("password")}
                    disabled={isProcessing}
                    endAdornment={
                      <PasswordToggler
                        isVisible={isPasswdVisible}
                        setIsVisible={(state: boolean) =>
                          setIsPasswdVisible(state)
                        }
                      />
                    }
                    onKeyPress={_onKeyPress}
                  />
                  {hasError("password") && (
                    <GBInputErrorText
                      text={getError(
                        "password",
                        errors.password as ValidationRule,
                      )}
                    />
                  )}
                </FormRow>
                <FormRow>
                  <GBStaticInputLabel
                    labelFor="confPassword"
                    text={t("forms.signup.fields.confPassword.label")}
                  />
                  <GBOutlinedInput
                    id="confPassword"
                    fullWidth
                    name="confPassword"
                    type={isConfPasswdVisible ? "text" : "password"}
                    value={values.confPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={hasError("confPassword")}
                    disabled={isProcessing}
                    endAdornment={
                      <PasswordToggler
                        isVisible={isConfPasswdVisible}
                        setIsVisible={(state: boolean) =>
                          setIsConfPasswdVisible(state)
                        }
                      />
                    }
                    onKeyPress={_onKeyPress}
                  />
                  {hasError("confPassword") && (
                    <GBInputErrorText
                      text={getError(
                        "confPassword",
                        errors.confPassword as ValidationRule,
                      )}
                    />
                  )}
                </FormRow>
              </div>
            </ContentSpinner>
            <div className={authBoxClasses.buttonsWrap}>
              <GBCustomButton
                className={authBoxClasses.blockCenter}
                type="submit"
                disabled={isProcessing}
              >
                {t("forms.forgot.buttons.submit")}
              </GBCustomButton>
              {errorCode ? (
                <ErrorText
                  textAlign="center"
                  text={t([
                    `forms.reset.errors.${errorCode}`,
                    `${Namespaces.Common}:errors.${errorCode}`,
                    `${Namespaces.Common}:errors.unexpected_error`,
                  ])}
                />
              ) : null}
            </div>
          </form>
        )}
      </>
    </AuthBox>
  );
}

export default ResetPasswordView;
