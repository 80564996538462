import { makeStyles, Theme } from "@material-ui/core/styles";

const useAlignInfoStyles = makeStyles((theme: Theme) => ({
  content: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

export { useAlignInfoStyles };
