import GBCircularProgress from "components/GBCircularProgress/GBCircularProgress";
import GBSpin from "components/GBSpin/GBSpin";
import StageStepButton from "components/StageStepButton/StageStepButton";
import StageStepFrame from "components/StageStepFrame/StageStepFrame";
import StageStepTitle from "components/StageStepTitle/StageStepTitle";
import UnexpectedError from "components/UnexpectedError/UnexpectedError";
import { ApiErrorKey } from "constants/api/apiErrors";
import { useFormik } from "formik";
import { Namespaces } from "i18n";
import { useTranslation } from "react-i18next";
import Story2Form from "./components/Story2Form";
import { story2FormValidationSchema } from "./ImpactPurposeStory2.consts";
import { ImpactPurposeStory2FormValues } from "./ImpactPurposeStory2.types";

type ImpactPurposeStory2ViewProps = {
  onFormSubmit: (data: ImpactPurposeStory2FormValues) => void;
  initialValues: ImpactPurposeStory2FormValues;
  prevUrl: string;
  isUpdating: boolean;
  isFetching: boolean;
  isFetchError: boolean;
  getApiError: () => ApiErrorKey | undefined;
};

function ImpactPurposeStory2View(props: ImpactPurposeStory2ViewProps) {
  const {
    onFormSubmit,
    prevUrl,
    isUpdating,
    isFetching,
    isFetchError,
    initialValues,
    getApiError,
  } = props;

  const { t } = useTranslation([Namespaces.Impact, Namespaces.Survey]);

  const formik = useFormik<ImpactPurposeStory2FormValues>({
    enableReinitialize: true,
    validationSchema: story2FormValidationSchema,
    initialValues,
    onSubmit: onFormSubmit,
  });

  const getError = () => {
    const error = Object.values(formik.errors)[0];
    return error ? t(`purpose.story2.form.errors.${error}`) : undefined;
  };

  return (
    <StageStepFrame
      title={t("purpose.title")}
      controls={[
        <StageStepButton.Back
          goTo={prevUrl}
          disabled={isFetching || isUpdating}
        >
          {t(`${Namespaces.Survey}:buttons.back`)}
        </StageStepButton.Back>,
        <StageStepButton.Next
          onClick={() => formik.handleSubmit()}
          disabled={isFetching || isUpdating}
        >
          {t(`${Namespaces.Survey}:buttons.continue`)}
        </StageStepButton.Next>,
      ]}
      error={getApiError() || getError()}
    >
      {isFetching ? (
        <GBCircularProgress keepHeight="480px" />
      ) : isFetchError ? (
        <UnexpectedError />
      ) : (
        <GBSpin spinning={isUpdating}>
          <StageStepTitle>{t("purpose.story2.title")}</StageStepTitle>
          <Story2Form {...formik} />
        </GBSpin>
      )}
    </StageStepFrame>
  );
}

export default ImpactPurposeStory2View;
