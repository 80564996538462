import { API } from "utils/api/http-client";
import { apiEndpoints } from "constants/api/apiEndpoints";
import { PutScoreRequestPayload } from "types/score/PutScoreRequestPayload";
import { GetScoreResultResponsePayload } from "types/score/GetScoreResultResponsePayload";
import { GetScoreResponsePayload } from "types/score/GetScoreResponsePayload";
import { PostScoreRequestPayload } from "types/score/PostScoreRequestPayload";
import { GetEmotionalIntlResponsePayload } from "types/score/GetEmotionalIntlResponsePayload";
import { PulseFormValues } from "types/score/PulseForm.types";
import { PutPulseRequestPayload } from "types/score/PutPulseRequestPayload";
import { GetPulseResponsePayload } from "types/score/GetPulseResponsePayload";

const { score, pulse } = apiEndpoints;

class ScoreApiService {
  getScore = () => {
    return API.get<GetScoreResponsePayload>(score.getScore);
  };

  postScore = (data: PostScoreRequestPayload) => {
    return API.post(score.postScore, data);
  };

  putScore = (data: PutScoreRequestPayload) => {
    return API.put(score.putScore, data);
  };

  getPulse = () => {
    return API.get<GetPulseResponsePayload>(pulse.getPulse, {
      params: {
        token: new URLSearchParams(window.location.search).get("token"),
      },
    });
  };

  postPulse = (data: PulseFormValues) => {
    return API.post(pulse.postPulse, data);
  };

  putPulse = (data: PutPulseRequestPayload) => {
    return API.put(pulse.putPulse, data);
  };

  getResult = () => {
    return API.get<GetScoreResultResponsePayload>(score.getResult);
  };

  getEmotionalIntl = () => {
    return API.get<GetEmotionalIntlResponsePayload>(score.getEmotionalIntl);
  };
}

export default new ScoreApiService();
