import { makeStyles, Theme } from "@material-ui/core/styles";
import { WIDE_CONTAINER_WIDTH } from "constants/settings";
import { borderRadiuses, textColors } from "constants/themeSettings";

const useAlignSectionStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: "48px",
  },
  sub: {
    backgroundColor: "#F8F8F8",
    padding: "37px 0",
    textAlign: "center",
    fontSize: "28px",
    lineHeight: "32px",
    color: textColors.black.light,
    textTransform: "uppercase",
    borderRadius: borderRadiuses.medium,
    fontWeight: 700,
    marginTop: "-8px",
  },
  align: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: "32px",
    [theme.breakpoints.down(1040)]: {
      flexDirection: "column",
    },
  },
  alignBlock: {
    textAlign: "center",
    backgroundColor: "#fdefe6",
    padding: "40px 60px",
    color: theme.palette.primary.main,
    fontSize: "24px",
    fontWeight: 500,
    lineHeight: "32px",
    borderRadius: borderRadiuses.medium,
    position: "relative",
    width: "590px",
    [theme.breakpoints.down(WIDE_CONTAINER_WIDTH)]: {
      marginBottom: "20px",
      padding: "30px",
      width: "560px",
    },
    [theme.breakpoints.down(1300)]: {
      width: "500px",
    },
    [theme.breakpoints.down(1180)]: {
      width: "450px",
    },
    [theme.breakpoints.down(1040)]: {
      width: "590px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "40px 30px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  textRow: {
    marginBottom: "13px",

    "&:last-child": {
      marginBottom: "0",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "20px",
    },
  },
  arrow: {
    height: "248px",
  },
  triangle: {
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
    height: "85px",
    right: "-15.4%",
    [theme.breakpoints.down(1300)]: {
      right: "-17.4%",
    },
    [theme.breakpoints.down(1180)]: {
      right: "-12.4%",
      height: "55px",
    },
  },
  triangleRight: {
    left: "-5%",
    [theme.breakpoints.down(1300)]: {
      left: "-5.5%",
    },
    [theme.breakpoints.down(1180)]: {
      left: "-3.5%",
    },
  },
}));

export { useAlignSectionStyles };
