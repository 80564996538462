import { ValidationRule } from "constants/validationRules";
import * as Yup from "yup";

export const experiencesFormValidationSchema = Yup.object({
  adultDecision: Yup.string()
    .trim()
    .max(35, ValidationRule.Max)
    .required(ValidationRule.Required),
  adversity: Yup.string()
    .trim()
    .max(35, ValidationRule.Max)
    .required(ValidationRule.Required),
});
