import { makeStyles, Theme } from "@material-ui/core/styles";

const useImpactPurposeFinalStyles = makeStyles((theme: Theme) => ({
  statement: {
    fontSize: "24px",
    lineHeight: "32px",
    fontWeight: 700,
    marginBottom: "14px",
  },
  accent: {
    color: theme.palette.primary.main,
  },
}));

export { useImpactPurposeFinalStyles };
