import { makeStyles, Theme } from "@material-ui/core/styles";
import { WIDE_CONTAINER_WIDTH } from "constants/settings";

const useScoreStyles = makeStyles<Theme, {}>((theme: any) => ({
  subtitle: {
    ...theme.typography.surveyText,
    fontSize: "24px",
    fontWeight: 700,
  },
  text: {
    marginTop: "40px",
    ...theme.typography.surveyText,
  },
  wrapper: {
    ...theme.typography.formMedium,
    width: "inherit",
    marginTop: "40px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    [theme.breakpoints.down(WIDE_CONTAINER_WIDTH)]: {
      justifyContent: "space-around",
    },
    [theme.breakpoints.down(675)]: {
      flexDirection: "column",
    },
  },
  dnaOuterClass: {
    marginBottom: "30px",
  },
  formRow: {
    marginBottom: "40px",
  },
  identityItem: {
    marginBottom: "20px",
    [theme.breakpoints.down(675)]: {
      width: "350px",
      maxWidth: "unset",
      margin: "0 auto 20px auto",
    },
    [theme.breakpoints.down(450)]: {
      width: "100%",
    },
  },
  identityVerbRow: {
    marginTop: "15px",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    paddingRight: "22px",
    [theme.breakpoints.down(WIDE_CONTAINER_WIDTH)]: {
      paddingRight: 0,
    },
  },
  identityVerbTitle: {
    ...theme.typography.surveyText,
    fontWeight: 700,
  },
  identityVerbInputs: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "16px 0 40px 0",
    [theme.breakpoints.down(755)]: {
      flexDirection: "column",
    },
  },
  identityVerbSelect: {
    width: "280px",
    margin: "0",
    [theme.breakpoints.down(WIDE_CONTAINER_WIDTH)]: {
      width: "220px",
    },
    [theme.breakpoints.down(755)]: {
      width: "100%",
      marginBottom: "20px",
      "&:last-child": {
        marginBottom: "0",
      },
    },
  },
}));

export { useScoreStyles };
