import { generalRoutes } from "constants/generalRoutes";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useHistory, matchPath, useLocation } from "react-router";
import { fetchSurveysResult } from "utils/api/queryFns";
import { SnapshotStep } from "./Snapshot.consts";
import { getSnapshotStepsNav, prepareSnapshotPath } from "./Snapshot.utils";
import SnapshotView from "./SnapshotView";

function Snapshot() {
  const history = useHistory();
  const location = useLocation();
  const match = matchPath<{ step: SnapshotStep }>(location.pathname, {
    path: generalRoutes.SNAPSHOT,
    exact: true,
    strict: false,
  });

  const [snapshotStep, setSnapshotStep] = useState<SnapshotStep>(
    SnapshotStep.Step1,
  );

  const resultsQuery = useQuery(fetchSurveysResult.key, fetchSurveysResult.fn);
  const results = useMemo(
    () => resultsQuery.data?.data.payload,
    [resultsQuery.data?.data.payload],
  );

  const handleStepNext = () => {
    const { next } = getSnapshotStepsNav(snapshotStep);
    history.push(prepareSnapshotPath(next));
    setSnapshotStep(next);
  };

  const handleStepBack = () => {
    const { prev } = getSnapshotStepsNav(snapshotStep);
    history.push(prepareSnapshotPath(prev));
    setSnapshotStep(prev);
  };

  const handleLastStepNext = () => {
    history.push(generalRoutes.USER_MENU);
  };

  useEffect(() => {
    const urlStep = match?.params.step;
    if (urlStep && Object.values(SnapshotStep).includes(urlStep)) {
      setSnapshotStep(urlStep);
    } else {
      setSnapshotStep(SnapshotStep.Step1);
      history.push(generalRoutes.SNAPSHOT);
    }
  }, [history, match?.params.step]);

  const isLoading = resultsQuery.isLoading;
  const isError = resultsQuery.isError;

  return (
    <SnapshotView
      snapshotStep={snapshotStep}
      onStepBack={handleStepBack}
      onStepNext={handleStepNext}
      onLastStepNext={handleLastStepNext}
      isLoading={isLoading}
      isError={isError}
      surveysResult={results}
    />
  );
}

export default Snapshot;
