import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  borderRadiuses,
  commonColors,
  textColors,
} from "constants/themeSettings";

const useScoreSectionStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  title: {
    marginBottom: "20px",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  scoreImg: {
    marginBottom: "9px",
    width: "500px",
    paddingTop: "12px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      "& img": {
        width: "100%",
      },
    },
  },
  score: {
    fontSize: "24px",
    lineHeight: "32px",
    fontWeight: 700,
    color: commonColors.green,
    marginLeft: "398px",
    width: "100px",
    textAlign: "center",
  },
  emergency: { marginLeft: "80px", color: commonColors.red },
  needPush: { marginLeft: "186px", color: commonColors.yellow },
  needNudge: { marginLeft: "294px", color: commonColors.blue },

  mobileScore: { color: commonColors.green },
  mobileEmergency: { color: commonColors.red },
  mobilePush: { color: commonColors.yellow },
  mobileNudge: { color: commonColors.blue },

  dnaBlock: {
    padding: "24px 33px",
    backgroundColor: "#F8F8F8",
    borderRadius: borderRadiuses.medium,
    [theme.breakpoints.down("md")]: {
      marginTop: "32px",
      width: "720px",
    },
    [theme.breakpoints.down(900)]: {
      width: "100%",
    },
  },
  dnaList: {
    fontSize: "20px",
    lineHeight: "24px",
    color: textColors.black.light,
    fontWeight: 500,
  },
  identityBlock: {
    marginBottom: "20px",
    "&:last-child": {
      marginBottom: "0",
    },
  },
  identity: {
    marginBottom: "8px",
  },
  identityText: {
    paddingLeft: "14px",
    display: "flex",
    fontWeight: 400,

    "& > span": {
      fontSize: "10px",
      paddingRight: "10px",
    },
  },
  warn: {
    height: "50px",
  },
  emotionalIntlWrap: {
    marginTop: "32px",
  },
  accent: {
    fontWeight: 700,
  },
  colorRed: {
    color: commonColors.red,
  },
  colorYellow: {
    color: commonColors.yellow,
  },
  colorBlue: {
    color: commonColors.blue,
  },
  colorGreen: {
    color: commonColors.green,
  },
}));

export { useScoreSectionStyles };
