export const apiEndpoints = {
  superadmin: {
    users: {
      getUsers: "/superadmin/profile/all",
      getUserDetail: "/superadmin/profile/:id",
    },
    partners: {
      getPartners: "/superadmin/partner",
      putPartner: "/superadmin/partner",
    },
    feedbacks: {
      getFeebacks: "/superadmin/feedback",
      deleteFeedback: "/superadmin/feedback",
    },
  },
  partner: {
    profile: {
      fetchProfile: "/partner/profile",
    },
    users: {
      getUsers: "/partner/users",
      getUserDetail: "/partner/users/:id",
    },
  },
  align: {
    getAlign: "/align",
    postAlign: "/align",
    getResult: "/align/result",
  },
  auth: {
    register: "/auth/register",
    login: "/auth/login",
    forgotPasswd: "/auth/forgot-password",
    resetPasswd: "/auth/reset",
    generatePasswd: "/auth/generate-password",
    registerPartner: "/auth/register-partner",
    getToken: "auth/sendToken",
  },
  common: {
    contactUs: "/contact-us",
    getSurveysResult: "/result",
    calculatePurposeScoreGuest: "/score/calc-score",
    calculateEmotionalIntScore: "/score/calc-eq",
    feedback: "/feedback",
    generateRegistrationCode: "/code/generate",
    checkRegistrationCode: "/code/check",
    purposePulseLink: "pulse/generate-link",
  },
  impact: {
    getImpact: "/impact",
    postImpact: "/impact",
  },
  grow: {
    getGrow: "/grow",
    putGrow: "/grow",
    postGrow: "/grow",
    getResult: "/grow/result",
  },
  score: {
    getScore: "/score",
    postScore: "/score",
    putScore: "/score",
    getResult: "/score/result",
    getPulse: "/score/fetchPulse",
    getEmotionalIntl: "/score/emotionalIntl",
  },
  pulse: {
    getPulse: "/pulse",
    postPulse: "/pulse",
    putPulse: "/pulse",
  },
  user: {
    fetchProfile: "/profile",
  },
  payment: {
    createCustomer: "/stripe/create-customer",
    createSubscription: "/stripe/create-subscription",
    getSubscriptions: "stripe/subscriptions/:customerId",
    getCustomer: "stripe/customer/:id",
    getConfig: "stripe/config",
  },
};
