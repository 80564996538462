import { useTranslation } from "react-i18next";
import { Namespaces } from "i18n";
import arrayMove from "array-move";
import StageStepFrame from "components/StageStepFrame/StageStepFrame";
import StageStepButton from "components/StageStepButton/StageStepButton";
import GBCircularProgress from "components/GBCircularProgress/GBCircularProgress";
import UnexpectedError from "components/UnexpectedError/UnexpectedError";
import GBSpin from "components/GBSpin/GBSpin";
import { IdentityVerb } from "types/score/DNA";
import { useTouchDeviceDetector } from "components/hooks/useTouchDeviceDetector";
import ImpactList from "../../../Score/components/ImpactList/ImpactList";
import { useScoreStyles } from "../../../Score/styles/Score.styles";
import StageStepText from "components/StageStepText/StageStepText";

type DnaVerbsViewProps = {
  onValueChange: (value: Array<IdentityVerb>) => void;
  values: Array<IdentityVerb>;
  getApiError: () => string | undefined;
  isFetching: boolean;
  isUpdating: boolean;
  isFetchError: boolean;
  onStepNext: () => void;
  onStepBack: () => void;
};

function DnaRankView(props: DnaVerbsViewProps) {
  const {
    onValueChange,
    isFetching,
    values,
    isUpdating,
    isFetchError,
    onStepNext,
    onStepBack,
    getApiError,
  } = props;

  const { t } = useTranslation([
    Namespaces.Score,
    Namespaces.Survey,
    Namespaces.Common,
  ]);
  const classes = useScoreStyles();
  const { isTouchDevice } = useTouchDeviceDetector();

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    const items: Array<IdentityVerb> = arrayMove(
      values,
      oldIndex,
      newIndex,
    ).map((item, key) => {
      item.rank = key += 1;
      return item;
    });
    onValueChange(items);
  };

  return (
    <StageStepFrame
      title={t("DNA.rank.title")}
      childrenClassName={classes.dnaOuterClass}
      controls={[
        <StageStepButton.Back
          onClick={onStepBack}
          disabled={isFetching || isUpdating}
        >
          {t(`${Namespaces.Survey}:buttons.back`)}
        </StageStepButton.Back>,
        <StageStepButton.Next
          onClick={onStepNext}
          disabled={isFetching || isUpdating}
        >
          {t(`${Namespaces.Survey}:buttons.continue`)}
        </StageStepButton.Next>,
      ]}
      error={getApiError()}
    >
      <div className={classes.subtitle}>{t("DNA.rank.subtitle")}</div>
      <StageStepText className={classes.text}>
        {t("DNA.rank.description")}
      </StageStepText>
      {isFetching ? (
        <GBCircularProgress keepHeight="848px" />
      ) : isFetchError ? (
        <UnexpectedError />
      ) : (
        <GBSpin spinning={isUpdating}>
          <div className={classes.wrapper}>
            <ImpactList
              onSortEnd={onSortEnd}
              pressDelay={isTouchDevice ? 200 : 0}
              impacts={values.sort((a, b) => (a.rank < b.rank ? -1 : 1))}
            />
          </div>
        </GBSpin>
      )}
    </StageStepFrame>
  );
}
export default DnaRankView;
