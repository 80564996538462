import { ApiErrorKey } from "constants/api/apiErrors";
import { MaterialTableProps } from "material-table";
import { useRef, useState } from "react";
import SuperAdminApiService from "services/SuperAdminApiService";
import { AdminFeedback } from "types/admin/feedbacks/AdminFeedback";
import { DeleteAdminFeedbacksRequestPayload } from "types/admin/feedbacks/DeleteAdminFeedbacksRequestPayload";
import { ApiClientError } from "types/api/ApiClientError";
import { selectApiError } from "utils/api/selectApiError";
import {
  DeleteModalData,
  Modals,
  QueryFeedbacksFnArgs,
  QueryFeedbacksFnPayload,
} from "./AdminFeedbacks.types";
import AdminFeedbacksView from "./AdminFeedbacksView";
import { UserType } from "constants/userTypes";
import { useSelector } from "react-redux";
import { RootState } from "store";

function AdminFeedbacks() {
  const [apiError, setApiError] = useState<ApiErrorKey | null>(null);
  const tableRef = useRef<MaterialTableProps<object> | null>(null);
  const { user } = useSelector((state: RootState) => state.auth);

  const isSuperAdmin = user?.userType === UserType.SuperAdmin;

  const [deleteModalData, setDeleteModalData] = useState<DeleteModalData>({
    isOpen: false,
    feedback: null,
  });

  const modals: Modals = {
    delete: {
      data: deleteModalData,
      setData: setDeleteModalData,
    },
  };

  const refetchFeedbacks = () => {
    //@ts-ignore
    tableRef?.current?.onQueryChange();
  };

  const handleApiError = (e: Error) => {
    console.error(e);
    const error = e as ApiClientError;
    setApiError(selectApiError(error.response?.data.message));
  };

  const queryFeedbacks = async ({
    query,
    cb,
  }: QueryFeedbacksFnArgs): Promise<QueryFeedbacksFnPayload> => {
    let result: QueryFeedbacksFnPayload = {
      data: [],
      page: 0,
      totalCount: 0,
    };

    if (isSuperAdmin) {
      try {
        setApiError(null);
        const { data } = await SuperAdminApiService.getFeedbacks({
          skip: query.page * query.pageSize,
          limit: query.pageSize,
          search: query.search,
        });
        if (cb) {
          cb(data.payload.pagination.totalCount);
        }
        result = {
          data: data.payload.items,
          page: data.payload.pagination.page,
          totalCount: data.payload.pagination.totalCount,
        };
      } catch (e) {
        handleApiError(e as Error);
      }
    }

    return result;
  };

  const handleDeleteFeedback = async (feedback: AdminFeedback | null) => {
    if (feedback && isSuperAdmin) {
      const requestData: DeleteAdminFeedbacksRequestPayload = {
        ids: [feedback?._id],
      };
      try {
        setApiError(null);
        await SuperAdminApiService.deleteFeedbacks(requestData);
        refetchFeedbacks();
      } catch (e) {
        handleApiError(e as Error);
      }
    }
  };

  return (
    <AdminFeedbacksView
      tableRef={tableRef}
      queryFeedbacks={queryFeedbacks}
      apiError={apiError}
      modals={modals}
      handleDeleteFeedback={handleDeleteFeedback}
    />
  );
}

export default AdminFeedbacks;
