export const ageRanges: string[] = [
  "16-24",
  "25-29",
  "30-36",
  "37-44",
  "45-54",
  "55-64",
  "65-74",
  "75+",
];
