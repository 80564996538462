import * as Yup from "yup";
import { emailRegExp, passwordRegExp } from "constants/regExps";
import { ValidationRule } from "constants/validationRules";
import { PartnerLoginFormValues } from "./PartnerLogin.types";

export const partnerLoginFormInitialValues: PartnerLoginFormValues = {
  email: "",
  password: "",
};

export const partnerLoginFormValidationSchema = Yup.object({
  email: Yup.string()
    .trim()
    .matches(emailRegExp, ValidationRule.InvalidEmailFormat)
    .required(ValidationRule.Required),
  password: Yup.string()
    .trim()
    .matches(passwordRegExp, ValidationRule.InvalidPasswordFormat)
    .required(ValidationRule.Required),
});
