export enum GrowComfortBeginI18nKey {
  Comfort1 = "comfort1",
  Comfort2 = "comfort2",
  Comfort3 = "comfort3",
  Comfort4 = "comfort4",
  Comfort5 = "comfort5",
}

export enum GrowComfortEndI18nKey {
  Comfort6 = "comfort6",
  Comfort7 = "comfort7",
  Comfort8 = "comfort8",
  Comfort9 = "comfort9",
  Comfort10 = "comfort10",
}

export enum GrowConflictBeginI18nKey {
  Conflict1 = "conflict1",
  Conflict2 = "conflict2",
  Conflict3 = "conflict3",
  Conflict4 = "conflict4",
  Conflict5 = "conflict5",
}

export enum GrowConflictEndI18nKey {
  Conflict6 = "conflict6",
  Conflict7 = "conflict7",
  Conflict8 = "conflict8",
  Conflict9 = "conflict9",
  Conflict10 = "conflict10",
}
