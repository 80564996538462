import GBCustomButton from "components/GBCustomButton/GBCustomButton";
import PersonIcon from "@material-ui/icons/Person";
import { superAdminMenuRoutes } from "./SuperAdminMenu.consts";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import FeedbackIcon from "@material-ui/icons/Feedback";
import GroupIcon from "@material-ui/icons/Group";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useTranslation } from "react-i18next";
import { Namespaces } from "i18n";
import { useHistory, useLocation } from "react-router";

type SuperAdminMenuProps = {
  checkActive: (route: string) => "secondary" | undefined;
};

function SuperAdminMenu(props: SuperAdminMenuProps) {
  const { checkActive } = props;

  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation(Namespaces.Admin);

  return (
    <>
      {Object.values(superAdminMenuRoutes).includes(location.pathname) ? (
        <>
          <GBCustomButton
            linkType="router-link"
            href={superAdminMenuRoutes.profile}
            variant="contained"
            color={checkActive(superAdminMenuRoutes.profile)}
            fullWidth
            startIcon={<PersonIcon />}
          >
            {t("layout.aside.buttons.profile")}
          </GBCustomButton>
          <GBCustomButton
            linkType="router-link"
            href={superAdminMenuRoutes.partners}
            variant="contained"
            color={checkActive(superAdminMenuRoutes.partners)}
            fullWidth
            startIcon={<RecordVoiceOverIcon />}
          >
            {t("layout.aside.buttons.partners")}
          </GBCustomButton>
          <GBCustomButton
            linkType="router-link"
            href={superAdminMenuRoutes.users}
            variant="contained"
            color={checkActive(superAdminMenuRoutes.users)}
            fullWidth
            startIcon={<GroupIcon />}
          >
            {t("layout.aside.buttons.users")}
          </GBCustomButton>
          <GBCustomButton
            linkType="router-link"
            href={superAdminMenuRoutes.feedbacks}
            variant="contained"
            color={checkActive(superAdminMenuRoutes.feedbacks)}
            fullWidth
            startIcon={<FeedbackIcon />}
          >
            {t("layout.aside.buttons.feedbacks")}
          </GBCustomButton>
        </>
      ) : (
        <GBCustomButton
          variant="contained"
          fullWidth
          startIcon={<ArrowBackIcon />}
          onClick={() => history.goBack()}
          color="secondary"
        >
          {t("layout.aside.buttons.back")}
        </GBCustomButton>
      )}
    </>
  );
}

export default SuperAdminMenu;
