import React, { useState } from "react";
import { ForgotPasswdRequestBody } from "types/auth/ForgotPasswdRequestBody";
import { ForgotPasswordFormValues } from "./ForgotPassword.types";
import ForgotPasswordView from "./ForgotPasswordView";
import { ForgotPasswordFormStatus } from "constants/ForgotPasswordFormStatus";
import { ApiErrorKey } from "constants/api/apiErrors";
import { useFormik } from "formik";
import {
  forgotPasswordFormInitialValues,
  forgotPasswordFormValidationSchema,
} from "./ForgotPassword.conts";
import { ApiClientError } from "types/api/ApiClientError";
import AuthenticationApiService from "services/AuthenticationApiService";
import { selectApiError } from "utils/api/selectApiError";
import { useHistory } from "react-router";
import { generalRoutes } from "constants/generalRoutes";

function ForgotPassword() {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<ApiErrorKey | null>(null);

  const history = useHistory<{ from: string }>();

  const handleSubmit = async (data: ForgotPasswordFormValues) => {
    const credentials: ForgotPasswdRequestBody = {
      email: data.email.trim(),
    };
    try {
      formik.setStatus(ForgotPasswordFormStatus.ToBeSend);
      setIsProcessing(true);
      setErrorCode(null);

      await AuthenticationApiService.forgotPassword(credentials);

      setIsProcessing(false);
      formik.resetForm();
      formik.setStatus(ForgotPasswordFormStatus.IsSent);
    } catch (e) {
      formik.setStatus(ForgotPasswordFormStatus.IsError);
      console.error(e);
      const error = e as ApiClientError;
      setIsProcessing(false);
      setErrorCode(selectApiError(error.response?.data.message));
    }
  };

  const formik = useFormik<ForgotPasswordFormValues>({
    validationSchema: forgotPasswordFormValidationSchema,
    enableReinitialize: true,
    initialValues: forgotPasswordFormInitialValues,
    onSubmit: handleSubmit,
  });

  const handleGoToLogin = () => {
    history.push(generalRoutes.PARTNER_LOGIN);
  };

  return (
    <ForgotPasswordView
      isProcessing={isProcessing}
      errorCode={errorCode}
      handleGoToLogin={handleGoToLogin}
      {...formik}
    />
  );
}

export default ForgotPassword;
