import { makeStyles, Theme } from "@material-ui/core/styles";
import { WIDE_CONTAINER_WIDTH } from "constants/settings";

const useBuildFormStyles = makeStyles((theme: Theme) => ({
  flexBox: {
    display: "flex",
    [theme.breakpoints.down(WIDE_CONTAINER_WIDTH)]: {
      flexDirection: "column",
      "& > div": {
        marginBottom: "15px",
        "&:last-child": {
          marginBottom: "0",
        },
      },
    },
  },
  selectLabel: {
    lineHeight: "26px",
    fontWeight: 700,
    width: "285px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    marginTop: "25px",
    marginRight: "16px",
    [theme.breakpoints.down(WIDE_CONTAINER_WIDTH)]: {
      width: "100%",
    },
  },
  select: {
    width: "285px",
    marginRight: "16px",
    [theme.breakpoints.down(WIDE_CONTAINER_WIDTH)]: {
      width: "100%",
      marginRight: "0",
    },
  },
  accent: {
    color: theme.palette.primary.main,
  },
  statementBlock: {
    width: "896px",
    [theme.breakpoints.down(WIDE_CONTAINER_WIDTH)]: {
      width: "100%",
    },
  },
  statementTitle: {
    [theme.breakpoints.down(460)]: {
      fontSize: "20px",
      lineHeight: "26px",
    },
  },
  statementText: {
    width: "720px",
    [theme.breakpoints.down(WIDE_CONTAINER_WIDTH)]: {
      width: "100%",
    },
  },
}));

export { useBuildFormStyles };
