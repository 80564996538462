import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useArrowStyles } from "./styles/Arrow.styles";
import cx from "classnames";

type ArrowProps = {
  vertical?: boolean;
  startText: string;
  endText: string;
  className?: string;
  width?: number;
  textUnder?: boolean;
};

function Arrow(props: ArrowProps) {
  const { vertical, startText, endText, className, width, textUnder } = props;
  const classes = useArrowStyles({ width, vertical, textUnder });

  const rootClasses = cx(classes.root, className);

  return (
    <div className={rootClasses}>
      <div className={classes.textBlock}>
        <div className={classes.text}>{startText}</div>
        <div className={classes.text}>{endText}</div>
      </div>
      <div className={classes.arrowWrap}>
        <ChevronLeftIcon className={classes.icon} />
        <ChevronRightIcon className={cx(classes.icon, classes.iconRight)} />
      </div>
    </div>
  );
}

export default Arrow;
