import React from "react";
import { SvgIcon } from "@material-ui/core";

function GBPasswordVisibleIcon(props: React.ComponentProps<typeof SvgIcon>) {
  return (
    <SvgIcon {...props} viewBox="0 0 33 32">
      <path
        fill="none"
        stroke="#687a8f"
        strokeLinejoin="miter"
        strokeLinecap="butt"
        strokeMiterlimit="4"
        strokeWidth="2"
        d="M30.667 18.667c0 0-5.333-9.333-14.667-9.333s-14.667 9.333-14.667 9.333"
      ></path>
      <path
        fill="none"
        stroke="#687a8f"
        strokeLinejoin="miter"
        strokeLinecap="butt"
        strokeMiterlimit="4"
        strokeWidth="2"
        d="M16 9.333v-5.333"
      ></path>
      <path
        fill="none"
        stroke="#687a8f"
        strokeLinejoin="miter"
        strokeLinecap="butt"
        strokeMiterlimit="4"
        strokeWidth="2"
        d="M8.667 11.333l-2.643-4.4"
      ></path>
      <path
        fill="none"
        stroke="#687a8f"
        strokeLinejoin="miter"
        strokeLinecap="butt"
        strokeMiterlimit="4"
        strokeWidth="2"
        d="M22.667 10.667l2.667-4"
      ></path>
      <path
        fill="none"
        stroke="#687a8f"
        strokeLinejoin="miter"
        strokeLinecap="butt"
        strokeMiterlimit="4"
        strokeWidth="2"
        d="M21.333 20c0 2.946-2.388 5.333-5.333 5.333s-5.333-2.388-5.333-5.333c0-2.946 2.388-5.333 5.333-5.333s5.333 2.388 5.333 5.333z"
      ></path>
    </SvgIcon>
  );
}

export default GBPasswordVisibleIcon;
