import { useExerciseMenuPageStyles } from "./styles/ExerciseMenu.styles";
import { exercise } from "types/menus/exercise";
import ExerciseLink from "components/ExerciseLink/ExerciseLink";

type ExerciseMenuViewProps = {
  title: string;
  exercises: exercise[];
  illustration: string;
};

function ExerciseMenuView(props: ExerciseMenuViewProps) {
  const { title, exercises, illustration } = props;

  const classes = useExerciseMenuPageStyles();

  return (
    <div className={classes.root}>
      <div className={classes.splitDiv}>
        <div className={classes.menu}>
          {exercises.map(
            ({ title, path, description, newWindow, accessGated }, k) => (
              <ExerciseLink
                key={`exercise-link-${k}`}
                title={title}
                path={path}
                description={description}
                newWindow={newWindow}
                accessGated={accessGated}
              />
            ),
          )}
        </div>
        <div className={classes.titleDiv}>
          <h1>{title}</h1>
          <img alt={`${title} illustration`} src={illustration}></img>
        </div>
      </div>
    </div>
  );
}

export default ExerciseMenuView;
