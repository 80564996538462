import FormRow from "components/FormRow/FormRow";
import GBInputErrorText from "components/GBInputErrorText/GBInputErrorText";
import GBOutlinedInput from "components/GBOutlinedInput/GBOutlinedInput";
import GBStaticInputLabel from "components/GBStaticInputLabel/GBStaticInputLabel";
import { ValidationRule } from "constants/validationRules";
import { FormikProps } from "formik";
import { Namespaces } from "i18n";
import { useTranslation } from "react-i18next";
import { ImpactPurposeCapabilitiesFormValues } from "../ImpactPurposeCapabilities.types";

type CapabilitiesFormProps =
  FormikProps<ImpactPurposeCapabilitiesFormValues> & {};

function CapabilitiesForm(props: CapabilitiesFormProps) {
  const { values, handleBlur, handleChange, handleSubmit, touched, errors } =
    props;

  const { t } = useTranslation(Namespaces.Impact);

  const hasError = (
    name: keyof ImpactPurposeCapabilitiesFormValues,
  ): boolean => {
    return !!(touched[name] && errors[name]);
  };

  const getError = (
    field: keyof ImpactPurposeCapabilitiesFormValues,
    errorCode: ValidationRule,
  ) => {
    return t([
      `purpose.capabilities.form.${field}.errors.${errorCode}`,
      `${Namespaces.Common}:errors.${errorCode}`,
    ]);
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormRow>
        <GBStaticInputLabel
          labelFor="teachAbout"
          variant="thin"
          text={t("purpose.capabilities.form.teachAbout.label")}
        />
        <GBOutlinedInput
          fullWidth
          id="teachAbout"
          name="teachAbout"
          type="text"
          value={values.teachAbout}
          onChange={handleChange}
          onBlur={handleBlur}
          error={hasError("teachAbout")}
        />
        {hasError("teachAbout") && (
          <GBInputErrorText
            text={getError("teachAbout", errors.teachAbout as ValidationRule)}
          />
        )}
      </FormRow>
      <FormRow>
        <GBStaticInputLabel
          labelFor="improveArea"
          variant="thin"
          text={t("purpose.capabilities.form.improveArea.label")}
        />
        <GBOutlinedInput
          fullWidth
          id="improveArea"
          name="improveArea"
          type="text"
          value={values.improveArea}
          onChange={handleChange}
          onBlur={handleBlur}
          error={hasError("improveArea")}
        />
        {hasError("improveArea") && (
          <GBInputErrorText
            text={getError("improveArea", errors.improveArea as ValidationRule)}
          />
        )}
      </FormRow>
    </form>
  );
}

export default CapabilitiesForm;
