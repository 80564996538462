import { apiEndpoints } from "constants/api/apiEndpoints";
import { UserProfileResponsePayload } from "types/user/UserProfileResponsePayload";
import { API } from "utils/api/http-client";

const { user } = apiEndpoints;

class UserApiService {
  fetchProfile = () => {
    return API.get<UserProfileResponsePayload>(user.fetchProfile);
  };
}

export default new UserApiService();
