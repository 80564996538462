import { Theme, makeStyles } from "@material-ui/core/styles";

const useGBDividerStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: "#F1F0F2",
    height: "2px",
    border: "none",
  },
  disableMargins: {
    margin: "0",
  },
}));

export { useGBDividerStyles };
