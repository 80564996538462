import { UserType } from "constants/userTypes";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { fetchPartnerProfile } from "utils/api/queryFns";
import AdminProfileView from "./AdminProfileView";
import { loadStripe } from "@stripe/stripe-js";
import { Elements as StripeElements } from "@stripe/react-stripe-js";
import { STRIPE_PUBLIC_KEY } from "constants/settings";
import { authActions } from "store/auth/actions";
import { useEffect } from "react";

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

function AdminProfile() {
  const { user } = useSelector((state: RootState) => state.auth);
  const isPartner = user?.userType === UserType.Partner;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authActions.checkAuth());
  }, [dispatch]);

  const partnerProfileQuery = useQuery(
    fetchPartnerProfile.key,
    fetchPartnerProfile.fn,
    {
      enabled: isPartner,
    },
  );
  const partnerProfile = useMemo(
    () => partnerProfileQuery.data?.data.payload,
    [partnerProfileQuery.data?.data.payload],
  );

  const isLoading = partnerProfileQuery.isLoading;
  const isError = partnerProfileQuery.isError;

  return (
    <StripeElements stripe={stripePromise}>
      <AdminProfileView
        isLoading={isLoading}
        isError={isError}
        partnerProfile={partnerProfile}
        isPartner={isPartner}
        userData={user}
      />
    </StripeElements>
  );
}

export default AdminProfile;
