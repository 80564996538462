import { appHistory } from "appHistory";
import Routes from "Routes";
import { appTheme } from "theme";
import ReactPixel from "react-facebook-pixel";
import { FB_PIXEL_ID } from "constants/settings";
import { useEffect } from "react";
import AppProviders from "AppProviders";
import AppModals from "components/AppModals/AppModals";

function App() {
  useEffect(() => {
    if (FB_PIXEL_ID) {
      ReactPixel.init(FB_PIXEL_ID);
      ReactPixel.pageView();
    }
  }, []);

  useEffect(() => {
    const loader = document?.querySelector("#loader");
    if (loader) {
      loader.remove();
    }
  });

  return (
    <AppProviders theme={appTheme} history={appHistory}>
      <Routes />
      <AppModals />
    </AppProviders>
  );
}

export default App;
