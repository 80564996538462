import { GOBEYOND_PUBLIC } from "./settings";

const endpoint = GOBEYOND_PUBLIC;

const redirectRoutes = {
  HOME: `${endpoint}/`,
  SCORE: `${endpoint}/score`,
};

export { redirectRoutes };
