import React from "react";

declare module "@material-ui/core/styles/createTypography" {
  interface Typography {
    menuTitle: React.CSSProperties;
    menuDescription: React.CSSProperties;
    surveySubtitle: React.CSSProperties;
    formLabel: React.CSSProperties;
    formMedium: React.CSSProperties;
    surveyText: React.CSSProperties;
  }
  // allow configuration using `createMuiTheme`
  interface TypographyOptions {
    menuTitle?: React.CSSProperties;
    menuDescription?: React.CSSProperties;
    surveySubtitle: React.CSSProperties;
    formLabel?: React.CSSProperties;
    formMedium?: React.CSSProperties;
    surveyText?: React.CSSProperties;
  }
}

declare module "@material-ui/core/Typography/Typography" {
  interface TypographyPropsVariantOverrides {
    menuTitle: true;
    menuDescription: true;
    surveySubtitle: true;
    formMedium: true;
    surveyText: true;
  }
}

export const commonColors = {
  black: "#07283B",
  white: "#ffffff",
  yellow: "#F4B504",
  gray: "#687A8F",
  red: "#D34837",
  blue: "#2C8CE5",
  green: "#4E9F68",
};

export const primaryColor = {
  main: "#f7882f",
  light: "#ff863e",
  lighten: "#f9be9b",
  lightest: "#fddfcd",
  dark: "#d85201",
  darken: "#bb4c08",
  contrastLight: commonColors.white,
  contrastDark: commonColors.black,
};

export const secondaryColor = {
  main: "#687A8F",
  light: "#A4AFBC",
  lighten: "#D2D7DD",
  lightest: "#F8F8F8",
  dark: "#4e5c6d",
  darken: "#3d4958",
  darknest: "#07283B",
  contrastLight: commonColors.white,
  contrastDark: commonColors.black,
};

export const errorColor = {
  main: "#ff2121",
  light: "#f16161",
  lighten: "#f18484",
  tinted: "#fff2f2",
  dark: "#e41e1d",
  darken: "#c51e1d",
  contrastLight: commonColors.white,
  contrastDark: commonColors.black,
};

export const warningColor = {
  main: "#ffb74d",
  light: "#ffc97a",
  lighten: "#ffd9a0",
  dark: "#efaa45",
  darken: "#e09c37",
  contrastLight: commonColors.white,
  contrastDark: commonColors.black,
};

export const infoColor = {
  main: "#64b5f6",
  light: "#85c9ff",
  lighten: "#b0dcff",
  dark: "#55a6e6",
  darken: "#529cd6",
  contrastLight: commonColors.white,
  contrastDark: commonColors.black,
};

export const successColor = {
  main: "#4E9F68",
  light: "#6cd451",
  lighten: "#cdecd7",
  hoverBgColor: "#71b286",
  dark: "#4E9F68",
  darken: "#3b9a23",
  contrastLight: commonColors.white,
  contrastDark: commonColors.black,
};

export const textColors = {
  black: {
    main: commonColors.black,
    light: "#687A8F",
    lighten: "#9fbed0",
  },
  white: {
    main: commonColors.white,
    dark: "#ececec",
  },
};

export const greyColors = [
  // from lighten to darken
  "#fafafa",
  "#f5f5f5",
  "#eeeeee",
  "#e0e0e0",
  "#bdbdbd",
  "#9e9e9e",
  "#757575",
  "#616161",
  "#424242",
  "#212121",
];

export const borderRadiuses = {
  main: 8,
  sharp: 4,
  medium: 10,
  round: 12,
};

export const shadows = {
  tiny: "0px 2px 8px rgba(7, 40, 59, 0.05)",
  soft: "0px 5px 15px rgba(0, 0, 0, 0.09)",
};

export const typographySettings = {
  fontFamily: [
    "Inter",
    "Montserrat",
    "Roboto",
    "Ubuntu",
    "Arial",
    "sans-serif",
  ].join(","),
  h2: {
    fontWeight: 700,
    fontSize: "42px",
    lineHeight: "54px",
  },
  h3: {
    fontWeight: 700,
    fontSize: "32px",
    lineHeight: "34px",
  },
  h4: {
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "32px",
  },
  menuTitle: {
    fontSize: "20px",
    lineHeight: "32px",
    fontWeight: 700,
  },
  menuDescription: {
    fontSize: "16px",
    lineHeight: "22px",
    fontWeight: 400,
  },
  surveySubtitle: {
    color: commonColors.black,
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "32px",
  },
  surveyText: {
    color: commonColors.black,
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "32px",
  },

  formLabel: {
    color: commonColors.black,
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "30px",
  },
  formMedium: {
    display: "flex",
    flexDirection: "column" as const,
    width: "722px",
  },
  successMsg: {
    display: "flex",
    justifyContent: "center",
    color: primaryColor.main,
    fontSize: "20px",
    fontWeight: 700,
    marginBottom: "20px",
  },
  errorMsg: {
    display: "flex",
    justifyContent: "center",
    color: errorColor.main,
    fontSize: "20px",
    fontWeight: 700,
    marginBottom: "20px",
  },
};

export const buttonStyles = {
  palette: {
    default: {
      bgColor: "#e8e8e8",
      textColor: textColors.black.main,
      hoverBgColor: greyColors[3],
      hoverTextColor: textColors.black.main,
      disabledBgColor: "#f4f4f4",
      disabledTextColor: textColors.black.lighten,
    },
    primary: {
      bgColor: primaryColor.main,
      textColor: primaryColor.contrastLight,
      hoverBgColor: primaryColor.light,
      hoverTextColor: primaryColor.contrastLight,
      disabledTextColor: primaryColor.contrastLight,
      disabledBgColor: primaryColor.lighten,
    },
    header: {
      bgColor: secondaryColor.lightest,
      textColor: secondaryColor.darknest,
      hoverBgColor: greyColors[3],
      hoverTextColor: secondaryColor.darknest,
      disabledTextColor: secondaryColor.main,
      disabledBgColor: commonColors.white,
    },
    portalDefault: {
      bgColor: successColor.dark,
      textColor: commonColors.white,
      hoverBgColor: primaryColor.lighten,
      hoverTextColor: successColor.dark,
      disabledTextColor: commonColors.white,
      disabledBgColor: primaryColor.lightest,
    },
    portalPrimary: {
      bgColor: successColor.dark,
      textColor: commonColors.white,
      hoverBgColor: successColor.hoverBgColor,
      hoverTextColor: textColors.black.main,
      disabledTextColor: successColor.dark,
      disabledBgColor: successColor.lighten,
    },
  },
  size: {
    default: {
      borderRadius: borderRadiuses.main,
      padding: "0 30px",
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: "24px",
      height: "54px",
    },
    small: {
      borderRadius: borderRadiuses.main,
      padding: "0 15px",
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "19px",
      height: "35px",
    },
    large: {
      borderRadius: borderRadiuses.main,
      padding: "0 60px",
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: "24px",
      height: "54px",
    },
    portalDefault: {
      borderRadius: borderRadiuses.medium,
      padding: "9px 60px",
      fontWeight: 500,
      fontSize: "20px",
      lineHeight: "34px",
      height: "52px",
    },
    portalSmall: {
      borderRadius: borderRadiuses.medium,
      fontWeight: 500,
      lineHeight: "34px",
      fontSize: "20px",
      padding: "5px 16px",
      height: "44px",
    },
    portalLarge: {
      borderRadius: borderRadiuses.round,
      fontWeight: 700,
      lineHeight: "34px",
      fontSize: "20px",
      padding: "19px 24px",
      height: "72px",
    },
  },
  variant: {
    text: {
      padding: "0",
    },
  },
};

export const outlinedInputStyles = {
  borderRadius: 12,
  backgroundColor: "#fff",
  fontSize: "18px",
  lineHeight: "26px",
  fontWeight: 600,
  color: textColors.black.main,
  borderWidth: "2px",
  borderColor: "#D2D7DD",
  padding: "19px 19px 19px 24px",
  height: "auto",

  disabled: {
    backgroundColor: "#F2EDEB",
    color: "#D7CAC4",
  },

  error: {
    backgroundColor: errorColor.tinted,
    borderColor: errorColor.main,
  },

  focused: {
    borderColor: primaryColor.main,
  },
};

export const landingSectionBackgrounds = {
  variant1: "#ffffff",
  variant2: "#F9F9F9",
};
