import { XNARROW_CONTAINER_WIDTH } from "constants/settings";
import GBContainer from "components/GBContainer/GBContainer";
import { usePurposeScoreGuestStyles } from "./styles/PurposeScoreGuest.styles";
import { useTranslation } from "react-i18next";
import { Namespaces } from "i18n";
import { Typography } from "@material-ui/core";
import GBTimeIcon from "components/svg-icons/GBTimeIcon";
import GBDivider from "components/GBDivider/GBDivider";
import CalculateScoreForm from "./components/CalculateScoreForm/CalculateScoreForm";

function PurposeScoreGuestView() {
  const classes = usePurposeScoreGuestStyles();
  const { t } = useTranslation(Namespaces.PurposeScore);

  return (
    <GBContainer width={XNARROW_CONTAINER_WIDTH}>
      <div className={classes.root}>
        <div>
          <Typography variant="h2" component="h2">
            {t("heading.title")}
          </Typography>
          <div className={classes.subtitle}>{t("heading.subtitle")}</div>
          <div className={classes.time}>
            <GBTimeIcon className={classes.timeIcon} />
            {t("heading.time")}
          </div>
          <GBDivider className={classes.headingDivider} />
        </div>
        <CalculateScoreForm />
      </div>
    </GBContainer>
  );
}

export default PurposeScoreGuestView;
