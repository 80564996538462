import { makeStyles } from "@material-ui/core";
import { textColors } from "constants/themeSettings";

const useEmotionalIntlFormStyles = makeStyles({
  wrapper: {
    marginTop: "40px",
  },
  time: {
    display: "flex",
    alignItems: "center",
    color: textColors.black.light,
    fontSize: "22px",
    lineHeight: "26px",
    marginTop: "5px",
  },
  timeIcon: {
    marginRight: "10px",
  },
  quizFormRow: {
    marginBottom: "48px",
  },
});

export { useEmotionalIntlFormStyles };
