import { Namespaces } from "i18n";
import { IdentityTypes } from "types/score/DNA";

export const IDENTITIES: IdentityTypes = {
  ACHIEVEMENT: {
    title: `${Namespaces.Identity}:achievement.title`,
    verbsTitle: `${Namespaces.Identity}:achievement.verbsTitle`,
    description: `${Namespaces.Identity}:achievement.description`,
    verbs: [
      "accomplish",
      "achieve",
      "acquire",
      "administer",
      "align",
      "attain",
      "attract",
      "boost",
      "compel",
      "delegate",
      "deliver",
      "direct",
      "earn",
      "empower",
      "enable",
      "ensure",
      "establish",
      "exceed",
      "excel",
      "fulfill",
      "gain",
      "grow",
      "head",
      "increase",
      "influence",
      "initiate",
      "inspire",
      "lead",
      "manage",
      "navigate",
      "negotiate",
      "obtain",
      "outperform",
      "oversee",
      "perform",
      "pioneered",
      "procure",
      "produced",
      "reach",
      "realize",
      "receive",
      "spearhead",
      "succeed",
      "supervise",
      "unify",
      "win",
    ],
  },
  CONSERVATION: {
    title: `${Namespaces.Identity}:conservation.title`,
    verbsTitle: `${Namespaces.Identity}:conservation.verbsTitle`,
    description: `${Namespaces.Identity}:conservation.description`,
    verbs: [
      "accommodate",
      "accomplish",
      "achieve",
      "act",
      "advance",
      "aid",
      "align",
      "allow",
      "appreciate",
      "assemble",
      "assess",
      "assist",
      "bolster",
      "buildUp",
      "care",
      "change",
      "comfort",
      "communicate",
      "conceptualize",
      "coordinate",
      "cultivate",
      "educate",
      "empathize",
      "empower",
      "encourage",
      "execute",
      "foster",
      "further",
      "grow",
      "help",
      "initiate",
      "inspire",
      "join",
      "listen",
      "merge",
      "orchestrate",
      "promote",
      "provide",
      "sacrifice",
      "secure",
      "serve",
      "standWith",
      "sustain",
      "unite",
      "validate",
      "value",
    ],
  },
  CARING: {
    title: `${Namespaces.Identity}:caring.title`,
    verbsTitle: `${Namespaces.Identity}:caring.verbsTitle`,
    description: `${Namespaces.Identity}:caring.description`,
    verbs: [
      "accommodate",
      "advise",
      "aid",
      "allow",
      "appreciate",
      "assist",
      "bolster",
      "buildUp",
      "care",
      "coach",
      "comfort",
      "consult",
      "counsel",
      "cultivate",
      "educate",
      "empathize",
      "empower",
      "encourage",
      "foster",
      "further",
      "guide",
      "help",
      "join",
      "listen",
      "love",
      "mediate",
      "mentor",
      "merge",
      "moderate",
      "motivate",
      "provide",
      "raise",
      "sacrifice",
      "secure",
      "serve",
      "standWith",
      "sustain",
      "teach",
      "train",
      "tutor",
      "unite",
      "validate",
      "value",
    ],
  },
  FREEDOM: {
    title: `${Namespaces.Identity}:freedom.title`,
    verbsTitle: `${Namespaces.Identity}:freedom.verbsTitle`,
    description: `${Namespaces.Identity}:freedom.description`,
    verbs: [
      "attain",
      "allocate",
      "analyze",
      "build",
      "calculate",
      "celebrate",
      "choose",
      "claim",
      "compute",
      "conceptualize",
      "count",
      "craft",
      "create",
      "defend",
      "deliver",
      "design",
      "develop",
      "discern",
      "draft",
      "entertain",
      "estimate",
      "evaluate",
      "explain",
      "express",
      "finance",
      "formulate",
      "found",
      "generate",
      "illustrate",
      "innovate",
      "invent",
      "liberate",
      "obtain",
      "originate",
      "pilot",
      "pioneer",
      "process",
      "program",
      "project",
      "propose",
      "quantify",
      "quote",
      "release",
      "remove",
      "restore",
      "secure",
      "sing",
      "support",
    ],
  },
  RESPECT: {
    title: `${Namespaces.Identity}:respect.title`,
    verbsTitle: `${Namespaces.Identity}:respect.verbsTitle`,
    description: `${Namespaces.Identity}:respect.description`,
    verbs: [
      "accept",
      "accomplish",
      "achieve",
      "admire",
      "appreciate",
      "assure",
      "command",
      "earned",
      "empower",
      "encourage",
      "engage",
      "establish",
      "express",
      "gain",
      "gaine",
      "influence",
      "inspires",
      "love",
      "maintain",
      "preserve",
      "reveal",
      "revered",
      "sacrifice",
      "testify",
      "trust",
      "value",
    ],
  },
  TRADITION: {
    title: `${Namespaces.Identity}:tradition.title`,
    verbsTitle: `${Namespaces.Identity}:tradition.verbsTitle`,
    description: `${Namespaces.Identity}:tradition.description`,
    verbs: [
      "accept",
      "assess",
      "collect",
      "compile",
      "connect",
      "create",
      "detect",
      "diagnose",
      "discover",
      "establish",
      "examine",
      "extract",
      "identify",
      "inherit",
      "investigate",
      "maintain",
      "measure",
      "possess",
      "preserve",
      "prove",
      "qualify",
      "report",
      "represent",
      "research",
      "retain",
      "review",
      "revive",
      "search",
      "standWith",
      "survey",
      "sustain",
      "trust",
      "value",
    ],
  },
  ENJOYMENT: {
    title: `${Namespaces.Identity}:enjoyment.title`,
    verbsTitle: `${Namespaces.Identity}:enjoyment.verbsTitle`,
    description: `${Namespaces.Identity}:enjoyment.description`,
    verbs: [
      "build",
      "conceptualize",
      "craft",
      "create",
      "design",
      "develop",
      "draft",
      "entertain",
      "experience",
      "extract",
      "find",
      "found",
      "generate",
      "illustrate",
      "innovate",
      "invent",
      "measure",
      "pilot",
      "pioneer",
      "possess",
      "procure",
      "propose",
      "rejoice",
      "relish",
      "savor",
      "secure",
      "seek",
      "share",
      "sing",
    ],
  },
  STABILITY: {
    title: `${Namespaces.Identity}:stability.title`,
    verbsTitle: `${Namespaces.Identity}:stability.verbsTitle`,
    description: `${Namespaces.Identity}:stability.description`,
    verbs: [
      "accelerate",
      "advance",
      "affirm",
      "anticipate",
      "arrange",
      "assess",
      "assign",
      "assure",
      "balance",
      "centralize",
      "change",
      "confirm",
      "consolidate",
      "coordinate",
      "corroborate",
      "define",
      "determine",
      "eliminate",
      "enforce",
      "enhance",
      "ensure",
      "equalize",
      "establish",
      "expand",
      "form",
      "fortify",
      "improve",
      "maintain",
      "maximize",
      "modify",
      "optimize",
      "organize",
      "plan",
      "possess",
      "predict",
      "prepare",
      "preserve",
      "prioritize",
      "recommend",
      "reduce",
      "refine",
      "restore",
      "restructure",
      "save",
      "schedule",
      "secure",
      "setUp",
      "simplify",
      "specify",
      "streamline",
      "strengthen",
      "structure",
      "sustain",
      "uphold",
    ],
  },
  EQUALITY: {
    title: `${Namespaces.Identity}:equality.title`,
    verbsTitle: `${Namespaces.Identity}:equality.verbsTitle`,
    description: `${Namespaces.Identity}:equality.description`,
    verbs: [
      "accommodate",
      "accomplish",
      "advocate",
      "agree",
      "aid",
      "allow",
      "appreciate",
      "assist",
      "bolster",
      "buildUp",
      "care",
      "celebrate",
      "comfort",
      "compare",
      "correspond",
      "cultivate",
      "empathize",
      "empower",
      "encourage",
      "engage",
      "establish",
      "foster",
      "further",
      "help",
      "initiate",
      "introduce",
      "invite",
      "join",
      "listen",
      "love",
      "maintain",
      "match",
      "merge",
      "provide",
      "raise",
      "sacrifice",
      "secure",
      "serve",
      "standWith",
      "sustain",
      "unite",
      "validate",
      "value",
    ],
  },
};
