import cx from "classnames";
import { useGBListStyles } from "./styles/GBList.styles";

type GBListProps = {
  label?: string;
  items: string[] | JSX.Element[];
  className?: string;
  labelClassName?: string;
  itemClassName?: string;
  disableMarker?: boolean;
  marker?: JSX.Element;
  markerClassName?: string;
  markerFlex?: "flex-start";
};

function GBList(props: GBListProps) {
  const {
    label,
    items,
    className,
    labelClassName,
    itemClassName,
    disableMarker,
    markerClassName,
    marker,
    markerFlex,
  } = props;
  const classes = useGBListStyles();

  const rootClasses = cx(classes.root, className);
  const labelClasses = cx(classes.label, labelClassName);
  const itemClasses = cx(
    classes.li,
    {
      [classes.liFlexStart]: markerFlex === "flex-start",
    },
    itemClassName,
  );
  const markerClasses = cx(
    classes.markerWrap,
    {
      [classes.markerFlexStart]: markerFlex === "flex-start",
    },
    markerClassName,
  );

  return (
    <div className={rootClasses}>
      {label ? <div className={labelClasses}>{label}</div> : null}
      {items.map((i: string | JSX.Element, k: number) => (
        <div key={k} className={itemClasses}>
          {!disableMarker && (
            <div className={markerClasses}>
              {marker ? marker : <span className={classes.liMarker}></span>}
            </div>
          )}
          {i}
        </div>
      ))}
    </div>
  );
}

export default GBList;
