import { ValidationRule } from "constants/validationRules";
import * as Yup from "yup";

export const capabilitiesFormValidationSchema = Yup.object({
  teachAbout: Yup.string()
    .trim()
    .max(35, ValidationRule.Max)
    .required(ValidationRule.Required),
  improveArea: Yup.string()
    .trim()
    .max(35, ValidationRule.Max)
    .required(ValidationRule.Required),
});
