import { ForgotPasswordFormValues } from "./ForgotPassword.types";
import * as Yup from "yup";
import { emailRegExp } from "constants/regExps";
import { ValidationRule } from "constants/validationRules";

export const forgotPasswordFormInitialValues: ForgotPasswordFormValues = {
  email: "",
};

export const forgotPasswordFormValidationSchema = Yup.object({
  email: Yup.string()
    .trim()
    .matches(emailRegExp, ValidationRule.InvalidEmailFormat)
    .required(ValidationRule.Required),
});
