import { Namespaces } from "i18n";
import ScoreIcon from "components/svg-icons/ScoreIcon";
import AlignIcon from "components/svg-icons/AlignIcon";
import ImpactIcon from "components/svg-icons/ImpactIcon";
import GrowIcon from "components/svg-icons/GrowIcon";
import EngageIcon from "components/svg-icons/EngageIcon";
import { SurveyTypeEnum, MainMenuType } from "types/MainMenu";
import { generalRoutes } from "constants/generalRoutes";
//Score
import DnaBegin from "pages/Exercises/components/surveys/Impact/PurposeDNA/DNABegin/DnaBegin";
import DnaIdentities from "pages/Exercises/components/surveys/Impact/PurposeDNA/DnaIdentities/DnaIdentities";
import DnaRank from "pages/Exercises/components/surveys/Impact/PurposeDNA/DnaRank/DnaRank";
import DnaResult from "pages/Exercises/components/surveys/Impact/PurposeDNA/DNAResult/DnaResult";
import DnaVerbs from "pages/Exercises/components/surveys/Impact/PurposeDNA/DnaVerbs/DnaVerbs";
import ScoreForm from "pages/Exercises/components/surveys/Score/PurposeScore/ScoreForm/ScoreForm";
import ScoreResult from "pages/Exercises/components/surveys/Score/PurposeScore/ScoreResult/ScoreResult";
import EmotionalIntlForm from "pages/Exercises/components/surveys/Score/EmotionalIntl/EmotionalIntlForm/EmotionalIntlForm";
import EmotionalIntlResult from "pages/Exercises/components/surveys/Score/EmotionalIntl/EmotionalIntlResult/EmotionalIntlResult";
import PulseBegin from "pages/Exercises/components/surveys/Score/PurposePulse/PulseBegin/PulseBegin";
import PulseForm from "pages/Exercises/components/surveys/Score/PurposePulse/PulseForm/PulseForm";
import PulseResult from "pages/Exercises/components/surveys/Score/PurposePulse/PulseResult/PulseResult";

//Align
import AlignBegin from "pages/Exercises/components/surveys/Align/AlignBegin/AlignBegin";
import AlignInfo from "pages/Exercises/components/surveys/Align/AlignInfo/AlignInfo";
import AlignResult from "pages/Exercises/components/surveys/Align/AlignResult/AlignResult";
import AlignTheme from "pages/Exercises/components/surveys/Align/AlignTheme/AlignTheme";
//Impact
import ImpactPromiseBegin from "pages/Exercises/components/surveys/Impact/Promise/ImpactPromiseBegin/ImpactPromiseBegin";
import ImpactPromiseBuild from "pages/Exercises/components/surveys/Impact/Promise/ImpactPromiseBuild/ImpactPromiseBuild";
import ImpactPromiseResult from "pages/Exercises/components/surveys/Impact/Promise/ImpactPromiseResult/ImpactPromiseResult";
import ImpactPurposeBegin from "pages/Exercises/components/surveys/Impact/Purpose/ImpactPurposeBegin/ImpactPurposeBegin";
import ImpactPurposeBuild from "pages/Exercises/components/surveys/Impact/Purpose/ImpactPurposeBuild/ImpactPurposeBuild";
import ImpactPurposeCapabilities from "pages/Exercises/components/surveys/Impact/Purpose/ImpactPurposeCapabilities/ImpactPurposeCapabilities";
import ImpactPurposeExperiences from "pages/Exercises/components/surveys/Impact/Purpose/ImpactPurposeExperiences/ImpactPurposeExperiences";
import ImpactPurposeFinal from "pages/Exercises/components/surveys/Impact/Purpose/ImpactPurposeFinal/ImpactPurposeFinal";
import ImpactPurposePassions from "pages/Exercises/components/surveys/Impact/Purpose/ImpactPurposePassions/ImpactPurposePassions";
import ImpactPurposePhilanthropy from "pages/Exercises/components/surveys/Impact/Purpose/ImpactPurposePhilanthropy/ImpactPurposePhilanthropy";
import ImpactPurposeResult from "pages/Exercises/components/surveys/Impact/Purpose/ImpactPurposeResult/ImpactPurposeResult";
import ImpactPurposeStory1 from "pages/Exercises/components/surveys/Impact/Purpose/ImpactPurposeStory1/ImpactPurposeStory1";
import ImpactPurposeStory2 from "pages/Exercises/components/surveys/Impact/Purpose/ImpactPurposeStory2/ImpactPurposeStory2";
import ImpactPurposeStory3 from "pages/Exercises/components/surveys/Impact/Purpose/ImpactPurposeStory3/ImpactPurposeStory3";
import ImpactPurposeTalents from "pages/Exercises/components/surveys/Impact/Purpose/ImpactPurposeTalents/ImpactPurposeTalents";
//Grow
import GrowBegin from "pages/Exercises/components/surveys/Grow/GrowBegin/GrowBegin";
import GrowComfortBegin from "pages/Exercises/components/surveys/Grow/GrowComfortBegin/GrowComfortBegin";
import GrowComfortEnd from "pages/Exercises/components/surveys/Grow/GrowComfortEnd/GrowComfortEnd";
import GrowConflictBegin from "pages/Exercises/components/surveys/Grow/GrowConflictBegin/GrowConflictBegin";
import GrowConflictEnd from "pages/Exercises/components/surveys/Grow/GrowConflictEnd/GrowConflictEnd";
import GrowResult from "pages/Exercises/components/surveys/Grow/GrowResult/GrowResult";

export const MAIN_MENU: MainMenuType = {
  SCORE: {
    title: `${Namespaces.Common}:main_menu.score_title`,
    description: `${Namespaces.Common}:main_menu.score_description`,
    icon: ScoreIcon,
    url: generalRoutes.SCORE_MENU,
    path: SurveyTypeEnum.SCORE,
    stages: {
      purposescore: {
        title: `${Namespaces.Survey}:score.score.title`,
        path: "purposescore",
        dataPath: "score",
        steps: {
          form: {
            path: "form",
            component: ScoreForm,
          },
          result: {
            path: "result",
            component: ScoreResult,
          },
        },
      },
      purposepulse: {
        title: `${Namespaces.Survey}:score.pulse.title`,
        path: "purpose-pulse",
        dataPath: "pulse",
        steps: {
          instructions: {
            path: "instructions",
            component: PulseBegin,
          },
          form: {
            path: "form",
            component: PulseForm,
          },
          result: {
            path: "results",
            component: PulseResult,
          },
        },
      },
      eqscore: {
        title: `${Namespaces.Survey}:score.emotionalIntl.title`,
        path: "eq-score",
        dataPath: "emotionalIntl",
        steps: {
          form: {
            path: "form",
            component: EmotionalIntlForm,
          },
          result: {
            path: "result",
            component: EmotionalIntlResult,
          },
        },
      },
    },
  },
  ALIGN: {
    title: `${Namespaces.Common}:main_menu.align_title`,
    description: `${Namespaces.Common}:main_menu.align_description`,
    icon: AlignIcon,
    url: generalRoutes.ALIGN_MENU,
    path: SurveyTypeEnum.ALIGN,
    stages: {
      root: {
        steps: {
          begin: {
            path: "begin",
            component: AlignBegin,
          },
          info: {
            path: "info",
            component: AlignInfo,
          },
          themes: {
            path: "themes",
            component: AlignTheme,
          },
          result: {
            path: "result",
            component: AlignResult,
          },
        },
      },
    },
  },
  IMPACT: {
    title: `${Namespaces.Common}:main_menu.impact_title`,
    description: `${Namespaces.Common}:main_menu.impact_description`,
    icon: ImpactIcon,
    url: generalRoutes.IMPACT_MENU,
    path: SurveyTypeEnum.IMPACT,
    stages: {
      dna: {
        title: `${Namespaces.Survey}:score.dna.title`,
        path: "dna",
        dataPath: "DNA",
        steps: {
          discern: {
            path: "discern",
            component: DnaBegin,
          },
          identities: {
            path: "identities",
            component: DnaIdentities,
          },
          verbs: {
            path: "verbs",
            component: DnaVerbs,
          },
          rank: {
            path: "rank",
            component: DnaRank,
          },
          result: {
            path: "result",
            component: DnaResult,
          },
        },
      },
      purpose: {
        title: `${Namespaces.Survey}:impact.purpose.title`,
        dataPath: "lifePurpose",
        path: "purpose",
        steps: {
          begin: {
            path: "begin",
            component: ImpactPurposeBegin,
          },
          story1: {
            path: "story1",
            component: ImpactPurposeStory1,
          },
          story2: {
            path: "story2",
            component: ImpactPurposeStory2,
          },
          story3: {
            path: "story3",
            component: ImpactPurposeStory3,
          },
          experiences: {
            path: "experiences",
            component: ImpactPurposeExperiences,
          },
          talents: {
            path: "talents",
            component: ImpactPurposeTalents,
          },
          capabilities: {
            path: "capabilities",
            component: ImpactPurposeCapabilities,
          },
          passions: {
            path: "passions",
            component: ImpactPurposePassions,
          },
          philanthropy: {
            path: "philanthropy",
            component: ImpactPurposePhilanthropy,
          },
          build: {
            path: "build",
            component: ImpactPurposeBuild,
          },
          final: {
            path: "final",
            component: ImpactPurposeFinal,
          },
          result: {
            path: "result",
            component: ImpactPurposeResult,
          },
        },
      },
      promise: {
        title: `${Namespaces.Survey}:impact.promise.title`,
        path: "promise",
        dataPath: "promiseStatements",
        steps: {
          begin: {
            path: "begin",
            component: ImpactPromiseBegin,
          },
          build: {
            path: "build",
            component: ImpactPromiseBuild,
          },
          result: {
            path: "result",
            component: ImpactPromiseResult,
          },
        },
      },
    },
  },
  GROW: {
    title: `${Namespaces.Common}:main_menu.grow_title`,
    description: `${Namespaces.Common}:main_menu.grow_description`,
    icon: GrowIcon,
    url: generalRoutes.GROW_MENU,
    path: SurveyTypeEnum.GROW,
    stages: {
      root: {
        steps: {
          begin: {
            path: "begin",
            component: GrowBegin,
          },
          comfort1: {
            path: "comfort-begin",
            component: GrowComfortBegin,
          },
          comfort2: {
            path: "comfort-end",
            component: GrowComfortEnd,
          },
          conflict1: {
            path: "conflict-begin",
            component: GrowConflictBegin,
          },
          conflict2: {
            path: "conflict-end",
            component: GrowConflictEnd,
          },
          result: {
            path: "result",
            component: GrowResult,
          },
        },
      },
    },
  },
  ENGAGE: {
    title: `${Namespaces.Common}:main_menu.engage_title`,
    description: `${Namespaces.Common}:main_menu.engage_description`,
    icon: EngageIcon,
  },
};
