import FormRow from "components/FormRow/FormRow";
import GBInputErrorText from "components/GBInputErrorText/GBInputErrorText";
import GBOutlinedInput from "components/GBOutlinedInput/GBOutlinedInput";
import GBSelect from "components/GBSelect/GBSelect";
import GBStaticInputLabel from "components/GBStaticInputLabel/GBStaticInputLabel";
import { ValidationRule } from "constants/validationRules";
import { useFormik } from "formik";
import { Namespaces } from "i18n";
import { useTranslation, Trans } from "react-i18next";
import {
  calculateScoreFormInitEmptyValues,
  calculateScoreFormValidationSchema,
  calculateScoreQuizFields,
  calculateScoreQuizFieldSettings,
} from "./CalculateScoreForm.consts";
import { CalculateScoreFormValues } from "./CalculateScoreForm.types";
import { usePurposeScoreFormStyles } from "./styles/PurposeScoreForm.styles";
import MenuItem from "@material-ui/core/MenuItem";
import { ageRanges } from "constants/ageRanges";
import GBDivider from "components/GBDivider/GBDivider";
import RangeQuizField from "components/RangeQuizField/RangeQuizField";
import AlignCenter from "components/AlignCenter/AlignCenter";
import GBButton from "components/GBButton/GBButton";
import GBSpin from "components/GBSpin/GBSpin";
import { useState } from "react";
import { CalculatePurposeScoreRequestPayload } from "types/common/CalculatePurposeScoreRequestPayload";
import { ApiErrorKey } from "constants/api/apiErrors";
import CommonApiService from "services/CommonApiService";
import { ApiClientError } from "types/api/ApiClientError";
import { selectApiError } from "utils/api/selectApiError";
import ErrorText from "components/ErrorText/ErrorText";
import ScoreResult from "../ScoreResult/ScoreResult";
import { getPurposeScoreI18nKey } from "utils/getPurposeScoreI18nKey";
import ScoreFormGraph from "components/svg-icons/ScoreFormGraph";
import { PurposeScoreTypesI18nKey } from "constants/purposeScoreTypes";
import { scrollTop } from "utils/scrollTop";
import { useHistory } from "react-router";
import { generalRoutes } from "constants/generalRoutes";
import GBModal from "components/GBModal/GBModal";

function CalculateScoreForm() {
  const classes = usePurposeScoreFormStyles();
  const { t } = useTranslation([Namespaces.PurposeScore, Namespaces.Common]);
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<ApiErrorKey | undefined>(
    undefined,
  );
  const [scoreValue, setScoreValue] = useState<number>(0);
  const [isMessage, setIsMessage] = useState<boolean>(false);
  const onSubmit = async (data: CalculateScoreFormValues) => {
    const calculatePurposeScoreRequestData: CalculatePurposeScoreRequestPayload =
      {
        name: data.name.trim(),
        ageRange: data.ageRange.trim(),
        email: data.email.trim(),
        day: data.day,
        life: data.life,
        lifeIncludes: data.lifeIncludes,
        personalExistence: data.personalExistence,
        goals: data.goals,
        dreams: data.dreams,
        legacy: data.legacy,
        lifeControl: data.lifeControl,
        currentMissions: data.currentMissions,
        purpose: data.purpose,
      };

    try {
      setIsSubmitting(true);
      setErrorCode(undefined);
      setScoreValue(0);

      const { data } = await CommonApiService.calculatePurposeScoreGuest(
        calculatePurposeScoreRequestData,
      );

      setIsSubmitting(false);
      setScoreValue(data.payload.score);
    } catch (e) {
      console.error(e);
      const error = e as ApiClientError;
      setIsSubmitting(false);
      setScoreValue(0);
      setErrorCode(selectApiError(error.response?.data.message));
    }
  };

  const formik = useFormik<CalculateScoreFormValues>({
    validationSchema: calculateScoreFormValidationSchema,
    enableReinitialize: true,
    initialValues: calculateScoreFormInitEmptyValues,
    onSubmit: onSubmit,
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    errors,
    setFieldValue,
  } = formik;

  const getScoreResultTitle = (scoreValue: number) => {
    const key: PurposeScoreTypesI18nKey = getPurposeScoreI18nKey(scoreValue);
    const titleKey =
      key === PurposeScoreTypesI18nKey.Emergency ||
      key === PurposeScoreTypesI18nKey.NeedPush
        ? "titleLowScore"
        : "title";
    return t(`form.result.${titleKey}`, {
      score: scoreValue,
    });
  };

  const hasError = (name: keyof CalculateScoreFormValues): boolean => {
    return !!(touched[name] && errors[name]);
  };

  const getError = (
    field: keyof CalculateScoreFormValues,
    errorCode: ValidationRule,
  ) => {
    return t([
      `form.fields.${field}.errors.${errorCode}`,
      `${Namespaces.Common}:errors.${errorCode}`,
    ]);
  };

  const handleFinish = () => {
    setScoreValue(0);
    scrollTop();
    setIsMessage(true);
  };

  return (
    <>
      <GBSpin spinning={isSubmitting}>
        <div className={classes.root}>
          <form onSubmit={handleSubmit}>
            <FormRow className={classes.formRow}>
              <GBStaticInputLabel
                component="div"
                variant="medium"
                labelFor="name"
                text={t("form.fields.name.label")}
              />
              <GBOutlinedInput
                fullWidth
                id="name"
                name="name"
                type="text"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={hasError("name")}
                disabled={isSubmitting}
              />
              {hasError("name") && (
                <GBInputErrorText
                  text={getError("name", errors.name as ValidationRule)}
                />
              )}
            </FormRow>
            <FormRow className={classes.formRowAge}>
              <GBStaticInputLabel
                component="div"
                variant="medium"
                labelFor="ageRange"
                text={t("form.fields.ageRange.label")}
              />
              <GBSelect
                fullWidth
                id="ageRange"
                name="ageRange"
                value={values.ageRange}
                onChange={handleChange}
                onBlur={handleBlur}
                error={hasError("ageRange")}
                disabled={isSubmitting}
              >
                {ageRanges.map((r, k) => (
                  <MenuItem key={k} value={r}>
                    {t(`${Namespaces.Common}:misc.age_range`, {
                      range: r,
                    })}
                  </MenuItem>
                ))}
              </GBSelect>
              {hasError("ageRange") && (
                <GBInputErrorText
                  text={getError("ageRange", errors.ageRange as ValidationRule)}
                />
              )}
            </FormRow>
            <FormRow className={classes.formRow}>
              <GBStaticInputLabel
                component="div"
                variant="medium"
                labelFor="email"
                text={t("form.fields.email.label")}
              />

              <GBOutlinedInput
                fullWidth
                id="email"
                name="email"
                type="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={hasError("email")}
                placeholder={t("form.fields.email.placeholder")}
                disabled={isSubmitting}
              />
              {hasError("email") && (
                <GBInputErrorText
                  text={getError("email", errors.email as ValidationRule)}
                />
              )}
            </FormRow>
            <GBDivider className={classes.divider} />
            <div className={classes.quiz}>
              {calculateScoreQuizFields.map((i, k) => (
                <FormRow key={k} fullWidth className={classes.quizFormRow}>
                  <GBStaticInputLabel
                    labelFor={i}
                    variant="medium"
                    text={t(`form.fields.${i}.label`)}
                  />
                  <RangeQuizField
                    fullWidth
                    id={i}
                    name={i}
                    value={values[i]}
                    from={calculateScoreQuizFieldSettings.smallest}
                    to={calculateScoreQuizFieldSettings.highest}
                    error={hasError(i)}
                    fromText={t(`form.fields.${i}.smallest`)}
                    toText={t(`form.fields.${i}.highest`)}
                    onChange={(value: number) => setFieldValue(i, value)}
                    disabled={isSubmitting}
                  />
                </FormRow>
              ))}
            </div>
            <div className={classes.submitBlock}>
              {scoreValue ? (
                <ScoreResult
                  title={
                    <Trans
                      i18nKey={getScoreResultTitle(scoreValue)}
                      components={{
                        accent: <span className={classes.accent} />,
                      }}
                    />
                  }
                  type={t(
                    `form.result.types.${getPurposeScoreI18nKey(
                      scoreValue,
                    )}.label`,
                  )}
                  text={
                    <Trans
                      i18nKey={t(
                        `form.result.types.${getPurposeScoreI18nKey(
                          scoreValue,
                        )}.desc`,
                      )}
                      components={{
                        p: <p />,
                      }}
                    />
                  }
                  img={
                    <ScoreFormGraph
                      score={getPurposeScoreI18nKey(scoreValue)}
                    />
                  }
                  buttonText={t("form.result.buttons.finish")}
                  onButtonClick={handleFinish}
                />
              ) : (
                <AlignCenter>
                  <GBButton
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {t("form.buttons.submit")}
                  </GBButton>
                </AlignCenter>
              )}
            </div>
            {errorCode ? (
              <ErrorText
                className={classes.errorBlock}
                textAlign="center"
                text={t([
                  `form.errors.${errorCode}`,
                  `${Namespaces.Common}:errors.${errorCode}`,
                  `${Namespaces.Common}:errors.unexpected_error`,
                ])}
              />
            ) : null}
          </form>
        </div>
      </GBSpin>
      <GBModal
        open={isMessage}
        title="Congratulations!"
        size="medium"
        withTitleDivider
      >
        <p>
          Congratulations on completing Purpose Score.
          <br />
          <br />
          You can now close this window.
        </p>
      </GBModal>
    </>
  );
}

export default CalculateScoreForm;
