import GBCircularProgress from "components/GBCircularProgress/GBCircularProgress";
import GBSpin from "components/GBSpin/GBSpin";
import StageStepButton from "components/StageStepButton/StageStepButton";
import StageStepContainer from "components/StageStepContainer/StageStepContainer";
import StageStepFrame from "components/StageStepFrame/StageStepFrame";
import StageStepText from "components/StageStepText/StageStepText";
import StageStepTitle from "components/StageStepTitle/StageStepTitle";
import UnexpectedError from "components/UnexpectedError/UnexpectedError";
import { ApiErrorKey } from "constants/api/apiErrors";
import { Formik } from "formik";
import { Namespaces } from "i18n";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { alignThemeFormValidationSchema } from "./AlignTheme.consts";
import { AlignThemeFormValues } from "./AlignTheme.types";
import ThemeForm from "./components/ThemeForm/ThemeForm";
import { useAlignThemeStyles } from "./styles/AlignThemes.styles";

type AlignThemeViewProps = {
  onFormSubmit: (data: AlignThemeFormValues) => void;
  initialValues: AlignThemeFormValues;
  isFetching: boolean;
  isUpdating: boolean;
  isFetchError: boolean;
  prevUrl: string | undefined;
  getApiError: () => string | undefined;
  getFormError: () => string | undefined;
  setFormError: (code: string | null) => void;
  setApiError: (code: ApiErrorKey | null) => void;
};

function AlignThemeView(props: AlignThemeViewProps) {
  const {
    isFetching,
    isUpdating,
    isFetchError,
    prevUrl,
    getApiError,
    getFormError,
    setFormError,
    setApiError,
    onFormSubmit,
    initialValues,
  } = props;

  const { t } = useTranslation([
    Namespaces.Align,
    Namespaces.Survey,
    Namespaces.Common,
  ]);

  const classes = useAlignThemeStyles();
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const handleNextClick = () => {
    setApiError(null);
    setFormError(null);
    submitButtonRef.current?.click();
  };

  return (
    <StageStepFrame
      title={t("steps.title")}
      controls={[
        <StageStepButton.Back
          goTo={prevUrl}
          disabled={isFetching || isUpdating}
        >
          {t(`${Namespaces.Survey}:buttons.back`)}
        </StageStepButton.Back>,
        <StageStepButton.Next
          onClick={handleNextClick}
          disabled={isFetching || isUpdating}
        >
          {t(`${Namespaces.Survey}:buttons.continue`)}
        </StageStepButton.Next>,
      ]}
      error={getApiError() || getFormError()}
    >
      <StageStepContainer className={classes.textBlock}>
        <StageStepTitle gutter="16px">
          {t("steps.theme.text_block.label")}
        </StageStepTitle>
        <StageStepText>{t("steps.theme.text_block.text")}</StageStepText>
      </StageStepContainer>
      {isFetching ? (
        <GBCircularProgress keepHeight="288px" />
      ) : isFetchError ? (
        <UnexpectedError />
      ) : (
        <GBSpin spinning={isUpdating}>
          <Formik
            className={classes.content}
            initialValues={initialValues}
            onSubmit={onFormSubmit}
            validationSchema={alignThemeFormValidationSchema}
          >
            {(formik) => {
              return (
                <ThemeForm
                  setFormError={(code: string | null) => setFormError(code)}
                  submitButtonRef={submitButtonRef}
                  {...formik}
                />
              );
            }}
          </Formik>
        </GBSpin>
      )}
    </StageStepFrame>
  );
}

export default AlignThemeView;
