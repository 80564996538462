import { makeStyles, Theme } from "@material-ui/core/styles";
import { commonColors } from "constants/themeSettings";

const useStageStepTitleStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: "32px",
  },
  gutter16: {
    marginBottom: "16px",
  },
  blue: {
    color: commonColors.blue,
  },
  red: {
    color: commonColors.red,
  },
}));

export { useStageStepTitleStyles };
