import { makeStyles, Theme } from "@material-ui/core/styles";
import { textColors } from "constants/themeSettings";

const useTextChipStyles = makeStyles((theme: Theme) => ({
  root: {
    background: "#F8F8F8",
    color: textColors.black.light,
    borderRadius: "50%",
    fontSize: "18px",
    lineHeight: "26px",
    fontWeight: 700,
    padding: "7px",
    height: "40px",
    minWidth: "40px",
    textAlign: "center",
    cursor: "default",
    userSelect: "none",
  },
}));

export { useTextChipStyles };
