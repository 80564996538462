import React from "react";
import { useGBMTDetailPanelStyles } from "../styles/GBMTDetailPanel.styles";
import cx from "classnames";

type RowProps = {
  children: React.ReactNode;
  className?: string;
  column?: boolean;
  spaceBetween?: boolean;
  vFlexStart?: boolean;
};

function Row(props: RowProps) {
  const { children, className, column, spaceBetween, vFlexStart } = props;
  const classes = useGBMTDetailPanelStyles();

  const rowClasses = cx(
    classes.row,
    {
      [classes.rowDirCol]: column,
      [classes.rowSpaceBetween]: spaceBetween,
      [classes.vFlexStart]: vFlexStart,
    },
    className,
  );

  return <div className={rowClasses}>{children}</div>;
}

export default Row;
