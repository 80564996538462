import { SelectProps } from "@material-ui/core";
import GBSelectIcon from "components/svg-icons/GBSelectIcon";
import { RedesignedSelect, useGBSelectStyles } from "./styles/GBSelect.styles";
import cx from "classnames";

type GBSelectProps = SelectProps & {
  className?: string;
};

const GBSelect = (props: GBSelectProps) => {
  const { className, children, ...rest } = props;
  const classes = useGBSelectStyles();

  const rootClasses = cx(classes.root, className);

  return (
    <RedesignedSelect
      className={rootClasses}
      variant="outlined"
      IconComponent={() => <GBSelectIcon />}
      {...rest}
    >
      {children}
    </RedesignedSelect>
  );
};

export default GBSelect;
