import cx from "classnames";
import { useFormRowStyles } from "./styles/FormRow.styles";

type FormRowProps = {
  children: React.ReactNode;
  className?: string;
  fullWidth?: boolean;
  gutter?: "16px" | "48px";
};

function FormRow(props: FormRowProps) {
  const { children, className, fullWidth, gutter } = props;
  const classes = useFormRowStyles();

  const rootClasses = cx(
    classes.root,
    {
      [classes.fullWidth]: fullWidth,
      [classes.gutter16]: gutter === "16px",
      [classes.gutter48]: gutter === "48px",
    },
    className,
  );

  return <div className={rootClasses}>{children}</div>;
}

export default FormRow;
