import { reduce as _reduce } from "lodash";

export const prepareRoutePath = (
  path: string,
  params: { [key: string]: string | number } = {},
  query: string = "",
) => {
  const preparedPath = _reduce(
    params,
    (result, value, key) =>
      result.replace(key.charAt(0) === ":" ? key : `:${key}`, `${value}`),
    path,
  );
  return query
    ? `${preparedPath}${query.charAt(0) === "?" ? query : `?${query}`}`
    : preparedPath;
};
