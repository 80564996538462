import { API } from "utils/api/http-client";
import { apiEndpoints } from "constants/api/apiEndpoints";
import { GetImpactResponsePayload } from "types/impact/GetImpactResponsePayload";
import { PostImpactRequestPayload } from "types/impact/PostImpactRequestPayload";

const { impact } = apiEndpoints;

class ImpactApiService {
  getImpact = () => {
    return API.get<GetImpactResponsePayload>(impact.getImpact);
  };

  postImpact = (data: PostImpactRequestPayload) => {
    return API.post(impact.postImpact, data);
  };
}

export default new ImpactApiService();
