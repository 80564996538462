import cx from "classnames";
import { useGBStaticInputLabelStyles } from "./styles/GBStaticInputLabel.styles";

type GBStaticInputLabelProps = {
  labelFor?: string;
  text: string | JSX.Element;
  className?: string;
  variant?: "medium" | "thin" | "small";
  component?: "div";
};

function GBStaticInputLabel(props: GBStaticInputLabelProps) {
  const { component, labelFor, text, className, variant } = props;
  const classes = useGBStaticInputLabelStyles();

  const rootClasses = cx(
    classes.root,
    {
      [classes.small]: variant === "small",
      [classes.medium]: variant === "medium",
      [classes.thin]: variant === "thin",
    },
    className,
  );

  return (
    <>
      {component === "div" ? (
        <div className={rootClasses}>{text}</div>
      ) : (
        <label className={rootClasses} htmlFor={labelFor || "#"}>
          {text}
        </label>
      )}
    </>
  );
}

export default GBStaticInputLabel;
