import { ImpactPurpose } from "types/impact/ImpactPurpose";
import { ImpactPurposeTalentsFormValues } from "./ImpactPurposeTalents.types";

export const prepareInitialValues = (
  data?: ImpactPurpose,
): ImpactPurposeTalentsFormValues => ({
  talent1: data?.talent1 || "",
  talent2: data?.talent2 || "",
  talent3: data?.talent3 || "",
});
