export const ACCESS_GATED_URLS = [
  "purpose-pulse",
  "friction-eq",
  "dna",
  "promise",
  "purposepulse",
  "frictioneq",
  "trials-triumphs",
  "purpose",
];
