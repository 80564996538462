import { useSnapshotSectionLabelStyles } from "./styles/SnapshotSectionLabel.styles";
import cx from "classnames";

type SnapshotSectionLabelProps = {
  text: string;
  className?: string;
  color: "yellow" | "gray" | "primary" | "black" | "green";
};

function SnapshotSectionLabel(props: SnapshotSectionLabelProps) {
  const { text, className, color } = props;
  const classes = useSnapshotSectionLabelStyles();

  const rootClases = cx(classes.root, className);
  const labelClasse = cx(classes.label, {
    [classes.yellow]: color === "yellow",
    [classes.gray]: color === "gray",
    [classes.primary]: color === "primary",
    [classes.black]: color === "black",
    [classes.green]: color === "green",
  });

  return (
    <div className={rootClases}>
      <div className={labelClasse}>{text}</div>
    </div>
  );
}

export default SnapshotSectionLabel;
