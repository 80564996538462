import common from "./common/en.json";
import auth from "./auth/en.json";
import identity from "./identity/en.json";
import notFound from "./notFound/en.json";
import purposeScore from "./purposeScore/en.json";
import survey from "./survey/en.json";
import align from "./align/en.json";
import score from "./score/en.json";
import impact from "./impact/en.json";
import grow from "./grow/en.json";
import snapshot from "./snapshot/en.json";
import admin from "./admin/en.json";

export const translationsEN = {
  admin,
  common,
  auth,
  notFound,
  identity,
  purposeScore,
  survey,
  score,
  align,
  impact,
  grow,
  snapshot,
};
