import React from "react";
import { SvgIcon } from "@material-ui/core";

function GBSelectIcon(props: React.ComponentProps<typeof SvgIcon>) {
  return (
    <SvgIcon {...props} viewBox="0 0 33 32">
      <path d="M16 25.12c-0.573 0-1.147-0.219-1.584-0.656l-13.759-13.759c-0.875-0.875-0.875-2.294 0-3.169s2.294-0.875 3.169 0l12.174 12.175 12.174-12.175c0.875-0.875 2.294-0.875 3.169 0s0.876 2.294 0 3.169l-13.759 13.759c-0.437 0.437-1.011 0.656-1.584 0.656z"></path>
    </SvgIcon>
  );
}

export default GBSelectIcon;
