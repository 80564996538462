import { useMemo } from "react";
import { GrowSquareData } from "./GrowSquare.types";
import { useGrowSquareStyles } from "./styles/GrowSquare.styles";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { Namespaces } from "i18n";
import Arrow from "./components/Arrow/Arrow";
import { GrowCategory } from "constants/grow/growCategories";
import { useMediaQuery, useTheme } from "@material-ui/core";

type GrowSquareProps = {
  data: GrowSquareData;
};

function GrowSquare(props: GrowSquareProps) {
  const { data } = props;

  const classes = useGrowSquareStyles();
  const { t } = useTranslation(Namespaces.Snapshot);
  const theme = useTheme();
  const isDown700 = useMediaQuery(theme.breakpoints.down(700));
  const isDown600 = useMediaQuery(theme.breakpoints.down(600));
  const isDown460 = useMediaQuery(theme.breakpoints.down(460));

  const highestValue = useMemo(
    () =>
      data.reduce(
        (acc, item) => (acc = acc > item.score ? acc : item.score),
        0,
      ),
    [data],
  );

  const squareData = useMemo(
    () => [
      data.filter((e) => e.category === GrowCategory.Analyst)[0],
      data.filter((e) => e.category === GrowCategory.Companion)[0],
      data.filter((e) => e.category === GrowCategory.Catalyst)[0],
      data.filter((e) => e.category === GrowCategory.Visionary)[0],
    ],
    [data],
  );

  return (
    <div className={classes.root}>
      <div className={classes.grid}>
        <div className={classes.inner}>{t("step2.grow.chart.title")}</div>
        {squareData.map((e, k) => (
          <div
            key={k}
            className={cx(classes.item, {
              [classes.selected]: e.score === highestValue,
            })}
          >
            <div>
              <div className={classes.title}>
                {t(`step2.grow.categories.${e.category}`)}
              </div>
              <div className={classes.subtitle}>
                ({t(`step2.grow.subtitles.${e.category}`)})
              </div>
            </div>
          </div>
        ))}
        <Arrow
          width={
            isDown460 ? 245 : isDown600 ? 305 : isDown700 ? 405 : undefined
          }
          vertical
          startText={t(`step2.grow.axisTitles.y.start`)}
          endText={t(`step2.grow.axisTitles.y.end`)}
          className={classes.arrowY}
        />
        <Arrow
          width={
            isDown460 ? 245 : isDown600 ? 305 : isDown700 ? 405 : undefined
          }
          textUnder
          startText={t(`step2.grow.axisTitles.x.start`)}
          endText={t(`step2.grow.axisTitles.x.end`)}
          className={classes.arrowX}
        />
      </div>
    </div>
  );
}

export default GrowSquare;
