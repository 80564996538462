import { apiEndpoints } from "constants/api/apiEndpoints";
import { GetPartnerProfileResponsePayload } from "types/admin/partners/GetPartnerProfileResponsePayload";
import { GetAdminUserDetailResponsePayload } from "types/admin/users/GetAdminUserDetailResponsePayload";
import { GetAdminUsersResponsePayload } from "types/admin/users/GetAdminUsersResponsePayload";
import { EntityId } from "types/EntityId";
import { API } from "utils/api/http-client";
import queryString from "query-string";
import { PaginationQueryParams } from "types/PaginationQueryParams";
import { SearchQueryParams } from "types/SearchQueryParams";

const { partner } = apiEndpoints;

class PartnerApiService {
  // Profile

  fetchPartnerProfile = () => {
    return API.get<GetPartnerProfileResponsePayload>(
      partner.profile.fetchProfile,
    );
  };

  // Users

  getUsers = (params: PaginationQueryParams & SearchQueryParams) => {
    return API.get<GetAdminUsersResponsePayload>(
      `${partner.users.getUsers}?${queryString.stringify(params)}`,
    );
  };

  getUserDetail = (id: EntityId) => {
    return API.get<GetAdminUserDetailResponsePayload>(
      partner.users.getUserDetail.replace(":id", id),
    );
  };
}

export default new PartnerApiService();
