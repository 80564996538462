import { useErrorTextStyles } from "./styles/ErrorText.styles";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { Namespaces } from "i18n";

type ErrorTextProps = {
  text: string;
  textAlign?: "center" | "right";
  className?: string;
};

function ErrorText(props: ErrorTextProps) {
  const { text, textAlign, className } = props;
  const classes = useErrorTextStyles();
  const { t } = useTranslation(Namespaces.Common);

  const wrapperClasses = cx(
    classes.root,
    {
      [classes.textCenter]: textAlign === "center",
      [classes.textRight]: textAlign === "right",
    },
    className,
  );

  return (
    <div aria-label={t("labels.aria.error_text")} className={wrapperClasses}>
      {text}
    </div>
  );
}

export default ErrorText;
