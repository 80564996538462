import Table from "./components/Table/Table";
import { Modals, QueryFeedbacksFn } from "./AdminFeedbacks.types";
import { ApiErrorKey } from "constants/api/apiErrors";
import ApiError from "components/ApiError/ApiError";
import DeleteCodeModal from "components/GBConfirmModal/GBConfirmModal";
import { useTranslation } from "react-i18next";
import { Namespaces } from "i18n";
import { AdminFeedback } from "types/admin/feedbacks/AdminFeedback";
import { MutableRefObject } from "react";
import { MaterialTableProps } from "material-table";

type AdminFeedbacksViewProps = {
  queryFeedbacks: QueryFeedbacksFn;
  apiError: ApiErrorKey | null;
  modals: Modals;
  handleDeleteFeedback: (feedback: AdminFeedback | null) => void;
  tableRef: MutableRefObject<MaterialTableProps<object> | null>;
};

function AdminFeedbacksView(props: AdminFeedbacksViewProps) {
  const { queryFeedbacks, apiError, modals, handleDeleteFeedback, tableRef } =
    props;

  const { t } = useTranslation(Namespaces.Admin);

  return (
    <>
      {apiError ? (
        <ApiError errorKey={apiError} />
      ) : (
        <>
          <Table
            queryData={queryFeedbacks}
            tableRef={tableRef}
            modals={modals}
          />
          <DeleteCodeModal
            open={modals.delete.data.isOpen}
            onClose={() =>
              modals.delete.setData({ feedback: null, isOpen: false })
            }
            text={t("feedbacks.modals.deleteFeedback.text", {
              author: `${modals.delete.data.feedback?.user.first_name} ${modals.delete.data.feedback?.user.last_name}`,
            })}
            onConfirm={() => handleDeleteFeedback(modals.delete.data.feedback)}
          />
        </>
      )}
    </>
  );
}

export default AdminFeedbacksView;
