import { makeStyles, Theme } from "@material-ui/core/styles";

const useAlignResultStyles = makeStyles((theme: Theme) => ({
  list: {
    marginTop: "32px",

    "&:last-child": {
      marginTop: "56px",
    },
  },
}));

export { useAlignResultStyles };
