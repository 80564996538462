import { scoreLevel } from "../../utils/getPurposeScoreI18nKey";

type ScoreFormSurveyIconProps = {
  score?: scoreLevel;
  width?: string;
  height?: string;
};

const ScoreFormSurveyIcon = ({
  score = scoreLevel.CRITICAL,
  width = "500px",
  height = "300px",
}: ScoreFormSurveyIconProps): JSX.Element => {
  let fillRedScoreColor = "#EDEFF2";
  let fillYellowScoreColor = "#EDEFF2";
  let fillBlueScoreColor = "#EDEFF2";
  let fillGreenScoreColor = "#EDEFF2";
  let rotate = 9;
  switch (score) {
    case scoreLevel.CRITICAL:
      fillRedScoreColor = "#D34837";
      break;
    case scoreLevel.LOW:
      fillYellowScoreColor = "#E3AD42";
      rotate = 55;
      break;
    case scoreLevel.MIDDLE:
      fillBlueScoreColor = "#2C8CE6";
      rotate = 100;
      break;
    default:
      fillGreenScoreColor = "#4E9F68";
      rotate = 145;
  }
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 770 450"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(515, 146)">
        <path
          d="M128.069 0.00146484L0.765869 127.305C31.2844 159.557 50 203.093 50 251H230C230 153.387 191.15 64.8481 128.069 0.00146484Z"
          fill={fillGreenScoreColor}
        />
      </g>
      <g transform="translate(389, 38)">
        <path
          d="M0 180.068C45.9324 181.321 87.5685 199.781 118.695 229.234L245.998 101.93C182.261 39.9285 95.6342 1.33591 0 0.0340576V180.068Z"
          fill={fillBlueScoreColor}
        />
      </g>
      <g transform="translate(133, 38)">
        <path
          d="M246 0.0340576C150.366 1.33591 63.7391 39.9285 0.00146484 101.931L127.305 229.234C158.431 199.781 200.068 181.321 246 180.068V0.0340576Z"
          fill={fillYellowScoreColor}
        />
      </g>
      <g transform="translate(24, 147)">
        <path
          d="M229.234 127.305L101.93 0.00158691C38.8496 64.8482 0 153.387 0 251H180C180 203.093 198.716 159.557 229.234 127.305Z"
          fill={fillRedScoreColor}
        />
      </g>
      <g transform="translate(650, 131)">
        <path
          d="M0.141113 7.92973C65.0312 74.5866 105 165.626 105 266H115C115 162.864 73.9123 69.3251 7.21283 0.858032L0.141113 7.92973Z"
          fill="#4E9F68"
        />
      </g>
      <g transform="translate(389, 17)">
        <path
          d="M253.07 114.859L260.142 107.787C192.783 42.167 101.157 1.33668 0 0.0322266V10.0331C98.3956 11.3363 187.522 51.0477 253.07 114.859Z"
          fill="#2C8CE6"
        />
      </g>
      <g transform="translate(118, 18)">
        <path
          d="M261 0.0322266C159.843 1.33668 68.2168 42.1671 0.85791 107.787L7.9296 114.859C73.4779 51.0478 162.604 11.3363 261 10.0331V0.0322266Z"
          fill="#E3AD42"
        />
      </g>
      <g transform="translate(4, 132)">
        <path
          d="M0 266C0 162.864 41.0875 69.3252 107.787 0.858154L114.859 7.92985C49.9686 74.5867 10 165.626 10 266H0Z"
          fill="#D34837"
        />
      </g>
      <circle cx="384" cy="397" r="50" fill="#07283B" />

      <g transform={`translate(127, 317) rotate(${rotate} 260 76)`}>
        <path
          d="M4.34361 13.5863L264.891 63.9467L257.169 95.0011L3.37837 17.4681L4.34361 13.5863Z"
          fill="#07283B"
        />
      </g>
    </svg>
  );
};

export default ScoreFormSurveyIcon;
