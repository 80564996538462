import * as Yup from "yup";
import { ValidationRule } from "constants/validationRules";
import { EmotionalIntlQuizField } from "./EmotionalIntlForm.types";

export const emotionalIntlQuizFields = Object.values(EmotionalIntlQuizField);

export const emotionalIntlQuizFieldSettings = {
  init: 0,
  smallest: 1,
  highest: 6,
};

const { init } = emotionalIntlQuizFieldSettings;

export const emotionalIntlFormValidationSchema = Yup.object({
  recognizeEmotions: Yup.number()
    .moreThan(init)
    .required(ValidationRule.Required),
  loseTemper: Yup.number().moreThan(init).required(ValidationRule.Required),
  goodListener: Yup.number().moreThan(init).required(ValidationRule.Required),
  calmSelf: Yup.number().moreThan(init).required(ValidationRule.Required),
  enjoyOrganizing: Yup.number()
    .moreThan(init)
    .required(ValidationRule.Required),
  movingOn: Yup.number().moreThan(init).required(ValidationRule.Required),
  strengthsWeaknesses: Yup.number()
    .moreThan(init)
    .required(ValidationRule.Required),
  avoidConflict: Yup.number().moreThan(init).required(ValidationRule.Required),
  askFeedback: Yup.number().moreThan(init).required(ValidationRule.Required),
  readEmotions: Yup.number().moreThan(init).required(ValidationRule.Required),
  buildRapport: Yup.number().moreThan(init).required(ValidationRule.Required),
  activeListening: Yup.number()
    .moreThan(init)
    .required(ValidationRule.Required),
});
