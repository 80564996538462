import { PurposeScoreTypesI18nKey } from "constants/purposeScoreTypes";

const LEVELS = {
  LOW: 2.5,
  MID: 3.9,
  HIGH: 4.9,
};
export enum scoreLevel {
  CRITICAL = "critical",
  LOW = "low",
  MIDDLE = "middle",
  HIGH = "high",
}

export const getPurposeScoreI18nKey = (score: number) => {
  if (score <= 2.5) {
    return PurposeScoreTypesI18nKey.Emergency;
  } else if (score >= 2.6 && score <= 3.9) {
    return PurposeScoreTypesI18nKey.NeedPush;
  } else if (score >= 4 && score <= 4.9) {
    return PurposeScoreTypesI18nKey.NeedNudge;
  } else {
    return PurposeScoreTypesI18nKey.Mentor;
  }
};

export const getPurposeScoreKey = (score: number) => {
  if (score <= LEVELS.LOW) {
    return scoreLevel.CRITICAL;
  } else if (score <= LEVELS.MID) {
    return scoreLevel.LOW;
  } else if (score <= LEVELS.HIGH) {
    return scoreLevel.MIDDLE;
  } else {
    return scoreLevel.HIGH;
  }
};
