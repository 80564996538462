import { MuiThemeProvider, Theme } from "@material-ui/core/styles";
import { Router, RouterProps } from "react-router";
import { Provider } from "react-redux";
import { store } from "store";
import { QueryClient, QueryClientProvider } from "react-query";
import React from "react";
import AuthWatcher from "components/AuthWatcher/AuthWatcher";
import { Elements as StripeElements } from "@stripe/react-stripe-js";
import { STRIPE_PUBLIC_KEY } from "constants/settings";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

type AppProvidersProps = {
  children: React.ReactNode;
  theme: Theme;
  history: RouterProps["history"];
};

const AppProviders = (props: AppProvidersProps) => {
  const { children, theme, history } = props;

  return (
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <Router history={history}>
          <QueryClientProvider client={queryClient}>
            <AuthWatcher>
              <StripeElements stripe={stripePromise}>{children}</StripeElements>
            </AuthWatcher>
          </QueryClientProvider>
        </Router>
      </MuiThemeProvider>
    </Provider>
  );
};

export default AppProviders;
