import { ValidationRule } from "constants/validationRules";
import * as Yup from "yup";

export const comfortEndFormValidationSchema = Yup.object().shape({
  comfort6: Yup.number()
    .moreThan(0, ValidationRule.Required)
    .required(ValidationRule.Required),
  comfort7: Yup.number()
    .moreThan(0, ValidationRule.Required)
    .required(ValidationRule.Required),
  comfort8: Yup.number()
    .moreThan(0, ValidationRule.Required)
    .required(ValidationRule.Required),
  comfort9: Yup.number()
    .moreThan(0, ValidationRule.Required)
    .required(ValidationRule.Required),
  comfort10: Yup.number()
    .moreThan(0, ValidationRule.Required)
    .required(ValidationRule.Required),
});
