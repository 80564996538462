import { API } from "utils/api/http-client";
import { apiEndpoints } from "constants/api/apiEndpoints";
import { CreateCustomerRequestPayload } from "types/payment/CreateCustomerRequestPayload";
import { CreateSubscriptionRequestPayload } from "types/payment/CreateSubscriptionRequestPayload";
import { GetSubscriptionsResponsePayload } from "types/payment/GetSubscriptionsResponsePayload";
import { EntityId } from "types/EntityId";
import { GetCustomerResponsePayload } from "types/payment/GetCustomerResponsePayload";
import { CreateCustomerResponsePayload } from "types/payment/CreateCustomerResponsePayload";
import { GetConfigResponsePayload } from "types/payment/GetConfigResponsePayload";
import { CreateSubscriptionResponsePayload } from "types/payment/CreateSubscriptionResponsePayload";

const { payment } = apiEndpoints;

class PaymentApiService {
  createCustomer = (data: CreateCustomerRequestPayload) => {
    return API.post<CreateCustomerResponsePayload>(
      payment.createCustomer,
      data,
    );
  };

  createSubscription = (data: CreateSubscriptionRequestPayload) => {
    return API.post<CreateSubscriptionResponsePayload>(
      payment.createSubscription,
      data,
    );
  };

  getSubscriptions = (customerId: EntityId) => {
    return API.get<GetSubscriptionsResponsePayload>(
      payment.getSubscriptions.replace(":customerId", customerId),
    );
  };

  getCustomer = (id: EntityId) => {
    return API.get<GetCustomerResponsePayload>(
      payment.getCustomer.replace(":id", id),
    );
  };

  getConfig = () => {
    return API.get<GetConfigResponsePayload>(payment.getConfig);
  };
}

export default new PaymentApiService();
