import React from "react";
import MaterialTable, { MaterialTableProps } from "material-table";
import { tableIcons } from "constants/tableIcons";
import { CSSProperties } from "@material-ui/styles";
import { Translation } from "react-i18next";
import { Namespaces } from "i18n";
import {
  gBMaterialTableStyles,
  useGBMaterialTableStyles,
} from "./styles/GBMaterialTable.styles";

export type GBMaterialTableProps<RowData extends object> =
  MaterialTableProps<RowData>;

class GBMaterialTable<RowData extends object> extends React.PureComponent<
  GBMaterialTableProps<RowData>
> {
  render() {
    const {
      options = {},
      components = {},
      localization = {},
      ...otherProps
    } = this.props;

    return (
      <Translation ns={Namespaces.Common}>
        {(t) => {
          const classes = useGBMaterialTableStyles();
          return (
            <>
              <MaterialTable
                components={{
                  Container: (props) => (
                    <div className={classes.root}>{props.children}</div>
                  ),
                  ...components,
                }}
                icons={tableIcons}
                options={gBMaterialTableStyles({
                  ...options,
                })}
                localization={{
                  ...(t("material_table", {
                    returnObjects: true,
                  }) || {}),
                  ...localization,
                }}
                {...otherProps}
              />
            </>
          );
        }}
      </Translation>
    );
  }
}

export default GBMaterialTable;

export const iconCellStyles: CSSProperties = {
  maxWidth: 130,
  textAlign: "center",
};
