import { MenuItem } from "@material-ui/core";
import GBInputErrorText from "components/GBInputErrorText/GBInputErrorText";
import GBOutlinedInput from "components/GBOutlinedInput/GBOutlinedInput";
import GBSelect from "components/GBSelect/GBSelect";
import StageStepTitle from "components/StageStepTitle/StageStepTitle";
import { ValidationRule } from "constants/validationRules";
import { FormikProps } from "formik";
import { Namespaces } from "i18n";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ImpactPromiseBuildFormValues } from "../../ImpactPromiseBuild.types";
import { useBuildFormStyles } from "./styles/BuildForm.styles";

type BuildFormProps = FormikProps<ImpactPromiseBuildFormValues> & {};

function BuildForm(props: BuildFormProps) {
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    errors,
    setFieldValue,
  } = props;

  const classes = useBuildFormStyles();
  const { t } = useTranslation(Namespaces.Impact);

  const hasError = (name: keyof ImpactPromiseBuildFormValues): boolean => {
    return !!(touched[name] && errors[name]);
  };

  const getError = (
    field: keyof ImpactPromiseBuildFormValues,
    errorCode: ValidationRule,
  ) => {
    return t([
      `promise.build.form.fields.${field}.errors.${errorCode}`,
      `${Namespaces.Common}:errors.${errorCode}`,
    ]);
  };

  useEffect(() => {
    setFieldValue(
      "finalPromise",
      t("promise.build.form.fields.finalPromise.text", {
        verb: values.promiseVerb.toLowerCase(),
        component: values.promiseComponent.toLowerCase(),
      }),
    );
  }, [setFieldValue, t, values.promiseComponent, values.promiseVerb]);

  const promiseVerbs = useMemo(
    () => [
      t("promise.build.promise_verbs.verb1"),
      t("promise.build.promise_verbs.verb2"),
    ],
    [t],
  );

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <StageStepTitle gutter="16px">{values.purposeFinal}</StageStepTitle>
      <StageStepTitle gutter="16px">
        {t("promise.build.form.title")}
      </StageStepTitle>
      <div className={classes.flexBox}>
        <div className={classes.promiseVerb}>
          <GBSelect
            fullWidth
            className={classes.promiseVerb}
            id="promiseVerb"
            name="promiseVerb"
            value={values.promiseVerb}
            onChange={handleChange}
            onBlur={handleBlur}
            error={hasError("promiseVerb")}
          >
            {promiseVerbs.map((v, k) => (
              <MenuItem key={k} value={v}>
                {v}
              </MenuItem>
            ))}
          </GBSelect>
          {hasError("promiseVerb") && (
            <GBInputErrorText
              text={getError(
                "promiseVerb",
                errors.promiseVerb as ValidationRule,
              )}
            />
          )}
        </div>
        <div className={classes.promiseComponent}>
          <GBOutlinedInput
            id="promiseComponent"
            fullWidth
            name="promiseComponent"
            type="text"
            value={values.promiseComponent}
            onChange={handleChange}
            onBlur={handleBlur}
            error={hasError("promiseComponent")}
          />
          {hasError("promiseComponent") && (
            <GBInputErrorText
              text={getError(
                "promiseComponent",
                errors.promiseComponent as ValidationRule,
              )}
            />
          )}
        </div>
      </div>
    </form>
  );
}

export default BuildForm;
