import { ValidationRule } from "constants/validationRules";
import * as Yup from "yup";
import { PaymentStepFormValues } from "./PaymentStep.types";

export const paymentStepFormInitialValues: PaymentStepFormValues = {
  cardData: false,
};

export const paymentStepFormValidationSchema = Yup.object({
  cardData: Yup.boolean().oneOf([true], ValidationRule.Required),
});
