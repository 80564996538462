import Details from "components/GBMTDetailPanel/GBMTDetailPanel";
import { Namespaces } from "i18n";
import { useTranslation } from "react-i18next";
import { IPartner } from "types/admin/partners/IPartner";
import { prepareDate } from "utils/prepareDate";

type DetailPanelProps = {
  rowData: IPartner;
};

function DetailPanel(props: DetailPanelProps) {
  const { rowData } = props;

  const { t } = useTranslation([Namespaces.Admin, Namespaces.Common]);

  return (
    <Details>
      <Details.Row>
        <Details.Col bold>
          {t("partners.table.detailPanel.fields.id")}
        </Details.Col>
        <Details.Col>{rowData._id}</Details.Col>
      </Details.Row>
      <Details.Row>
        <Details.Col bold>
          {t("partners.table.detailPanel.fields.firstName")}
        </Details.Col>
        <Details.Col>{rowData.first_name}</Details.Col>
      </Details.Row>
      <Details.Row>
        <Details.Col bold>
          {t("partners.table.detailPanel.fields.lastName")}
        </Details.Col>
        <Details.Col>{rowData.last_name}</Details.Col>
      </Details.Row>
      <Details.Row>
        <Details.Col bold>
          {t("partners.table.detailPanel.fields.email")}
        </Details.Col>
        <Details.Col>{rowData.email}</Details.Col>
      </Details.Row>
      <Details.Row>
        <Details.Col bold>
          {t("partners.table.detailPanel.fields.paymentStatus")}
        </Details.Col>
        <Details.Col>
          {rowData.payment_status === "payed"
            ? t(`${Namespaces.Common}:payment_status.paid`)
            : rowData.payment_status === "not_payed"
            ? t(`${Namespaces.Common}:payment_status.notPaid`)
            : t(`${Namespaces.Common}:payment_status.pending`)}
        </Details.Col>
      </Details.Row>
      <Details.Row>
        <Details.Col bold>
          {t("partners.table.detailPanel.fields.initialLicences")}
        </Details.Col>
        <Details.Col>{rowData.initialRegistrationsLimit}</Details.Col>
      </Details.Row>
      <Details.Row>
        <Details.Col bold>
          {t("partners.table.detailPanel.fields.currentLicences")}
        </Details.Col>
        <Details.Col>{rowData.currentRegistrationsLimit}</Details.Col>
      </Details.Row>
      <Details.Row>
        <Details.Col bold>
          {t("partners.table.detailPanel.fields.code")}
        </Details.Col>
        <Details.Col>{rowData.advCode}</Details.Col>
      </Details.Row>
      <Details.Row>
        <Details.Col bold>
          {t("partners.table.detailPanel.fields.createdAt")}
        </Details.Col>
        <Details.Col>{prepareDate(rowData.createdAt)}</Details.Col>
      </Details.Row>
    </Details>
  );
}

export default DetailPanel;
