import { Alert } from "@material-ui/lab";
import FormRow from "components/FormRow/FormRow";
import GBCircularProgress from "components/GBCircularProgress/GBCircularProgress";
import GBModal from "components/GBModal/GBModal";
import GBOutlinedInput from "components/GBOutlinedInput/GBOutlinedInput";
import GBSpin from "components/GBSpin/GBSpin";
import GBStaticInputLabel from "components/GBStaticInputLabel/GBStaticInputLabel";
import RangeQuizField from "components/RangeQuizField/RangeQuizField";
import StageStepButton from "components/StageStepButton/StageStepButton";
import StageStepContainer from "components/StageStepContainer/StageStepContainer";
import StageStepFrame from "components/StageStepFrame/StageStepFrame";
import StageStepTitle from "components/StageStepTitle/StageStepTitle";
import UnexpectedError from "components/UnexpectedError/UnexpectedError";
import { ApiErrorKey } from "constants/api/apiErrors";
import { useFormik } from "formik";
import { Namespaces } from "i18n";
import { useTranslation } from "react-i18next";
import { resultFormValidationSchema } from "./ImpactPurposeResult.consts";
import {
  ImpactPurposeResultFormValues,
  ImpactPurposeFinalFormValues,
} from "./ImpactPurposeResult.types";
import { useImpactPurposeResultStyles } from "./styles/ImpactPurposeResult.styles";

type ImpactPurposeResultViewProps = {
  onFormSubmit: (data: ImpactPurposeResultFormValues) => void;
  initialValues: ImpactPurposeFinalFormValues;
  prevUrl: string;
  isUpdating: boolean;
  isFetching: boolean;
  isFetchError: boolean;
  getApiError: () => ApiErrorKey | undefined;
  isCompleted: boolean;
  isMessage: boolean;
};

function ImpactPurposeResultView(props: ImpactPurposeResultViewProps) {
  const {
    onFormSubmit,
    initialValues,
    prevUrl,
    isUpdating,
    isFetching,
    isFetchError,
    getApiError,
    isCompleted,
    isMessage,
  } = props;

  const { t } = useTranslation([Namespaces.Impact, Namespaces.Survey]);
  const classes = useImpactPurposeResultStyles();

  const formik = useFormik<ImpactPurposeResultFormValues>({
    enableReinitialize: true,
    validationSchema: resultFormValidationSchema,
    initialValues: {
      ...initialValues,
      feedbackRate: 0,
      teamFeedback: "",
    },
    onSubmit: onFormSubmit,
  });

  const { values, handleChange, handleBlur, setFieldValue } = formik;

  return (
    <>
      <StageStepFrame
        title={t("purpose.title")}
        variant={isCompleted ? "success" : undefined}
        controls={[
          <StageStepButton.Back
            goTo={prevUrl}
            disabled={isFetching || isUpdating}
          >
            {t(`${Namespaces.Survey}:buttons.back`)}
          </StageStepButton.Back>,
          <StageStepButton.Next
            onClick={() => formik.handleSubmit()}
            disabled={isFetching || isUpdating || !isCompleted}
            completed
            noArrow
          >
            {t(`${Namespaces.Survey}:buttons.complete`)}
          </StageStepButton.Next>,
        ]}
        error={getApiError()}
        childrenClassName={classes.children}
      >
        <StageStepContainer>
          {isFetching ? (
            <GBCircularProgress keepHeight="360px" />
          ) : isFetchError ? (
            <UnexpectedError />
          ) : (
            <GBSpin spinning={isUpdating}>
              {isCompleted ? (
                <>
                  <StageStepTitle>{t("purpose.result.title")}</StageStepTitle>
                  <FormRow>
                    <GBStaticInputLabel
                      labelFor="purposeFinal"
                      variant="small"
                      text={values.purposeFinal}
                    />
                  </FormRow>
                </>
              ) : (
                <Alert severity="warning">
                  {t(`${Namespaces.Survey}:warnings.uncompleted_steps`)}
                </Alert>
              )}
            </GBSpin>
          )}
        </StageStepContainer>
      </StageStepFrame>
      <GBModal
        open={isMessage}
        title="Congratulations!"
        size="medium"
        withTitleDivider
      >
        <p>
          Congratulations on completing Define my Purpose. You should have an
          email with your purpose statement draft. You can revisit this exercise
          at any time.
          <br />
          <br />
          You can now close this window.
        </p>
      </GBModal>
    </>
  );
}

export default ImpactPurposeResultView;
