import {
  CalculateScoreFormValues,
  CalculateScoreQuizField,
} from "./CalculateScoreForm.types";
import * as Yup from "yup";
import { emailRegExp } from "constants/regExps";
import { ValidationRule } from "constants/validationRules";
import { TEXT_INPUT_MAX_SYMBOLS } from "constants/settings";

export const calculateScoreQuizFields = Object.values(CalculateScoreQuizField);

export const calculateScoreQuizFieldSettings = {
  init: 0,
  smallest: 1,
  highest: 6,
};

export const calculateScoreFormInitEmptyValues: CalculateScoreFormValues = {
  name: "",
  ageRange: "",
  email: "",
  day: calculateScoreQuizFieldSettings.init,
  life: calculateScoreQuizFieldSettings.init,
  lifeIncludes: calculateScoreQuizFieldSettings.init,
  personalExistence: calculateScoreQuizFieldSettings.init,
  goals: calculateScoreQuizFieldSettings.init,
  dreams: calculateScoreQuizFieldSettings.init,
  legacy: calculateScoreQuizFieldSettings.init,
  lifeControl: calculateScoreQuizFieldSettings.init,
  currentMissions: calculateScoreQuizFieldSettings.init,
  purpose: calculateScoreQuizFieldSettings.init,
};

export const calculateScoreFormValidationSchema = Yup.object({
  name: Yup.string()
    .trim()
    .required(ValidationRule.Required)
    .max(TEXT_INPUT_MAX_SYMBOLS, ValidationRule.TextInputMax),
  ageRange: Yup.string().trim().required(ValidationRule.Required),
  email: Yup.string()
    .trim()
    .matches(emailRegExp, ValidationRule.InvalidEmailFormat)
    .required(ValidationRule.RequiredEmail)
    .max(TEXT_INPUT_MAX_SYMBOLS, ValidationRule.TextInputMax),
  day: Yup.number()
    .moreThan(calculateScoreQuizFieldSettings.init)
    .required(ValidationRule.Required),
  life: Yup.number()
    .moreThan(calculateScoreQuizFieldSettings.init)
    .required(ValidationRule.Required),
  lifeIncludes: Yup.number()
    .moreThan(calculateScoreQuizFieldSettings.init)
    .required(ValidationRule.Required),
  personalExistence: Yup.number()
    .moreThan(calculateScoreQuizFieldSettings.init)
    .required(ValidationRule.Required),
  goals: Yup.number()
    .moreThan(calculateScoreQuizFieldSettings.init)
    .required(ValidationRule.Required),
  dreams: Yup.number()
    .moreThan(calculateScoreQuizFieldSettings.init)
    .required(ValidationRule.Required),
  legacy: Yup.number()
    .moreThan(calculateScoreQuizFieldSettings.init)
    .required(ValidationRule.Required),
  lifeControl: Yup.number()
    .moreThan(calculateScoreQuizFieldSettings.init)
    .required(ValidationRule.Required),
  currentMissions: Yup.number()
    .moreThan(calculateScoreQuizFieldSettings.init)
    .required(ValidationRule.Required),
  purpose: Yup.number()
    .moreThan(calculateScoreQuizFieldSettings.init)
    .required(ValidationRule.Required),
});
