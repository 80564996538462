import { Alert } from "@material-ui/lab";
import GBCircularProgress from "components/GBCircularProgress/GBCircularProgress";
import StageStepButton from "components/StageStepButton/StageStepButton";
import StageStepContainer from "components/StageStepContainer/StageStepContainer";
import StageStepFrame from "components/StageStepFrame/StageStepFrame";
import StageStepTitle from "components/StageStepTitle/StageStepTitle";
import UnexpectedError from "components/UnexpectedError/UnexpectedError";
import { Namespaces } from "i18n";
import { Trans, useTranslation } from "react-i18next";
import { EmotionalIntlScoreLevel } from "utils/getEmotionalIntlScoreI18nKey";
import { useEmotionalIntlResultStyles } from "./styles/EmotionalIntlResult.styles";
import cx from "classnames";
import StageStepText from "components/StageStepText/StageStepText";
import GBModal from "components/GBModal/GBModal";

type EmotionalIntlResultViewProp = {
  isSuccess: boolean;
  prevUrl: string | undefined;
  isLoading: boolean;
  onComplete: () => void;
  isError: boolean;
  score: number;
  scoreI18nKey: EmotionalIntlScoreLevel;
  isMessage: boolean;
};

function EmotionalIntlResultView(props: EmotionalIntlResultViewProp) {
  const {
    isSuccess,
    prevUrl,
    isLoading,
    onComplete,
    isError,
    score,
    scoreI18nKey,
    isMessage,
  } = props;

  const { t } = useTranslation([Namespaces.Score, Namespaces.Survey]);
  const classes = useEmotionalIntlResultStyles();

  const scoreClasses = cx(classes.accent, {
    [classes.colorRed]: scoreI18nKey === EmotionalIntlScoreLevel.CRITICAL,
    [classes.colorYellow]: scoreI18nKey === EmotionalIntlScoreLevel.LOW,
    [classes.colorBlue]: scoreI18nKey === EmotionalIntlScoreLevel.MIDDLE,
    [classes.colorGreen]: scoreI18nKey === EmotionalIntlScoreLevel.HIGH,
  });

  return (
    <>
      <StageStepFrame
        title={t("emotionalIntl.title")}
        variant={isSuccess ? "success" : undefined}
        controls={[
          <StageStepButton.Back goTo={prevUrl} disabled={isLoading}>
            {t(`${Namespaces.Survey}:buttons.back`)}
          </StageStepButton.Back>,
          <StageStepButton.Next
            completed
            onClick={onComplete}
            disabled={isLoading || !isSuccess}
            noArrow
          >
            {t(`${Namespaces.Survey}:buttons.complete`)}
          </StageStepButton.Next>,
        ]}
      >
        <StageStepContainer>
          {isLoading ? (
            <GBCircularProgress keepHeight="333px" />
          ) : isError ? (
            <UnexpectedError />
          ) : isSuccess ? (
            <>
              <StageStepTitle>{t("emotionalIntl.result.title")}</StageStepTitle>
              <div className={classes.scoreWrap}>
                <Trans
                  i18nKey={t("emotionalIntl.result.score", {
                    score: score.toFixed(1),
                  })}
                  components={{
                    accent: <span className={scoreClasses} />,
                  }}
                />
              </div>
              <StageStepText className={classes.text}>
                {t(`emotionalIntl.result.results.${scoreI18nKey}`)}
              </StageStepText>
            </>
          ) : (
            <Alert severity="warning">
              {t(`${Namespaces.Survey}:warnings.uncompleted_steps`)}
            </Alert>
          )}
        </StageStepContainer>
      </StageStepFrame>
      <GBModal
        open={isMessage}
        title="Congratulations!"
        size="medium"
        withTitleDivider
      >
        <p>
          Congratulations on completing EQ Score.
          <br />
          <br />
          You can now close this window.
        </p>
      </GBModal>
    </>
  );
}

export default EmotionalIntlResultView;
