import { useTranslation } from "react-i18next";
import { Namespaces } from "i18n";
import React, { useMemo } from "react";
import StageStepFrame from "components/StageStepFrame/StageStepFrame";
import StageStepButton from "components/StageStepButton/StageStepButton";
import GBCircularProgress from "components/GBCircularProgress/GBCircularProgress";
import UnexpectedError from "components/UnexpectedError/UnexpectedError";
import GBSpin from "components/GBSpin/GBSpin";
import { IDENTITIES } from "constants/identities";
import {
  DNAFormValues,
  IdentityType,
  IdentityTypeEnum,
  IdentityTypeEnumKeys,
  IdentityVerb,
} from "types/score/DNA";
import { MenuItem } from "@material-ui/core";
import GBSelect from "components/GBSelect/GBSelect";
import GBTypography from "components/GBTypography/GBTypography";
import _ from "lodash";
import { useScoreStyles } from "../../../Score/styles/Score.styles";

type IdentityVerbs = {
  [id: string]: Array<string>;
};

type DnaVerbsViewProps = {
  onValueChange: (value: Array<IdentityVerb>) => void;
  values: DNAFormValues;
  isFetching: boolean;
  isFetchError: boolean;
  onStepNext: () => void;
  onStepBack: () => void;
};

function DnaVerbsView(props: DnaVerbsViewProps) {
  const {
    onValueChange,
    isFetching,
    isFetchError,
    values,
    onStepNext,
    onStepBack,
  } = props;

  const { t } = useTranslation([
    Namespaces.Score,
    Namespaces.Survey,
    Namespaces.Common,
  ]);
  const classes = useScoreStyles();

  const verbs = useMemo(() => {
    const result: IdentityVerbs = {};
    values.identities.forEach((val) => {
      const currVerbs = values.impacts
        ? values.impacts
            .filter((impact) => impact.identity === val)
            .map((verb) => verb.verb)
        : Array(3).fill("");
      result[val] = new Array(3).fill("").map((text, key) => {
        return currVerbs[key] || "";
      });
    });
    return result;
  }, [values.identities, values.impacts]);

  const getIdentityKeys = (): Array<
    [IdentityTypeEnumKeys, IdentityTypeEnum]
  > => {
    const result: Array<[IdentityTypeEnumKeys, IdentityTypeEnum]> = [];
    _.each(IdentityTypeEnum, (identity, identityKey) => {
      if (values.identities && values.identities.indexOf(identity) !== -1) {
        result.push([
          identityKey as IdentityTypeEnumKeys,
          identity as IdentityTypeEnum,
        ]);
      }
    });
    return result;
  };

  const getSelectValues = (identity: IdentityTypeEnumKeys, key: number) => {
    const identityVal: IdentityTypeEnum = IdentityTypeEnum[identity];
    const currIdentity = IDENTITIES[identity] as IdentityType;
    if (currIdentity) {
      return currIdentity.verbs.map((verb) => {
        const value = verb;
        const text = t(`${Namespaces.Identity}:${identityVal}.verbs.${verb}`);
        let disabled = false;
        if (verbs[identityVal]) {
          const index = verbs[identityVal].indexOf(value);
          if ([-1, key].indexOf(index) === -1) {
            disabled = true;
          }
        }
        return {
          value,
          text,
          disabled,
        };
      });
    }
    return [];
  };

  const getVerbValue = (identity: IdentityTypeEnum, key: number): string => {
    if (!values.impacts) return "";
    const identityValues = values.impacts
      .filter((val) => val.identity === identity)
      .map((val) => val.verb);
    return identityValues[key] || "";
  };

  const getIdentityTitle = (identity: IdentityTypeEnumKeys): string => {
    return IDENTITIES[identity] ? t(IDENTITIES[identity].verbsTitle) : identity;
  };

  const handleChange = (
    event: React.ChangeEvent<any>,
    identity: IdentityTypeEnumKeys,
    key: number,
  ) => {
    const identityVal: IdentityTypeEnum = IdentityTypeEnum[identity];
    verbs[identityVal][key] = event.target.value;
    const result: Array<IdentityVerb> = [];
    Object.entries(verbs).forEach(([key, val]) => {
      val.forEach((verb, index) => {
        result.push({
          identity: key,
          verb,
          rank: index,
        } as IdentityVerb);
      });
    });
    onValueChange(result);
  };

  const formIsValid = () => {
    return (
      values.impacts &&
      values.identities &&
      values.impacts.filter((impact) => {
        return !(
          !impact.verb || values.identities.indexOf(impact.identity) === -1
        );
      }).length === 9
    );
  };

  return (
    <StageStepFrame
      title={t("DNA.verbs.title")}
      childrenClassName={classes.dnaOuterClass}
      controls={[
        <StageStepButton.Back onClick={onStepBack} disabled={isFetching}>
          {t(`${Namespaces.Survey}:buttons.back`)}
        </StageStepButton.Back>,
        <StageStepButton.Next
          onClick={onStepNext}
          disabled={isFetching || !formIsValid()}
        >
          {t(`${Namespaces.Survey}:buttons.continue`)}
        </StageStepButton.Next>,
      ]}
    >
      <div className={classes.subtitle}>{t("DNA.verbs.subtitle")}</div>
      {isFetching ? (
        <GBCircularProgress keepHeight="848px" />
      ) : isFetchError ? (
        <UnexpectedError />
      ) : (
        <GBSpin spinning={isFetching}>
          <div className={classes.wrapper}>
            {getIdentityKeys().map(([key, value]) => (
              <div key={key} className={classes.identityVerbRow}>
                <GBTypography
                  variant="h1"
                  className={classes.identityVerbTitle}
                >
                  {getIdentityTitle(key)}
                </GBTypography>
                <div className={classes.identityVerbInputs}>
                  <GBSelect
                    id={`${key}0`}
                    name={`${key}0`}
                    value={getVerbValue(value, 0)}
                    onChange={(e) => handleChange(e, key, 0)}
                    className={classes.identityVerbSelect}
                  >
                    {getSelectValues(key, 0).map((y, k) => (
                      <MenuItem
                        disabled={y.disabled}
                        key={k}
                        value={y.value.toString()}
                      >
                        {y.text}
                      </MenuItem>
                    ))}
                  </GBSelect>
                  <GBSelect
                    id={`${key}1`}
                    name={`${key}1`}
                    value={getVerbValue(value, 1)}
                    onChange={(e) => handleChange(e, key, 1)}
                    className={classes.identityVerbSelect}
                  >
                    {getSelectValues(key, 1).map((y, k) => (
                      <MenuItem
                        disabled={y.disabled}
                        key={k}
                        value={y.value.toString()}
                      >
                        {y.text}
                      </MenuItem>
                    ))}
                  </GBSelect>
                  <GBSelect
                    id={`${key}2`}
                    name={`${key}2`}
                    value={getVerbValue(value, 2)}
                    onChange={(e) => handleChange(e, key, 2)}
                    className={classes.identityVerbSelect}
                  >
                    {getSelectValues(key, 2).map((y, k) => (
                      <MenuItem
                        disabled={y.disabled}
                        key={k}
                        value={y.value.toString()}
                      >
                        {y.text}
                      </MenuItem>
                    ))}
                  </GBSelect>
                </div>
              </div>
            ))}
          </div>
        </GBSpin>
      )}
    </StageStepFrame>
  );
}
export default DnaVerbsView;
