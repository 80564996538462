import GBCircularProgress from "components/GBCircularProgress/GBCircularProgress";
import React from "react";
import { useContentSpinnerStyles } from "./styles/ContentSpinner.styles";

type ContentSpinnerProps = {
  children: React.ReactNode;
  spinning: boolean;
};

function ContentSpinner(props: ContentSpinnerProps) {
  const { children, spinning } = props;
  const classes = useContentSpinnerStyles();

  return (
    <div className={classes.wrapper}>
      {spinning ? (
        <>
          <div className={classes.spinnerOverlay}>
            <GBCircularProgress />
          </div>
          <div className={classes.childrenWrap}>{children}</div>
        </>
      ) : (
        children
      )}
    </div>
  );
}

export default ContentSpinner;
