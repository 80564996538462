import { ImpactPurpose } from "types/impact/ImpactPurpose";
import { ImpactPurposeStory2FormValues } from "./ImpactPurposeStory2.types";

export const prepareInitialValues = (
  data?: ImpactPurpose,
): ImpactPurposeStory2FormValues => ({
  college: data?.college || "",
  major: data?.major || "",
  industry: data?.industry || "",
  position: data?.position || "",
  childLove: data?.childLove || "",
  career: data?.career || "",
});
