import React from "react";
import { MAIN_MENU } from "constants/mainMenu";
import { useMainMenuPageStyles } from "./styles/MainMenu.styles";
import { useTranslation } from "react-i18next";
import { Namespaces } from "../../i18n";
import cx from "classnames";
import UserMenuItem from "../../components/UserMenuItem/UserMenuItem";
import { useHistory } from "react-router";
import GBCustomButton from "components/GBCustomButton/GBCustomButton";
import { generalRoutes } from "constants/generalRoutes";
import { useTheme } from "@material-ui/core";

function MainMenuView() {
  const { t } = useTranslation([Namespaces.Common]);
  const history = useHistory();
  const classes = useMainMenuPageStyles();
  const redirectTo = (url?: string) => {
    url && history.push(url);
  };

  return (
    <div className={classes.root}>
      <UserMenuItem
        isButton
        className={classes.menuItem}
        icon={MAIN_MENU.SCORE.icon}
        title={t(MAIN_MENU.SCORE.title)}
        description={t(MAIN_MENU.SCORE.description)}
        onClick={() => redirectTo(MAIN_MENU.SCORE.url)}
      />
      <UserMenuItem
        isButton
        className={classes.menuItem}
        icon={MAIN_MENU.ALIGN.icon}
        title={t(MAIN_MENU.ALIGN.title)}
        description={t(MAIN_MENU.ALIGN.description)}
        onClick={() => redirectTo(MAIN_MENU.ALIGN.url)}
      />
      <UserMenuItem
        isButton
        className={classes.menuItem}
        icon={MAIN_MENU.IMPACT.icon}
        title={t(MAIN_MENU.IMPACT.title)}
        description={t(MAIN_MENU.IMPACT.description)}
        onClick={() => redirectTo(MAIN_MENU.IMPACT.url)}
      />
    </div>
  );
}

export default MainMenuView;
