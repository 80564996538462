import { Redirect, Route, RouteProps, useLocation } from "react-router";
import { ComponentType, createElement, useEffect } from "react";
import { RootState } from "store";
import RenderChildren from "components/RenderChildren/RenderChildren";
import { useDispatch, useSelector } from "react-redux";
import { UserType } from "constants/userTypes";
import GBCircularProgress from "components/GBCircularProgress/GBCircularProgress";
import { generalRoutes } from "constants/generalRoutes";
import AppModals from "components/AppModals/AppModals";
import { authModalActions } from "store/authModal/actions";
import { ACCESS_GATED_URLS } from "constants/gatedUrls";

type GBRouteProps = RouteProps & {
  layout?: ComponentType<any>;
  privateRoute?: boolean;
  superAdminRoute?: boolean;
  partnerRoute?: boolean;
  payedPartnerRoute?: boolean;
};

const GBRoute = (props: GBRouteProps) => {
  const {
    layout = RenderChildren,
    component,
    render,
    children,
    privateRoute,
    superAdminRoute,
    partnerRoute,
    payedPartnerRoute,
    ...restProps
  } = props;

  const auth = useSelector((state: RootState) => state.auth);
  const location = useLocation();
  const dispatch = useDispatch();
  const hasAccess = useSelector((state: RootState) => state.authModal.open);

  const isGatedUrl = () => {
    for (const path of location.pathname.split("/")) {
      const isGated = ACCESS_GATED_URLS.includes(path);
      const params = new URLSearchParams(location.search) as any;
      console.log(params?.get("token"));
      if (isGated && !params?.get("token")) return true;
    }
    return false;
  };

  useEffect(() => {
    if (isGatedUrl()) {
      dispatch(
        authModalActions.open(
          location.pathname.split("/").pop() || location.pathname,
        ),
      );
    }
  }, [location]);

  return (
    <Route
      {...restProps}
      render={(matchProps) => {
        const { isAuthenticated: isAuthed, user } = auth;
        const isPartner = isAuthed && user?.userType === UserType.Partner;
        const isPayedPartner =
          isAuthed && isPartner && user?.paymentStatus === "payed";
        const isSuperAdmin = isAuthed && user?.userType === UserType.SuperAdmin;

        if (
          (superAdminRoute || partnerRoute || payedPartnerRoute) &&
          !auth.user &&
          auth.isAuthenticated
        ) {
          return <GBCircularProgress />;
        }

        if (hasAccess) return <AppModals />;
        if (
          (privateRoute && !isAuthed) ||
          (payedPartnerRoute && !isPayedPartner) ||
          (superAdminRoute && !isSuperAdmin) ||
          (partnerRoute && !isPartner)
        ) {
          return <Redirect to={generalRoutes.USER_MENU} />;
        }
        if (render) {
          return createElement(layout, matchProps, render(matchProps));
        }
        return createElement(
          layout,
          matchProps,
          component ? createElement(component, matchProps) : children,
        );
      }}
    />
  );
};

export default GBRoute;
