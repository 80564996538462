import React from "react";
import { QueryKeys } from "constants/api/queryKeys";
import { AxiosResponse } from "axios";
import { AlignResponsePayload } from "types/align/GetAlignResponsePayload";
import { ImpactResponsePayload } from "types/impact/GetImpactResponsePayload";
import { PurposeFormValues } from "types/score/ScoreForm.types";
import { ApiResponseType } from "types/api/ApiResponseType";
import { GrowResponsePayload } from "./grow/GetGrowResponsePayload";

export type FetchSurveyResponseType =
  ApiResponseType<FetchSurveyResponsePayload>;

export type FetchSurveyResponsePayload =
  | AlignResponsePayload
  | PurposeFormValues
  | ImpactResponsePayload
  | GrowResponsePayload;

export type FetchParamType = {
  key: QueryKeys;
  fn: () => Promise<AxiosResponse<FetchSurveyResponseType>>;
};

export enum SurveyTypeEnum {
  SCORE = "score",
  ALIGN = "align",
  IMPACT = "impact",
  GROW = "grow",
  ENGAGE = "engage",
}

export type SurveyState = {
  [key: string]: { [id: string]: boolean };
};

export type StepType = {
  fetchInfoNeeded?: boolean;
  path: string;
  component?: React.ComponentType<any>;
};

export type StageType = {
  title?: string;
  description?: string;
  isPaid?: boolean;
  path?: string;
  dataPath?: string;
  steps: { [id: string]: StepType };
};

export type SurveyType = {
  title: string;
  description: string;
  icon: React.ComponentType<any>;
  url?: string;
  path?: SurveyTypeEnum;
  fetchParam?: FetchParamType;
  stages?: { [id: string]: StageType };
};

export type MainMenuType = {
  [key in keyof typeof SurveyTypeEnum]: SurveyType;
};
