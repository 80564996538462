import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { APP_DEFAULT_LANGUAGE } from "constants/settings";
import { translationsEN } from "translations/translationsEN";
import { Languages } from "constants/appLanguages";

const resources = {
  en: translationsEN,
};

export enum Namespaces {
  Admin = "admin",
  Common = "common",
  Auth = "auth",
  NotFound = "notFound",
  Identity = "identity",
  Score = "score",
  Align = "align",
  Survey = "survey",
  PurposeScore = "purposeScore",
  Impact = "impact",
  Grow = "grow",
  Snapshot = "snapshot",
}

const createI18nInstance = ({
  language = APP_DEFAULT_LANGUAGE,
}: {
  language?: string;
}) => {
  const instance = i18next.createInstance();
  instance.use(initReactI18next).init({
    lng: language,
    fallbackLng: APP_DEFAULT_LANGUAGE,
    supportedLngs: Object.values(Languages),
    resources,
    ns: Object.values(Namespaces),
    load: "languageOnly",
    react: {
      useSuspense: true,
      bindI18n: "languageChanged",
    },
    interpolation: {
      escapeValue: false,
    },
  });

  return instance;
};

const i18n = createI18nInstance({});

export default i18n;
