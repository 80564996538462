import cx from "classnames";
import { useTitleHintStyles } from "./styles/TitleHint.styles";

type TitleHintProps = {
  variant?: "tiny";
  text: string;
  className?: string;
};

function TitleHint(props: TitleHintProps) {
  const { text, className, variant } = props;
  const classes = useTitleHintStyles();
  const rootClasses = cx(
    classes.root,
    {
      [classes.tiny]: variant === "tiny",
    },
    className,
  );
  return <span className={rootClasses}>{text}</span>;
}

export default TitleHint;
