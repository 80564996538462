import { API } from "utils/api/http-client";
import { apiEndpoints } from "constants/api/apiEndpoints";
import { GetAlignResponsePayload } from "types/align/GetAlignResponsePayload";
import { PostAlignRequestPayload } from "types/align/PostAlignRequestPayload";
import { GetAlignResultResponsePayload } from "types/align/GetAlignResultResponsePayload";

const { align } = apiEndpoints;

class AlignApiService {
  getAlign = () => {
    return API.get<GetAlignResponsePayload>(align.getAlign);
  };

  postAlign = (data: PostAlignRequestPayload) => {
    return API.post(align.postAlign, data);
  };

  getResult = () => {
    return API.get<GetAlignResultResponsePayload>(align.getResult);
  };
}

export default new AlignApiService();
