import { useTranslation } from "react-i18next";
import { Namespaces } from "i18n";
import StageStepFrame from "components/StageStepFrame/StageStepFrame";
import StageStepButton from "components/StageStepButton/StageStepButton";
import GBCircularProgress from "components/GBCircularProgress/GBCircularProgress";
import UnexpectedError from "components/UnexpectedError/UnexpectedError";
import GBSpin from "components/GBSpin/GBSpin";
import { IDENTITIES } from "constants/identities";
import QuizTile from "components/QuizTile/QuizTile";
import { IdentityTypeEnum } from "types/score/DNA";
import { useScoreStyles } from "../../../Score/styles/Score.styles";

type DnaIdentitiesViewProps = {
  onValueChange: (value: Array<string>) => void;
  values: Array<string>;
  isFetching: boolean;
  isUpdating: boolean;
  isFetchError: boolean;
  onStepNext: () => void;
  onStepBack: () => void;
};

function DnaIdentitiesView(props: DnaIdentitiesViewProps) {
  const {
    onValueChange,
    isFetching,
    isUpdating,
    isFetchError,
    values,
    onStepNext,
    onStepBack,
  } = props;

  const { t } = useTranslation([
    Namespaces.Score,
    Namespaces.Survey,
    Namespaces.Common,
  ]);
  const classes = useScoreStyles();

  const handleSelect = (key: string) => {
    const identity: IdentityTypeEnum = (IdentityTypeEnum as any)[key];
    if (identity) {
      if (values.indexOf(identity) === -1) {
        if (values.length < 3) {
          onValueChange([...values, identity]);
        }
      } else {
        onValueChange(values.filter((val) => val !== identity));
      }
    }
  };
  const isSelected = (key: string): boolean => {
    const identity: IdentityTypeEnum = (IdentityTypeEnum as any)[key];
    if (identity) {
      return values.indexOf(identity) !== -1;
    }
    return false;
  };

  return (
    <StageStepFrame
      title={t("DNA.identities.title")}
      controls={[
        <StageStepButton.Back
          onClick={onStepBack}
          disabled={isFetching || isUpdating}
        >
          {t(`${Namespaces.Survey}:buttons.back`)}
        </StageStepButton.Back>,
        <StageStepButton.Next
          onClick={onStepNext}
          disabled={isFetching || isUpdating || !(values.length === 3)}
        >
          {t(`${Namespaces.Score}:DNA.identities.buttons.submit`, {
            count: values.length,
          })}
        </StageStepButton.Next>,
      ]}
    >
      <div className={classes.subtitle}>
        {t("DNA.identities.subtitle", { count: values.length })}
      </div>
      {isFetching ? (
        <GBCircularProgress keepHeight="848px" />
      ) : isFetchError ? (
        <UnexpectedError />
      ) : (
        <GBSpin spinning={isUpdating}>
          <div className={classes.wrapper}>
            {Object.entries(IDENTITIES).map(([key, identity]) => (
              <QuizTile
                key={key}
                selected={isSelected(key)}
                onClick={() => handleSelect(key)}
                className={classes.identityItem}
                title={t(identity.title)}
                text={t(identity.description)}
              />
            ))}
          </div>
        </GBSpin>
      )}
    </StageStepFrame>
  );
}
export default DnaIdentitiesView;
