import React from "react";
import { Button, Modal, ModalProps } from "@material-ui/core";
import { useGBModalStyles } from "./styles/GBModal.styles";
import cx from "classnames";
import GBButton from "components/GBButton/GBButton";
import { useTranslation } from "react-i18next";
import { Namespaces } from "i18n";
import ErrorText from "components/ErrorText/ErrorText";

export type GBModalProps = ModalProps & {
  withTitleDivider?: boolean;
  children: JSX.Element;
  header?: JSX.Element;
  footer?: JSX.Element;
  size?: "tiny" | "small" | "medium" | "big" | "large" | "full-screen" | "auto";
  onOk?: () => any;
  onCancel?: () => any;
  okButtonText?: string;
  cancelButtonText?: string;
  title?: string | JSX.Element;
  paperClassName?: string;
  okButtonProps?: React.ComponentProps<typeof Button>;
  cancelButtonProps?: React.ComponentProps<typeof Button>;
  errorText?: string;
};

function GBModal(props: GBModalProps) {
  const {
    withTitleDivider,
    children,
    footer,
    onOk,
    onCancel,
    okButtonText,
    cancelButtonText,
    title,
    size,
    paperClassName,
    open,
    header,
    okButtonProps = {},
    cancelButtonProps = {},
    errorText,
    ...otherProps
  } = props;
  const classes = useGBModalStyles();
  const { t } = useTranslation(Namespaces.Common);
  const headerClasses = cx(classes.header, {
    [classes.titleDivider]: withTitleDivider,
  });

  const renderHeader = () => {
    if (header) {
      return <div className={headerClasses}>{header}</div>;
    } else if (!header && title) {
      return (
        <div className={headerClasses}>
          <div className={classes.title}>{title}</div>
        </div>
      );
    }
    return null;
  };

  const renderFooter = () => {
    if (footer) {
      return <div className={classes.footer}>{footer}</div>;
    } else if (!footer && (onOk || onCancel)) {
      return (
        <div className={classes.footer}>
          <div className={classes.defaultButtonsWrap}>
            {onCancel && (
              <GBButton
                {...cancelButtonProps}
                color="primary"
                size="large"
                variant="text"
                className={classes.defaultButton}
                onClick={onCancel}
              >
                {cancelButtonText || t("labels.cancel")}
              </GBButton>
            )}
            {onOk && (
              <GBButton
                {...okButtonProps}
                color="primary"
                size="large"
                variant="contained"
                onClick={onOk}
                className={classes.defaultButton}
              >
                {okButtonText || t("labels.ok")}
              </GBButton>
            )}
          </div>
          {errorText ? <ErrorText text={errorText} /> : null}
        </div>
      );
    }
    return null;
  };

  return (
    <Modal
      BackdropProps={{ style: { backgroundColor: "#E1E4E9" } }}
      open={open}
      {...otherProps}
      className={cx(classes.modal, {
        [classes.titleDivider]: withTitleDivider,
      })}
    >
      <div
        className={cx(
          classes.paper,
          {
            [classes.sizeTiny]: size === "tiny",
            [classes.sizeSmall]: size === "small",
            [classes.sizeMedium]: size === "medium",
            [classes.sizeBig]: size === "big",
            [classes.sizeLarge]: size === "large",
            [classes.sizeFullScreen]: size === "full-screen",
            [classes.sizeAuto]: size === "auto",
          },
          paperClassName,
        )}
      >
        {renderHeader()}
        <div className={classes.body}>{children}</div>
        {renderFooter()}
      </div>
    </Modal>
  );
}

export default GBModal;
