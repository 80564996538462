import GBCircularProgress from "components/GBCircularProgress/GBCircularProgress";
import HUSpin from "components/GBSpin/GBSpin";
import GBStepsProgress from "components/GBStepsProgress/GBStepsProgress";
import { useSurvey } from "components/hooks/useSurvey";
import StageStepButton from "components/StageStepButton/StageStepButton";
import StageStepContainer from "components/StageStepContainer/StageStepContainer";
import StageStepFrame from "components/StageStepFrame/StageStepFrame";
import StageStepText from "components/StageStepText/StageStepText";
import StageStepTitle from "components/StageStepTitle/StageStepTitle";
import UnexpectedError from "components/UnexpectedError/UnexpectedError";
import { SITUATIONS_STEPS } from "constants/grow/settings";
import { useFormik } from "formik";
import { Namespaces } from "i18n";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import ComfortBeginForm from "./components/ComfortBeginForm";
import { comfortBeginFormValidationSchema } from "./GrowComfortBegin.consts";
import { ComfortBeginFormValues } from "./GrowComfortBegin.types";
import { useGrowComfortBeginStyles } from "./styles/GrowComfortBegin.styles";

type GrowComfortBeginViewProps = {
  onSubmit: (data: ComfortBeginFormValues) => void;
  initialValues: ComfortBeginFormValues;
  isFetching: boolean;
  isFetchError: boolean;
  isUpdating: boolean;
  getApiError: () => string | undefined;
};

function GrowComfortBeginView(props: GrowComfortBeginViewProps) {
  const {
    onSubmit,
    initialValues,
    getApiError,
    isUpdating,
    isFetching,
    isFetchError,
  } = props;
  const { t } = useTranslation([Namespaces.Grow, Namespaces.Survey]);
  const { prevUrl } = useSurvey();

  const classes = useGrowComfortBeginStyles();

  const formik = useFormik<ComfortBeginFormValues>({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: comfortBeginFormValidationSchema,
    onSubmit: onSubmit,
  });

  const getFormError = useCallback(() => {
    const errors = Object.values(formik.errors);
    if (errors.length) {
      return t([
        `steps.comfort_begin.errors.${errors[0]}`,
        `${Namespaces.Common}:errors.${errors[0]}`,
      ]);
    }
    return undefined;
  }, [formik.errors, t]);

  return (
    <StageStepFrame
      title={t("steps.title")}
      controls={[
        <StageStepButton.Back goTo={prevUrl}>
          {t(`${Namespaces.Survey}:buttons.back`)}
        </StageStepButton.Back>,
        <StageStepButton.Next
          onClick={() => formik.handleSubmit()}
          disabled={isFetching || isUpdating}
        >
          {t(`${Namespaces.Survey}:buttons.continue`)}
        </StageStepButton.Next>,
      ]}
      headingDetails={<GBStepsProgress all={SITUATIONS_STEPS} current={1} />}
      error={getApiError() || getFormError()}
    >
      {isFetching ? (
        <GBCircularProgress keepHeight="1321px" />
      ) : isFetchError ? (
        <UnexpectedError />
      ) : (
        <HUSpin spinning={isUpdating}>
          <StageStepContainer>
            <StageStepTitle gutter="16px" color="blue">
              {t("steps.comfort_begin.title")}
            </StageStepTitle>
            <StageStepText>{t("steps.comfort_begin.text")}</StageStepText>
          </StageStepContainer>
          <div className={classes.form}>
            <ComfortBeginForm {...formik} />
          </div>
        </HUSpin>
      )}
    </StageStepFrame>
  );
}

export default GrowComfortBeginView;
