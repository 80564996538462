export enum SnapshotStep {
  Step1 = "step1",
  Step2 = "step2",
}

export enum SnapshotColor {
  Yellow = "yellow",
  Gray = "gray",
  Black = "black",
}
