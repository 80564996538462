import AuthModal from "components/AuthModal/AuthModal";
import ContactUsModal from "components/ContactUsModal/ContactUsModal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { contactUsModalActions } from "store/contactUsModal/actions";
import { authModalActions } from "store/authModal/actions";

function AppModals() {
  const dispatch = useDispatch();
  const contactUsModal = useSelector(
    (state: RootState) => state.contactUsModal,
  );

  const authModal = useSelector((state: RootState) => state.authModal);

  return (
    <>
      <ContactUsModal
        open={contactUsModal.open}
        onClose={() => dispatch(contactUsModalActions.close())}
      />
      <AuthModal
        open={authModal.open}
        onClose={() => dispatch(authModalActions.close())}
      />
    </>
  );
}

export default AppModals;
