import { OutlinedInput, Theme, withStyles } from "@material-ui/core";
import { outlinedInputStyles } from "constants/themeSettings";

export const RedesignedOutlineInput = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: outlinedInputStyles.backgroundColor,
    fontSize: outlinedInputStyles.fontSize,
    lineHeight: outlinedInputStyles.lineHeight,
    fontWeight: outlinedInputStyles.fontWeight,
    color: outlinedInputStyles.color,
    "&:hover:not($focused):not($error) $notchedOutline": {
      borderWidth: outlinedInputStyles.borderWidth,
      borderColor: outlinedInputStyles.borderColor,
    },
    "&:hover&$disabled $notchedOutline": {
      borderColor: outlinedInputStyles.borderColor,
    },
    borderRadius: outlinedInputStyles.borderRadius,
    "&.MuiInputBase-fullWidth": {
      width: "100%",
    },
    "&$disabled": {
      backgroundColor: outlinedInputStyles.disabled.backgroundColor,
      color: outlinedInputStyles.disabled.color,
      cursor: "not-allowed",
      pointerEvents: "none",
    },
    "& $notchedOutline": {
      borderWidth: outlinedInputStyles.borderWidth,
      borderColor: outlinedInputStyles.borderColor,
    },
    "&$disabled $notchedOutline": {
      borderWidth: outlinedInputStyles.borderWidth,
      borderColor: outlinedInputStyles.borderColor,
    },
  },
  input: {
    padding: outlinedInputStyles.padding,
    height: outlinedInputStyles.height,
    "$adornedStart &": {
      paddingLeft: 0,
    },
  },
  adornedStart: {},
  inputMultiline: {
    padding: "0px",
    lineHeight: "22px",
  },
  notchedOutline: {
    borderWidth: outlinedInputStyles.borderWidth,
  },
  focused: {
    $notchedOutline: {
      borderWidth: outlinedInputStyles.borderWidth,
      borderColor: outlinedInputStyles.focused.borderColor,
    },
    caretColor: outlinedInputStyles.focused.borderColor,
  },
  disabled: {},
  error: {
    backgroundColor: outlinedInputStyles.error.backgroundColor,
    "&:not($focused) $notchedOutline": {
      border: `2px solid ${outlinedInputStyles.error.borderColor}`,
    },
  },
}))(OutlinedInput);
