import { useSurvey } from "components/hooks/useSurvey";
import StageStepButton from "components/StageStepButton/StageStepButton";
import StageStepContainer from "components/StageStepContainer/StageStepContainer";
import StageStepFrame from "components/StageStepFrame/StageStepFrame";
import StageStepImage from "components/StageStepImage/StageStepImage";
import StageStepText from "components/StageStepText/StageStepText";
import { Namespaces } from "i18n";
import { useTranslation } from "react-i18next";

function AlignBeginView() {
  const { t } = useTranslation([Namespaces.Align, Namespaces.Survey]);
  const { nextUrl } = useSurvey();

  return (
    <StageStepFrame
      title={t("steps.title")}
      controls={[
        <StageStepButton.Next goTo={nextUrl}>
          {t(`${Namespaces.Survey}:buttons.begin`)}
        </StageStepButton.Next>,
      ]}
    >
      <StageStepContainer>
        <StageStepImage url={t("steps.begin.img")} />
        <StageStepText>{t("steps.begin.text")}</StageStepText>
      </StageStepContainer>
    </StageStepFrame>
  );
}

export default AlignBeginView;
