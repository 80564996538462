import GBCircularProgress from "components/GBCircularProgress/GBCircularProgress";
import GBSpin from "components/GBSpin/GBSpin";
import StageStepButton from "components/StageStepButton/StageStepButton";
import StageStepContainer from "components/StageStepContainer/StageStepContainer";
import StageStepFrame from "components/StageStepFrame/StageStepFrame";
import StageStepTitle from "components/StageStepTitle/StageStepTitle";
import UnexpectedError from "components/UnexpectedError/UnexpectedError";
import { ApiErrorKey } from "constants/api/apiErrors";
import { useFormik } from "formik";
import { Namespaces } from "i18n";
import { useTranslation } from "react-i18next";
import TalentsForm from "./components/TalentsForm";
import { talentsFormValidationSchema } from "./ImpactPurposeTalents.consts";
import { ImpactPurposeTalentsFormValues } from "./ImpactPurposeTalents.types";

type ImpactPurposeTalentsViewProps = {
  onFormSubmit: (data: ImpactPurposeTalentsFormValues) => void;
  initialValues: ImpactPurposeTalentsFormValues;
  prevUrl: string;
  isUpdating: boolean;
  isFetching: boolean;
  isFetchError: boolean;
  getApiError: () => ApiErrorKey | undefined;
};

function ImpactPurposeTalentsView(props: ImpactPurposeTalentsViewProps) {
  const {
    onFormSubmit,
    prevUrl,
    isUpdating,
    isFetching,
    isFetchError,
    initialValues,
    getApiError,
  } = props;

  const { t } = useTranslation([Namespaces.Impact, Namespaces.Survey]);

  const formik = useFormik<ImpactPurposeTalentsFormValues>({
    enableReinitialize: true,
    validationSchema: talentsFormValidationSchema,
    initialValues,
    onSubmit: onFormSubmit,
  });

  return (
    <StageStepFrame
      title={t("purpose.title")}
      controls={[
        <StageStepButton.Back
          goTo={prevUrl}
          disabled={isFetching || isUpdating}
        >
          {t(`${Namespaces.Survey}:buttons.back`)}
        </StageStepButton.Back>,
        <StageStepButton.Next
          onClick={() => formik.handleSubmit()}
          disabled={isFetching || isUpdating}
        >
          {t(`${Namespaces.Survey}:buttons.continue`)}
        </StageStepButton.Next>,
      ]}
      error={getApiError()}
    >
      <StageStepContainer>
        {isFetching ? (
          <GBCircularProgress keepHeight="336px" />
        ) : isFetchError ? (
          <UnexpectedError />
        ) : (
          <GBSpin spinning={isUpdating}>
            <StageStepTitle>{t("purpose.talents.title")}</StageStepTitle>
            <TalentsForm {...formik} />
          </GBSpin>
        )}
      </StageStepContainer>
    </StageStepFrame>
  );
}

export default ImpactPurposeTalentsView;
