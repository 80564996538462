import { makeStyles, Theme } from "@material-ui/core/styles";

const useGrowSectionStyles = makeStyles((theme: Theme) => ({
  inner: {
    paddingTop: "25px",
  },
  growDescWrap: {
    marginBottom: "48px",
    "&:last-child": {
      marginBottom: "0",
    },
  },
}));

export { useGrowSectionStyles };
