const generalRoutes = {
  USER_MENU: `/menu`,
  SNAPSHOT: "/snapshot",
  SCORE_MENU: "/score",
  ALIGN_MENU: "/align",
  IMPACT_MENU: "/impact",
  GROW_MENU: "/grow",
  ENGAGE_MENU: "/engage",
  PARTNER_LOGIN: "/partner-login",
  PARTNER_SIGNUP: "/partner-signup",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password/:token",

  SUPERADMIN_PROFILE: "/superadmin/profile",
  SUPERADMIN_PARTNERS: "/superadmin/partners",
  SUPERADMIN_USERS: "/superadmin/users",
  SUPERADMIN_FEEDBACKS: "/superadmin/feedbacks",
  SUPERADMIN_USER_SNAPSHOT: "/superadmin/snapshot/:id",

  PARTNER_PROFILE: "/partner/profile",
  PARTNER_USERS: "/partner/users",
  PARTNER_USER_SNAPSHOT: "/partner/snapshot/:id",
};

const exerciseRoutes = {
  PURPOSE_SCORE: "/purpose-score",
  PURPOSE_PULSE: "/purpose-pulse",
  PURPOSE_SCORE_GUEST: "/purpose-score-guest",
  EQ_SCORE: "/eq-score",
  FEQ: "/friction-eq",
  TRIALS_TRIUMPHS: "/trials-triumphs",
  PURPOSE: "/purpose",
  PROMISE: "/promise",
  DNA: "/dna",
};

const MENU_MATCH_PARAMS = "/:subcomponent?/:step?";
const RESET_MATCH_PARAMS = "/:token";

export { generalRoutes, exerciseRoutes, MENU_MATCH_PARAMS, RESET_MATCH_PARAMS };
