import { useSurvey } from "components/hooks/useSurvey";
import { ApiErrorKey } from "constants/api/apiErrors";
import { generalRoutes } from "constants/generalRoutes";
import { QueryKeys } from "constants/api/queryKeys";
import { Namespaces } from "i18n";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router";
import { ApiClientError } from "types/api/ApiClientError";
import { fetchAlign } from "utils/api/queryFns";
import { selectApiError } from "utils/api/selectApiError";
import { prepareInitialValues } from "./AlignTheme.utils";
import { AlignThemeFormValues } from "./AlignTheme.types";
import AlignThemeView from "./AlignThemeView";
import { useUpdateAlign } from "components/hooks/mutations/useUpdateAlign";
import _ from "lodash";

function AlignTheme() {
  const { t } = useTranslation([
    Namespaces.Align,
    Namespaces.Survey,
    Namespaces.Common,
  ]);

  const { prevUrl, nextUrl } = useSurvey();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [apiError, setApiError] = useState<ApiErrorKey | null>(null);
  const [formError, setFormError] = useState<string | null>(null);
  const history = useHistory();
  const queryClient = useQueryClient();
  const updateAlign = useUpdateAlign();

  const alignQuery = useQuery(fetchAlign.key, fetchAlign.fn);
  const align = useMemo(
    () => alignQuery.data?.data.payload,
    [alignQuery.data?.data.payload],
  );

  const preparedInitialValues = useMemo(
    () =>
      prepareInitialValues(align?.importantTrials, align?.importantTriumphs),
    [align?.importantTrials, align?.importantTriumphs],
  );

  const handleStepNext = () => {
    if (nextUrl) {
      history.push(nextUrl);
    } else {
      history.push(generalRoutes.ALIGN_MENU);
    }
  };

  const handleFormSubmit = async (data: AlignThemeFormValues) => {
    try {
      setIsUpdating(true);
      setApiError(null);
      setFormError(null);
      await updateAlign.mutateAsync({
        formData: {
          importantTrials: _.compact(data.importantTrials),
          importantTriumphs: _.compact(data.importantTriumphs),
        },
        align,
      });
      await queryClient.refetchQueries([QueryKeys.fetchAlign]);
      setIsUpdating(false);
      handleStepNext();
    } catch (e) {
      console.error(e);
      const error = e as ApiClientError;
      setIsUpdating(false);
      setApiError(selectApiError(error.response?.data.message));
    }
  };

  const isFetching = useMemo(
    () => alignQuery.isLoading,
    [alignQuery.isLoading],
  );

  const isFetchError = useMemo(() => alignQuery.isError, [alignQuery.isError]);

  const getApiError = useCallback(() => {
    return apiError
      ? t([
          `steps.theme.errors.${apiError}`,
          `${Namespaces.Survey}:errors.${apiError}`,
          `${Namespaces.Common}:errors.${apiError}`,
        ])
      : undefined;
  }, [apiError, t]);

  const getFormError = useCallback(() => {
    return formError
      ? t([
          `steps.theme.errors.${formError}`,
          `${Namespaces.Survey}:errors.${formError}`,
          `${Namespaces.Common}:errors.${formError}`,
        ])
      : undefined;
  }, [formError, t]);

  return (
    <AlignThemeView
      onFormSubmit={handleFormSubmit}
      initialValues={preparedInitialValues}
      isFetching={isFetching}
      isFetchError={isFetchError}
      isUpdating={isUpdating}
      prevUrl={prevUrl}
      getApiError={getApiError}
      getFormError={getFormError}
      setFormError={setFormError}
      setApiError={setApiError}
    />
  );
}

export default AlignTheme;
