import { makeStyles } from "@material-ui/core";
import { commonColors, textColors } from "constants/themeSettings";

const useEmotionalIntlResultStyles = makeStyles({
  scoreWrap: {
    fontSize: "25px",
    color: textColors.black.light,
    marginBottom: "20px",
  },
  accent: {
    fontWeight: 700,
    fontSize: "100px",
  },
  colorRed: {
    color: commonColors.red,
  },
  colorYellow: {
    color: commonColors.yellow,
  },
  colorBlue: {
    color: commonColors.blue,
  },
  colorGreen: {
    color: commonColors.green,
  },
  text: {
    color: textColors.black.light,
  },
});

export { useEmotionalIntlResultStyles };
