import SnapshotSectionLabel from "components/SnapshotSectionLabel/SnapshotSectionLabel";
import { GrowCategory } from "constants/grow/growCategories";
import { Namespaces } from "i18n";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useGrowSectionStyles } from "./styles/GrowSection.styles";
import cx from "classnames";

type GrowSectionProps = {
  situations: {
    result: Array<{
      category: GrowCategory;
      score: number;
    }>;
  };
};

function GrowSection(props: GrowSectionProps) {
  const {
    situations: { result },
  } = props;

  const classes = useGrowSectionStyles();
  const { t } = useTranslation([Namespaces.Admin, Namespaces.Snapshot]);

  const highestValue = useMemo(
    () =>
      result.reduce(
        (acc, item) => (acc = acc > item.score ? acc : item.score),
        0,
      ),
    [result],
  );

  return (
    <>
      <SnapshotSectionLabel
        text={t("snapshot.content.grow.label")}
        color="black"
      />
      <div className={classes.content}>
        {result.map((c, k) => (
          <div
            key={k}
            className={cx(classes.row, {
              [classes.selected]: c.score === highestValue,
            })}
          >
            <b>
              {t(`${Namespaces.Snapshot}:step2.grow.categories.${c.category}`)}:
            </b>
            <span>{c.score}</span>
          </div>
        ))}
      </div>
    </>
  );
}

export default GrowSection;
