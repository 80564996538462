import ExerciseMenuView from "./ExerciseMenuView";
import ScoreIllustration from "assets/img/score_illustration.svg";
import { exerciseRoutes } from "constants/generalRoutes";

function ScoreMenu() {
  return (
    <ExerciseMenuView
      title="Score"
      exercises={[
        {
          title: "Purpose Score",
          description: "Get a 60 second check on your current fulfillment",
          path: exerciseRoutes.PURPOSE_SCORE,
          newWindow: false,
        },
        {
          title: "Purpose Pulse",
          description:
            "Take an in-depth look at your current condition across 24 insights",
          path: exerciseRoutes.PURPOSE_PULSE,
          accessGated: true,
        },
        {
          title: "EQ Score",
          description: "See how you stack up accross the four parts of EQ",
          path: exerciseRoutes.EQ_SCORE,
        },
      ]}
      illustration={ScoreIllustration}
    />
  );
}
export default ScoreMenu;
