import { useSurvey } from "components/hooks/useSurvey";
import StageStepButton from "components/StageStepButton/StageStepButton";
import StageStepContainer from "components/StageStepContainer/StageStepContainer";
import StageStepFrame from "components/StageStepFrame/StageStepFrame";
import StageStepImage from "components/StageStepImage/StageStepImage";
import StageStepText from "components/StageStepText/StageStepText";
import { Namespaces } from "i18n";
import { useTranslation } from "react-i18next";
import { useScoreStyles } from "../../styles/Score.styles";

function PulseBeginView() {
  const { t } = useTranslation([Namespaces.Score, Namespaces.Survey]);
  const classes = useScoreStyles();
  const { nextUrl, prevUrl } = useSurvey();
  return (
    <StageStepFrame
      title={t("pulse.begin.title")}
      controls={[
        <StageStepButton.Back goTo={prevUrl}>
          {t(`${Namespaces.Survey}:buttons.back`)}
        </StageStepButton.Back>,
        <StageStepButton.Next goTo={nextUrl}>
          {t(`${Namespaces.Survey}:buttons.begin`)}
        </StageStepButton.Next>,
      ]}
    >
      <StageStepContainer>
        <StageStepImage url={t("pulse.begin.img")} />
        <StageStepText className={classes.subtitle}>
          {t("pulse.begin.subtitle")}
        </StageStepText>
        <StageStepText className={classes.text}>
          {t("pulse.begin.text1")}
        </StageStepText>
      </StageStepContainer>
    </StageStepFrame>
  );
}

export default PulseBeginView;
