import GBCustomButton from "components/GBCustomButton/GBCustomButton";
import GBProfileIcon from "components/svg-icons/GBProfileIcon";
import { generalRoutes } from "constants/generalRoutes";
import { Namespaces } from "i18n";
import { useTranslation } from "react-i18next";

function ProfileButton() {
  const { t } = useTranslation(Namespaces.Common);

  return (
    <GBCustomButton
      linkType="router-link"
      href={generalRoutes.USER_MENU}
      size="small"
      variant="contained"
      color="secondary"
      startIcon={<GBProfileIcon />}
    >
      {t("header.buttons.home")}
    </GBCustomButton>
  );
}

export default ProfileButton;
