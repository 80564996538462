import { makeStyles, Theme } from "@material-ui/core/styles";
import { commonColors } from "constants/themeSettings";

const useGBCheckStyles = makeStyles((theme: Theme) => ({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  check: {
    height: "32px",
    width: "32px",
    fill: theme.palette.primary.main,
    "input:disabled ~ &": {
      opacity: ".5",
    },
  },
  icon: {
    borderRadius: "50%",
    width: "32px",
    height: "32px",
    border: "2px solid #687A8F",
    backgroundColor: "#fff",
    "input:disabled ~ &": {
      background: "#e8f3fa",
      opacity: ".4",
    },
  },
  checkedPrimary: {
    fill: theme.palette.primary.main,
  },
  checkedBlue: {
    fill: commonColors.blue,
  },
  checkedRed: {
    fill: commonColors.red,
  },
  checkedSecondary: {
    fill: theme.palette.secondary.main,
  },
}));

export { useGBCheckStyles };
