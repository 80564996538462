import { makeStyles, Theme } from "@material-ui/core/styles";
import { textColors } from "constants/themeSettings";

const useHeaderSectionStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: "40px",
    paddingBottom: "16px",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "40px",
    },
  },
  title: {
    color: theme.palette.primary.main,
  },
  subtitle: {
    color: textColors.black.light,
    paddingTop: "20px",
  },
}));

export { useHeaderSectionStyles };
