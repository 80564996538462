import { ApiErrorKey } from "constants/api/apiErrors";
import { generalRoutes } from "constants/generalRoutes";
import { useFormik } from "formik";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import AuthenticationApiService from "services/AuthenticationApiService";
import { authActions } from "store/auth/actions";
import { ApiClientError } from "types/api/ApiClientError";
import { LoginRequestBody } from "types/auth/LoginRequestBody";
import { selectApiError } from "utils/api/selectApiError";
import {
  partnerLoginFormInitialValues,
  partnerLoginFormValidationSchema,
} from "./PartnerLogin.consts";
import { PartnerLoginFormValues } from "./PartnerLogin.types";
import PartnerLoginView from "./PartnerLoginView";

function PartnerLogin() {
  const dispatch = useDispatch();

  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<ApiErrorKey | null>(null);
  const history = useHistory();

  const handleSubmit = async (data: PartnerLoginFormValues) => {
    const credentials: LoginRequestBody = {
      email: data.email.trim(),
      password: data.password.trim(),
    };

    try {
      setIsProcessing(true);
      setErrorCode(null);

      const {
        data: { payload, message },
      } = await AuthenticationApiService.login(credentials);

      const { token } = payload;

      if (token) {
        setIsProcessing(false);
        formik.resetForm();
        dispatch(authActions.login(token));
        window.location.pathname = generalRoutes.USER_MENU;
      } else {
        setIsProcessing(false);
        setErrorCode(selectApiError(message));
      }
    } catch (e) {
      console.error(e);
      const error = e as ApiClientError;
      setIsProcessing(false);
      setErrorCode(selectApiError(error.response?.data.message));
    }
  };

  const formik = useFormik<PartnerLoginFormValues>({
    validationSchema: partnerLoginFormValidationSchema,
    enableReinitialize: true,
    initialValues: partnerLoginFormInitialValues,
    onSubmit: handleSubmit,
  });

  const handleGoToSignup = () => {
    history.push(generalRoutes.PARTNER_SIGNUP);
  };

  const handleGoToForgotPassword = () => {
    history.push({
      pathname: generalRoutes.FORGOT_PASSWORD,
      state: {
        from: generalRoutes.PARTNER_LOGIN,
      },
    });
  };

  return (
    <PartnerLoginView
      isProcessing={isProcessing}
      errorCode={errorCode}
      handleGoToSignup={handleGoToSignup}
      handleGoToForgotPassword={handleGoToForgotPassword}
      {...formik}
    />
  );
}

export default PartnerLogin;
