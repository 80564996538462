import { EmailAuthValues } from "./AuthModal.types";
import * as Yup from "yup";
import { emailRegExp } from "constants/regExps";
import { ValidationRule } from "constants/validationRules";

export const AuthFormInitValues: EmailAuthValues = {
  email: "",
};

export const AuthFormValuesFormValidationSchema = Yup.object({
  email: Yup.string()
    .trim()
    .matches(emailRegExp, ValidationRule.InvalidEmailFormat)
    .required(ValidationRule.Required),
});
