import { Theme, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { buttonStyles } from "constants/themeSettings";

export const RedesignedButton = withStyles((theme: Theme) => ({
  root: {
    borderRadius: buttonStyles.size.portalDefault.borderRadius,
    color: buttonStyles.palette.primary.textColor,
    backgroundColor: buttonStyles.palette.primary.bgColor,
    fontWeight: buttonStyles.size.portalDefault.fontWeight,
    fontSize: buttonStyles.size.portalDefault.fontSize,
    lineHeight: buttonStyles.size.portalDefault.lineHeight,
    padding: buttonStyles.size.portalDefault.padding,
    textTransform: "capitalize",
    textAlign: "center",
    "&:hover": {
      color: buttonStyles.palette.primary.hoverTextColor,
      backgroundColor: buttonStyles.palette.primary.hoverBgColor,
    },
    "&:disabled": {
      color: buttonStyles.palette.portalDefault.disabledTextColor,
      backgroundColor: buttonStyles.palette.portalDefault.disabledBgColor,
    },
    "& .MuiButton-endIcon": {
      "& svg": {
        marginLeft: "11px",
        fontSize: "18px",
      },
    },
    "& .MuiButton-startIcon": {
      "& svg": {
        marginRight: "11px",
        fontSize: "18px",
      },
    },
  },
  sizeSmall: {
    padding: buttonStyles.size.portalSmall.padding,
    fontWeight: buttonStyles.size.portalSmall.fontWeight,
    fontSize: buttonStyles.size.portalSmall.fontSize,
    lineHeight: buttonStyles.size.portalSmall.lineHeight,
    height: buttonStyles.size.portalSmall.height,
    "& :first-child": {
      "& svg": {
        fontSize: 26,
        marginRight: "5px",
      },
    },
  },
  sizeLarge: {
    padding: buttonStyles.size.portalLarge.padding,
    fontWeight: buttonStyles.size.portalLarge.fontWeight,
    fontSize: buttonStyles.size.portalLarge.fontSize,
    lineHeight: buttonStyles.size.portalLarge.lineHeight,
    height: buttonStyles.size.portalLarge.height,
    borderRadius: buttonStyles.size.portalLarge.borderRadius,
  },
  containedPrimary: {
    color: buttonStyles.palette.portalPrimary.textColor,
    backgroundColor: buttonStyles.palette.portalPrimary.bgColor,
    "&:hover": {
      color: buttonStyles.palette.portalPrimary.textColor,
      backgroundColor: buttonStyles.palette.portalPrimary.hoverBgColor,
    },
    "&:disabled": {
      color: buttonStyles.palette.portalPrimary.disabledTextColor,
      backgroundColor: buttonStyles.palette.portalPrimary.disabledBgColor,
    },
  },
  containedSecondary: {
    color: buttonStyles.palette.header.textColor,
    backgroundColor: buttonStyles.palette.header.bgColor,
    "&:hover": {
      color: buttonStyles.palette.header.hoverTextColor,
      backgroundColor: buttonStyles.palette.header.hoverBgColor,
    },
    "&:disabled": {
      color: buttonStyles.palette.header.disabledTextColor,
      backgroundColor: buttonStyles.palette.header.disabledBgColor,
    },
  },
}))(Button);
