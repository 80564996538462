import { useTranslation } from "react-i18next";
import { Namespaces } from "i18n";
import { useDnaResultStyles } from "./styles/DnaResult.styles";
import StageStepFrame from "components/StageStepFrame/StageStepFrame";
import { useSurvey } from "components/hooks/useSurvey";
import StageStepButton from "components/StageStepButton/StageStepButton";
import GBSpin from "components/GBSpin/GBSpin";
import GBList from "components/GBList/GBList";
import {
  IdentityTypeEnum,
  IdentityTypeEnumKeys,
  IdentityVerb,
} from "types/score/DNA";
import { IDENTITIES } from "constants/identities";
import { useMemo } from "react";
import _ from "lodash";
import GBModal from "components/GBModal/GBModal";

type ScoreResultViewProps = {
  result?: { identities: Array<string>; impacts: Array<IdentityVerb> };
  onComplete: () => void;
  isFetching: boolean;
  isFetchError?: boolean;
  isMessage: boolean;
};

function DnaResultView(props: ScoreResultViewProps) {
  const { result, isFetching, onComplete, isMessage } = props;
  const { t } = useTranslation([
    Namespaces.Score,
    Namespaces.Survey,
    Namespaces.Common,
  ]);

  const classes = useDnaResultStyles();
  const { prevUrl } = useSurvey();

  const topIdentities = useMemo(() => {
    if (result?.identities) {
      return result.identities.map((identity) => {
        const identityVal = _.findKey(
          IdentityTypeEnum,
          (i) => i === identity,
        ) as IdentityTypeEnumKeys;
        if (identityVal) {
          const currIdentity = IDENTITIES[identityVal];
          if (currIdentity) {
            return t(currIdentity.title);
          }
        }
        return identity;
      });
    }
    return [];
  }, [result, t]);

  const getTopImpacts = (): Array<string> => {
    return (
      result?.impacts.map((impact) =>
        t(`${Namespaces.Identity}:${impact.identity}.verbs.${impact.verb}`),
      ) || []
    );
  };

  return (
    <>
      <StageStepFrame
        title={t("DNA.result.title")}
        variant="success"
        controls={[
          <StageStepButton.Back goTo={prevUrl}>
            {t(`${Namespaces.Survey}:buttons.back`)}
          </StageStepButton.Back>,
          <StageStepButton.Next completed noArrow onClick={onComplete}>
            {t(`${Namespaces.Survey}:buttons.complete`)}
          </StageStepButton.Next>,
        ]}
      >
        <div className={classes.subtitle}>{t("DNA.result.subtitle")}</div>
        <GBSpin spinning={isFetching}>
          <GBList
            label={t("DNA.result.topIdentities")}
            items={topIdentities}
            className={classes.list}
          />
          <GBList
            label={t("DNA.result.topImpacts")}
            items={getTopImpacts()}
            className={classes.list}
          />
        </GBSpin>
      </StageStepFrame>
      <GBModal
        open={isMessage}
        title="Congratulations!"
        size="medium"
        withTitleDivider
      >
        <p>
          Congratulations on completing Purpose DNA. You should have an email
          with your purpose types and impact words. You can revisit this
          exercise at any time.
          <br />
          <br />
          You can now close this window.
        </p>
      </GBModal>
    </>
  );
}
export default DnaResultView;
