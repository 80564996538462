import { PurposeScoreTypesI18nKey } from "../../constants/purposeScoreTypes";

type ScoreFormGraphProps = {
  score?: PurposeScoreTypesI18nKey;
};

const ScoreFormGraph = ({
  score = PurposeScoreTypesI18nKey.Emergency,
}: ScoreFormGraphProps): JSX.Element => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 201 152"
      fill="none"
      strokeWidth="3"
      strokeLinecap="round"
      stroke="#07283B"
    >
      <path
        stroke={
          score === PurposeScoreTypesI18nKey.Mentor ? "#4E9F68" : "#07283B"
        }
        d="M130.1,20.1v22.7l14,2.8V107l12.2,2.5 M130.1,20.1l26.2-5.1l26,5.1 M130.1,20.1l26.2,5.9 M156.3,109.5l26-5.6
	V20.1 M156.3,109.5V26 M182.3,20.1l-26,5.9"
      />
      <path
        stroke={
          score === PurposeScoreTypesI18nKey.NeedNudge ? "#2C8CE5" : "#07283B"
        }
        d="M91.9,45.6v29.8l14,2.8v37.4l12.2,2.5 M91.9,45.6l26.2-5.1l26,5.1 M91.9,45.6l26.2,5.9 M118.1,118.2l26-5.6v-67
	 M118.1,118.2V51.4 M144.1,45.6l-26,5.9"
      />
      <path
        stroke={
          score === PurposeScoreTypesI18nKey.NeedPush ? "#F4B504" : "#07283B"
        }
        d="M53.7,78.2v25.7L68,107v16.8l12,2.6 M53.7,78.2l26.2-5.1l26,5.1 M53.7,78.2L79.9,84 M79.9,126.3l26-5.6V78.2
	 M79.9,126.3V84 M105.9,78.2l-26,5.9"
      />
      <path
        stroke={
          score === PurposeScoreTypesI18nKey.Emergency ? "#D34837" : "#07283B"
        }
        d="M15.7,106.7v24.2l26.2,5.6 M15.7,106.7l26.2-5.1l26,5.1 M15.7,106.7l26.2,5.9 M41.9,136.5l26-5.6v-24.2
	 M41.9,136.5v-23.9 M67.9,106.7l-26,5.9"
      />
    </svg>
  );
};

export default ScoreFormGraph;
