import { makeStyles, Theme } from "@material-ui/core/styles";

const useFormRowStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: "32px",
    "&:last-child": {
      marginBottom: "0",
    },
  },
  gutter16: {
    marginBottom: "16px",
  },
  gutter48: {
    marginBottom: "48px",
  },
  fullWidth: {
    width: "100%",
  },
}));

export { useFormRowStyles };
