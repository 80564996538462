import { ImpactPromise } from "types/impact/ImpactPromise";
import { ImpactPurpose } from "types/impact/ImpactPurpose";
import { ImpactPromiseBuildFormValues } from "./ImpactPromiseBuild.types";

export const prepareInitialValues = (data?: {
  ImpactPromise: ImpactPromise | undefined;
  ImpactPurpose: ImpactPurpose | undefined;
}): ImpactPromiseBuildFormValues => ({
  purposeFinal: data?.ImpactPurpose?.purposeFinal || "",
  promiseVerb: data?.ImpactPromise?.promiseVerb || "",
  promiseComponent: data?.ImpactPromise?.promiseComponent || "",
  finalPromise: data?.ImpactPromise?.finalPromise || "",
});
