import { makeStyles, Theme } from "@material-ui/core/styles";
import { WIDE_CONTAINER_WIDTH } from "constants/settings";

const useStageStepContainerStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "720px",
    [theme.breakpoints.down(WIDE_CONTAINER_WIDTH)]: {
      width: "100%",
    },
  },
  fullWidth: {
    width: "100%",
  },
}));

export { useStageStepContainerStyles };
