import { ApiErrorKey } from "constants/api/apiErrors";
import { UserType } from "constants/userTypes";
import { useState } from "react";
import { useSelector } from "react-redux";
import PartnerApiService from "services/PartnerApiService";
import SuperAdminApiService from "services/SuperAdminApiService";
import { RootState } from "store";
import { ApiClientError } from "types/api/ApiClientError";
import { selectApiError } from "utils/api/selectApiError";
import { QueryUsersFnArgs, QueryUsersFnPayload } from "./AdminUsers.types";
import AdminUsersView from "./AdminUsersView";

function AdminUsers() {
  const [apiError, setApiError] = useState<ApiErrorKey | null>(null);
  const { user } = useSelector((state: RootState) => state.auth);
  const isSuperAdmin = user?.userType === UserType.SuperAdmin;
  const isPartner = user?.userType === UserType.Partner;

  const queryUsers = async ({
    query,
    cb,
  }: QueryUsersFnArgs): Promise<QueryUsersFnPayload> => {
    let result: QueryUsersFnPayload = {
      data: [],
      page: 0,
      totalCount: 0,
    };
    try {
      setApiError(null);
      if (isSuperAdmin) {
        const { data } = await SuperAdminApiService.getUsers({
          skip: query.page * query.pageSize,
          limit: query.pageSize,
          search: query.search,
        });
        if (cb) {
          cb(data.payload.pagination.totalCount);
        }
        result = {
          data: data.payload.items,
          page: data.payload.pagination.page,
          totalCount: data.payload.pagination.totalCount,
        };
      }
      if (isPartner) {
        const { data } = await PartnerApiService.getUsers({
          skip: query.page * query.pageSize,
          limit: query.pageSize,
          search: query.search,
        });
        if (cb) {
          cb(data.payload.pagination.totalCount);
        }
        result = {
          data: data.payload.items,
          page: data.payload.pagination.page,
          totalCount: data.payload.pagination.totalCount,
        };
      }
    } catch (e) {
      console.error(e);
      const error = e as ApiClientError;
      setApiError(selectApiError(error.response?.data.message));
    }
    return result;
  };

  return <AdminUsersView queryUsers={queryUsers} apiError={apiError} />;
}

export default AdminUsers;
