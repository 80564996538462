import { makeStyles, Theme } from "@material-ui/core/styles";

const useGBModalStyles = makeStyles((theme: Theme) => ({
  modal: {
    padding: "124px 15px 0 15px",
    overflowY: "auto",
    "&$titleDivider $header": {
      borderBottom: "2px solid #EEEEEE",
    },
  },
  paper: {
    width: "auto",
    display: "flex",
    flexDirection: "column",
    maxWidth: "640px",
    backgroundColor: theme.palette.common.white,
    border: "none",
    borderRadius: theme.shape.borderRadius,
    margin: "auto auto 400px auto",

    "&:focus": {
      outline: 0,
    },
    "&$sizeTiny": {
      width: "100%",
      maxWidth: "270px",
      padding: "20px",
    },
    "&$sizeSmall": {
      width: "100%",
      maxWidth: "400px",
      padding: "25px",
    },
    "&$sizeMedium": {
      width: "100%",
      maxWidth: "540px",
      padding: "45px 35px",
    },
    "&$sizeBig": {
      width: "100%",
      maxWidth: "640px",
      padding: "50px 40px",
    },
    "&$sizeLarge": {
      width: "100%",
      maxWidth: "814px",
      padding: "50px 40px",
    },
    "&$sizeFullScreen": {
      width: "100%",
      maxWidth: "100%",
      padding: "50px 40px",
    },
    "&$sizeAuto": {
      width: "auto",
      padding: "45px 35px",
    },
  },
  header: {
    marginBottom: "33px",
  },
  title: {
    fontSize: "32px",
    lineHeight: "34px",
    fontWeight: 700,
    textAlign: "center",
  },
  titleDivider: {
    paddingBottom: "12px",
  },
  body: {
    flex: 1,
    overflow: "hidden",
  },
  footer: {
    paddingTop: "43px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // "& > *:not(:first-child)": {
    //   marginTop: "12px",
    // },
  },
  defaultButtonsWrap: {
    display: "flex",
    justifyContent: "space-around",
  },
  defaultButton: {
    margin: "0 10px",
  },
  sizeTiny: {},
  sizeSmall: {},
  sizeMedium: {},
  sizeBig: {},
  sizeLarge: {},
  sizeFullScreen: {},
  sizeAuto: {},
}));

export { useGBModalStyles };
