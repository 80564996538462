import { generalRoutes } from "constants/generalRoutes";
import { Redirect } from "react-router";

function NotFoundView() {
  return (
    <div>
      <h1>404, The page you are looking for cannot be found</h1>
    </div>
  );
  // return <Redirect to={generalRoutes.USER_MENU} />;
}

export default NotFoundView;
