import { makeStyles, Theme } from "@material-ui/core/styles";
import { WIDE_CONTAINER_WIDTH } from "constants/settings";
import { commonColors, textColors } from "constants/themeSettings";

const useScoreResultStyles = makeStyles<Theme, {}>((theme: any) => ({
  content: {
    display: "flex",
    justifyContent: "space-between",
  },
  subtitle: {
    ...theme.typography.surveySubtitle,
    padding: "10px 0",
    [theme.breakpoints.down(470)]: {
      fontSize: "18px",
    },
  },
  result: {
    fontWeight: 700,
    fontSize: "44px",
    lineHeight: "34px",
  },
  colorRed: {
    color: commonColors.red,
  },
  colorYellow: {
    color: commonColors.yellow,
  },
  colorBlue: {
    color: commonColors.blue,
  },
  colorGreen: {
    color: commonColors.green,
  },
  flexBlock: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  scoreTitle: {
    fontSize: "44px",
    lineHeight: "34px",
    fontWeight: 700,
    [theme.breakpoints.down("xs")]: {
      fontSize: "35px",
      lineHeight: "28px",
    },
    [theme.breakpoints.down(470)]: {
      fontSize: "28px",
      lineHeight: "20px",
    },
  },
  scoreDescription: {
    fontSize: "20",
    lineHeight: "32px",
    fontWeight: 400,
  },
  time: {
    display: "flex",
    alignItems: "center",
    color: textColors.black.light,
    fontSize: "22px",
    lineHeight: "26px",
    marginTop: "5px",
  },
  timeIcon: {
    marginRight: "10px",
  },
  wrapper: {
    ...theme.typography.formMedium,
    marginTop: "40px",
  },
  formRow: {
    marginBottom: "40px",
  },
  sublabel: {
    fontSize: "22px",
    lineHeight: "27px",
    fontWeight: 400,
    color: textColors.black.light,
    marginBottom: "18px",
  },
  divider: {
    marginTop: "56px",
  },
  quizFormRow: {
    marginBottom: "40px",
  },
  submitBlock: {
    paddingTop: "72px",
  },
  errorBlock: {
    paddingTop: "40px",
  },
  accent: {
    color: theme.palette.primary.main,
  },
  iconWrap: {
    paddingTop: "34px",
    [theme.breakpoints.down(WIDE_CONTAINER_WIDTH)]: {
      display: "flex",
      justifyContent: "center",
    },
    [theme.breakpoints.down("xs")]: {
      "& svg": {
        width: "100%",
        height: "200px",
      },
    },
  },
}));
export { useScoreResultStyles };
