import ScoreResultView from "./ScoreResultView";
import { useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { fetchScore } from "utils/api/queryFns";
import {
  prepareScoreResultValue,
  scoreResultIsValid,
} from "constants/purposeScoreTypes";
import { useSurvey } from "components/hooks/useSurvey";
import { useHistory } from "react-router";

function ScoreResult() {
  const history = useHistory();
  const scoreQuery = useQuery(fetchScore.key, fetchScore.fn);
  const score = useMemo(
    () => scoreQuery.data?.data.payload,
    [scoreQuery.data?.data.payload],
  );
  const { prevUrl } = useSurvey();

  useEffect(() => {
    if (!scoreResultIsValid(score?.score) && prevUrl) {
      history.push(prevUrl);
    }
  }, [score, history, prevUrl]);

  const scoreResult = useMemo(
    () => prepareScoreResultValue(score?.score),
    [score],
  );
  const isFetching = useMemo(
    () => scoreQuery.isLoading,
    [scoreQuery.isLoading],
  );

  const isFetchError = useMemo(() => scoreQuery.isError, [scoreQuery.isError]);

  return (
    <ScoreResultView
      scoreVal={scoreResult}
      isFetching={isFetching}
      isFetchError={isFetchError}
    />
  );
}
export default ScoreResult;
