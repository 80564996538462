import { createStyles } from "@material-ui/core";
import { typographyStyles } from "styles/jss/typographyStyles";

export const gbTypographyStyles = createStyles({
  h1: {
    ...typographyStyles.h1,
  },
  h3: {
    ...typographyStyles.h3,
  },
});
