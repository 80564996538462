import { ContactUsFormValues } from "./ContactUsModal.types";
import * as Yup from "yup";
import { emailRegExp } from "constants/regExps";
import { ValidationRule } from "constants/validationRules";

export const contactUsFormInitValues: ContactUsFormValues = {
  firstName: "",
  lastName: "",
  email: "",
};

export const contactUsFormValidationSchema = Yup.object({
  firstName: Yup.string().trim().required(ValidationRule.Required),
  lastName: Yup.string().trim(),
  email: Yup.string()
    .trim()
    .matches(emailRegExp, ValidationRule.InvalidEmailFormat)
    .required(ValidationRule.Required),
});
