import { useTheme } from "@material-ui/core/styles";

const AlignIcon = (): JSX.Element => {
  const theme = useTheme();
  return (
    <svg
      width="88"
      height="98"
      viewBox="0 0 88 98"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="dynamicColor"
        d="M39.5 5.06218C42.2846 3.45448 45.7154 3.45448 48.5 5.06218L79.8013 23.134C82.5859 24.7417 84.3013 27.7128 84.3013 30.9282V67.0718C84.3013 70.2872 82.5859 73.2583 79.8013 74.866L48.5 92.9378C45.7154 94.5455 42.2846 94.5455 39.5 92.9378L8.19873 74.866C5.41412 73.2583 3.69873 70.2872 3.69873 67.0718V30.9282C3.69873 27.7128 5.41412 24.7417 8.19873 23.134L39.5 5.06218Z"
        stroke={theme.palette.secondary.light}
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g transform="translate(21, 48)">
        <path d="M0 1H46" stroke="#f7882f" strokeWidth="2" />
      </g>
      <g transform="translate(30, 21)">
        <path
          d="M10 1V11H3L14 23L25 11H18V1H10Z"
          stroke="#07283B"
          strokeWidth="2"
        />
      </g>
      <g transform="translate(30, 52)">
        <path
          d="M18 24L18 14L25 14L14 2L3 14L10 14L10 24L18 24Z"
          stroke="#07283B"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};

export default AlignIcon;
