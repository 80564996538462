import { useFooterStyles } from "./styles/Footer.styles";
import { useTranslation } from "react-i18next";
import { Namespaces } from "i18n";
import GBContainer from "components/GBContainer/GBContainer";
import { NARROW_CONTAINER_WIDTH } from "constants/settings";
import { Link } from "@material-ui/core";
import FacebookIcon from "components/svg-icons/socials/FacebookIcon";
import LinkedinIcon from "components/svg-icons/socials/LinkedinIcon";
import InstagramIcon from "components/svg-icons/socials/InstagramIcon";
import gbLogo from "assets/img/gb-logo.png";
import DownloadFile from "components/DownloadFile/DownloadFile";
import { useDispatch } from "react-redux";
import { contactUsModalActions } from "store/contactUsModal/actions";

function Footer() {
  const classes = useFooterStyles();
  const { t } = useTranslation(Namespaces.Common);
  const dispatch = useDispatch();

  return (
    <footer className={classes.root}>
      <GBContainer width={NARROW_CONTAINER_WIDTH}>
        <div className={classes.footerTop}>
          <div className={classes.logo}>
            <img src={gbLogo} alt={t("app_name")} />
          </div>
          <ul className={classes.nav}>
            <li className={classes.navItem}>
              <DownloadFile path={t("download_links.terms")}>
                {t("footer.menu.terms")}
              </DownloadFile>
            </li>
            <li className={classes.navItem}>
              <DownloadFile path={t("download_links.privacy")}>
                {t("footer.menu.privacy")}
              </DownloadFile>
            </li>
            {/* <li className={classes.navItem}>
              <DownloadFile path={t("download_links.faq")}>
                {t("footer.menu.faq")}
              </DownloadFile>
            </li> */}
            {/* <li className={classes.navItem}>
              <button onClick={() => dispatch(contactUsModalActions.open())}>
                {t("footer.menu.contact_us")}
              </button>
            </li> */}
          </ul>
        </div>
      </GBContainer>
      <hr className={classes.hr} />
      <GBContainer width={NARROW_CONTAINER_WIDTH}>
        <div className={classes.footerBottom}>
          <div className={classes.rights}>
            {t("footer.rights", {
              year: new Date().getFullYear(),
            })}
          </div>
          <div className={classes.socials}>
            <Link
              className={classes.social}
              target="_blank"
              href={t("socials.instagram")}
            >
              <InstagramIcon />
            </Link>
            <Link
              className={classes.social}
              target="_blank"
              href={t("socials.linkedin")}
            >
              <LinkedinIcon />
            </Link>
            <Link
              className={classes.social}
              target="_blank"
              href={t("socials.facebook")}
            >
              <FacebookIcon />
            </Link>
          </div>
        </div>
      </GBContainer>
    </footer>
  );
}

export default Footer;
