import { useSurvey } from "components/hooks/useSurvey";
import { generalRoutes } from "constants/generalRoutes";
import { redirectRoutes } from "constants/redirectRoutes";
import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router";
import { fetchScoreResult } from "utils/api/queryFns";
import { getEmotionalIntlScoreI18nKey } from "utils/getEmotionalIntlScoreI18nKey";
import EmotionalIntlResultView from "./EmotionalIntlResultView";

function EmotionalIntlResult() {
  const prevUrl = generalRoutes.SCORE_MENU;
  const history = useHistory();
  const [isMessage, setIsMessage] = useState<boolean>(false);
  const eqScore = localStorage.getItem("eqResultScore") || 0;
  const score = parseFloat(eqScore?.toString());

  const scoreI18nKey = useMemo(
    () => getEmotionalIntlScoreI18nKey(score || 0),
    [score],
  );

  return (
    <EmotionalIntlResultView
      isSuccess={!!score}
      prevUrl={prevUrl}
      isLoading={false}
      onComplete={() => setIsMessage(true)}
      isError={false}
      score={score || 0}
      scoreI18nKey={scoreI18nKey}
      isMessage={isMessage}
    />
  );
}

export default EmotionalIntlResult;
