import * as Yup from "yup";
import { ValidationRule } from "constants/validationRules";
import { PaymentModalFormValues } from "./PaymentModal.types";

export const paymentModalFormInitValues: PaymentModalFormValues = {
  cardData: false,
};

export const paymentModalFormValidationSchema = Yup.object({
  cardData: Yup.boolean().oneOf([true], ValidationRule.Required),
});
