import { useScoreSectionStyles } from "./styles/ScoreSection.styles";
import SnapshotSectionLabel from "components/SnapshotSectionLabel/SnapshotSectionLabel";
import { Trans, useTranslation } from "react-i18next";
import { Namespaces } from "i18n";
import { ScoreSectionData } from "../../AdminUserSnapshot.types";
import { Typography } from "@material-ui/core";
import { useMemo } from "react";
import { getPurposeScoreI18nKey } from "utils/getPurposeScoreI18nKey";
import cx from "classnames";
import { PurposeScoreTypesI18nKey } from "constants/purposeScoreTypes";
import GBDescription from "components/GBDescription/GBDescription";
import GBList from "components/GBList/GBList";
import {
  EmotionalIntlScoreLevel,
  getEmotionalIntlScoreI18nKey,
} from "utils/getEmotionalIntlScoreI18nKey";

type ScoreSectionProps = {
  data: ScoreSectionData;
};

function ScoreSection(props: ScoreSectionProps) {
  const {
    data: { score, identities, emotionalIntlScore },
  } = props;
  const classes = useScoreSectionStyles();
  const { t } = useTranslation([Namespaces.Admin, Namespaces.Identity]);

  const purposeScore = useMemo(
    () => getPurposeScoreI18nKey(score || 0),
    [score],
  );

  const scoreClases = cx(classes.score, {
    [classes.emergency]: purposeScore === PurposeScoreTypesI18nKey.Emergency,
    [classes.push]: purposeScore === PurposeScoreTypesI18nKey.NeedPush,
    [classes.nudge]: purposeScore === PurposeScoreTypesI18nKey.NeedNudge,
  });

  const emotionalIntlScoreI18nKey = useMemo(
    () => getEmotionalIntlScoreI18nKey(emotionalIntlScore || 0),
    [emotionalIntlScore],
  );

  const emotionalIntlScoreClasses = cx(classes.accent, {
    [classes.colorRed]:
      emotionalIntlScoreI18nKey === EmotionalIntlScoreLevel.CRITICAL,
    [classes.colorYellow]:
      emotionalIntlScoreI18nKey === EmotionalIntlScoreLevel.LOW,
    [classes.colorBlue]:
      emotionalIntlScoreI18nKey === EmotionalIntlScoreLevel.MIDDLE,
    [classes.colorGreen]:
      emotionalIntlScoreI18nKey === EmotionalIntlScoreLevel.HIGH,
  });

  const renderDNADescText = (list: string[]) => (
    <GBList
      items={list.map((i) => (
        <div>
          {t(`${Namespaces.Identity}:${i}.title`)}
          <div className={classes.dnaDesc}>
            {t(`${Namespaces.Identity}:${i}.description`)}
          </div>
        </div>
      ))}
      markerFlex="flex-start"
    />
  );

  return (
    <>
      <SnapshotSectionLabel
        text={t("snapshot.content.score.label")}
        color="yellow"
      />
      <div className={classes.content}>
        <div className={classes.flexBox}>
          <div className={classes.scoreWrap}>
            <Typography variant="h4" component="h4">
              {t("snapshot.content.score.score_title")}:
            </Typography>
            <b className={scoreClases}>{score}</b>
          </div>
          {identities && (
            <div className={classes.dnaWrap}>
              <GBDescription
                fullWidth
                title={`${t("snapshot.content.score.dna_title")}:`}
                text={renderDNADescText(identities)}
              />
            </div>
          )}
        </div>
        {!!emotionalIntlScore && (
          <GBDescription
            className={classes.emotionalIntlWrap}
            title={
              <Trans
                i18nKey={t("snapshot.content.score.emotional_intl_score", {
                  score: emotionalIntlScore.toFixed(1) || 0,
                })}
                components={{
                  accent: <span className={emotionalIntlScoreClasses} />,
                }}
              />
            }
            text={t(
              `${Namespaces.Score}:emotionalIntl.result.results.${emotionalIntlScoreI18nKey}`,
            )}
          />
        )}
      </div>
    </>
  );
}

export default ScoreSection;
