import { useDownloadFileStyles } from "./styles/DownloadFile.styles";
import cx from "classnames";

type DownloadFileProps = {
  path: string;
  children: JSX.Element;
  className?: string;
};

function DownloadFile(props: DownloadFileProps) {
  const { path, children, className } = props;
  const classes = useDownloadFileStyles();

  return (
    <a
      href={path}
      target="_blank"
      rel="noreferrer"
      className={cx(classes.root, className)}
    >
      {children}
    </a>
  );
}

export default DownloadFile;
