import { Alert } from "@material-ui/lab";
import GBCircularProgress from "components/GBCircularProgress/GBCircularProgress";
import GBSpin from "components/GBSpin/GBSpin";
import GrowSquare from "components/GrowSquare/GrowSquare";
import { useSurvey } from "components/hooks/useSurvey";
import StageStepButton from "components/StageStepButton/StageStepButton";
import StageStepContainer from "components/StageStepContainer/StageStepContainer";
import StageStepFrame from "components/StageStepFrame/StageStepFrame";
import UnexpectedError from "components/UnexpectedError/UnexpectedError";
import { Namespaces } from "i18n";
import { useTranslation } from "react-i18next";
import { GrowResultResponsePayload } from "types/grow/GetGrowResultResponsePayload";
import { useGrowResultStyles } from "./styles/GrowResult.styles";

type GrowResultViewProps = {
  growResult: GrowResultResponsePayload | undefined;
  isCompleted: boolean;
  onSubmit: () => void;
  isUpdating: boolean;
  getApiError: () => string | undefined;
  isFetching: boolean;
  isFetchError: boolean;
};

function GrowResultView(props: GrowResultViewProps) {
  const {
    isCompleted,
    onSubmit,
    isUpdating,
    getApiError,
    isFetching,
    isFetchError,
    growResult,
  } = props;

  const { t } = useTranslation(Namespaces.Grow);
  const { prevUrl } = useSurvey();
  const classes = useGrowResultStyles();

  return (
    <StageStepFrame
      title={t("steps.title")}
      variant={isCompleted ? "success" : undefined}
      controls={[
        <StageStepButton.Back goTo={prevUrl} disabled={isUpdating}>
          {t(`${Namespaces.Survey}:buttons.back`)}
        </StageStepButton.Back>,
        <StageStepButton.Next
          onClick={onSubmit}
          disabled={isUpdating || !isCompleted}
          completed
          noArrow
        >
          {t(`${Namespaces.Survey}:buttons.complete`)}
        </StageStepButton.Next>,
      ]}
      error={getApiError()}
    >
      <StageStepContainer>
        {isFetching ? (
          <GBCircularProgress keepHeight="363px" />
        ) : isFetchError ? (
          <UnexpectedError />
        ) : (
          <GBSpin spinning={isUpdating}>
            {isCompleted ? (
              <div className={classes.root}>
                {growResult && (
                  <GrowSquare
                    data={[
                      growResult.situations.result[0],
                      growResult.situations.result[1],
                      growResult.situations.result[2],
                      growResult.situations.result[3],
                    ]}
                  />
                )}
              </div>
            ) : (
              <Alert severity="warning">
                {t(`${Namespaces.Survey}:warnings.uncompleted_steps`)}
              </Alert>
            )}
          </GBSpin>
        )}
      </StageStepContainer>
    </StageStepFrame>
  );
}

export default GrowResultView;
