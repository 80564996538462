import { Namespaces } from "i18n";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import SnapshotSectionLabel from "components/SnapshotSectionLabel/SnapshotSectionLabel";
import { useAlignSectionStyles } from "./styles/AlignSection.styles";
import Alert from "@material-ui/lab/Alert";
import SnapArrow from "assets/img/snap-impact-arrow.svg";
import SnapRoundTriangle from "assets/img/snap-round-triangle.svg";
import SnapRoundTriangleRight from "assets/img/snap-round-triangle-right.svg";
import cx from "classnames";
import { AlignSectionData } from "pages/Snapshot/components/Step1/Step1.types";
import { useMediaQuery, useTheme } from "@material-ui/core";

type AlignSectionProps = {
  data: AlignSectionData;
};

function AlignSection(props: AlignSectionProps) {
  const {
    data: { trials, triumphs },
  } = props;

  const classes = useAlignSectionStyles();
  const { t } = useTranslation(Namespaces.Snapshot);
  const theme = useTheme();
  const isDown1040 = useMediaQuery(theme.breakpoints.down(1040));

  const isUpcompleted = useMemo(() => !trials || !triumphs, [trials, triumphs]);

  return (
    <div className={classes.root}>
      {isUpcompleted ? (
        <Alert severity="warning">
          {t("step1.align.warnings.not_completed")}
        </Alert>
      ) : (
        <>
          <SnapshotSectionLabel text={t("step1.align.label")} color="gray" />
          <div className={classes.sub}>{t("step1.align.sub")}</div>
          <div className={classes.align}>
            <div className={classes.alignBlock}>
              <div>
                {trials?.map((trial, k) => (
                  <div key={k} className={classes.textRow}>
                    {t("step1.align.value", {
                      value: trial.toLowerCase(),
                    })}
                  </div>
                ))}
              </div>
              {!isDown1040 && (
                <img
                  src={SnapRoundTriangle}
                  className={classes.triangle}
                  alt=""
                />
              )}
            </div>
            {!isDown1040 && (
              <img className={classes.arrow} src={SnapArrow} alt="" />
            )}
            <div className={classes.alignBlock}>
              <div>
                {triumphs?.map((triumph, k) => (
                  <div key={k} className={classes.textRow}>
                    {t("step1.align.value", {
                      value: triumph.toLowerCase(),
                    })}
                  </div>
                ))}
              </div>
              {!isDown1040 && (
                <img
                  src={SnapRoundTriangleRight}
                  className={cx(classes.triangle, classes.triangleRight)}
                  alt=""
                />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default AlignSection;
