export const typographyStyles = {
  h1: {
    fontSize: "32px",
    lineHeight: "40px",
    fontWeight: 700,
  },
  h2: {},
  h3: {
    fontSize: "24px",
    lineHeight: "32px",
    fontWeight: 700,
  },
  h4: {},
  h5: {},
  h6: {},
};
