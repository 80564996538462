import { ApiErrorKey } from "constants/api/apiErrors";
import { ForgotPasswordFormStatus } from "constants/ForgotPasswordFormStatus";
import { useFormik } from "formik";
import { Namespaces } from "i18n";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import AuthenticationApiService from "services/AuthenticationApiService";
import { ApiClientError } from "types/api/ApiClientError";
import { ResetPasswordRequestBody } from "types/auth/ResetPasswordRequestBody";
import { selectApiError } from "utils/api/selectApiError";
import {
  resetPasswordFormInitialValues,
  resetPasswordFormValidationSchema,
} from "./ResetPassword.conts";
import { ResetPasswordFormValues } from "./ResetPassword.types";
import ResetPasswordView from "./ResetPasswordView";

function ResetPassword() {
  const matchParams: { token?: string } = useParams();

  const { t } = useTranslation([Namespaces.Auth, Namespaces.Common]);

  const [isVerifying, setIsVerifying] = useState<boolean>(true);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<ApiErrorKey | null>(null);
  const [resetToken, setResetToken] = useState<string | null>(null);
  const [isTokenError, setIsTokenError] = useState<boolean>(false);

  useEffect(() => {
    const resetToken = matchParams.token;
    if (resetToken) {
      setResetToken(resetToken);
      setIsVerifying(true);
      AuthenticationApiService.getProfile(resetToken)
        .then((response) => {
          if (!response.data.payload.user) {
            setIsTokenError(true);
          }
          setIsVerifying(false);
        })
        .catch((e) => {
          console.error(e);
          setIsTokenError(true);
          setIsVerifying(false);
        });
    } else {
      setIsTokenError(true);
    }
  }, [matchParams.token, resetToken, t]);

  const handleSubmit = async (data: ResetPasswordFormValues) => {
    if (resetToken) {
      const resetRequestBody: ResetPasswordRequestBody = {
        password: data.password,
        confirm_password: data.confPassword,
      };

      try {
        setIsProcessing(true);
        setErrorCode(null);
        await AuthenticationApiService.resetPassword(
          resetToken,
          resetRequestBody,
        );
        setIsProcessing(false);
        formik.resetForm();
        formik.setStatus(ForgotPasswordFormStatus.IsSent);
      } catch (e) {
        console.error(e);
        const error = e as ApiClientError;
        setIsProcessing(false);
        setErrorCode(selectApiError(error.response?.data.message));
      }
    }
  };

  const formik = useFormik<ResetPasswordFormValues>({
    validationSchema: resetPasswordFormValidationSchema,
    enableReinitialize: true,
    initialValues: resetPasswordFormInitialValues,
    onSubmit: handleSubmit,
  });

  return (
    <ResetPasswordView
      isVerifying={isVerifying}
      isTokenError={isTokenError}
      isProcessing={isProcessing}
      errorCode={errorCode}
      {...formik}
    />
  );
}

export default ResetPassword;
