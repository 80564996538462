import { makeStyles, Theme } from "@material-ui/core/styles";
import { WIDE_CONTAINER_WIDTH } from "constants/settings";

const useImpactStyles = makeStyles((theme: Theme) => ({
  storyFormRow: {
    fontSize: "20px",
    lineHeight: "32px",
    marginBottom: "24px",
    [theme.breakpoints.down(750)]: {
      marginBottom: "16px",
    },
  },
  storyFormField: {
    width: "280px",
    margin: "0 16px",
    [theme.breakpoints.down(WIDE_CONTAINER_WIDTH)]: {
      width: "200px",
    },
    [theme.breakpoints.down(750)]: {
      width: "100%",
      margin: "16px 0",
    },
  },
  storyFormFieldShort: {
    width: "264px",
    margin: "0 16px",
    [theme.breakpoints.down(WIDE_CONTAINER_WIDTH)]: {
      width: "200px",
    },
    [theme.breakpoints.down(750)]: {
      width: "100%",
      margin: "16px 0",
    },
  },
  storyFormFieldLarge: {
    width: "380px",
  },
}));

export { useImpactStyles };
