import React from "react";
import { Button } from "@material-ui/core";
import { RedesignedButton } from "./styles/GBCustomButton.styles";
import { useHistory } from "react-router";

export default React.forwardRef<
  HTMLButtonElement,
  React.ComponentProps<typeof Button> & {
    linkType?: "router-link";
    target?: "_blank";
  }
>((props, ref) => {
  const history = useHistory();
  const { linkType, href, target, ..._props } = props;

  const _onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (props.onClick) {
      props.onClick(e);
    }
    if (linkType === "router-link" && href) {
      history.push(href);
    }
  };

  const _href = linkType !== "router-link" ? href : undefined;
  const _target =
    linkType !== "router-link" && target === "_blank" && href
      ? target
      : undefined;

  return (
    <RedesignedButton
      disableElevation={true}
      ref={ref}
      onClick={_onClick}
      href={_href}
      //@ts-ignore
      target={_target}
      {..._props}
    />
  );
});
