import QuizTile from "components/QuizTile/QuizTile";
import { getRangeArray } from "utils/getRangeArray";
import { useRangeQuizFieldStyles } from "./styles/RangeQuizField.styles";
import cx from "classnames";
import { useMediaQuery, useTheme } from "@material-ui/core";

type RangeQuizFieldProps = {
  id?: string;
  name?: string;
  from: number;
  to: number;
  fromText?: string;
  toText?: string;
  fullWidth?: boolean;
  value?: number;
  className?: string;
  onChange?: (value: number) => void;
  disabled?: boolean;
  error?: boolean;
};

function RangeQuizField(props: RangeQuizFieldProps) {
  const {
    id,
    name,
    from,
    to,
    fromText,
    toText,
    value,
    className,
    fullWidth,
    onChange,
    disabled,
    error,
  } = props;

  const classes = useRangeQuizFieldStyles({ range: to });
  const range = getRangeArray(from, to);
  const theme = useTheme();
  const isDown500 = useMediaQuery(theme.breakpoints.down(500));

  const rootClasses = cx(
    classes.root,
    {
      [classes.fullWidth]: fullWidth,
    },
    className,
  );

  const handleClick = (v: number) => {
    if (onChange) {
      onChange(v);
    }
  };

  return (
    <div id={id} className={rootClasses}>
      <div className={classes.tiles}>
        {range.map((i, k) => (
          <div key={k} className={classes.tileWrap}>
            <QuizTile
              selected={value === i}
              variant="small"
              title={i.toString()}
              onClick={() => handleClick(i)}
              disabled={disabled}
              error={error}
              className={classes.tile}
            />
          </div>
        ))}
      </div>
      <div className={classes.rangeText}>
        {fromText ? (
          <div>
            {isDown500 && `${from} - `}
            {fromText}
          </div>
        ) : null}
        {toText ? (
          <div className={classes.rangedTextRight}>
            {isDown500 && `${to} - `}
            {toText}
          </div>
        ) : null}
      </div>
      <input hidden value={value} id={id} name={name} onChange={() => null} />
    </div>
  );
}

export default RangeQuizField;
