import { makeStyles, Theme } from "@material-ui/core/styles";
import { borderRadiuses, textColors } from "constants/themeSettings";

const useGBStepsProgressStyles = makeStyles<
  Theme,
  { progressPercent?: number }
>((theme: Theme) => ({
  root: {
    height: "24px",
    background: "#EEEEEE",
    borderRadius: borderRadiuses.sharp,
    overflow: "hidden",
    position: "relative",
  },
  progress: ({ progressPercent }) => ({
    width: `${progressPercent}%`,
    backgroundColor: "#4E9F68",
    height: "100%",
  }),
  text: {
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
    left: "50%",
    fontSize: "14px",
    lineHeight: "22px",
    color: textColors.black.main,
    fontWeight: 600,
    cursor: "default",
    userSelect: "none",
  },
  textInverted: {
    color: "#fff",
  },
}));

export { useGBStepsProgressStyles };
