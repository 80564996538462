export enum ValidationRule {
  Required = "required",
  Max = "max",
  Min = "min",
  InvalidEmailFormat = "invalid_email_format",
  InvalidPasswordFormat = "invalid_password_format",
  NotMatch = "not_match",
  RequiredEmail = "required_email",
  TextInputMax = "text_input_max",
  AtLeastOne = "at_least_one",
}
