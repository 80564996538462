import { makeStyles, Theme } from "@material-ui/core";

const useGrowSquareStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: "100px",
    [theme.breakpoints.down(600)]: {
      paddingBottom: "50px",
    },
  },
  grid: {
    display: "grid",
    gridGap: "10px",
    gridTemplateColumns: "repeat(2, 250px)",
    gridTemplateRows: "250px 250px",
    gridAutoFlow: "column",
    position: "relative",
    [theme.breakpoints.down(700)]: {
      gridTemplateColumns: "repeat(2, 200px)",
      gridTemplateRows: "200px 200px",
      gridGap: "5px",
    },
    [theme.breakpoints.down(600)]: {
      gridTemplateColumns: "repeat(2, 150px)",
      gridTemplateRows: "150px 150px",
    },
    [theme.breakpoints.down(460)]: {
      gridTemplateColumns: "repeat(2, 120px)",
      gridTemplateRows: "120px 120px",
    },
  },
  item: {
    backgroundColor: "#E0E0E0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    borderTopLeftRadius: "12px",
    "&:nth-child(3)": {
      borderRadius: "0",
      borderBottomLeftRadius: "12px",
    },
    "&:nth-child(4)": {
      borderRadius: "0",
      borderTopRightRadius: "12px",
    },
    "&:nth-child(5)": {
      borderRadius: "0",
      borderBottomRightRadius: "12px",
    },
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  title: {
    fontSize: "28px",
    fontWeight: 700,
    textTransform: "uppercase",
    [theme.breakpoints.down(700)]: {
      fontSize: "20px",
    },
    [theme.breakpoints.down(600)]: {
      fontSize: "18px",
    },
    [theme.breakpoints.down(460)]: {
      fontSize: "16px",
    },
  },
  subtitle: {
    fontSize: "16px",
    fontWeight: 500,
    [theme.breakpoints.down(700)]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down(600)]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down(460)]: {
      fontSize: "10px",
    },
  },
  inner: {
    position: "absolute",
    color: "#687A8F",
    width: "220px",
    height: "120px",
    backgroundColor: "#fff",
    borderRadius: "12px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    fontSize: "25px",
    fontWeight: 700,
    zIndex: 100,
    [theme.breakpoints.down(700)]: {
      fontSize: "19px",
      width: "180px",
      height: "100px",
    },
    [theme.breakpoints.down(600)]: {
      fontSize: "16px",
      width: "150px",
      height: "70px",
    },
    [theme.breakpoints.down(460)]: {
      fontSize: "14px",
      width: "120px",
      height: "50px",
    },
  },
  arrowY: {
    position: "absolute",
    left: "-70px",
    top: "0",
    bottom: "0",
    [theme.breakpoints.down(600)]: {
      left: "-45px",
    },
  },
  arrowX: {
    position: "absolute",
    bottom: "-70px",
    right: "0",
    [theme.breakpoints.down(600)]: {
      bottom: "-45px",
    },
  },
}));

export { useGrowSquareStyles };
