import cx from "classnames";
import { useGBInputErrorTextStyles } from "./styles/GBInputErrorText.styles";

type GBInputErrorTextProps = {
  text: string;
  removeLeftPadding?: boolean;
  className?: string;
};

function GBInputErrorText(props: GBInputErrorTextProps) {
  const { text, removeLeftPadding, className } = props;
  const classes = useGBInputErrorTextStyles();

  return (
    <div
      className={cx(
        classes.root,
        {
          [classes.removeLeftPadding]: removeLeftPadding,
        },
        className,
      )}
    >
      {text}
    </div>
  );
}

export default GBInputErrorText;
