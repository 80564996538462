import { ImpactPurpose } from "types/impact/ImpactPurpose";
import { ImpactPurposePassionsFormValues } from "./ImpactPurposePassions.types";

export const prepareInitialValues = (
  data?: ImpactPurpose,
): ImpactPurposePassionsFormValues => ({
  mainPassion: data?.mainPassion || "",
  passion1: data?.passion1 || "",
  passion2: data?.passion2 || "",
  passion3: data?.passion3 || "",
});
