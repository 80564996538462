import cx from "classnames";
import { useStageStepTextStyles } from "./styles/StageStepText.styles";

type StageStepTextProps = {
  children: string | JSX.Element;
  className?: string;
  fullWidth?: boolean;
};

function StageStepText(props: StageStepTextProps) {
  const { children, className, fullWidth } = props;
  const classes = useStageStepTextStyles();

  const rootClasses = cx(
    classes.root,
    {
      [classes.fullWidth]: fullWidth,
    },
    className,
  );

  return <div className={rootClasses}>{children}</div>;
}

export default StageStepText;
