import { ImpactPurpose } from "types/impact/ImpactPurpose";
import { ImpactPurposeStory1FormValues } from "./ImpactPurposeStory1.types";

export const prepareInitialValues = (
  data?: ImpactPurpose,
): ImpactPurposeStory1FormValues => ({
  year: data?.year || "",
  city: data?.city || "",
  familyClass: data?.familyClass || "",
  familyRelations: data?.familyRelations || "",
  immediateFamily: data?.immediateFamily || "",
});
