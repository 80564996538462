import { useTheme } from "@material-ui/core/styles";

const ScoreIcon = (): JSX.Element => {
  const theme = useTheme();
  return (
    <svg
      width="88"
      height="98"
      viewBox="0 0 88 98"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.5 5.06218C42.2846 3.45448 45.7154 3.45448 48.5 5.06218L79.8013 23.134C82.5859 24.7417 84.3013 27.7128 84.3013 30.9282V67.0718C84.3013 70.2872 82.5859 73.2583 79.8013 74.866L48.5 92.9378C45.7154 94.5455 42.2846 94.5455 39.5 92.9378L8.19873 74.866C5.41412 73.2583 3.69873 70.2872 3.69873 67.0718V30.9282C3.69873 27.7128 5.41412 24.7417 8.19873 23.134L39.5 5.06218Z"
        className="dynamicColor"
        stroke={theme.palette.secondary.light}
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g transform="translate(26, 35)">
        <path
          d="M2 6L8 2.5359L8 9.4641L2 6Z"
          stroke="#f7882f"
          strokeWidth="2"
        />
      </g>
      <g transform="translate(44, 52)">
        <path
          d="M2 6L8 2.5359L8 9.4641L2 6Z"
          stroke="#07283B"
          strokeWidth="2"
        />
      </g>
      <g transform="translate(62, 31)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-2.27131e-07 6.00001L9 0.803861L9 11.1962L-2.27131e-07 6.00001ZM7 7.73206L7 4.26796L4 6.00001L7 7.73206Z"
          fill="#f7882f"
        />
      </g>
      <rect
        x="23"
        y="29"
        width="4"
        height="40"
        rx="2"
        stroke="#07283B"
        strokeWidth="2"
      />
      <rect
        x="41"
        y="29"
        width="4"
        height="40"
        rx="2"
        stroke="#07283B"
        strokeWidth="2"
      />
      <rect
        x="59"
        y="29"
        width="4"
        height="40"
        rx="2"
        stroke="#07283B"
        strokeWidth="2"
      />
    </svg>
  );
};

export default ScoreIcon;
