import ImpactCard from "../ImpactCard/ImpactCard";
import { useImpactListStyles } from "./styles/ImpactList.styles";
import { SortableContainer } from "react-sortable-hoc";
import { IdentityVerb } from "types/score/DNA";

type ImpactListProps = {
  impacts: Array<IdentityVerb>;
};

function ImpactList(props: ImpactListProps) {
  const { impacts } = props;
  const classes = useImpactListStyles();
  return (
    <div className={classes.impactList}>
      {impacts.map((i, k) => (
        <ImpactCard key={k} impact={i} rank={k + 1} index={k} />
      ))}
    </div>
  );
}

export default SortableContainer(ImpactList);
