import { makeStyles, Theme } from "@material-ui/core/styles";
import { borderRadiuses } from "constants/themeSettings";

const useStageStepImageStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    height: "405px",
    borderRadius: borderRadiuses.round,
    marginBottom: "32px",
    [theme.breakpoints.down("xs")]: {
      height: "300px",
    },
    [theme.breakpoints.down(450)]: {
      height: "200px",
    },
  },
}));

export { useStageStepImageStyles };
