import { ContactUsModalActions, ContactUsModalActionTypes } from "./types";

const openContactUsModalAction = (): ContactUsModalActionTypes => ({
  type: ContactUsModalActions.OPEN,
});

const closeContactUsModalAction = (): ContactUsModalActionTypes => ({
  type: ContactUsModalActions.CLOSE,
});

export const contactUsModalActions = {
  open: openContactUsModalAction,
  close: closeContactUsModalAction,
};
