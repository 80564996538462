export enum pulseFormQuizField {
  EE1P = "EE1P",
  EE2N = "EE2N",
  EE3P = "EE3P",
  EE4N = "EE4N",
  EM1P = "EM1P",
  EM2N = "EM2N",
  EM3P = "EM3P",
  EM4N = "EM4N",
  EP1P = "EP1P",
  EP2N = "EP2N",
  EP3P = "EP3P",
  EP4N = "EP4N",
  ER1P = "ER1P",
  ER2N = "ER2N",
  ER3P = "ER3P",
  ER4N = "ER4N",
  FI1P = "FI1P",
  FI2N = "FI2N",
  FI3P = "FI3P",
  FI4N = "FI4N",
  FO1P = "FO1P",
  FO2N = "FO2N",
  FO3P = "FO3P",
  FO4N = "FO4N",
  FS1P = "FS1P",
  FS2N = "FS2N",
  FS3P = "FS3P",
  FS4N = "FS4N",
  FW1P = "FW1P",
  FW2N = "FW2N",
  FW3P = "FW3P",
  FW4N = "FW4N",
  IJ1P = "IJ1P",
  IJ2N = "IJ2N",
  IJ3P = "IJ3P",
  IJ4N = "IJ4N",
  IC1P = "IC1P",
  IC2N = "IC2N",
  IC3P = "IC3P",
  IC4N = "IC4N",
  IA1P = "IA1P",
  IA2N = "IA2N",
  IA3P = "IA3P",
  IA4N = "IA4N",
  IR1P = "IR1P",
  IR2N = "IR2N",
  IR3P = "IR3P",
  IR4N = "IR4N",
  LG1P = "LG1P",
  LG2N = "LG2N",
  LG3P = "LG3P",
  LG4N = "LG4N",
  LP1P = "LP1P",
  LP2N = "LP2N",
  LP3P = "LP3P",
  LP4N = "LP4N",
  LS1P = "LS1P",
  LS2N = "LS2N",
  LS3P = "LS3P",
  LS4N = "LS4N",
  LV1P = "LV1P",
  LV2N = "LV2N",
  LV3P = "LV3P",
  LV4N = "LV4N",
  SF1N = "SF1N",
  SF2P = "SF2P",
  SF3P = "SF3P",
  SF4N = "SF4N",
  SM1P = "SM1P",
  SM2N = "SM2N",
  SM3N = "SM3N",
  SM4P = "SM4P",
  SR1P = "SR1P",
  SR2N = "SR2N",
  SR3N = "SR3N",
  SR4P = "SR4P",
  SW1P = "SW1P",
  SW2N = "SW2N",
  SW3P = "SW3P",
  SW4N = "SW4N",
  WG1P = "WG1P",
  WG2N = "WG2N",
  WG3P = "WG3P",
  WG4N = "WG4N",
  WP1P = "WP1P",
  WP2N = "WP2N",
  WP3P = "WP3P",
  WP4N = "WP4N",
  WS1P = "WS1P",
  WS2N = "WS2N",
  WS3P = "WS3P",
  WS4N = "WS4N",
  WV1P = "WV1P",
  WV2N = "WV2N",
  WV3P = "WV3P",
  WV4N = "WV4N",
}

export type PulseFormValues = Record<pulseFormQuizField, number> & {
  completed?: boolean;
};

export type PulseFromRequestPayload = {};
