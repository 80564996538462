import _ from "lodash";
import { ImpactPurpose } from "types/impact/ImpactPurpose";
import { ImpactPurposeFinalFormValues } from "./ImpactPurposeResult.types";

export const checkImpactPurposeStageCompleted = (
  purpose: ImpactPurpose | undefined,
) => {
  if (purpose) {
    const { completed, teamFeedback, feedbackRate, ...purposeData } = purpose;
    if (
      Object.keys(purposeData).length ===
      _.compact(Object.values(purposeData)).length
    ) {
      return true;
    }
  }
  return false;
};

export const prepareInitialValues = (
  data?: ImpactPurpose,
): ImpactPurposeFinalFormValues => ({
  purposeFinal: data?.purposeFinal || "",
});
