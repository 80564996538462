import * as Yup from "yup";
import { passwordRegExp } from "constants/regExps";
import { ValidationRule } from "constants/validationRules";
import { ResetPasswordFormValues } from "./ResetPassword.types";

export const resetPasswordFormInitialValues: ResetPasswordFormValues = {
  password: "",
  confPassword: "",
};

export const resetPasswordFormValidationSchema = Yup.object({
  password: Yup.string()
    .trim()
    .matches(passwordRegExp, ValidationRule.InvalidPasswordFormat)
    .required(ValidationRule.Required),
  confPassword: Yup.string()
    .trim()
    .matches(passwordRegExp, ValidationRule.InvalidPasswordFormat)
    .oneOf([Yup.ref("password"), null], ValidationRule.NotMatch)
    .required(ValidationRule.Required),
});
