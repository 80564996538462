import cx from "classnames";
import { Typography } from "@material-ui/core";
import { useAuthBoxStyles } from "./styles/AuthBox.styles";

type AuthBoxProp = {
  children: JSX.Element;
  title?: string;
  className?: string;
};

function AuthBox(props: AuthBoxProp) {
  const { children, title, className } = props;
  const classes = useAuthBoxStyles();

  const rootClasses = cx(classes.root, className);

  return (
    <div className={rootClasses}>
      <div className={classes.box}>
        {title && (
          <Typography
            variant="h3"
            component="h1"
            align="center"
            className={classes.title}
          >
            {title}
          </Typography>
        )}
        {children}
      </div>
    </div>
  );
}

export default AuthBox;
