import GBCircularProgress from "components/GBCircularProgress/GBCircularProgress";
import GBList from "components/GBList/GBList";
import StageStepButton from "components/StageStepButton/StageStepButton";
import StageStepFrame from "components/StageStepFrame/StageStepFrame";
import UnexpectedError from "components/UnexpectedError/UnexpectedError";
import { Namespaces } from "i18n";
import Alert from "@material-ui/lab/Alert";
import { useTranslation } from "react-i18next";
import { useAlignResultStyles } from "./styles/AlignResult.styles";
import GBSpin from "components/GBSpin/GBSpin";
import StageStepTitle from "components/StageStepTitle/StageStepTitle";
import GBModal from "components/GBModal/GBModal";

type AlignResultViewProps = {
  isSuccess: boolean;
  isProcessing: boolean;
  isFetching: boolean;
  isFetchError: boolean;
  topTrials: string[] | undefined;
  topTriumphs: string[] | undefined;
  prevUrl: string | undefined;
  onComplete: () => void;
  getApiError: () => string | undefined;
  isMessage: boolean;
};

function AlignResultView(props: AlignResultViewProps) {
  const {
    isSuccess,
    isProcessing,
    isFetchError,
    isFetching,
    topTrials,
    topTriumphs,
    prevUrl,
    onComplete,
    getApiError,
    isMessage,
  } = props;

  const classes = useAlignResultStyles();
  const { t } = useTranslation([Namespaces.Align, Namespaces.Survey]);

  return (
    <>
      <StageStepFrame
        title={t("steps.title")}
        variant={isSuccess ? "success" : undefined}
        controls={[
          <StageStepButton.Back
            goTo={prevUrl}
            disabled={isFetching || isProcessing}
          >
            {t(`${Namespaces.Survey}:buttons.back`)}
          </StageStepButton.Back>,
          <StageStepButton.Next
            completed
            onClick={onComplete}
            disabled={isFetching || isProcessing || !isSuccess}
            noArrow
          >
            {t(`${Namespaces.Survey}:buttons.complete`)}
          </StageStepButton.Next>,
        ]}
        error={getApiError()}
      >
        {isFetching ? (
          <GBCircularProgress keepHeight="476px" />
        ) : isFetchError ? (
          <UnexpectedError />
        ) : isSuccess && topTriumphs?.length && topTrials?.length ? (
          <GBSpin spinning={isProcessing}>
            <StageStepTitle>{t("steps.result.title")}</StageStepTitle>
            <GBList
              label={t("steps.result.lists.triumphs.label")}
              items={topTriumphs}
              className={classes.list}
            />
            <GBList
              label={t("steps.result.lists.trials.label")}
              items={topTrials}
              className={classes.list}
            />
          </GBSpin>
        ) : (
          <Alert severity="warning">
            {t(`${Namespaces.Survey}:warnings.uncompleted_steps`)}
          </Alert>
        )}
      </StageStepFrame>
      <GBModal
        open={isMessage}
        title="Congratulations!"
        size="medium"
        withTitleDivider
      >
        <p>
          Congratulations on completing Trials and Triumphs. You should have an
          email with your values. You can revisit this exercise at any time.
          <br />
          <br />
          You can now close this window.
        </p>
      </GBModal>
    </>
  );
}

export default AlignResultView;
