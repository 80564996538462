import { useUpdateAlign } from "components/hooks/mutations/useUpdateAlign";
import { useSurvey } from "components/hooks/useSurvey";
import { ApiErrorKey } from "constants/api/apiErrors";
import { generalRoutes } from "constants/generalRoutes";
import { QueryKeys } from "constants/api/queryKeys";
import { Namespaces } from "i18n";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router";
import { ApiClientError } from "types/api/ApiClientError";
import { fetchAlign } from "utils/api/queryFns";
import { selectApiError } from "utils/api/selectApiError";
import { checkSuccess } from "./AlignResult.utils";
import AlignResultView from "./AlignResultView";

function AlignResult() {
  const { t } = useTranslation([
    Namespaces.Align,
    Namespaces.Survey,
    Namespaces.Common,
  ]);

  const { prevUrl } = useSurvey();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [apiError, setApiError] = useState<ApiErrorKey | null>(null);
  const [isMessage, setIsMessage] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const history = useHistory();
  const updateAlign = useUpdateAlign();

  const alignQuery = useQuery(fetchAlign.key, fetchAlign.fn);
  const align = useMemo(
    () => alignQuery.data?.data.payload,
    [alignQuery.data?.data.payload],
  );

  const isFetching = useMemo(
    () => alignQuery.isLoading,
    [alignQuery.isLoading],
  );

  const isFetchError = useMemo(() => alignQuery.isError, [alignQuery.isError]);

  const isSuccess = useMemo(() => checkSuccess(align), [align]);

  const getApiError = useCallback(() => {
    return apiError
      ? t([
          `steps.info.errors.${apiError}`,
          `${Namespaces.Survey}:errors.${apiError}`,
          `${Namespaces.Common}:errors.${apiError}`,
        ])
      : undefined;
  }, [apiError, t]);

  const handleComplete = async () => {
    try {
      setIsProcessing(true);
      setApiError(null);
      await updateAlign.mutateAsync({ align, completed: true });
      await queryClient.refetchQueries([QueryKeys.fetchAlign]);
      setIsProcessing(false);
      //history.push(generalRoutes.USER_MENU);
      setIsMessage(true);
    } catch (e) {
      console.error(e);
      const error = e as ApiClientError;
      setIsProcessing(false);
      setApiError(selectApiError(error.response?.data.message));
    }
  };

  return (
    <AlignResultView
      isSuccess={isSuccess}
      isProcessing={isProcessing}
      isFetchError={isFetchError}
      isFetching={isFetching}
      topTrials={align?.importantTrials}
      topTriumphs={align?.importantTriumphs}
      prevUrl={prevUrl}
      onComplete={handleComplete}
      getApiError={getApiError}
      isMessage={isMessage}
    />
  );
}

export default AlignResult;
