import { useHeaderStyles } from "./styles/Header.styles";
import { AppBar, Drawer, useTheme } from "@material-ui/core";
import ElevationScroll from "components/ElevationScroll/ElevationScroll";
import GBContainer from "components/GBContainer/GBContainer";
import cx from "classnames";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { useState } from "react";
import React from "react";
import MobileMenu from "./components/MobileMenu/MobileMenu";

type HeaderProps = {
  fixed?: boolean;
  nav?: Array<JSX.Element | null>;
  buttons?: JSX.Element[];
  containerWith?: number;
  logo: JSX.Element;
  appBarClassName?: string;
  hidePricing?: boolean;
};

function Header(props: HeaderProps) {
  const { logo, fixed, nav, buttons, containerWith, appBarClassName } = props;

  const classes = useHeaderStyles();
  const theme = useTheme();

  const isDownSm = useMediaQuery(theme.breakpoints.down("sm"));

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setIsMobileMenuOpen(open);
    };

  const renderHeader = (position: "fixed" | "static") => (
    <AppBar
      elevation={0}
      className={cx(classes.appBar, appBarClassName)}
      color="default"
      position={position}
    >
      <GBContainer width={containerWith}>
        <div className={classes.root}>
          <div className={classes.logoBox}>{logo}</div>
          {isDownSm ? (
            <React.Fragment key="drawer">
              <IconButton
                onClick={toggleDrawer(true)}
                color="inherit"
                aria-label="menu"
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor="left"
                open={isMobileMenuOpen}
                onClose={toggleDrawer(false)}
              >
                <div
                  role="presentation"
                  onClick={toggleDrawer(false)}
                  onKeyDown={toggleDrawer(false)}
                >
                  <MobileMenu nav={nav} buttons={buttons} />
                </div>
              </Drawer>
            </React.Fragment>
          ) : (
            <div className={classes.menu}>
              {nav?.length ? (
                <ul className={classes.nav}>
                  {nav.map((i, k) =>
                    i ? (
                      <li
                        key={k}
                        className={cx(classes.navItem, {
                          [classes.disablePaddingRight]:
                            k === nav.length - 1 && !buttons,
                        })}
                      >
                        {i}
                      </li>
                    ) : null,
                  )}
                </ul>
              ) : null}
              {buttons?.length ? (
                <ul className={classes.buttons}>
                  {buttons.map((b, k) => (
                    <li key={k} className={classes.buttonItem}>
                      {b}
                    </li>
                  ))}
                </ul>
              ) : null}
            </div>
          )}
        </div>
      </GBContainer>
    </AppBar>
  );

  return (
    <>
      {fixed ? (
        <ElevationScroll>{renderHeader("fixed")}</ElevationScroll>
      ) : (
        renderHeader("static")
      )}
    </>
  );
}

export default Header;
