import React from "react";
import { SvgIcon } from "@material-ui/core";

function DragIcon(props: React.ComponentProps<typeof SvgIcon>) {
  return (
    <SvgIcon {...props} viewBox="0 0 18 8">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.875 0H1.125C0.50625 0 0 0.45 0 1C0 1.55 0.50625 2 1.125 2H16.875C17.4937 2 18 1.55 18 1C18 0.45 17.4937 0 16.875 0ZM1.125 8H16.875C17.4937 8 18 7.55 18 7C18 6.45 17.4937 6 16.875 6H1.125C0.50625 6 0 6.45 0 7C0 7.55 0.50625 8 1.125 8Z"
        fill="#DFDFDF"
      />
    </SvgIcon>
  );
}

export default DragIcon;
