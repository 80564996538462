import { ApiErrorKey } from "constants/api/apiErrors";
import { generalRoutes } from "constants/generalRoutes";
import { QueryKeys } from "constants/api/queryKeys";
import { Namespaces } from "i18n";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router";
import GrowApiService from "services/GrowApiService";
import { ApiClientError } from "types/api/ApiClientError";
import { fetchGrow, fetchGrowResult } from "utils/api/queryFns";
import { selectApiError } from "utils/api/selectApiError";
import { getCompletedStatus } from "./GrowResult.utils";
import GrowResultView from "./GrowResultView";

function GrowResult() {
  const { t } = useTranslation([
    Namespaces.Grow,
    Namespaces.Survey,
    Namespaces.Common,
  ]);

  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [apiError, setApiError] = useState<ApiErrorKey | null>(null);
  const history = useHistory();
  const queryClient = useQueryClient();

  const growQuery = useQuery(fetchGrow.key, fetchGrow.fn);
  const grow = useMemo(
    () => growQuery.data?.data.payload,
    [growQuery.data?.data.payload],
  );

  const growResultQuery = useQuery(fetchGrowResult.key, fetchGrowResult.fn);
  const growResult = useMemo(
    () => growResultQuery.data?.data.payload,
    [growResultQuery.data?.data.payload],
  );

  const handleSubmit = async () => {
    try {
      setIsUpdating(true);
      setApiError(null);
      await GrowApiService.putGrow({
        field: "completed",
        value: true,
      });
      await queryClient.refetchQueries([QueryKeys.fetchGrow]);
      setIsUpdating(false);
      history.push(generalRoutes.USER_MENU);
    } catch (e) {
      console.error(e);
      const error = e as ApiClientError;
      setIsUpdating(false);
      setApiError(selectApiError(error.response?.data.message));
    }
  };

  const isFetching = growQuery.isLoading || growResultQuery.isLoading;
  const isFetchError = growQuery.isError || growResultQuery.isError;

  const getApiError = useCallback(() => {
    return apiError
      ? t([
          `steps.result.errors.${apiError}`,
          `${Namespaces.Survey}:errors.${apiError}`,
          `${Namespaces.Common}:errors.${apiError}`,
        ])
      : undefined;
  }, [apiError, t]);

  const isCompleted = useMemo(
    () => getCompletedStatus(grow) && !!growResult,
    [grow, growResult],
  );

  return (
    <GrowResultView
      growResult={growResult}
      isCompleted={isCompleted}
      onSubmit={handleSubmit}
      isFetching={isFetching}
      isFetchError={isFetchError}
      isUpdating={isUpdating}
      getApiError={getApiError}
    />
  );
}

export default GrowResult;
