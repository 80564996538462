import { useSurvey } from "components/hooks/useSurvey";
import { ApiErrorKey } from "constants/api/apiErrors";
import { generalRoutes } from "constants/generalRoutes";
import { QueryKeys } from "constants/api/queryKeys";
import { Namespaces } from "i18n";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router";
import GrowApiService from "services/GrowApiService";
import { ApiClientError } from "types/api/ApiClientError";
import { fetchGrow } from "utils/api/queryFns";
import { selectApiError } from "utils/api/selectApiError";
import { ComfortBeginFormValues } from "./GrowComfortBegin.types";
import {
  prepareComfortToSubmit,
  prepareInitialValues,
} from "./GrowComfortBegin.utils";
import GrowComfortBeginView from "./GrowComfortBeginView";

function GrowComfortBegin() {
  const { t } = useTranslation([
    Namespaces.Grow,
    Namespaces.Survey,
    Namespaces.Common,
  ]);

  const { nextUrl } = useSurvey();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [apiError, setApiError] = useState<ApiErrorKey | null>(null);
  const history = useHistory();
  const queryClient = useQueryClient();

  const growQuery = useQuery(fetchGrow.key, fetchGrow.fn);
  const grow = useMemo(
    () => growQuery.data?.data.payload,
    [growQuery.data?.data.payload],
  );

  const preparedInitialValues = useMemo(
    () => prepareInitialValues(grow),
    [grow],
  );

  const handleStepNext = () => {
    if (nextUrl) {
      history.push(nextUrl);
    } else {
      history.push(generalRoutes.GROW_MENU);
    }
  };

  const handleFormSubmit = async (data: ComfortBeginFormValues) => {
    try {
      setIsUpdating(true);
      setApiError(null);
      await GrowApiService.putGrow({
        field: "comfort1",
        value: prepareComfortToSubmit(data),
      });
      await queryClient.refetchQueries([QueryKeys.fetchGrow]);
      setIsUpdating(false);
      handleStepNext();
    } catch (e) {
      console.error(e);
      const error = e as ApiClientError;
      setIsUpdating(false);
      setApiError(selectApiError(error.response?.data.message));
    }
  };

  const isFetching = growQuery.isLoading;
  const isFetchError = growQuery.isError;

  const getApiError = useCallback(() => {
    return apiError
      ? t([
          `steps.comfort_begin.errors.${apiError}`,
          `${Namespaces.Survey}:errors.${apiError}`,
          `${Namespaces.Common}:errors.${apiError}`,
        ])
      : undefined;
  }, [apiError, t]);

  return (
    <GrowComfortBeginView
      onSubmit={handleFormSubmit}
      initialValues={preparedInitialValues}
      isFetching={isFetching}
      isFetchError={isFetchError}
      isUpdating={isUpdating}
      getApiError={getApiError}
    />
  );
}

export default GrowComfortBegin;
