export enum QueryKeys {
  fetchProfile = "fetchProfile",
  fetchAlign = "fetchAlign",
  fetchScore = "fetchScore",
  fetchPulse = "fetchPulse",
  fetchImpact = "fetchImpact",
  fetchGrow = "fetchGrow",
  fetchScoreResult = "fetchScoreResult",
  fetchAlignResult = "fetchAlignResult",
  fetchSurveyResultShort = "fetchSurveyResultShort",
  fetchSuperAdminUserDetail = "fetchSuperAdminUserDetail",
  fetchPartnerUserDetail = "fetchPartnerUserDetail",
  fetchSuperAdminAdvCodes = "fetchSuperAdminAdvCodes",
  fetchGrowResult = "fetchGrowResult",
  fetchPartnerProfile = "fetchPartnerProfile",
  fetchSurveysResult = "fetchSurveysResult",
  fetchPaymentConfig = "fetchPaymentConfig",
  fetchEmotionalIntl = "fetchEmotionalIntl",
  // fetchPurposePulse = "fetchPurposePulse",
}
