import { makeStyles, Theme } from "@material-ui/core/styles";
import { errorColor } from "constants/themeSettings";

const useGBInputErrorTextStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: "10px 0 0 10px",
    color: errorColor.main,
    fontSize: "14px",
    fontWeight: 400,
  },
  removeLeftPadding: {
    paddingLeft: 0,
  },
}));

export { useGBInputErrorTextStyles };
