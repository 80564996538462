import { useTranslation } from "react-i18next";
import { Namespaces } from "i18n";
import { useFormik } from "formik";
import { usePulseFormStyles } from "./styles/PulseForm.styles";
import GBTimeIcon from "components/svg-icons/GBTimeIcon";
import { PulseFormValues } from "types/score/PulseForm.types";
import {
  pulseFormValidationSchema,
  pulseQuizFields,
  pulseQuizFieldSettings,
} from "constants/purposeScoreTypes";
import FormRow from "components/FormRow/FormRow";
import GBStaticInputLabel from "components/GBStaticInputLabel/GBStaticInputLabel";
// import { ValidationRule } from "constants/validationRules";
import RangeQuizField from "components/RangeQuizField/RangeQuizField";
import StageStepFrame from "components/StageStepFrame/StageStepFrame";
import StageStepButton from "components/StageStepButton/StageStepButton";
import { ApiErrorKey } from "constants/api/apiErrors";
import GBSpin from "components/GBSpin/GBSpin";

type PulseFormViewProps = {
  onValueChange: (key: string, value: string) => void;
  initialValues: PulseFormValues;
  getApiError: () => string | undefined;
  getFormError: () => string | undefined;
  setFormError: (code: string | null) => void;
  setApiError: (code: ApiErrorKey | null) => void;
  onStepNext: () => void;
};

function PulseFormView(props: PulseFormViewProps) {
  const {
    getApiError,
    getFormError,
    setFormError,
    setApiError,
    onValueChange,
    initialValues,
    onStepNext,
  } = props;

  const { t } = useTranslation([
    Namespaces.Score,
    Namespaces.Survey,
    Namespaces.Common,
  ]);
  const classes = usePulseFormStyles();

  const formik = useFormik<PulseFormValues>({
    validationSchema: pulseFormValidationSchema,
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: () => handleNextClick(),
  });

  const {
    values,
    handleSubmit,
    touched,
    errors,
    setFieldValue,
    // setFieldTouched,
    // validateField,
  } = formik;

  const hasError = (name: keyof PulseFormValues): boolean => {
    return !!(touched[name] && errors[name]);
  };

  // const getError = (
  //   field: keyof PulseFormValues,
  //   errorCode: ValidationRule,
  // ) => {
  //   return t([
  //     `pulse.form.fields.${field}.errors.${errorCode}`,
  //     `${Namespaces.Common}:errors.${errorCode}`,
  //   ]);
  // };

  const handleNextClick = () => {
    if (formIsValid()) {
      setApiError(null);
      setFormError(null);
      onStepNext();
    }
  };

  // const handleBlurCustom = (field: string, value: string) => {
  //   setFieldTouched(field);
  //   validateField(field);
  //   onValueChange(field, value);
  // };

  const handleChangeSave = (i: string, value: number) => {
    setFieldValue(i, value);
    onValueChange(i, value.toString());
  };

  const formIsValid = () => {
    return Object.keys(errors).length === 0;
  };

  return (
    <StageStepFrame
      title={t("pulse.form.steps.title")}
      controls={[
        <StageStepButton.Next
          onClick={() => formik.handleSubmit()}
          disabled={!formIsValid()}
        >
          {t(`${Namespaces.Score}:pulse.form.buttons.submit`)}
        </StageStepButton.Next>,
      ]}
      error={getApiError() || getFormError()}
      childrenClassName={classes.children}
    >
      <div className={classes.subtitle}>{t("pulse.form.steps.subtitle")}</div>
      <div className={classes.time}>
        <GBTimeIcon className={classes.timeIcon} />
        {t("pulse.form.steps.time")}
      </div>
      <GBSpin spinning={false}>
        <form onSubmit={handleSubmit} className={classes.wrapper}>
          <div>
            {pulseQuizFields.map((i, k) => (
              <FormRow key={k} fullWidth className={classes.quizFormRow}>
                <GBStaticInputLabel
                  labelFor={i}
                  variant="small"
                  text={t(`pulse.form.fields.${i}.label`)}
                />
                <RangeQuizField
                  fullWidth
                  id={i}
                  name={i}
                  value={values[i]}
                  from={pulseQuizFieldSettings.smallest}
                  to={pulseQuizFieldSettings.highest}
                  error={hasError(i)}
                  fromText={t(`pulse.form.fields.${i}.smallest`)}
                  toText={t(`pulse.form.fields.${i}.highest`)}
                  onChange={(value: number) => handleChangeSave(i, value)}
                />
              </FormRow>
            ))}
          </div>
        </form>
      </GBSpin>
    </StageStepFrame>
  );
}
export default PulseFormView;
