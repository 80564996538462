import React from "react";
import { useDispatch } from "react-redux";
import { authActions } from "store/auth/actions";

type AuthWatcherProps = {
  children: React.ReactNode;
};

function AuthWatcher(props: AuthWatcherProps) {
  const { children } = props;
  const dispatch = useDispatch();
  dispatch(authActions.checkAuth());
  return <>{children}</>;
}

export default AuthWatcher;
