import { usePulseResultFieldStyles } from "./styles/PulseResultField.styles";
import cx from "classnames";
import { useMediaQuery, useTheme } from "@material-ui/core";

type PulseResultFieldProps = {
  id?: string;
  name?: string;
  description?: string;
  q1_title?: string;
  q2_title?: string;
  q3_title?: string;
  q4_title?: string;
  fullWidth?: boolean;
  value: number;
  className?: string;
  error?: boolean;
};

function PulseResultField(props: PulseResultFieldProps) {
  const {
    id,
    name,
    description,
    q1_title,
    q2_title,
    q3_title,
    q4_title,
    value,
    className,
    fullWidth,
    // error,
  } = props;

  const classes = usePulseResultFieldStyles({ value: value });
  // const range = getRangeArray(from, to);
  const theme = useTheme();
  const isDown500 = useMediaQuery(theme.breakpoints.down(500));

  const rootClasses = cx(
    classes.root,
    {
      [classes.fullWidth]: fullWidth,
    },
    className,
  );

  return (
    <div id={id} className={rootClasses}>
      <div className={classes.insight}>
        <div>
          <div className={classes.insightTitle}>{name}</div>
          <div className={classes.text}>{description}</div>
        </div>

        <div className={classes.pulseBar}>
          <div className={classes.redBar} />
          <div className={classes.yellowBar} />
          <div className={classes.blueBar} />
          <div className={classes.greenBar} />
          <div className={classes.dot} />
        </div>
        <div className={classes.rangeText}>
          {q1_title ? (
            <div className={classes.label}>
              {isDown500}
              {q1_title}
            </div>
          ) : null}
          {q2_title ? (
            <div className={classes.label}>
              {isDown500}
              {q2_title}
            </div>
          ) : null}
          {q3_title ? (
            <div className={classes.label}>
              {isDown500}
              {q3_title}
            </div>
          ) : null}
          {q4_title ? (
            <div className={classes.label}>
              {isDown500}
              {q4_title}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default PulseResultField;
