import { Typography, useMediaQuery, useTheme } from "@material-ui/core";
import FormRow from "components/FormRow/FormRow";
import GBOutlinedInput from "components/GBOutlinedInput/GBOutlinedInput";
import TextChip from "components/TextChip/TextChip";
import { FieldArray, Form, FormikProps } from "formik";
import { Namespaces } from "i18n";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AlignInfoFormValues } from "../../AlignInfo.types";
import { useInfoFormStyles } from "./styles/InfoForm.styles";

type InfoFormProps = FormikProps<AlignInfoFormValues> & {
  setFormError: (code: string | null) => void;
  submitButtonRef: React.RefObject<HTMLButtonElement>;
};

function InfoForm(props: InfoFormProps) {
  const {
    setFormError,
    submitButtonRef,
    errors,
    values,
    handleChange,
    handleBlur,
  } = props;

  const classes = useInfoFormStyles();
  const { t } = useTranslation(Namespaces.Align);
  const theme = useTheme();
  const isDownXs = useMediaQuery(theme.breakpoints.down("xs"));

  const formErrors = useMemo(() => Object.values(errors), [errors]);

  useEffect(() => {
    if (formErrors.length && typeof formErrors[0] === "string") {
      setFormError(formErrors[0]);
    } else {
      setFormError(null);
    }
  }, [formErrors, setFormError]);

  return (
    <Form className={classes.wrapper}>
      <div>
        <Typography variant="h4" component="h4" className={classes.label}>
          {t("steps.info.trials.title")}
        </Typography>
        <FieldArray
          name="trials"
          render={() => (
            <>
              {values.trials.map((trial, index) => (
                <FormRow key={index} className={classes.formRow}>
                  <GBOutlinedInput
                    id="trials"
                    name={`trials.${index}`}
                    placeholder={t(`steps.info.trials.placeholder.${index}`)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={trial}
                    error={!!errors.trials}
                    className={classes.input}
                  />
                  {!isDownXs && (
                    <TextChip
                      className={classes.number}
                      text={(index + 1).toString()}
                    />
                  )}
                </FormRow>
              ))}
            </>
          )}
        />
      </div>
      <div>
        <Typography variant="h4" component="h4" className={classes.label}>
          {t("steps.info.triumphs.title")}
        </Typography>
        <FieldArray
          name="triumphs"
          render={() => (
            <>
              {values.triumphs.map((triumph, index) => (
                <FormRow key={index} className={classes.formRow}>
                  <GBOutlinedInput
                    id="triumphs"
                    name={`triumphs.${index}`}
                    placeholder={t(`steps.info.triumphs.placeholder.${index}`)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={triumph}
                    error={!!errors.triumphs}
                    className={classes.input}
                  />
                </FormRow>
              ))}
            </>
          )}
        />
      </div>
      <button ref={submitButtonRef} type="submit" hidden></button>
    </Form>
  );
}

export default InfoForm;
