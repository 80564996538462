import { useSurvey } from "components/hooks/useSurvey";
import { ApiErrorKey } from "constants/api/apiErrors";
import { generalRoutes } from "constants/generalRoutes";
import { QueryKeys } from "constants/api/queryKeys";
import { Namespaces } from "i18n";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router";
import { ApiClientError } from "types/api/ApiClientError";
import { fetchImpact } from "utils/api/queryFns";
import { selectApiError } from "utils/api/selectApiError";
import { prepareInitialValues } from "./ImpactPurposeStory2.utils";
import { ImpactPurposeStory2FormValues } from "./ImpactPurposeStory2.types";
import ImpactPurposeStory2View from "./ImpactPurposeStory2View";
import { useUpdateImpact } from "components/hooks/mutations/useUpdateImpact";

function ImpactPurposeStory2() {
  const { t } = useTranslation([
    Namespaces.Impact,
    Namespaces.Survey,
    Namespaces.Common,
  ]);

  const { prevUrl, nextUrl } = useSurvey();
  const history = useHistory();
  const queryClient = useQueryClient();
  const updateImpact = useUpdateImpact();

  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [apiError, setApiError] = useState<ApiErrorKey | null>(null);

  const impactQuery = useQuery(fetchImpact.key, fetchImpact.fn);
  const impact = useMemo(
    () => impactQuery.data?.data.payload,
    [impactQuery.data?.data.payload],
  );

  const isFetching = useMemo(
    () => impactQuery.isLoading,
    [impactQuery.isLoading],
  );

  const isFetchError = useMemo(
    () => impactQuery.isError,
    [impactQuery.isError],
  );

  const preparedInitialValues = useMemo(
    () => prepareInitialValues(impact?.lifePurpose),
    [impact?.lifePurpose],
  );

  const handleStepNext = () => {
    if (nextUrl) {
      history.push({ pathname: nextUrl, search: history.location.search });
    }
  };

  const handleFormSubmit = async (data: ImpactPurposeStory2FormValues) => {
    try {
      setIsUpdating(true);
      setApiError(null);
      await updateImpact.mutateAsync({
        purposeFormData: data,
        impact,
      });
      await queryClient.refetchQueries([QueryKeys.fetchImpact]);
      setIsUpdating(false);
      handleStepNext();
    } catch (e) {
      console.error(e);
      const error = e as ApiClientError;
      setIsUpdating(false);
      setApiError(selectApiError(error.response?.data.message));
    }
  };

  const getApiError = useCallback(() => {
    return apiError
      ? t([
          `purpose.story2.errors.${apiError}`,
          `${Namespaces.Survey}:errors.${apiError}`,
          `${Namespaces.Common}:errors.${apiError}`,
        ])
      : undefined;
  }, [apiError, t]);

  return (
    <ImpactPurposeStory2View
      onFormSubmit={handleFormSubmit}
      initialValues={preparedInitialValues}
      prevUrl={prevUrl || generalRoutes.IMPACT_MENU}
      isUpdating={isUpdating}
      isFetching={isFetching}
      isFetchError={isFetchError}
      getApiError={getApiError}
    />
  );
}

export default ImpactPurposeStory2;
