import { ValidationRule } from "constants/validationRules";
import * as Yup from "yup";

export const comfortBeginFormValidationSchema = Yup.object().shape({
  comfort1: Yup.number()
    .moreThan(0, ValidationRule.Required)
    .required(ValidationRule.Required),
  comfort2: Yup.number()
    .moreThan(0, ValidationRule.Required)
    .required(ValidationRule.Required),
  comfort3: Yup.number()
    .moreThan(0, ValidationRule.Required)
    .required(ValidationRule.Required),
  comfort4: Yup.number()
    .moreThan(0, ValidationRule.Required)
    .required(ValidationRule.Required),
  comfort5: Yup.number()
    .moreThan(0, ValidationRule.Required)
    .required(ValidationRule.Required),
});
