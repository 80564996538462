import { makeStyles, Theme } from "@material-ui/core/styles";
import { WIDE_CONTAINER_WIDTH } from "constants/settings";
import { textColors } from "constants/themeSettings";

const usePurposeScoreGuestStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: "50px",
    [theme.breakpoints.down(WIDE_CONTAINER_WIDTH)]: {
      overflow: "hidden",
    },
  },
  subtitle: {
    color: textColors.black.light,
    fontSize: "22px",
    lineHeight: "27px",
    padding: "10px 0",
  },
  time: {
    display: "flex",
    alignItems: "center",
    color: textColors.black.light,
    fontSize: "19px",
    lineHeight: "23px",
  },
  timeIcon: {
    marginRight: "10px",
  },
  headingDivider: {
    marginTop: "41px",
  },
}));

export { usePurposeScoreGuestStyles };
