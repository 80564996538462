import { makeStyles } from "@material-ui/core";
import {
  errorColor,
  primaryColor,
  successColor,
  textColors,
} from "constants/themeSettings";

export const getGbCardInputStyles = (error: boolean) => ({
  base: {
    iconColor: error ? errorColor.main : primaryColor.main,
    fontSize: "18px",
    lineHeight: "26px",
    fontWeight: 600,
    color: textColors.black.main,
    "::placeholder": {
      color: error ? errorColor.main : textColors.black.light,
      fontWeight: 500,
    },
  },
  invalid: {
    iconColor: errorColor.main,
    color: errorColor.main,
  },
  complete: {
    iconColor: successColor.main,
  },
});

export const useGBCardInputStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "64px",
    border: "2px solid #D2D7DD",
    borderRadius: 12,
    cursor: "text",
    "& > div": {
      width: "100%",
      height: "100%",
      padding: "18px 19px 0 24px",
    },
  },
  focused: {
    border: `2px solid ${primaryColor.main}`,
  },
  error: {
    border: `2px solid ${errorColor.main}`,
    backgroundColor: errorColor.tinted,
  },
  completed: {
    border: `2px solid ${successColor.main}`,
  },
  notFocused: {
    border: "2px solid #D2D7DD",
  },
  disabled: {
    opacity: "0.5",
    pointerEvents: "none",
  },
});
