import ScoreFormView from "./ScoreFormView";
import { useSurvey } from "components/hooks/useSurvey";
import { useCallback, useMemo, useState } from "react";
import { ApiErrorKey } from "constants/api/apiErrors";
import { useHistory } from "react-router";
import { useQuery } from "react-query";
import { fetchScore } from "utils/api/queryFns";
import { generalRoutes } from "constants/generalRoutes";
import { Namespaces } from "i18n";
import ScoreApiService from "services/ScoreApiService";
import { useTranslation } from "react-i18next";
import { prepareScoreInitialValues } from "constants/purposeScoreTypes";

function ScoreForm() {
  const { t } = useTranslation([
    Namespaces.Align,
    Namespaces.Survey,
    Namespaces.Common,
  ]);

  const { nextUrl } = useSurvey();
  const [apiError, setApiError] = useState<ApiErrorKey | null>(null);
  const [formError, setFormError] = useState<string | null>(null);
  const history = useHistory();

  const scoreQuery = useQuery(fetchScore.key, fetchScore.fn);
  const score = useMemo(
    () => scoreQuery.data?.data.payload,
    [scoreQuery.data?.data.payload],
  );

  const preparedInitialValues = useMemo(
    () =>
      prepareScoreInitialValues(
        score?.score,
        score?.DNA,
        score?.pulse,
        score?.completed,
      ),
    [score],
  );

  const handleStepNext = async () => {
    try {
      await ScoreApiService.putScore({
        field: "completed",
        value: true,
        step: "score",
      });
      if (nextUrl) {
        history.push(nextUrl);
      } else {
        history.push(generalRoutes.SCORE_MENU);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleValueChange = async (key: string, value: string | boolean) => {
    try {
      await ScoreApiService.putScore({ field: key, value, step: "score" });
    } catch (e) {
      console.error(e);
    }
  };
  const isFetching = useMemo(
    () => scoreQuery.isLoading,
    [scoreQuery.isLoading],
  );

  const isFetchError = useMemo(() => scoreQuery.isError, [scoreQuery.isError]);

  const getApiError = useCallback(() => {
    return apiError
      ? t([
          `steps.info.errors.${apiError}`,
          `${Namespaces.Survey}:errors.${apiError}`,
          `${Namespaces.Common}:errors.${apiError}`,
        ])
      : undefined;
  }, [apiError, t]);

  const getFormError = useCallback(() => {
    return formError
      ? t([
          `steps.info.errors.${formError}`,
          `${Namespaces.Survey}:errors.${formError}`,
          `${Namespaces.Common}:errors.${formError}`,
        ])
      : undefined;
  }, [formError, t]);

  return (
    <ScoreFormView
      onValueChange={handleValueChange}
      initialValues={preparedInitialValues.score}
      isFetching={isFetching}
      isFetchError={isFetchError}
      getApiError={getApiError}
      getFormError={getFormError}
      setFormError={setFormError}
      setApiError={setApiError}
      onStepNext={handleStepNext}
    />
  );
}
export default ScoreForm;
