export enum IdentityTypeEnum {
  ACHIEVEMENT = "achievement",
  CONSERVATION = "conservation",
  CARING = "caring",
  FREEDOM = "freedom",
  RESPECT = "respect",
  TRADITION = "tradition",
  ENJOYMENT = "enjoyment",
  STABILITY = "stability",
  EQUALITY = "equality",
}

export type IdentityTypeEnumKeys = keyof typeof IdentityTypeEnum;

export type IdentityType = {
  title: string;
  verbsTitle: string;
  description: string;
  verbs: Array<string>;
};

export type IdentityVerb = {
  identity: string;
  verb: string;
  rank: number;
};

export type IdentityVerbs = {
  [id: string]: Array<string>;
};

export type IdentityTypes = {
  [key in keyof typeof IdentityTypeEnum]: IdentityType;
};

export type DNAFormValues = {
  identities: Array<string>;
  impacts: Array<IdentityVerb>;
  completed?: boolean;
};
