import { makeStyles, Theme } from "@material-ui/core/styles";
import { WIDE_CONTAINER_WIDTH } from "constants/settings";
import { successColor } from "constants/themeSettings";

export const useStageStepFrameStyles = makeStyles((theme: Theme) => ({
  root: {},
  headingBlock: {},
  headingDetails: {
    marginTop: "16px",
  },
  mainTitle: {
    lineHeight: "34px",
  },
  headingDivider: {
    margin: "12px 0 0 0",
  },
  controlsDivider: {
    margin: "0 0 16px 0",
  },
  controlsBlock: {},
  controlsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    margin: "-8px",
    [theme.breakpoints.down(WIDE_CONTAINER_WIDTH)]: {
      justifyContent: "center",
    },
  },
  controlsItem: {
    padding: "8px",
  },
  children: {
    marginTop: "24px",
    marginBottom: "72px",
  },
  titleSuccess: {
    color: successColor.main,
  },
  successIcon: {
    fill: successColor.main,
    marginLeft: "11px",
    width: "26px",
    height: "26px",
  },
}));
