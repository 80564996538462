import ExerciseView from "./ExerciseView";
import { useSurvey } from "components/hooks/useSurvey";
import { useLocation } from "react-router";

function Exercise() {
  const { survey, stage, step } = useSurvey();
  const location = useLocation();
  return (
    <ExerciseView
      survey={survey}
      stage={stage}
      step={step}
      token={new URLSearchParams(location.search).get("token")}
    />
  );
}
export default Exercise;
