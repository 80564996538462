import GBCircularProgress from "components/GBCircularProgress/GBCircularProgress";
import UnexpectedError from "components/UnexpectedError/UnexpectedError";
import AlignSection from "./components/AlignSection/AlignSection";
import HeaderSection from "pages/Snapshot/components/HeaderSection/HeaderSection";
import ImpactSection from "./components/ImpactSection/ImpactSection";
import ScoreSection from "./components/ScoreSection/ScoreSection";
import {
  AlignSectionData,
  ImpactSectionData,
  ScoreSectionData,
} from "./Step1.types";
import { useSnapshotStyles } from "pages/Snapshot/styles/Snapshot.styles";
import { useTranslation } from "react-i18next";
import { Namespaces } from "i18n";
import { Alert } from "@material-ui/lab";

type SnapshotViewProps = {
  isFetching: boolean;
  isFetchError: boolean;
  scoreSectionData: ScoreSectionData;
  alignSectionData: AlignSectionData;
  impactSectionData: ImpactSectionData;
  isStepsIncomplete: boolean;
};

function Step1View(props: SnapshotViewProps) {
  const {
    isFetching,
    isFetchError,
    scoreSectionData,
    alignSectionData,
    impactSectionData,
    isStepsIncomplete,
  } = props;

  const classes = useSnapshotStyles();
  const { t } = useTranslation(Namespaces.Snapshot);

  return (
    <>
      <HeaderSection text={t("step1.header.subtitle")} />
      {isFetching ? (
        <GBCircularProgress keepHeight="1000px" />
      ) : isFetchError ? (
        <div className={classes.errorBlock}>
          <UnexpectedError />
        </div>
      ) : (
        <div>
          {isStepsIncomplete ? (
            <Alert severity="warning">
              {t("step1.messages.steps_incomplete")}
            </Alert>
          ) : (
            <>
              {scoreSectionData.identities || scoreSectionData.score ? (
                <ScoreSection data={scoreSectionData} />
              ) : null}
              {alignSectionData.trials && alignSectionData.triumphs ? (
                <AlignSection data={alignSectionData} />
              ) : null}
              {impactSectionData.promise || impactSectionData.purpose ? (
                <ImpactSection data={impactSectionData} />
              ) : null}
            </>
          )}
        </div>
      )}
    </>
  );
}

export default Step1View;
