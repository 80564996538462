import ApiError from "components/ApiError/ApiError";
import { ApiErrorKey } from "constants/api/apiErrors";
import { QueryUsersFn } from "./AdminUsers.types";
import Table from "./components/Table/Table";

type AdminUsersViewProps = {
  queryUsers: QueryUsersFn;
  apiError: ApiErrorKey | null;
};

function AdminUsersView(props: AdminUsersViewProps) {
  const { queryUsers, apiError } = props;

  return (
    <>
      {apiError ? (
        <ApiError errorKey={apiError} />
      ) : (
        <Table queryData={queryUsers} />
      )}
    </>
  );
}

export default AdminUsersView;
