import { useGBStepsProgressStyles } from "./styles/GBStepsProgress.styles";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { Namespaces } from "i18n";

type GBStepsProgressProps = {
  all: number;
  current: number;
  className?: string;
};

function GBStepsProgress(props: GBStepsProgressProps) {
  const { all, current, className } = props;
  const { t } = useTranslation(Namespaces.Common);
  const classes = useGBStepsProgressStyles({
    progressPercent: (current / all) * 100,
  });

  const rootClasses = cx(classes.root, className);
  const textClasses = cx(classes.text, {
    [classes.textInverted]: current > all / 2,
  });

  return (
    <div className={rootClasses}>
      <div className={textClasses}>
        {t("misc.steps_of_range", {
          current,
          range: all,
        })}
      </div>
      <div className={classes.progress}></div>
    </div>
  );
}

export default GBStepsProgress;
