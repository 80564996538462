import { makeStyles, Theme } from "@material-ui/core/styles";
import { WIDE_CONTAINER_WIDTH } from "constants/settings";

const useThemeFormStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    [theme.breakpoints.down(WIDE_CONTAINER_WIDTH)]: {
      justifyContent: "center",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      "& > div:first-child": {
        marginBottom: "40px",
      },
    },
  },
  formRow: {
    display: "flex",
    alignItems: "center",
    marginBottom: "16px",
  },
  input: {
    width: "404px",
    [theme.breakpoints.down(WIDE_CONTAINER_WIDTH)]: {
      width: "330px",
    },
    [theme.breakpoints.down(770)]: {
      width: "250px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  number: {
    margin: "0 24px",
    [theme.breakpoints.down(WIDE_CONTAINER_WIDTH)]: {
      margin: "0 10px",
    },
  },
  label: {
    marginBottom: "32px",
  },
}));

export { useThemeFormStyles };
