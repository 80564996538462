export enum EmotionalIntlQuizField {
  RecognizeEmotions = "recognizeEmotions",
  LoseTemper = "loseTemper",
  GoodListener = "goodListener",
  CalmSelf = "calmSelf",
  EnjoyOrganizing = "enjoyOrganizing",
  MovingOn = "movingOn",
  StrengthsWeaknesses = "strengthsWeaknesses",
  AvoidConflict = "avoidConflict",
  AskFeedback = "askFeedback",
  ReadEmotions = "readEmotions",
  BuildRapport = "buildRapport",
  ActiveListening = "activeListening",
}

export type EmotionalIntlFormValues = Record<EmotionalIntlQuizField, number>;
