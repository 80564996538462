import React from "react";
import { SvgIcon } from "@material-ui/core";

function SuccessIcon(props: React.ComponentProps<typeof SvgIcon>) {
  return (
    <SvgIcon {...props} viewBox="0 0 33 32">
      <path d="M16 0c-3.165 0-6.258 0.938-8.889 2.696s-4.682 4.257-5.893 7.181c-1.211 2.924-1.528 6.141-0.91 9.244s2.141 5.955 4.379 8.192 5.089 3.761 8.192 4.379c3.104 0.617 6.321 0.301 9.244-0.911s5.423-3.262 7.181-5.893c1.758-2.631 2.697-5.725 2.697-8.889-0.005-4.242-1.692-8.309-4.692-11.308s-7.066-4.687-11.308-4.692zM23.62 13.198l-9.026 8.615c-0.229 0.218-0.534 0.34-0.851 0.34s-0.621-0.122-0.851-0.34l-4.512-4.308c-0.236-0.226-0.372-0.536-0.379-0.862s0.115-0.642 0.341-0.878c0.226-0.236 0.535-0.373 0.862-0.381s0.642 0.115 0.879 0.34l3.661 3.496 8.175-7.804c0.236-0.225 0.552-0.347 0.879-0.34s0.636 0.145 0.862 0.38 0.348 0.552 0.341 0.878c-0.007 0.326-0.144 0.637-0.379 0.862z"></path>
    </SvgIcon>
  );
}

export default SuccessIcon;
