import { makeStyles, Theme } from "@material-ui/core";
import { commonColors, textColors } from "constants/themeSettings";
import { Options } from "material-table";

export const useGBMaterialTableStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: "666px",
    width: "100%",
    "& .MuiToolbar-root": {
      paddingLeft: "0",
      paddingRight: "0",

      "& h6": {
        fontWeight: 700,
        fontSize: "28px",
        margin: "0",
        color: commonColors.black,
      },
    },
  },
}));

export function gBMaterialTableStyles<RowData extends object>(
  options: Options<RowData>,
): Options<RowData> {
  return {
    rowStyle: {
      color: commonColors.black,
    },
    headerStyle: {
      minHeight: "52px",
      color: textColors.black.light,
      fontSize: "12px",
      lineHeight: "18px",
      textTransform: "uppercase",
      borderWidth: "2px",
      wordBreak: "keep-all",
    },
    actionsCellStyle: {
      minWidth: "58px",
    },
    pageSizeOptions: [10, 20, 50],
    pageSize: 10,
    ...options,
  };
}
