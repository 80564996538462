import {
  ContactUsModalActions,
  ContactUsModalActionTypes,
  ContactUsModalState,
} from "./types";

export const initialContactUsModalState: ContactUsModalState = {
  open: false,
};

export function contactUsModal(
  state = initialContactUsModalState,
  action: ContactUsModalActionTypes,
): ContactUsModalState {
  switch (action.type) {
    case ContactUsModalActions.OPEN:
      return {
        ...state,
        open: true,
      };
    case ContactUsModalActions.CLOSE:
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
}
