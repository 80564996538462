import React from "react";
import { useAlignCenterStyles } from "./styles/AlignCenter.styles";
import cx from "classnames";

export default function AlignCenter({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  const classes = useAlignCenterStyles();
  return <div className={cx(classes.root, className)}>{children}</div>;
}
