import { useTheme } from "@material-ui/core/styles";

const EngageIcon = (): JSX.Element => {
  const theme = useTheme();
  return (
    <svg
      width="88"
      height="98"
      viewBox="0 0 88 98"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.5 5.06218C42.2846 3.45448 45.7154 3.45448 48.5 5.06218L79.8013 23.134C82.5859 24.7417 84.3013 27.7128 84.3013 30.9282V67.0718C84.3013 70.2872 82.5859 73.2583 79.8013 74.866L48.5 92.9378C45.7154 94.5455 42.2846 94.5455 39.5 92.9378L8.19873 74.866C5.41412 73.2583 3.69873 70.2872 3.69873 67.0718V30.9282C3.69873 27.7128 5.41412 24.7417 8.19873 23.134L39.5 5.06218Z"
        className="dynamicColor"
        stroke={theme.palette.secondary.light}
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g transform="translate(18, 40)">
        <mask id="path-1-inside-1" fill="white">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.08889 15L16.7555 0H35.2444L43.9111 15H8.08889ZM6.93333 17L0 29H52L45.0667 17H6.93333Z"
          />
        </mask>
        <path
          d="M16.7555 0V-2H15.6013L15.0238 -1.00055L16.7555 0ZM8.08889 15L6.35715 13.9994L4.6235 17H8.08889V15ZM35.2444 0L36.9762 -1.00056L36.3987 -2H35.2444V0ZM43.9111 15V17H47.3765L45.6428 13.9994L43.9111 15ZM6.93333 17V15H5.77906L5.2016 15.9994L6.93333 17ZM0 29L-1.73173 27.9994L-3.46538 31H0V29ZM52 29V31H55.4654L53.7317 27.9994L52 29ZM45.0667 17L46.7984 15.9994L46.2209 15H45.0667V17ZM15.0238 -1.00055L6.35715 13.9994L9.82062 16.0006L18.4873 1.00055L15.0238 -1.00055ZM35.2444 -2H16.7555V2H35.2444V-2ZM45.6428 13.9994L36.9762 -1.00056L33.5127 1.00056L42.1794 16.0006L45.6428 13.9994ZM8.08889 17H43.9111V13H8.08889V17ZM5.2016 15.9994L-1.73173 27.9994L1.73173 30.0006L8.66506 18.0006L5.2016 15.9994ZM0 31H52V27H0V31ZM53.7317 27.9994L46.7984 15.9994L43.3349 18.0006L50.2683 30.0006L53.7317 27.9994ZM45.0667 15H6.93333V19H45.0667V15Z"
          fill="black"
          mask="url(#path-1-inside-1)"
        />
      </g>
      <g transform="translate(35, 24)">
        <path
          d="M15.4 13H2.59998L9.00001 2L15.4 13Z"
          stroke="#f7882f"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};

export default EngageIcon;
