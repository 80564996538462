import PulseResultView from "./PulseResultView";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { fetchPulse } from "utils/api/queryFns";
import {
  preparePulseReport,
  pulseReport,
  pulseReportIsValid,
} from "constants/purposeScoreTypes";
import { useSurvey } from "components/hooks/useSurvey";
import { useHistory } from "react-router";

function PulseResult() {
  const history = useHistory();
  const scoreQuery = useQuery(fetchPulse.key, fetchPulse.fn);
  const pulse = useMemo(
    () => scoreQuery.data?.data.payload,
    [scoreQuery.data?.data.payload],
  );
  const { prevUrl } = useSurvey();

  useEffect(() => {
    if (!pulseReportIsValid(pulse) && prevUrl) {
      history.push(prevUrl);
    }
  }, [pulse, history, prevUrl]);

  const pulseResult = useMemo(
    () => preparePulseReport(pulse),
    [pulse],
  ) as pulseReport;
  const isFetching = useMemo(
    () => scoreQuery.isLoading,
    [scoreQuery.isLoading],
  );

  const isFetchError = useMemo(() => scoreQuery.isError, [scoreQuery.isError]);

  return (
    <PulseResultView
      pulseVal={pulseResult}
      isFetching={isFetching}
      isFetchError={isFetchError}
    />
  );
}
export default PulseResult;
