import { DNAFormValues } from "./DNA";
import { PulseFormValues } from "./PulseForm.types";

export enum scoreFormQuizField {
  Day = "day",
  Life = "life",
  LifeIncludes = "lifeIncludes",
  PersonalExistence = "personalExistence",
  Goals = "goals",
  Dreams = "dreams",
  Legacy = "legacy",
  LifeControl = "lifeControl",
  CurrentMissions = "currentMissions",
  Purpose = "purpose",
}

export type ScoreFormValues = Record<scoreFormQuizField, number> & {
  name: string;
  ageRange: string;
  email: string;
  completed?: boolean;
};

export type PurposeFormValues = {
  score: ScoreFormValues;
  DNA: DNAFormValues;
  pulse: PulseFormValues;
  completed: boolean;
};

export type ScoreResult = {
  result?: number;
  valid: boolean;
};

export type ScoreFromRequestPayload = {
  name: string;
  ageRange: string;
  email: string;
  day: number;
  life: number;
  lifeIncludes: number;
  personalExistence: number;
  goals: number;
  dreams: number;
  legacy: number;
  lifeControl: number;
  currentMissions: number;
  purpose: number;
};
