import { ImpactPurpose } from "types/impact/ImpactPurpose";
import { ImpactPurposeStory3FormValues } from "./ImpactPurposeStory3.types";

export const prepareInitialValues = (
  data?: ImpactPurpose,
): ImpactPurposeStory3FormValues => ({
  momInspired: data?.momInspired || "",
  momTaught: data?.momTaught || "",
  dadInspired: data?.dadInspired || "",
  dadTaught: data?.dadTaught || "",
  parentsLesson: data?.parentsLesson || "",
});
