import { makeStyles, Theme } from "@material-ui/core/styles";

const useStageStepTextStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    fontSize: "20px",
    lineHeight: "32px",
    fontWeight: 400,
  },
  fullWidth: {
    width: "100%",
  },
}));

export { useStageStepTextStyles };
