import { useMediaQuery, useTheme } from "@material-ui/core";
import AuthBox from "components/AuthBox/AuthBox";
import { useAuthBoxStyles } from "components/AuthBox/styles/AuthBox.styles";
import ContentSpinner from "components/ContentSpinner/ContentSpinner";
import ErrorText from "components/ErrorText/ErrorText";
import FormRow from "components/FormRow/FormRow";
import GBCustomButton from "components/GBCustomButton/GBCustomButton";
import GBInputErrorText from "components/GBInputErrorText/GBInputErrorText";
import GBOutlinedInput from "components/GBOutlinedInput/GBOutlinedInput";
import GBSimpleTextButton from "components/GBSimpleTextButton/GBSimpleTextButton";
import GBStaticInputLabel from "components/GBStaticInputLabel/GBStaticInputLabel";
import { ApiErrorKey } from "constants/api/apiErrors";
import { ForgotPasswordFormStatus } from "constants/ForgotPasswordFormStatus";
import { ValidationRule } from "constants/validationRules";
import { FormikProps } from "formik";
import { Namespaces } from "i18n";
import { Trans, useTranslation } from "react-i18next";
import { ForgotPasswordFormValues } from "./ForgotPassword.types";
import DoneIcon from "@material-ui/icons/Done";
import { useState } from "react";

type ForgotPasswordViewProps = FormikProps<ForgotPasswordFormValues> & {
  isProcessing: boolean;
  errorCode: ApiErrorKey | null;
  handleGoToLogin: () => void;
};

function ForgotPasswordView(props: ForgotPasswordViewProps) {
  const { isProcessing, errorCode, handleGoToLogin, ...formik } = props;

  const {
    values,
    status,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
  } = formik;

  const { t } = useTranslation([Namespaces.Auth, Namespaces.Common]);
  const authBoxClasses = useAuthBoxStyles();
  const theme = useTheme();
  const isDown450 = useMediaQuery(theme.breakpoints.down(450));

  const [email, setEmail] = useState<string | null>(null);

  const hasError = (name: keyof ForgotPasswordFormValues): boolean => {
    return !!(touched[name] && errors[name]);
  };

  const getError = (
    field: keyof ForgotPasswordFormValues,
    errorCode: ValidationRule,
  ) => {
    return t([
      `forms.forgot.fields.${field}.errors.${errorCode}`,
      `${Namespaces.Common}:errors.${errorCode}`,
    ]);
  };

  const _onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmit();
    }
  };

  return (
    <AuthBox title={t("forms.forgot.title")}>
      <form onSubmit={handleSubmit}>
        <ContentSpinner spinning={isProcessing}>
          <FormRow>
            <div className={authBoxClasses.labelBox}>
              <GBStaticInputLabel
                labelFor="email"
                text={t("forms.forgot.fields.email.label")}
              />
              {!isDown450 && (
                <GBSimpleTextButton
                  textAlign="right"
                  color="primary"
                  onClick={handleGoToLogin}
                >
                  {t("forms.forgot.addition.to_signin")}
                </GBSimpleTextButton>
              )}
            </div>
            <GBOutlinedInput
              id="email"
              fullWidth
              name="email"
              type="text"
              value={values.email}
              onChange={(e) => {
                handleChange(e);
                setEmail(e.target.value);
              }}
              onBlur={handleBlur}
              error={hasError("email")}
              disabled={isProcessing}
              onKeyPress={_onKeyPress}
            />
            {hasError("email") && (
              <GBInputErrorText
                text={getError("email", errors.email as ValidationRule)}
              />
            )}
          </FormRow>
        </ContentSpinner>
        <div className={authBoxClasses.buttonsWrap}>
          <GBCustomButton
            className={authBoxClasses.blockCenter}
            type="submit"
            disabled={isProcessing}
          >
            {t("forms.forgot.buttons.submit")}
          </GBCustomButton>
          {status === ForgotPasswordFormStatus.IsSent && (
            <div className={authBoxClasses.successBox}>
              <DoneIcon />
              <div className={authBoxClasses.successMsg}>
                <Trans
                  i18nKey={t("forms.forgot.success_msg", {
                    email,
                  })}
                  components={{
                    accent: (
                      <span className={authBoxClasses.successMsgAccent} />
                    ),
                  }}
                />
              </div>
            </div>
          )}
          {isDown450 && (
            <div className={authBoxClasses.xsAdditionButtons}>
              <GBSimpleTextButton
                textAlign="center"
                color="primary"
                onClick={handleGoToLogin}
                disabled={isProcessing}
              >
                {t("forms.forgot.addition.to_signin")}
              </GBSimpleTextButton>
            </div>
          )}
          {errorCode ? (
            <ErrorText
              textAlign="center"
              text={t([
                `forms.login.errors.${errorCode}`,
                `${Namespaces.Common}:errors.${errorCode}`,
                `${Namespaces.Common}:errors.unexpected_error`,
              ])}
            />
          ) : null}
        </div>
      </form>
    </AuthBox>
  );
}

export default ForgotPasswordView;
