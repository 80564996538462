import { makeStyles } from "@material-ui/core";
import { borderRadiuses, primaryColor } from "constants/themeSettings";

const useGrowSectionStyles = makeStyles({
  content: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  row: {
    padding: "10px",
    borderRadius: borderRadiuses.main,
    background: "transparent",
    marginBottom: "5px",
    display: "inline-block",
    fontSize: "30px",
    "& b": {
      marginRight: "5px",
    },
  },
  selected: {
    background: primaryColor.main,
    color: "#fff",
  },
});

export { useGrowSectionStyles };
