import { Theme, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { buttonStyles, commonColors } from "constants/themeSettings";

export const RedesignedButton = withStyles((theme: Theme) => ({
  root: {
    borderRadius: buttonStyles.size.default.borderRadius,
    color: buttonStyles.palette.default.textColor,
    backgroundColor: buttonStyles.palette.default.bgColor,
    fontWeight: buttonStyles.size.default.fontWeight,
    fontSize: buttonStyles.size.default.fontSize,
    lineHeight: buttonStyles.size.default.lineHeight,
    padding: buttonStyles.size.default.padding,
    height: buttonStyles.size.default.height,
    textTransform: "capitalize",
    textAlign: "center",
    "&:hover": {
      backgroundColor: "#e0e0e0",
      color: commonColors.black,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "15px",
    },
    "&:disabled": {
      color: "#9fa4a7",
    },
  },
  // disabled: {
  //   color: buttonStyles.palette.default.disabledTextColor,
  //   backgroundColor: buttonStyles.palette.default.disabledBgColor,
  // },
  // text: {
  //   color: buttonStyles.palette.default.textColor,
  //   backgroundColor: "none",
  //   "&:hover": {
  //     color: buttonStyles.palette.default.hoverTextColor,
  //     backgroundColor: "none",
  //   },
  // },
  sizeSmall: {
    padding: buttonStyles.size.small.padding,
    fontWeight: buttonStyles.size.small.fontWeight,
    fontSize: buttonStyles.size.small.fontSize,
    lineHeight: buttonStyles.size.small.lineHeight,
    height: buttonStyles.size.small.height,
    [theme.breakpoints.down("xs")]: {
      fontSize: "15px",
    },
  },
  sizeLarge: {
    padding: buttonStyles.size.large.padding,
    fontWeight: buttonStyles.size.large.fontWeight,
    fontSize: buttonStyles.size.large.fontSize,
    lineHeight: buttonStyles.size.large.lineHeight,
    height: buttonStyles.size.large.height,
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
      padding: "0 30px",
    },
  },

  // textPrimary: {
  //   color: theme.palette.primary.main,
  //   "&:hover": {
  //     color: "#F4854F",
  //     backgroundColor: "transparent",
  //   },
  //   "&:disabled": {
  //     color: "#ECEBED",
  //     fontWeight: 500,
  //     backgroundColor: "transparent",
  //   },
  // },

  // textSecondary: {
  //   color: theme.palette.secondary.main,
  //   fontWeight: 500,
  //   "&:hover": {
  //     color: theme.palette.secondary.light,
  //     fontWeight: 500,
  //     backgroundColor: "transparent",
  //   },
  //   "&:disabled": {
  //     color: "#D7CAC4",
  //     fontWeight: 500,
  //     backgroundColor: "transparent",
  //   },
  // },

  // outlined: {
  //   border: `2px solid ${buttonStyles.palette.default.textColor}`,
  //   backgroundColor: "#FFF",
  //   "&:hover": {
  //     backgroundColor: "transparent",
  //     border: "2px solid #F4854F",
  //   },
  //   "&:disabled": {
  //     backgroundColor: "transparent",
  //     border: "2px solid #ECEBED",
  //   },
  // },

  // outlinedPrimary: {
  //   color: theme.palette.primary.main,
  //   backgroundColor: "transparent",
  //   border: `2px solid ${theme.palette.primary.main}`,
  //   "&:hover": {
  //     color: "#F4854F",
  //     backgroundColor: "transparent",
  //     border: "2px solid #F4854F",
  //   },
  //   "&:disabled": {
  //     color: "#D7CAC4",
  //     border: "2px solid #ECEBED",
  //     backgroundColor: "transparent",
  //   },
  // },

  // outlinedSecondary: {
  //   color: theme.palette.secondary.main,
  //   fontWeight: 500,
  //   border: `2px solid ${theme.palette.secondary.main}`,
  //   backgroundColor: "transparent",
  //   "&:hover": {
  //     color: theme.palette.secondary.main,
  //     fontWeight: 500,
  //     border: `2px solid ${theme.palette.secondary.main}`,
  //     backgroundColor: "transparent",
  //   },
  //   "&:disabled": {
  //     color: "#D7CAC4",
  //     fontWeight: 500,
  //     border: "2px solid #ECEBED",
  //     backgroundColor: "transparent",
  //   },
  // },
  // contained: {
  //   backgroundColor: buttonStyles.palette.primary.bg,
  //   color: buttonStyles.palette.primary.textColor,
  //   "&:hover": {
  //     backgroundColor: buttonStyles.palette.primary.hoverBg,
  //     color: buttonStyles.palette.primary.hoverTextColor,
  //   },
  //   "&:disabled": {
  //     color: "#D7CAC4",
  //     backgroundColor: "#F2EDEB",
  //     fontWeight: 800,
  //   },
  // },
  containedPrimary: {
    color: buttonStyles.palette.primary.textColor,
    backgroundColor: buttonStyles.palette.primary.bgColor,
    "&:hover": {
      color: buttonStyles.palette.primary.textColor,
      backgroundColor: buttonStyles.palette.primary.hoverBgColor,
    },
    "&:disabled": {
      color: buttonStyles.palette.primary.disabledTextColor,
      backgroundColor: buttonStyles.palette.primary.disabledBgColor,
    },
  },
  // containedSecondary: {
  //   color: theme.palette.secondary.contrastText,
  //   backgroundColor: theme.palette.secondary.main,
  //   fontWeight: 500,
  //   "&:hover": {
  //     color: "#F4854F",
  //     backgroundColor: theme.palette.secondary.main,
  //     fontWeight: 500,
  //   },
  //   "&:disabled": {
  //     color: "#D7CAC4",
  //     backgroundColor: "#F2EDEB",
  //     fontWeight: 500,
  //   },
  // },
}))(Button);
