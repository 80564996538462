import { LOCAL_STORAGE_TOKEN_NAME } from "constants/settings";

export const authToken = (() => {
  const getKey = () => LOCAL_STORAGE_TOKEN_NAME;
  const get = () => localStorage.getItem(getKey());
  const set = (token: string) => localStorage.setItem(getKey(), token);
  const remove = () => localStorage.removeItem(getKey());
  return {
    get,
    set,
    remove,
  };
})();
