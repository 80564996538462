import { generalRoutes } from "constants/generalRoutes";
import { SnapshotStep } from "./Snapshot.consts";

export const getSnapshotStepsNav = (
  current: SnapshotStep,
): Record<"prev" | "next", SnapshotStep> => {
  if (current === SnapshotStep.Step1) {
    return {
      prev: SnapshotStep.Step1,
      next: SnapshotStep.Step2,
    };
  }
  return {
    prev: SnapshotStep.Step1,
    next: SnapshotStep.Step2,
  };
};

export const prepareSnapshotPath = (step: SnapshotStep) =>
  generalRoutes.SNAPSHOT;
