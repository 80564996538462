import { makeStyles } from "@material-ui/core/styles";

const useGBContainerStyles = (width: number) =>
  makeStyles(() => ({
    container: {
      "&.MuiContainer-maxWidthLg": {
        maxWidth: `${width}px`,
      },
    },
  }))();

export { useGBContainerStyles };
