import { QueryKeys } from "constants/api/queryKeys";
import SuperAdminApiService from "services/SuperAdminApiService";
import AlignApiService from "services/AlignApiService";
import CommonApiService from "services/CommonApiService";
import GrowApiService from "services/GrowApiService";
import ImpactApiService from "services/ImpactApiService";
import UserApiService from "services/UserApiService";
import ScoreApiService from "../../services/ScoreApiService";
import PartnerApiService from "services/PartnerApiService";
import PaymentApiService from "services/PaymentApiService";

export const fetchAlign = {
  key: QueryKeys.fetchAlign,
  fn: AlignApiService.getAlign,
};

export const fetchAlignResult = {
  key: QueryKeys.fetchAlignResult,
  fn: AlignApiService.getResult,
};

export const fetchImpact = {
  key: QueryKeys.fetchImpact,
  fn: ImpactApiService.getImpact,
};

export const fetchGrow = {
  key: QueryKeys.fetchGrow,
  fn: GrowApiService.getGrow,
};

export const fetchScore = {
  key: QueryKeys.fetchScore,
  fn: ScoreApiService.getScore,
};

export const fetchPulse = {
  key: QueryKeys.fetchPulse,
  fn: ScoreApiService.getPulse,
};

export const fetchScoreResult = {
  key: QueryKeys.fetchScoreResult,
  fn: ScoreApiService.getResult,
};

export const fetchProfile = {
  key: QueryKeys.fetchProfile,
  fn: UserApiService.fetchProfile,
};

export const fetchSuperAdminUserDetail = {
  key: QueryKeys.fetchSuperAdminUserDetail,
  fn: SuperAdminApiService.getUserDetail,
};

export const fetchPartnerUserDetail = {
  key: QueryKeys.fetchPartnerUserDetail,
  fn: PartnerApiService.getUserDetail,
};

export const fetchGrowResult = {
  key: QueryKeys.fetchGrowResult,
  fn: GrowApiService.getResult,
};

export const fetchPartnerProfile = {
  key: QueryKeys.fetchPartnerProfile,
  fn: PartnerApiService.fetchPartnerProfile,
};

export const fetchSurveysResult = {
  key: QueryKeys.fetchSurveysResult,
  fn: CommonApiService.getSurveysResult,
};

export const fetchPaymentConfig = {
  key: QueryKeys.fetchPaymentConfig,
  fn: PaymentApiService.getConfig,
};

export const fetchEmotionalIntl = {
  key: QueryKeys.fetchEmotionalIntl,
  fn: ScoreApiService.getEmotionalIntl,
};
