import { CardElement } from "@stripe/react-stripe-js";
import * as stripeJs from "@stripe/stripe-js";
import {
  getGbCardInputStyles,
  useGBCardInputStyles,
} from "./styles/GBCardInput.styles";
import cx from "classnames";
import { useState } from "react";
import { OnChangeEventType } from "./GBCardInput.types";

type GBCardInputProps = {
  onChange?: (event: OnChangeEventType) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  options?: stripeJs.StripeCardElementOptions;
  className?: string;
  error?: boolean;
  disabled?: boolean;
};

const getGbCardInputOptions = (
  error: boolean,
): stripeJs.StripeCardElementOptions => ({
  style: getGbCardInputStyles(error),
  hidePostalCode: true,
});

function GBCardInput(props: GBCardInputProps) {
  const { className, onChange, error, disabled, ...restProps } = props;
  const classes = useGBCardInputStyles();
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isCompleted, setIsCompleted] = useState<boolean>(false);

  const rootClasses = cx(
    classes.root,
    {
      [classes.focused]: isFocused,
      [classes.notFocused]: !isFocused && !isError && !error,
      [classes.error]: isError || error,
      [classes.completed]: isCompleted,
      [classes.disabled]: disabled,
    },
    className,
  );

  const _onFocus = () => {
    setIsFocused(true);
  };

  const _onBlur = () => {
    setIsFocused(false);
  };

  const _onChange = (event: OnChangeEventType) => {
    if (onChange) {
      onChange(event);
    }
    setIsError(!!event.error);
    setIsCompleted(event.complete);
  };

  return (
    <div className={rootClasses}>
      <CardElement
        onFocus={_onFocus}
        onBlur={_onBlur}
        onChange={_onChange}
        options={getGbCardInputOptions(!!error)}
        {...restProps}
      />
    </div>
  );
}

export default GBCardInput;
