import { Route, Switch } from "react-router";
import {
  generalRoutes,
  MENU_MATCH_PARAMS,
  exerciseRoutes,
} from "constants/generalRoutes";
import NotFound from "pages/NotFound/NotFound";
import GBRoute from "components/GBRoute/GBRoute";
import MainMenu from "pages/MainMenu/MainMenu";
import ScoreMenu from "pages/Menus/ScoreMenu";
import AlignMenu from "pages/Menus/AlignMenu";
import ImpactMenu from "pages/Menus/ImpactMenu";
import Snapshot from "pages/Snapshot/Snapshot";
import AdminLayout from "layouts/AdminLayout/AdminLayout";
import AdminUsers from "pages/Admin/AdminUsers/AdminUsers";
import AdminFeedbacks from "pages/Admin/AdminFeedbacks/AdminFeedbacks";
import AdminProfile from "pages/Admin/AdminProfile/AdminProfile";
import AdminUserSnapshot from "pages/Admin/AdminUserSnapshot/AdminUserSnapshot";
import AdminPartners from "pages/Admin/AdminPartners/AdminPartners";
import PartnerLogin from "pages/PartnerLogin/PartnerLogin";
import PartnerSignup from "pages/PartnerSignup/PartnerSignup";
import ForgotPassword from "pages/ForgotPassword/ForgotPassword";
import AuthLayout from "layouts/AuthLayout/AuthLayout";
import ResetPassword from "pages/ResetPassword/ResetPassword";
import ExerciseLayout from "layouts/ExerciseLayout/ExerciseLayout";
import Exercise from "pages/Exercises/Exercise";
import PurposeScoreGuest from "pages/PurposeScoreGuest/PurposeScoreGuest";
import { useSelector } from "react-redux";
import { RootState } from "store";

export default function Routes() {
  const auth = useSelector((state: RootState) => state.auth);
  return (
    <Switch>
      {/* Superadmin Routes */}

      <GBRoute
        exact
        superAdminRoute
        path={generalRoutes.SUPERADMIN_PROFILE}
        component={AdminProfile}
        layout={AdminLayout}
      />
      <GBRoute
        exact
        superAdminRoute
        path={generalRoutes.SUPERADMIN_PARTNERS}
        component={AdminPartners}
        layout={AdminLayout}
      />
      <GBRoute
        exact
        superAdminRoute
        path={generalRoutes.SUPERADMIN_USERS}
        component={AdminUsers}
        layout={AdminLayout}
      />
      <GBRoute
        exact
        superAdminRoute
        path={generalRoutes.SUPERADMIN_FEEDBACKS}
        component={AdminFeedbacks}
        layout={AdminLayout}
      />
      <GBRoute
        exact
        superAdminRoute
        path={generalRoutes.SUPERADMIN_USER_SNAPSHOT}
        component={AdminUserSnapshot}
        layout={AdminLayout}
      />

      {/* Partner Routes */}

      <GBRoute
        exact
        partnerRoute
        path={generalRoutes.PARTNER_PROFILE}
        component={AdminProfile}
        layout={AdminLayout}
      />
      <GBRoute
        exact
        payedPartnerRoute
        path={generalRoutes.PARTNER_USERS}
        component={AdminUsers}
        layout={AdminLayout}
      />
      <GBRoute
        exact
        payedPartnerRoute
        path={generalRoutes.PARTNER_USER_SNAPSHOT}
        component={AdminUserSnapshot}
        layout={AdminLayout}
      />

      {/* Exercise Menus */}
      <GBRoute
        exact
        path={generalRoutes.USER_MENU}
        component={MainMenu}
        layout={ExerciseLayout}
      />
      {/* Score */}
      <GBRoute
        exact
        path={generalRoutes.SCORE_MENU}
        component={ScoreMenu}
        layout={ExerciseLayout}
      />
      {/* <GBRoute
        exact
        path={`${generalRoutes.SCORE_MENU}${exerciseRoutes.PURPOSE_SCORE}`}
        component={ScoreMenu}
        layout={ExerciseLayout}
      /> */}
      <GBRoute
        exact
        path={`${generalRoutes.SCORE_MENU}${exerciseRoutes.PURPOSE_SCORE}`}
        component={PurposeScoreGuest}
        layout={ExerciseLayout}
      />

      <GBRoute
        exact
        path={generalRoutes.ALIGN_MENU}
        component={AlignMenu}
        layout={ExerciseLayout}
      />
      <GBRoute
        exact
        path={generalRoutes.IMPACT_MENU}
        component={ImpactMenu}
        layout={ExerciseLayout}
      />
      {/* COMING SOON */}
      {/* <GBRoute
        exact
        path={generalRoutes.GROW_MENU}
        component={GrowMenu}
        layout={ExerciseLayout}
      />
      <GBRoute
        exact
        path={generalRoutes.ENGAGE_MENU}
        component={EngageMenu}
        layout={ExerciseLayout}
      /> */}

      {/* Exercise Routes */}

      <GBRoute
        exact
        path={[
          `${generalRoutes.SCORE_MENU}${MENU_MATCH_PARAMS}`,
          `${generalRoutes.ALIGN_MENU}${MENU_MATCH_PARAMS}`,
          `${generalRoutes.IMPACT_MENU}${MENU_MATCH_PARAMS}`,
          `${generalRoutes.GROW_MENU}${MENU_MATCH_PARAMS}`,
          `${generalRoutes.ENGAGE_MENU}${MENU_MATCH_PARAMS}`,
        ]}
        component={Exercise}
        layout={ExerciseLayout}
      />
      {/* <GBRoute
        exact
        path={[`${generalRoutes.SCORE_MENU}/purpose-pulse/:token`]}
        component={Exercise}
        layout={ExerciseLayout}
      /> */}
      <GBRoute
        privateRoute
        layout={ExerciseLayout}
        exact
        path={generalRoutes.SNAPSHOT}
        component={Snapshot}
      />

      {/* Common routes */}
      <GBRoute
        exact
        path={generalRoutes.PARTNER_LOGIN}
        component={PartnerLogin}
        layout={AuthLayout}
      />
      <GBRoute
        exact
        path={generalRoutes.PARTNER_SIGNUP}
        component={PartnerSignup}
        layout={AuthLayout}
      />
      <GBRoute
        exact
        path={generalRoutes.FORGOT_PASSWORD}
        component={ForgotPassword}
        layout={AuthLayout}
      />
      <GBRoute
        exact
        path={generalRoutes.RESET_PASSWORD}
        component={ResetPassword}
        layout={AuthLayout}
      />
      <Route path="*" component={NotFound} />
    </Switch>
  );
}
