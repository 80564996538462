import { useTranslation } from "react-i18next";
import { Namespaces } from "i18n";
import { useFormik } from "formik";
import { useScoreFormStyles } from "./styles/ScoreForm.styles";
import GBTimeIcon from "components/svg-icons/GBTimeIcon";
import { ScoreFormValues } from "types/score/ScoreForm.types";
import {
  scoreFormValidationSchema,
  scoreQuizFields,
  scoreQuizFieldSettings,
} from "constants/purposeScoreTypes";
import FormRow from "components/FormRow/FormRow";
import GBStaticInputLabel from "components/GBStaticInputLabel/GBStaticInputLabel";
// import { ValidationRule } from "constants/validationRules";
import RangeQuizField from "components/RangeQuizField/RangeQuizField";
import StageStepFrame from "components/StageStepFrame/StageStepFrame";
import StageStepButton from "components/StageStepButton/StageStepButton";
import { ApiErrorKey } from "constants/api/apiErrors";
import GBCircularProgress from "components/GBCircularProgress/GBCircularProgress";
import UnexpectedError from "components/UnexpectedError/UnexpectedError";
import GBSpin from "components/GBSpin/GBSpin";
import { generalRoutes } from "constants/generalRoutes";

type ScoreFormViewProps = {
  onValueChange: (key: string, value: string) => void;
  initialValues: ScoreFormValues;
  isFetching: boolean;
  isFetchError: boolean;
  getApiError: () => string | undefined;
  getFormError: () => string | undefined;
  setFormError: (code: string | null) => void;
  setApiError: (code: ApiErrorKey | null) => void;
  onStepNext: () => void;
};

function ScoreFormView(props: ScoreFormViewProps) {
  const {
    isFetching,
    isFetchError,
    getApiError,
    getFormError,
    setFormError,
    setApiError,
    onValueChange,
    initialValues,
    onStepNext,
  } = props;

  const { t } = useTranslation([
    Namespaces.Score,
    Namespaces.Survey,
    Namespaces.Common,
  ]);
  const classes = useScoreFormStyles();

  const formik = useFormik<ScoreFormValues>({
    validationSchema: scoreFormValidationSchema,
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: () => handleNextClick(),
  });

  const {
    values,
    handleSubmit,
    touched,
    errors,
    setFieldValue,
    // setFieldTouched,
    // validateField,
  } = formik;

  const hasError = (name: keyof ScoreFormValues): boolean => {
    return !!(touched[name] && errors[name]);
  };

  // const getError = (
  //   field: keyof ScoreFormValues,
  //   errorCode: ValidationRule,
  // ) => {
  //   return t([
  //     `score.form.fields.${field}.errors.${errorCode}`,
  //     `${Namespaces.Common}:errors.${errorCode}`,
  //   ]);
  // };

  const handleNextClick = () => {
    if (formIsValid()) {
      setApiError(null);
      setFormError(null);
      onStepNext();
    }
  };

  // const handleBlurCustom = (field: string, value: string) => {
  //   setFieldTouched(field);
  //   validateField(field);
  //   onValueChange(field, value);
  // };

  const handleChangeSave = (i: string, value: number) => {
    setFieldValue(i, value);
    onValueChange(i, value.toString());
  };

  const formIsValid = () => {
    return Object.keys(errors).length === 0;
  };

  return (
    <StageStepFrame
      title={t("score.form.steps.title")}
      controls={[
        <StageStepButton.Back goTo={generalRoutes.SCORE_MENU}>
          {t(`${Namespaces.Survey}:buttons.back_home`)}
        </StageStepButton.Back>,
        <StageStepButton.Next
          onClick={() => formik.handleSubmit()}
          disabled={isFetching || !formIsValid()}
        >
          {t(`${Namespaces.Score}:score.form.buttons.submit`)}
        </StageStepButton.Next>,
      ]}
      error={getApiError() || getFormError()}
      childrenClassName={classes.children}
    >
      <div className={classes.subtitle}>
        {t("score.form.steps.subtitle")}
        hi
      </div>
      <div className={classes.time}>
        <GBTimeIcon className={classes.timeIcon} />
        {t("score.form.steps.time")}
      </div>
      {isFetching ? (
        <GBCircularProgress keepHeight="848px" />
      ) : isFetchError ? (
        <UnexpectedError />
      ) : (
        <GBSpin spinning={isFetching}>
          <form onSubmit={handleSubmit} className={classes.wrapper}>
            <div>
              {scoreQuizFields.map((i, k) => (
                <FormRow key={k} fullWidth className={classes.quizFormRow}>
                  <GBStaticInputLabel
                    labelFor={i}
                    variant="small"
                    text={t(`score.form.fields.${i}.label`)}
                  />
                  <RangeQuizField
                    fullWidth
                    id={i}
                    name={i}
                    value={values[i]}
                    from={scoreQuizFieldSettings.smallest}
                    to={scoreQuizFieldSettings.highest}
                    error={hasError(i)}
                    fromText={t(`score.form.fields.${i}.smallest`)}
                    toText={t(`score.form.fields.${i}.highest`)}
                    onChange={(value: number) => handleChangeSave(i, value)}
                  />
                </FormRow>
              ))}
            </div>
          </form>
        </GBSpin>
      )}
    </StageStepFrame>
  );
}
export default ScoreFormView;
