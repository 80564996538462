import React from "react";
import { SvgIcon } from "@material-ui/core";

function LinkedinIcon(props: React.ComponentProps<typeof SvgIcon>) {
  return (
    <SvgIcon {...props} viewBox="0 0 32 32">
      <path d="M0 0v32h32v-32h-32zM10.133 26.667h-4.48v-14.187h4.373v14.187zM7.893 10.453c-1.387 0-2.56-1.173-2.56-2.56s1.173-2.56 2.56-2.56c1.387 0 2.56 1.173 2.56 2.56s-1.173 2.56-2.56 2.56zM26.667 26.667h-4.373v-6.933c0-1.6 0-3.733-2.347-3.733s-2.667 1.813-2.667 3.627v7.040h-4.373v-14.187h4.267v1.92h0.107c0.533-1.067 2.027-2.347 4.16-2.347 4.48 0 5.333 2.987 5.333 6.72v7.893z"></path>
    </SvgIcon>
  );
}

export default LinkedinIcon;
