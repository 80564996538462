import {
  PurposeFormValues,
  scoreFormQuizField,
  ScoreFormValues,
} from "types/score/ScoreForm.types";
import {
  pulseFormQuizField,
  PulseFormValues,
} from "types/score/PulseForm.types";
import * as Yup from "yup";
import { ValidationRule } from "./validationRules";
import { DNAFormValues, IdentityVerb } from "types/score/DNA";

export enum PurposeScoreTypesI18nKey {
  Mentor = "mentor_other",
  NeedPush = "need_push",
  NeedNudge = "need_nudge",
  Emergency = "emergency",
}
export const scoreQuizFields = Object.values(scoreFormQuizField);

export const scoreQuizFieldSettings = {
  init: 0,
  smallest: 1,
  highest: 6,
};

export const pulseQuizFields = shuffle(Object.values(pulseFormQuizField));

function shuffle<T>(array: T[]): T[] {
  var currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

export const pulseQuizFieldSettings = {
  init: 0,
  smallest: 1,
  highest: 6,
};

export const scoreFormInitEmptyValues: ScoreFormValues = {
  name: "",
  ageRange: "",
  email: "",
  day: scoreQuizFieldSettings.init,
  life: scoreQuizFieldSettings.init,
  lifeIncludes: scoreQuizFieldSettings.init,
  personalExistence: scoreQuizFieldSettings.init,
  goals: scoreQuizFieldSettings.init,
  dreams: scoreQuizFieldSettings.init,
  legacy: scoreQuizFieldSettings.init,
  lifeControl: scoreQuizFieldSettings.init,
  currentMissions: scoreQuizFieldSettings.init,
  purpose: scoreQuizFieldSettings.init,
};

export const purposeDNAEmptyValues: DNAFormValues = {
  identities: [],
  impacts: [],
};

export const pulseFormInitEmptyValues: PulseFormValues = {
  EE1P: scoreQuizFieldSettings.init,
  EE2N: scoreQuizFieldSettings.init,
  EE3P: scoreQuizFieldSettings.init,
  EE4N: scoreQuizFieldSettings.init,
  EM1P: scoreQuizFieldSettings.init,
  EM2N: scoreQuizFieldSettings.init,
  EM3P: scoreQuizFieldSettings.init,
  EM4N: scoreQuizFieldSettings.init,
  EP1P: scoreQuizFieldSettings.init,
  EP2N: scoreQuizFieldSettings.init,
  EP3P: scoreQuizFieldSettings.init,
  EP4N: scoreQuizFieldSettings.init,
  ER1P: scoreQuizFieldSettings.init,
  ER2N: scoreQuizFieldSettings.init,
  ER3P: scoreQuizFieldSettings.init,
  ER4N: scoreQuizFieldSettings.init,
  FI1P: scoreQuizFieldSettings.init,
  FI2N: scoreQuizFieldSettings.init,
  FI3P: scoreQuizFieldSettings.init,
  FI4N: scoreQuizFieldSettings.init,
  FO1P: scoreQuizFieldSettings.init,
  FO2N: scoreQuizFieldSettings.init,
  FO3P: scoreQuizFieldSettings.init,
  FO4N: scoreQuizFieldSettings.init,
  FS1P: scoreQuizFieldSettings.init,
  FS2N: scoreQuizFieldSettings.init,
  FS3P: scoreQuizFieldSettings.init,
  FS4N: scoreQuizFieldSettings.init,
  FW1P: scoreQuizFieldSettings.init,
  FW2N: scoreQuizFieldSettings.init,
  FW3P: scoreQuizFieldSettings.init,
  FW4N: scoreQuizFieldSettings.init,
  IJ1P: scoreQuizFieldSettings.init,
  IJ2N: scoreQuizFieldSettings.init,
  IJ3P: scoreQuizFieldSettings.init,
  IJ4N: scoreQuizFieldSettings.init,
  IC1P: scoreQuizFieldSettings.init,
  IC2N: scoreQuizFieldSettings.init,
  IC3P: scoreQuizFieldSettings.init,
  IC4N: scoreQuizFieldSettings.init,
  IA1P: scoreQuizFieldSettings.init,
  IA2N: scoreQuizFieldSettings.init,
  IA3P: scoreQuizFieldSettings.init,
  IA4N: scoreQuizFieldSettings.init,
  IR1P: scoreQuizFieldSettings.init,
  IR2N: scoreQuizFieldSettings.init,
  IR3P: scoreQuizFieldSettings.init,
  IR4N: scoreQuizFieldSettings.init,
  LG1P: scoreQuizFieldSettings.init,
  LG2N: scoreQuizFieldSettings.init,
  LG3P: scoreQuizFieldSettings.init,
  LG4N: scoreQuizFieldSettings.init,
  LP1P: scoreQuizFieldSettings.init,
  LP2N: scoreQuizFieldSettings.init,
  LP3P: scoreQuizFieldSettings.init,
  LP4N: scoreQuizFieldSettings.init,
  LS1P: scoreQuizFieldSettings.init,
  LS2N: scoreQuizFieldSettings.init,
  LS3P: scoreQuizFieldSettings.init,
  LS4N: scoreQuizFieldSettings.init,
  LV1P: scoreQuizFieldSettings.init,
  LV2N: scoreQuizFieldSettings.init,
  LV3P: scoreQuizFieldSettings.init,
  LV4N: scoreQuizFieldSettings.init,
  SF1N: scoreQuizFieldSettings.init,
  SF2P: scoreQuizFieldSettings.init,
  SF3P: scoreQuizFieldSettings.init,
  SF4N: scoreQuizFieldSettings.init,
  SM1P: scoreQuizFieldSettings.init,
  SM2N: scoreQuizFieldSettings.init,
  SM3N: scoreQuizFieldSettings.init,
  SM4P: scoreQuizFieldSettings.init,
  SR1P: scoreQuizFieldSettings.init,
  SR2N: scoreQuizFieldSettings.init,
  SR3N: scoreQuizFieldSettings.init,
  SR4P: scoreQuizFieldSettings.init,
  SW1P: scoreQuizFieldSettings.init,
  SW2N: scoreQuizFieldSettings.init,
  SW3P: scoreQuizFieldSettings.init,
  SW4N: scoreQuizFieldSettings.init,
  WG1P: scoreQuizFieldSettings.init,
  WG2N: scoreQuizFieldSettings.init,
  WG3P: scoreQuizFieldSettings.init,
  WG4N: scoreQuizFieldSettings.init,
  WP1P: scoreQuizFieldSettings.init,
  WP2N: scoreQuizFieldSettings.init,
  WP3P: scoreQuizFieldSettings.init,
  WP4N: scoreQuizFieldSettings.init,
  WS1P: scoreQuizFieldSettings.init,
  WS2N: scoreQuizFieldSettings.init,
  WS3P: scoreQuizFieldSettings.init,
  WS4N: scoreQuizFieldSettings.init,
  WV1P: scoreQuizFieldSettings.init,
  WV2N: scoreQuizFieldSettings.init,
  WV3P: scoreQuizFieldSettings.init,
  WV4N: scoreQuizFieldSettings.init,
};

export const scoreFormValidationSchema = Yup.object({
  day: Yup.number()
    .moreThan(scoreQuizFieldSettings.init)
    .required(ValidationRule.Required),
  life: Yup.number()
    .moreThan(scoreQuizFieldSettings.init)
    .required(ValidationRule.Required),
  lifeIncludes: Yup.number()
    .moreThan(scoreQuizFieldSettings.init)
    .required(ValidationRule.Required),
  personalExistence: Yup.number()
    .moreThan(scoreQuizFieldSettings.init)
    .required(ValidationRule.Required),
  goals: Yup.number()
    .moreThan(scoreQuizFieldSettings.init)
    .required(ValidationRule.Required),
  dreams: Yup.number()
    .moreThan(scoreQuizFieldSettings.init)
    .required(ValidationRule.Required),
  legacy: Yup.number()
    .moreThan(scoreQuizFieldSettings.init)
    .required(ValidationRule.Required),
  lifeControl: Yup.number()
    .moreThan(scoreQuizFieldSettings.init)
    .required(ValidationRule.Required),
  currentMissions: Yup.number()
    .moreThan(scoreQuizFieldSettings.init)
    .required(ValidationRule.Required),
  purpose: Yup.number()
    .moreThan(scoreQuizFieldSettings.init)
    .required(ValidationRule.Required),
});

export const pulseFormValidationSchema = Yup.object({
  EE1P: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  EE2N: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  EE3P: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  EE4N: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  EM1P: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  EM2N: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  EM3P: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  EM4N: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  EP1P: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  EP2N: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  EP3P: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  EP4N: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  ER1P: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  ER2N: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  ER3P: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  ER4N: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  FI1P: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  FI2N: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  FI3P: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  FI4N: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  FO1P: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  FO2N: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  FO3P: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  FO4N: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  FS1P: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  FS2N: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  FS3P: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  FS4N: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  FW1P: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  FW2N: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  FW3P: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  FW4N: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  IJ1P: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  IJ2N: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  IJ3P: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  IJ4N: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  IC1P: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  IC2N: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  IC3P: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  IC4N: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  IA1P: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  IA2N: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  IA3P: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  IA4N: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  IR1P: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  IR2N: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  IR3P: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  IR4N: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  LG1P: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  LG2N: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  LG3P: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  LG4N: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  LP1P: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  LP2N: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  LP3P: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  LP4N: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  LS1P: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  LS2N: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  LS3P: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  LS4N: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  LV1P: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  LV2N: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  LV3P: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  LV4N: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  SF1N: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  SF2P: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  SF3P: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  SF4N: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  SM1P: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  SM2N: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  SM3N: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  SM4P: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  SR1P: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  SR2N: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  SR3N: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  SR4P: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  SW1P: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  SW2N: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  SW3P: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  SW4N: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  WG1P: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  WG2N: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  WG3P: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  WG4N: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  WP1P: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  WP2N: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  WP3P: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  WP4N: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  WS1P: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  WS2N: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  WS3P: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  WS4N: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  WV1P: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  WV2N: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  WV3P: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
  WV4N: Yup.number()
    .moreThan(pulseQuizFieldSettings.init)
    .required(ValidationRule.Required),
});

export const prepareScoreInitialValues = (
  fetchedScore?: ScoreFormValues,
  fetchedDNA?: DNAFormValues,
  fetchedPulse?: PulseFormValues,
  isCompleted?: boolean,
): PurposeFormValues => {
  return {
    score: fetchedScore
      ? { ...scoreFormInitEmptyValues, ...fetchedScore }
      : scoreFormInitEmptyValues,
    DNA: fetchedDNA
      ? { ...purposeDNAEmptyValues, ...fetchedDNA }
      : purposeDNAEmptyValues,
    pulse: fetchedPulse
      ? { ...pulseFormInitEmptyValues, ...fetchedPulse }
      : pulseFormInitEmptyValues,
    completed: !!isCompleted,
  };
};

export const prepareIdentitiesInitialValues = (
  fetchedDNA?: DNAFormValues,
): Array<string> => {
  return fetchedDNA?.identities || [];
};

export const prepareVerbsInitialValues = (
  fetchedDNA?: DNAFormValues,
): DNAFormValues => {
  return fetchedDNA || purposeDNAEmptyValues;
};

export const prepareRanksInitialValues = (
  fetchedDNA?: DNAFormValues,
): Array<IdentityVerb> => {
  return fetchedDNA?.impacts || purposeDNAEmptyValues.impacts;
};

export const scoreResultIsValid = (fetchedScore?: ScoreFormValues): boolean => {
  if (fetchedScore) {
    return Object.values(scoreFormQuizField).every((key) => {
      if (fetchedScore.hasOwnProperty(key)) {
        const val = fetchedScore[key];
        if (!val) return false;
      } else {
        return false;
      }
      return true;
    });
  }
  return true;
};

export const prepareScoreResultValue = (
  fetchedScore?: ScoreFormValues,
): number | undefined => {
  if (fetchedScore) {
    let count: number = 0;
    let amount: number = 0;
    Object.values(scoreFormQuizField).forEach((key) => {
      if (fetchedScore.hasOwnProperty(key)) {
        const val = fetchedScore[key];
        if (!val) return;
        amount += val;
        count += 1;
      } else {
        return;
      }
    });
    if (count) {
      return amount / count;
    }
  }
};

export const prepareDNAResultValue = (
  fetchedDNA?: DNAFormValues,
): { identities: Array<string>; impacts: Array<IdentityVerb> } => {
  if (fetchedDNA) {
    return {
      identities: fetchedDNA.identities,
      impacts: fetchedDNA.impacts
        .sort((a, b) => (a.rank < b.rank ? -1 : 1))
        .slice(0, 3)
        .map((impact) => impact),
    };
  }
  return {
    identities: [],
    impacts: [],
  };
};

export const preparePulseInitialValues = (
  fetchedPulse?: PulseFormValues,
): PulseFormValues => {
  return { ...pulseFormInitEmptyValues, ...fetchedPulse };
};

export enum pulseReportField {
  // SW = "Work Environemnt",
  // SR = "Close Relationships",
  // SF = "Financial Satisfaction",
  // SM = "Mental Wellbeing",
  // EM = "Mental Energy",
  // EE = "Emotional Energy",
  // ER = "Relational Energy",
  // EP = "Physical Energy",
  // WS = "Strengths @ Work",
  // WP = "Passion @ Work",
  // WV = "Values @ Work",
  // WG = "Goals @ Work",
  // LS = "Strengths in Life",
  // LP = "Passion in Life",
  // LV = "Values in Life",
  // LG = "Goals in Life",
  // FS = "Fulfillment - Relationships",
  // FI = "Fulfillment - Missions",
  // FO = "Fulfillment - Moments",
  // FW = "Fulfillment - Rewards",
  // IA = "Impact in Family",
  // IR = "Impact with Friends",
  // IJ = "Impact in Career",
  // IC = "Community/Cause",
  SW = "SW",
  SR = "SR",
  SF = "SF",
  SM = "SM",
  EM = "EM",
  EE = "EE",
  ER = "ER",
  EP = "EP",
  WS = "WS",
  WP = "WP",
  WV = "WV",
  WG = "WG",
  LS = "LS",
  LP = "LP",
  LV = "LV",
  LG = "LG",
  FS = "FS",
  FI = "FI",
  FO = "FO",
  FW = "FW",
  IA = "IA",
  IR = "IR",
  IJ = "IJ",
  IC = "IC",
}

export const pulseReportIsValid = (fetchedPulse?: PulseFormValues): boolean => {
  if (fetchedPulse) {
    return Object.values(pulseFormQuizField).every((key) => {
      if (fetchedPulse.hasOwnProperty(key)) {
        const val = fetchedPulse[key];
        if (!val) return false;
      } else {
        return false;
      }
      return true;
    });
  }
  return true;
};

export type pulseResult = {
  [index: string]: string | number | undefined;
  title: string;
  description?: string;
  score: number;
};

export type pulseReport = Record<pulseReportField, pulseResult> & {};

export const preparePulseReport = (
  fetchedPulse?: PulseFormValues,
): object | undefined => {
  if (fetchedPulse) {
    let results: pulseReport;
    Object.values(pulseFormQuizField).forEach((key) => {
      if (fetchedPulse.hasOwnProperty(key)) {
        const val = fetchedPulse[key];
        if (!val) {
          return;
        }
      } else {
        return;
      }
    });
    results = {
      SW: {
        title: "Work Environment",
        description: "Am I valued and understood at work?",
        score:
          fetchedPulse["SW1P"] +
          fetchedPulse["SW3P"] +
          Math.abs(fetchedPulse["SW2N"] + fetchedPulse["SW4N"] - 14),
      },
      SR: {
        title: "Close Relationships",
        description: "Do my close relationships reduce or produce stress?",
        score:
          fetchedPulse["SR1P"] +
          fetchedPulse["SR4P"] +
          Math.abs(fetchedPulse["SR2N"] + fetchedPulse["SR3N"] - 14),
      },
      SF: {
        title: "Financial Satisfaction",
        description: "Does my income meet my lifestyle needs?",
        score:
          fetchedPulse["SF2P"] +
          fetchedPulse["SF3P"] +
          Math.abs(fetchedPulse["SF1N"] + fetchedPulse["SF4N"] - 14),
      },
      SM: {
        title: "Mental Well-being",
        description: "Am I confident in my decisions?",
        score:
          fetchedPulse["SM1P"] +
          fetchedPulse["SM4P"] +
          Math.abs(fetchedPulse["SM2N"] + fetchedPulse["SM3N"] - 14),
      },
      EM: {
        title: "Mental Energy",
        description: "Do I have energy to make decisions through the day?",
        score:
          fetchedPulse["EM1P"] +
          fetchedPulse["EM3P"] +
          Math.abs(fetchedPulse["EM2N"] + fetchedPulse["EM4N"] - 14),
      },
      EE: {
        title: "Emotional Energy",
        description: "Do I waste energy on events beyond my control?",
        score:
          fetchedPulse["EE1P"] +
          fetchedPulse["EE3P"] +
          Math.abs(fetchedPulse["EE2N"] + fetchedPulse["EE4N"] - 14),
      },
      ER: {
        title: "Relational Energy",
        description: "Do my interactions charge or drain my battery?",
        score:
          fetchedPulse["ER1P"] +
          fetchedPulse["ER3P"] +
          Math.abs(fetchedPulse["ER2N"] + fetchedPulse["ER4N"] - 14),
      },
      EP: {
        title: "Physical Energy",
        description: "Do I have the energy to perform with excellence?",
        score:
          fetchedPulse["EP1P"] +
          fetchedPulse["EP3P"] +
          Math.abs(fetchedPulse["EP2N"] + fetchedPulse["EP4N"] - 14),
      },
      WS: {
        title: "Strengths at Work",
        description: "Am I using my strengths and talents at work?",
        score:
          fetchedPulse["WS1P"] +
          fetchedPulse["WS3P"] +
          Math.abs(fetchedPulse["WS2N"] + fetchedPulse["WS4N"] - 14),
      },
      WP: {
        title: "Passion at Work",
        description: "Do my passions align with my responsibilities at work?",
        score:
          fetchedPulse["WP1P"] +
          fetchedPulse["WP3P"] +
          Math.abs(fetchedPulse["WP2N"] + fetchedPulse["WP4N"] - 14),
      },
      WV: {
        title: "Values at Work",
        description: "Do my personal values support my company's values?",
        score:
          fetchedPulse["WV1P"] +
          fetchedPulse["WV3P"] +
          Math.abs(fetchedPulse["WV2N"] + fetchedPulse["WV4N"] - 14),
      },
      WG: {
        title: "Goals at Work",
        description:
          "Are my personal goals aligned with my organizations goals?",
        score:
          fetchedPulse["WG1P"] +
          fetchedPulse["WG3P"] +
          Math.abs(fetchedPulse["WG2N"] + fetchedPulse["WG4N"] - 14),
      },
      LS: {
        title: "Strengths in Life",
        description: "Do I use my natural talents in my day-to-day life?",
        score:
          fetchedPulse["LS1P"] +
          fetchedPulse["LS3P"] +
          Math.abs(fetchedPulse["LS2N"] + fetchedPulse["LS4N"] - 14),
      },
      LP: {
        title: "Passion in Life",
        description: "Are my passions aligned with my daily responsiblities?",
        score:
          fetchedPulse["LP1P"] +
          fetchedPulse["LP3P"] +
          Math.abs(fetchedPulse["LP2N"] + fetchedPulse["LP4N"] - 14),
      },
      LV: {
        title: "Values in Life",
        description:
          "Are my values aligning with the values of those people close to me?",
        score:
          fetchedPulse["LV1P"] +
          fetchedPulse["LV3P"] +
          Math.abs(fetchedPulse["LV2N"] + fetchedPulse["LV4N"] - 14),
      },
      LG: {
        title: "Goals in Life",
        description: "Do my personal goals align with those closest to me?",
        score:
          fetchedPulse["LG1P"] +
          fetchedPulse["LG3P"] +
          Math.abs(fetchedPulse["LG2N"] + fetchedPulse["LG4N"] - 14),
      },
      FS: {
        title: "Fulfillment - Relationships",
        description: "Do my closest relationships add value to my life?",
        score:
          fetchedPulse["FS1P"] +
          fetchedPulse["FS3P"] +
          Math.abs(fetchedPulse["FS2N"] + fetchedPulse["FS4N"] - 14),
      },
      FI: {
        title: "Fulfillment - Missions",
        description: "Are my daily responsiblities meaningful and fulfilling?",
        score:
          fetchedPulse["FI1P"] +
          fetchedPulse["FI3P"] +
          Math.abs(fetchedPulse["FI2N"] + fetchedPulse["FI4N"] - 14),
      },
      FO: {
        title: "Fulfillment - Moments",
        description: "Do I experience enough meaningful moments in my life?",
        score:
          fetchedPulse["FO1P"] +
          fetchedPulse["FO3P"] +
          Math.abs(fetchedPulse["FO2N"] + fetchedPulse["FO4N"] - 14),
      },
      FW: {
        title: "Fulfillment - Rewards",
        description: "Do I make a meaningful difference in other's lives?",
        score:
          fetchedPulse["FW1P"] +
          fetchedPulse["FW3P"] +
          Math.abs(fetchedPulse["FW2N"] + fetchedPulse["FW4N"] - 14),
      },
      IA: {
        title: "Impact in Family",
        description: "Will what I am doing impact the future of my family?",
        score:
          fetchedPulse["IA1P"] +
          fetchedPulse["IA3P"] +
          Math.abs(fetchedPulse["IA2N"] + fetchedPulse["IA4N"] - 14),
      },
      IR: {
        title: "Impact with Friends",
        description: "Am I making a difference in my close friends' lives?",
        score:
          fetchedPulse["IR1P"] +
          fetchedPulse["IR3P"] +
          Math.abs(fetchedPulse["IR2N"] + fetchedPulse["IR4N"] - 14),
      },
      IJ: {
        title: "Impact in Career",
        description:
          "Is my work making a meaningful difference in others' lives?",
        score:
          fetchedPulse["IJ1P"] +
          fetchedPulse["IJ3P"] +
          Math.abs(fetchedPulse["IJ2N"] + fetchedPulse["IJ4N"] - 14),
      },
      IC: {
        title: "Community/Cause",
        description: "Does the time I spend volunteering impact others' lives?",
        score:
          fetchedPulse["IC1P"] +
          fetchedPulse["IC3P"] +
          Math.abs(fetchedPulse["IC2N"] + fetchedPulse["IC4N"] - 14),
      },
    };
    if (results) {
      return results;
    }
  }
};
