import { useGBDividerStyles } from "./styles/GBDividerStyles";
import cx from "classnames";

type GBDividerProps = {
  className?: string;
  disableMargins?: boolean;
};

function GBDivider(props: GBDividerProps) {
  const { className, disableMargins } = props;
  const classes = useGBDividerStyles();

  const rootClasses = cx(
    classes.root,
    {
      [classes.disableMargins]: disableMargins,
    },
    className,
  );

  return <hr className={rootClasses} />;
}

export default GBDivider;
