import { useMutation } from "react-query";
import ImpactApiService from "services/ImpactApiService";
import { ImpactResponsePayload } from "types/impact/GetImpactResponsePayload";
import { ImpactPromise } from "types/impact/ImpactPromise";
import { ImpactPurpose } from "types/impact/ImpactPurpose";

const useUpdateImpact = () => {
  return useMutation(
    ({
      completed,
      purposeFormData,
      promiseFormData,
      impact,
    }: {
      completed?: boolean;
      purposeFormData?: Partial<ImpactPurpose>;
      promiseFormData?: Partial<ImpactPromise>;
      impact?: ImpactResponsePayload;
    }) => {
      return ImpactApiService.postImpact({
        lifePurpose: {
          completed: impact?.lifePurpose.completed || false,
          year: impact?.lifePurpose.year || "",
          city: impact?.lifePurpose.city || "",
          familyClass: impact?.lifePurpose.familyClass || "",
          familyRelations: impact?.lifePurpose.familyRelations || "",
          immediateFamily: impact?.lifePurpose.immediateFamily || "",
          college: impact?.lifePurpose.college || "",
          major: impact?.lifePurpose.major || "",
          industry: impact?.lifePurpose.industry || "",
          position: impact?.lifePurpose.position || "",
          childLove: impact?.lifePurpose.childLove || "",
          career: impact?.lifePurpose.career || "",
          momInspired: impact?.lifePurpose.momInspired || "",
          momTaught: impact?.lifePurpose.momTaught || "",
          dadInspired: impact?.lifePurpose.dadInspired || "",
          dadTaught: impact?.lifePurpose.dadTaught || "",
          parentsLesson: impact?.lifePurpose.parentsLesson || "",
          adultDecision: impact?.lifePurpose.adultDecision || "",
          adversity: impact?.lifePurpose.adversity || "",
          talent1: impact?.lifePurpose.talent1 || "",
          talent2: impact?.lifePurpose.talent2 || "",
          talent3: impact?.lifePurpose.talent3 || "",
          teachAbout: impact?.lifePurpose.teachAbout || "",
          improveArea: impact?.lifePurpose.improveArea || "",
          mainPassion: impact?.lifePurpose.mainPassion || "",
          passion1: impact?.lifePurpose.passion1 || "",
          passion2: impact?.lifePurpose.passion2 || "",
          passion3: impact?.lifePurpose.passion3 || "",
          peopleHelp: impact?.lifePurpose.peopleHelp || "",
          helpReason: impact?.lifePurpose.helpReason || "",
          purposeVerb: impact?.lifePurpose.purposeVerb || "",
          purposeComponent: impact?.lifePurpose.purposeComponent || "",
          purposeStatement: impact?.lifePurpose.purposeStatement || "",
          purposeOutcome: impact?.lifePurpose.purposeOutcome || "",
          purposeFinal: impact?.lifePurpose.purposeFinal || "",
          feedbackRate: impact?.lifePurpose.feedbackRate || 0,
          teamFeedback: impact?.lifePurpose.teamFeedback || "",
          ...purposeFormData,
        },
        promiseStatements: {
          completed: impact?.promiseStatements.completed || false,
          promiseVerb: impact?.promiseStatements.promiseVerb || "",
          promiseComponent: impact?.promiseStatements.promiseComponent || "",
          finalPromise: impact?.promiseStatements.finalPromise || "",
          ...promiseFormData,
        },
        completed: completed || impact?.completed || false,
      });
    },
    {
      onError: (e) => {
        throw e;
      },
    },
  );
};

export { useUpdateImpact };
