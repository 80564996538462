import { makeStyles, Theme } from "@material-ui/core";
import { commonColors } from "constants/themeSettings";

const useDetailPanelStyles = makeStyles((theme: Theme) => ({
  iconCompleted: {
    fill: commonColors.green,
  },
  icon: {
    fill: commonColors.red,
  },
  list: {
    marginLeft: "10px",
    "&:first-child": {
      marginLeft: "0",
    },
  },
}));

export { useDetailPanelStyles };
