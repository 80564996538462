import { generalRoutes } from "constants/generalRoutes";
import gbLogo from "assets/img/gb-logo.png";
import { useHistory } from "react-router-dom";
import { useAuthLayoutStyles } from "./styles/AuthLayout.styles";
import { useTranslation } from "react-i18next";
import { Namespaces } from "i18n";
import Header from "components/Header/Header";
import { WIDE_CONTAINER_WIDTH } from "constants/settings";
import GBButton from "components/GBButton/GBButton";
import GBContainer from "components/GBContainer/GBContainer";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { useEffect } from "react";

type AuthLayoutProps = {
  children: JSX.Element;
};

function AuthLayout({ children }: AuthLayoutProps) {
  const classes = useAuthLayoutStyles();
  const { t } = useTranslation(Namespaces.Common);
  const history = useHistory();
  const auth = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (auth.user && auth.isAuthenticated) {
      history.push(generalRoutes.USER_MENU);
    }
  }, [auth.isAuthenticated, auth.user, history]);

  const logo = <img src={gbLogo} alt={t("app_name")} />;

  const buttons = [
    <GBButton
      onClick={() => history.push(generalRoutes.PARTNER_LOGIN)}
      size="small"
      variant="contained"
      color="primary"
    >
      {t("header.buttons.partnerLogin")}
    </GBButton>,
  ];

  return (
    <div className={classes.root}>
      <Header
        fixed
        logo={logo}
        buttons={buttons}
        containerWith={WIDE_CONTAINER_WIDTH}
      />
      <GBContainer className={classes.content} width={WIDE_CONTAINER_WIDTH}>
        {children}
      </GBContainer>
    </div>
  );
}

export default AuthLayout;
