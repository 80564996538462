import { ALIGN_SURVEY_THEME_FIELDS_COUNT } from "constants/settings";
import { ValidationRule } from "constants/validationRules";
import { getArrayOfEmptyStrings } from "utils/getArrayOfEmptyStrings";
import * as Yup from "yup";

export const initialArray = getArrayOfEmptyStrings(
  ALIGN_SURVEY_THEME_FIELDS_COUNT,
);

export const alignThemeFormValidationSchema = Yup.object().shape({
  importantTrials: Yup.array().compact().min(1, ValidationRule.AtLeastOne),
  importantTriumphs: Yup.array().compact().min(1, ValidationRule.AtLeastOne),
});
