import { useSurvey } from "components/hooks/useSurvey";
import { ApiErrorKey } from "constants/api/apiErrors";
import { generalRoutes } from "constants/generalRoutes";
import { ValidationRule } from "constants/validationRules";
import { useFormik } from "formik";
import { Namespaces } from "i18n";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router";
import { ApiClientError } from "types/api/ApiClientError";
import { selectApiError } from "utils/api/selectApiError";
import { emotionalIntlFormValidationSchema } from "./EmotionalIntlForm.consts";
import { EmotionalIntlFormValues } from "./EmotionalIntlForm.types";
import EmotionalIntlFormView from "./EmotionalIntlFormView";
import CommonApiService from "services/CommonApiService";

function EmotionalIntlForm() {
  const { t } = useTranslation([Namespaces.Score, Namespaces.Common]);
  const { nextUrl } = useSurvey();
  const history = useHistory();

  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [apiError, setApiError] = useState<ApiErrorKey | null>(null);

  const handleStepNext = () => {
    if (nextUrl) {
      history.push(nextUrl);
    } else {
      history.push(generalRoutes.SCORE_MENU);
    }
  };

  const onSubmit = async (payload: EmotionalIntlFormValues) => {
    try {
      setIsProcessing(true);
      const { data } = await CommonApiService.calculateEmotionalInt(payload);
      localStorage.setItem("eqResultScore", data.payload.score.toString());
      handleStepNext();
    } catch (e) {
      console.error(e);
      setIsProcessing(false);
      const error = e as ApiClientError;
      setApiError(selectApiError(error.response?.data.message));
    }
  };

  const onValueChange = async (
    field: keyof EmotionalIntlFormValues,
    value: number,
  ) => {
    try {
      // await ScoreApiService.putScore({
      //   field,
      //   value,
      //   step: "emotionalIntl",
      // });
      // await queryClient.refetchQueries(QueryKeys.fetchEmotionalIntl);
    } catch (e) {
      console.error(e);
    }
  };

  const formik = useFormik<EmotionalIntlFormValues>({
    validationSchema: emotionalIntlFormValidationSchema,
    enableReinitialize: true,
    initialValues: {} as EmotionalIntlFormValues,
    onSubmit: onSubmit,
  });

  const { touched, errors } = formik;

  const hasError = (name: keyof EmotionalIntlFormValues): boolean => {
    return !!(touched[name] && errors[name]);
  };

  const getError = (
    field: keyof EmotionalIntlFormValues,
    errorCode: ValidationRule,
  ) => {
    return t([
      `emotionalIntl.form.fields.${field}.errors.${errorCode}`,
      `${Namespaces.Common}:errors.${errorCode}`,
      `${Namespaces.Common}:api_errors.unexpected_error`,
    ]);
  };

  return (
    <EmotionalIntlFormView
      isLoading={false}
      isError={false}
      isProcessing={isProcessing}
      onValueChange={onValueChange}
      apiError={apiError}
      hasError={hasError}
      getError={getError}
      {...formik}
    />
  );
}

export default EmotionalIntlForm;
