import { Position } from "../../types/Position";
import React from "react";
import { useUserMenuItemStyles } from "./styles/UserMenuItem.styles";
import { Typography } from "@material-ui/core";
import cx from "classnames";

type UserMenuItemProps = {
  isButton?: boolean;
  icon: React.ComponentType<any>;
  position?: Position;
  className: string;
  title: string;
  description: string;
  onClick?: () => void;
};

function UserMenuItem(props: UserMenuItemProps) {
  const {
    isButton,
    icon: Picture,
    position,
    className,
    title,
    description,
    onClick,
  } = props;

  const classes = useUserMenuItemStyles();
  const outerClassNames = cx(classes.outer, className);
  const titleClassNames = cx("title", classes.title);
  const descriptionClassNames = cx("description", classes.description);

  const RootComponent: React.ElementType = isButton ? "button" : "div";

  return (
    <RootComponent
      onClick={onClick}
      className={outerClassNames}
      style={{
        display: position ? "absolute" : "flex",
        top: position?.top,
        left: position?.left,
      }}
    >
      <Picture key={0} />
      <div className={classes.itemInfo} key={1}>
        <Typography className={titleClassNames}>{title}</Typography>
        <Typography className={descriptionClassNames}>{description}</Typography>
      </div>
    </RootComponent>
  );
}

export default UserMenuItem;
