export enum CalculateScoreQuizField {
  Day = "day",
  Life = "life",
  LifeIncludes = "lifeIncludes",
  PersonalExistence = "personalExistence",
  Goals = "goals",
  Dreams = "dreams",
  Legacy = "legacy",
  LifeControl = "lifeControl",
  CurrentMissions = "currentMissions",
  Purpose = "purpose",
}

export type CalculateScoreFormValues = Record<
  CalculateScoreQuizField,
  number
> & {
  name: string;
  ageRange: string;
  email: string;
};
