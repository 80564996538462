import PulseFormView from "./PulseFormView";
import { useSurvey } from "components/hooks/useSurvey";
import { useCallback, useMemo, useState } from "react";
import { ApiErrorKey } from "constants/api/apiErrors";
import { useHistory } from "react-router";
import { useQuery } from "react-query";
import { fetchPulse } from "utils/api/queryFns";
import { generalRoutes } from "constants/generalRoutes";
import { Namespaces } from "i18n";
import ScoreApiService from "services/ScoreApiService";
import { useTranslation } from "react-i18next";
import {
  preparePulseInitialValues,
  pulseFormInitEmptyValues,
} from "constants/purposeScoreTypes";

function PulseForm() {
  const { t } = useTranslation([
    Namespaces.Align,
    Namespaces.Survey,
    Namespaces.Common,
  ]);

  const { nextUrl } = useSurvey();
  const [apiError, setApiError] = useState<ApiErrorKey | null>(null);
  const [formError, setFormError] = useState<string | null>(null);
  const history = useHistory();

  const handleStepNext = async () => {
    try {
      await ScoreApiService.putPulse({
        token: new URLSearchParams(window.location.search).get("token"),
        field: "completed",
        value: true,
      });
      if (nextUrl) {
        history.push({ pathname: nextUrl, search: history.location.search });
      } else {
        history.push(generalRoutes.SCORE_MENU);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleValueChange = async (key: string, value: string | boolean) => {
    try {
      await ScoreApiService.putPulse({
        token: new URLSearchParams(window.location.search).get("token"),
        field: key,
        value,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const getApiError = useCallback(() => {
    return apiError
      ? t([
          `steps.info.errors.${apiError}`,
          `${Namespaces.Survey}:errors.${apiError}`,
          `${Namespaces.Common}:errors.${apiError}`,
        ])
      : undefined;
  }, [apiError, t]);

  const getFormError = useCallback(() => {
    return formError
      ? t([
          `steps.info.errors.${formError}`,
          `${Namespaces.Survey}:errors.${formError}`,
          `${Namespaces.Common}:errors.${formError}`,
        ])
      : undefined;
  }, [formError, t]);

  return (
    <PulseFormView
      onValueChange={handleValueChange}
      initialValues={pulseFormInitEmptyValues}
      getApiError={getApiError}
      getFormError={getFormError}
      setFormError={setFormError}
      setApiError={setApiError}
      onStepNext={handleStepNext}
    />
  );
}
export default PulseForm;
