import { initialArray } from "./AlignTheme.consts";
import { AlignThemeFormValues } from "./AlignTheme.types";

export const prepareInitialArray = (fetchedData: string[]): string[] => {
  const _data = fetchedData;
  return [
    ..._data,
    ...initialArray.slice(0, initialArray.length - _data.length),
  ];
};

export const prepareInitialValues = (
  fetchedImporntantTrials: string[] | undefined,
  fetchedImportantTriumphs: string[] | undefined,
): AlignThemeFormValues => ({
  importantTrials: fetchedImporntantTrials
    ? prepareInitialArray(fetchedImporntantTrials)
    : initialArray,
  importantTriumphs: fetchedImportantTriumphs
    ? prepareInitialArray(fetchedImportantTriumphs)
    : initialArray,
});
