import { Formik } from "formik";
import { Namespaces } from "i18n";
import { useTranslation } from "react-i18next";
import { alignInfoFormValidationSchema } from "./AlignInfo.consts";
import { AlignInfoFormValues } from "./AlignInfo.types";
import { useAlignInfoStyles } from "./styles/AlignInfo.styles";
import StageStepFrame from "components/StageStepFrame/StageStepFrame";
import StageStepButton from "components/StageStepButton/StageStepButton";
import { useRef } from "react";
import InfoForm from "./components/InfoForm/InfoForm";
import { ApiErrorKey } from "constants/api/apiErrors";
import GBCircularProgress from "components/GBCircularProgress/GBCircularProgress";
import GBSpin from "components/GBSpin/GBSpin";
import UnexpectedError from "components/UnexpectedError/UnexpectedError";

type AlignInfoViewProps = {
  onFormSubmit: (data: AlignInfoFormValues) => void;
  initialValues: AlignInfoFormValues;
  isFetching: boolean;
  isUpdating: boolean;
  isFetchError: boolean;
  prevUrl: string | undefined;
  getApiError: () => string | undefined;
  getFormError: () => string | undefined;
  setFormError: (code: string | null) => void;
  setApiError: (code: ApiErrorKey | null) => void;
};

function AlignInfoView(props: AlignInfoViewProps) {
  const {
    isFetching,
    isUpdating,
    isFetchError,
    prevUrl,
    getApiError,
    getFormError,
    setFormError,
    setApiError,
    onFormSubmit,
    initialValues,
  } = props;

  const { t } = useTranslation([
    Namespaces.Align,
    Namespaces.Survey,
    Namespaces.Common,
  ]);

  const classes = useAlignInfoStyles();
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const handleNextClick = () => {
    setApiError(null);
    setFormError(null);
    submitButtonRef.current?.click();
  };

  return (
    <StageStepFrame
      title={t("steps.title")}
      controls={[
        <StageStepButton.Back
          goTo={prevUrl}
          disabled={isFetching || isUpdating}
        >
          {t(`${Namespaces.Survey}:buttons.back`)}
        </StageStepButton.Back>,
        <StageStepButton.Next
          onClick={handleNextClick}
          disabled={isFetching || isUpdating}
        >
          {t(`${Namespaces.Survey}:buttons.continue`)}
        </StageStepButton.Next>,
      ]}
      error={getApiError() || getFormError()}
    >
      {isFetching ? (
        <GBCircularProgress keepHeight="848px" />
      ) : isFetchError ? (
        <UnexpectedError />
      ) : (
        <GBSpin spinning={isUpdating}>
          <Formik
            className={classes.content}
            initialValues={initialValues}
            onSubmit={onFormSubmit}
            validationSchema={alignInfoFormValidationSchema}
          >
            {(formik) => {
              return (
                <InfoForm
                  setFormError={(code: string | null) => setFormError(code)}
                  submitButtonRef={submitButtonRef}
                  {...formik}
                />
              );
            }}
          </Formik>
        </GBSpin>
      )}
    </StageStepFrame>
  );
}

export default AlignInfoView;
