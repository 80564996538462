import { makeStyles, Theme } from "@material-ui/core/styles";
import { successColor } from "constants/themeSettings";

const useAdminProfileStyles = makeStyles((theme: Theme) => ({
  nameBlock: {
    display: "flex",
    alignItems: "center",
  },
  profileIcon: {
    fontSize: "3rem",
    marginRight: "20px",
    opacity: "0.4",
  },
  infoBlock: {
    paddingTop: "40px",
    paddingLeft: "5px",
  },
  flexBox: {
    display: "flex",
    alignItems: "center",
    fontSize: "20px",
    marginBottom: "20px",
    "& b": {
      marginRight: "10px",
    },
  },
  info: {
    paddingTop: "32px",
  },
  expired: {
    marginBottom: "16px",
  },
  accent: {
    color: theme.palette.primary.main,
  },
  statusBox: {
    display: "flex",
    alignItems: "center",
    "& svg": {
      marginRight: "7px",
    },
  },
  successCheck: {
    fill: successColor.main,
  },
  payStatusBox: {
    display: "flex",
    alignItems: "center",
    "& button": {
      marginLeft: "20px",
      fontSize: "16px",
      fontWeight: 400,
    },
  },
}));

export { useAdminProfileStyles };
