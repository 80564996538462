import LogoutIcon from "components/svg-icons/LogoutIcon";
import { redirectRoutes } from "constants/redirectRoutes";
import { useDispatch } from "react-redux";
import { authActions } from "store/auth/actions";
import { useLogoutButtonStyles } from "./styles/LogoutButton.styles";

function LogoutButton() {
  const classes = useLogoutButtonStyles();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(authActions.logout());
    window.location.href = redirectRoutes.HOME;
  };

  return (
    <button onClick={handleLogout} className={classes.root}>
      <LogoutIcon />
    </button>
  );
}

export default LogoutButton;
