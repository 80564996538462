import { makeStyles, Theme } from "@material-ui/core/styles";
import { primaryColor } from "constants/themeSettings";

const useExerciseLinkStyles = makeStyles((theme: Theme) => ({
  exerciseLink: {
    backgroundColor: "#f0f0f0",
    display: "flex",
    flexDirection: "row",
    padding: "8px 15px",
    borderRadius: "20px",
    minWidth: "200px",
    cursor: "pointer",
    marginBottom: "20px",
    "&:hover": {
      opacity: "90%",
      border: "2px solid lightgray",
    },
  },
  exerciseTitle: {
    padding: "0px",
    margin: "5px",
    color: primaryColor.main,
  },
  exerciseDescription: {
    padding: "0px",
    margin: "5px",
  },
}));

export { useExerciseLinkStyles };
