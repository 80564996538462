import GBCircularProgress from "components/GBCircularProgress/GBCircularProgress";
import HUSpin from "components/GBSpin/GBSpin";
import GBStepsProgress from "components/GBStepsProgress/GBStepsProgress";
import { useSurvey } from "components/hooks/useSurvey";
import StageStepButton from "components/StageStepButton/StageStepButton";
import StageStepFrame from "components/StageStepFrame/StageStepFrame";
import UnexpectedError from "components/UnexpectedError/UnexpectedError";
import { SITUATIONS_STEPS } from "constants/grow/settings";
import { useFormik } from "formik";
import { Namespaces } from "i18n";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import ConflictEndForm from "./components/ConflictEndForm";
import { conflictEndFormValidationSchema } from "./GrowConflictEnd.consts";
import { ConflictEndFormValues } from "./GrowConflictEnd.types";

type GrowConflictEndViewProps = {
  onSubmit: (data: ConflictEndFormValues) => void;
  initialValues: ConflictEndFormValues;
  isFetching: boolean;
  isFetchError: boolean;
  isUpdating: boolean;
  getApiError: () => string | undefined;
};

function GrowConflictEndView(props: GrowConflictEndViewProps) {
  const {
    onSubmit,
    initialValues,
    getApiError,
    isUpdating,
    isFetching,
    isFetchError,
  } = props;
  const { t } = useTranslation([Namespaces.Grow, Namespaces.Survey]);
  const { prevUrl } = useSurvey();

  const formik = useFormik<ConflictEndFormValues>({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: conflictEndFormValidationSchema,
    onSubmit: onSubmit,
  });

  const getFormError = useCallback(() => {
    const errors = Object.values(formik.errors);
    if (errors.length) {
      return t([
        `steps.conflict_end.errors.${errors[0]}`,
        `${Namespaces.Common}:errors.${errors[0]}`,
      ]);
    }
    return undefined;
  }, [formik.errors, t]);

  return (
    <StageStepFrame
      title={t("steps.title")}
      controls={[
        <StageStepButton.Back goTo={prevUrl}>
          {t(`${Namespaces.Survey}:buttons.back`)}
        </StageStepButton.Back>,
        <StageStepButton.Next
          onClick={() => formik.handleSubmit()}
          disabled={isFetching || isUpdating}
        >
          {t(`${Namespaces.Survey}:buttons.continue`)}
        </StageStepButton.Next>,
      ]}
      headingDetails={<GBStepsProgress all={SITUATIONS_STEPS} current={4} />}
      error={getApiError() || getFormError()}
    >
      {isFetching ? (
        <GBCircularProgress keepHeight="1147px" />
      ) : isFetchError ? (
        <UnexpectedError />
      ) : (
        <HUSpin spinning={isUpdating}>
          <ConflictEndForm {...formik} />
        </HUSpin>
      )}
    </StageStepFrame>
  );
}

export default GrowConflictEndView;
